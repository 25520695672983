import React, { useState } from 'react';
import { Button,Modal, Spin, Row, Col, Upload } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { Config } from '../../public/js/config';
import { Base } from '../../public/js/base';
import Message from '../../component/message/message';//提示信息弹框
import PropTypes from 'prop-types';//props传参检查库

const UploadEstateModal = (props) => {

    const [isLoad, setIsLoad] = useState(false);
    const [uploadFileList, setUploadFileList] = useState([]);
    //錯誤信息彈框
    const [messageShow, setMessageShow] = useState(false);
    const [messageStyle, setMessageStyle] = useState("error");
    const [message, setMessage] = useState("");
    const [content, setContent] = useState("");
    const [list, setList] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpen2, setIsModalOpen2] = useState(true);
    const [isSave, setIsSave] = useState(true);
    
    const uploadChange = (e) => {
        messageShowHandler();
        let uploadFileList = [];
        uploadFileList.push(e.file);
        setUploadFileList(uploadFileList);
    }

    const removeFile = () => {
        messageShowHandler();
        setUploadFileList([]);
    }

    const save2 = () => {
        setIsLoad(true);
        if(!isSave){
            return;
        }
        setIsSave(false);
        let model = {
            list:list
        };
        model.menuFuncId = Config.funcInfo.funcId;
        Base.callBackApi("proEstate/uploadExcel", model).then((data) => {
            setIsSave(true);
            if ("SUCCESS" === data.msg) {
                setIsModalOpen2(true)
                props.onClose("success");
            } else {
                errFrameShow("error", data.msg);
                setIsModalOpen(false)
                setIsModalOpen2(true)
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }


    const save = () => {
        if(!isSave){
            return;
        }
        setIsSave(false);
        if(Base.isEmpty(uploadFileList)){
            errFrameShow("error", "请先上传文件！");
            return;
        }
        messageShowHandler();
        let formData = new FormData();
        let file = uploadFileList[0].originFileObj;
        formData.append("files", file);
        let fileName = file.name;
        let header = { authorization: Config.header.authorization }
        formData.append("menuFuncId", Config.funcInfo.funcId);
        setIsLoad(true);
        fetch(Config.apiDownloadServer + "uploadEstate/uploadExcel", {
            method: 'POST',
            body: formData,
            headers: header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob()).then(blob => {
            var filename = "";
            if(fileName.lastIndexOf("xlsx") > -1){
                filename = "屋苑/大厦_错误信息.xlsx"
            }else{
                filename = "屋苑/大厦_错误信息.xls"
            }
            var a = document.createElement('a');
            var url = window.URL.createObjectURL(blob);
            if (blob.type == "application/json") {
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                    setIsSave(true);
                    if ("SUCCESS" === data.msg) {
                        if(Array.isArray(data?.object.duplicateList)){
                            let info = data?.object.duplicateList.join(",");
                            setContent(info);
                            setIsModalOpen(true);
                            setList(data?.object.uploadList);
                            setIsModalOpen2(false)
                            // props.onClose(false);
                        }else{
                            console.log(data)
                            props.onClose("success");
                        }
                    } else {
                        errFrameShow("error", data.msg);
                    }
                }
                reader.readAsText(blob);
            } else {
                a.href = url;
                a.download = filename;
                a.click();
                window.URL.revokeObjectURL(url);
            }
        }).catch(err => ({ err })).finally(() => {
            setIsLoad(false);
        });
    }

    const onCancel = () => {
        setIsModalOpen2(true)
        props.onClose(false);
    }

    const closeFrame = (state) => {
        if(state){
            save2();
        }else{
            setIsModalOpen2(true)
            setIsModalOpen(false)
        }
    }

    /** 生成提示信息 */
    const errFrameShow = (style, msg) => {
        setMessageStyle(style);
        setMessage(msg);
        setMessageShow(true);
    }

    /** 清空提示信息 */
    const messageShowHandler = () => {
        setMessageShow(false);
        setMessage("");
    }

    return(
        <Spin size="large" spinning={isLoad} tip={"数据保存中..."}>
            <Modal
                visible={isModalOpen2}//控制弹框是否显示
                title="上传屋苑/大厦"//标题
                centered//垂直居中
                destroyOnClose={true}//关闭即销毁？
                closable//是否显示右上角的关闭按钮
                maskClosable={false}//点击蒙层是否允许关闭	
                width={Config.equipmentInfo.type === 'phone' ? '100%' : 700}//弹框宽度
                onCancel={onCancel}//点击取消触发事件
                footer={[
                    <Button key='save' type="primary" onClick={save} icon={<CheckCircleOutlined />}>确认</Button>, 
                    <Button key='cancel' className="buttonWhiteStyle" onClick={onCancel} icon={<CloseCircleOutlined />}>取消</Button>
                ]}
            >
                {messageShow ? <Message style={messageStyle} content={message} close={messageShowHandler} /> : ''}
                <div className='sysRow'>
                    <div className='sysCol'>
                        <div className="sysParam mustFill">上传文件</div>
                        <div className="sysInput">
                            <div style={{ float: "left " }}>
                                <Upload
                                    key="uploadFile"
                                    accept={".xlsx,.xls"}
                                    maxCount={1}
                                    fileList={uploadFileList}
                                    onChange={(e) => uploadChange(e)}
                                    showUploadList={false}>
                                    <Button icon={<UploadOutlined />}>点击上传</Button>
                                </Upload>
                            </div>
                            <span style={{ marginLeft: "10px", float: "left", height: "32px", lineHeight: "32px" }}>
                                {Base.isNotEmpty(uploadFileList) ?
                                    <div style={{ display: "flex", maxWidth: "400px" }}>
                                        <div className='fileNameCl' style={{color: "#000" }} >
                                            {uploadFileList[0].name}
                                        </div>
                                        &nbsp;&nbsp;&nbsp;
                                        <DeleteOutlined style={{ display: "inline-flex", alignItems: "center" }} onClick={removeFile} />
                                    </div>
                                : null}
                            </span>
                        </div>
                    </div>
                </div>
            </Modal>
             <Modal
                closable={false}
                className="estateModal"
                open={isModalOpen}
                width={"700px"}    
                onCancel={() => {closeFrame(false)}}
                maskClosable={false}//点击蒙层是否允许关闭	
                footer={[
                    <Button className="sysBtn btnBule" key='save' type="primary" onClick={() => closeFrame(true)} icon={<CheckCircleOutlined />}>确定</Button>,
                    <Button key='cancel' className="sysBtn" onClick={() => closeFrame(false)} icon={<CloseCircleOutlined />}>取消</Button>
                ]}
            >
                <div className="popupFrameCenter">
                    <span className='colorRedModal'>
                        {content} &nbsp;
                    </span>
                    {"重复是否继续上传"}</div>
            </Modal>
        </Spin>
    )

}

UploadEstateModal.propTypes = {
    onClose: PropTypes.func.isRequired,
}

export default UploadEstateModal;