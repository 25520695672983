import React, { useState, useEffect } from 'react';
import { Button, Table, Form, InputNumber, Spin, Select, Input, Tabs, Space } from 'antd';
import { CheckCircleOutlined,HomeOutlined, CloseCircleOutlined, SearchOutlined, RedoOutlined, PlusCircleOutlined, EditOutlined, DeleteOutlined, EyeOutlined, DownloadOutlined } from '@ant-design/icons';
import Message from '../../component/message/message';//提示信息弹框
import PopupFrame from '../../component/popupFrame/popupFrame';//删除弹框
import Audit from '../../component/audit/audit';//审核彈框
import { Config } from '../../public/js/config';
import { Base } from '../../public/js/base';
import { ContextHandler } from '../../public/js/contextHandler';
import context from '../../public/js/context';

const { TabPane } = Tabs;
const { Option } = Select;
const { TextArea } = Input;

const SysParam = (props) => {
    
    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    const [isLoad, setIsLoad] = useState(false);
    const [spinInfo, setSpinInfo] = useState("");
    const [isAudit, setIsAudit] = useState(false);
    const [auditModel, setAuditModel] = useState({});
    const [messageShow, setMessageShow] = useState(false);
    const [messageStyle, setMessageStyle] = useState("");
    const [message, setMessage] = useState("");
    const [tabIndex, setTabIndex] = useState("1");
    const [searchModel, setSearchModel] = useState({});
    const [oldSearchModel, setOldSearchModel] = useState({});
    const [tables, setTables] = useState([]);
    const [tablesCount, setTablesCount] = useState(0);
    const [current, setCurrent] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [checkboxState, setCheckboxState] = useState([]);
    const [isDeleteFrame, setIsDeleteFrame] = useState(false);
    const [flag, setFlag] = useState("");
    const [paramSegmentList, setParamSegmentList] = useState([]);

    useEffect(() => {
        searchParamSegmentList();
    }, []);

    const searchParamSegmentList = () => {
        Base.callBackApi("param/searchSysParamSegment").then((data) => {
            if ("SUCCESS" === data.msg) {
                setParamSegmentList(data.object ?? {});
                console.log(data.object)
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { });
    }

    const searchChange = (value) => {
        messageShowHandler();
        let paramSegmentModel = paramSegmentList.find(item => item.segment == value);
        setSearchModel(model =>{
            return {...model,...{segment :paramSegmentModel?.segment,shortDesc:paramSegmentModel?.shortDesc}};
        });
    }

    const onFinish = (values) => {
        search(pageSize, 1, "N", true);
    }

    const search = (pageSizeNew, currentPageNew, flag, isShowMsg) => {
        if(Config.funcInfo.canSelect === "N") {
            return;
        }
        messageShowHandler();  // 清除提示信息
        clearSelect(); // 清除选中状态
        let model = {};
        if ("Y" === flag) {     // 翻页
            model = oldSearchModel;
        } else {
            model = searchModel;
        }
        model.page = currentPageNew;
        model.pageSize = pageSizeNew;
        setIsLoad(true);
        setSpinInfo("查询中...");
        console.log(model)
        Base.callBackApi("param/search", model).then((data) => {
            if ("SUCCESS" === data.msg) {
                if (!data?.object?.list && isShowMsg) {
                    errFrameShow("info", '找不到符合條件記錄.');
                }
                if("N" === flag) {
                    let paramSegmentModel = paramSegmentList.find(item => item.segment == model.segment);
                    model.parmNam1 = paramSegmentModel?.parmValue1;
                    model.parmNam2 = paramSegmentModel?.parmValue2;
                    model.parmNam3 = paramSegmentModel?.parmValue3;
                    model.parmNam4 = paramSegmentModel?.parmValue4;
                    model.parmNam5 = paramSegmentModel?.parmValue5;
                    model.parmNam6 = paramSegmentModel?.parmValue6;
                    setOldSearchModel(JSON.parse(JSON.stringify(model)));
                }
                setTablesCount(data?.object?.total ?? 0);
                setTables(data?.object?.list ?? []);
                setCurrent(currentPageNew);
                setPageSize(pageSizeNew);
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }
    
    const reset = () => {
        messageShowHandler();
        clearSelect();
        setSearchModel({});
        setOldSearchModel({});
        setTablesCount(0);
        setTables([]);
        setPageSize(10);
    }

    // Tab页切换
    const handleSelect = (key) => {
        if (key) {
            if(key === "2") {
                if(checkboxState.length === 1 && Config.funcInfo.canUpdate === "Y") {
                    edit();
                } else if(Config.funcInfo.canInsert === "Y") {
                    add();
                }
            } else if(tabIndex !== "1") {
                setTabIndex("1");
            }
        }
    }

    const add = () => {
        messageShowHandler();
        form.validateFields(['segment']).then(values => {
            initForm({ activeInd: "Y", segment: searchModel.segment });
            setFlag("add");
            setTabIndex("2");
        });
    }

    const edit = () => {
        messageShowHandler();
        if(!selectedRowKeys || selectedRowKeys.length !== 1) {
            errFrameShow("error", "请选择一条数据");
            return;
        }
        let model = checkboxState[0];
        initForm(model);
        setFlag("edit");
        setTabIndex("2");
    }

    const initForm = (model = {}) => {
        form2.resetFields();
        form2.setFieldsValue({
            segment: model.segment ?? '',
            code: model.code ?? '',
            shortDesc: model.shortDesc ?? '',
            longDesc: model.longDesc ?? '',
            parmValue1: model.parmValue1 ?? '',
            parmValue2: model.parmValue2 ?? '',
            parmValue3: model.parmValue3 ?? '',
            parmValue4: model.parmValue4 ?? '',
            parmValue5: model.parmValue5 ?? '',
            parmValue6: model.parmValue6 ?? '',
            activeInd: model.activeInd ?? '',
            dispSeq: model.dispSeq ?? '',
        });
    }


    const deleteFrameClose = (state) => {
        if(state) {
            deleteData();
        }
        setIsDeleteFrame(false);
    }

    const deleteData = () => {
        setIsLoad(true);
        setSpinInfo("数据保存中...");
        let deleteModel = checkboxState[0];
        deleteModel.menuFuncId = Config.funcInfo.funcId;
        Base.callBackApi("param/delete", deleteModel).then((data) => {
            if ("SUCCESS" === data.msg) {
                search(pageSize, current, "Y", false);
                errFrameShow("success", "刪除成功");
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }

    const audit = () => {
        messageShowHandler();//清空提示信息
        if(!checkboxState || checkboxState.length !== 1) {
            errFrameShow("error", "请选择一条数据");
            return;
        }
        setAuditModel(checkboxState[0]);
        setIsAudit(true);
    }

    const auditCancel = () => {
        setIsAudit(false);
    }

    const downloadExcel = () => {
        messageShowHandler();//清空提示信息
        setIsLoad(true);
        setSpinInfo("下载中...")
        fetch(Config.apiDownloadServer + "param/exportReport", {
            method: 'POST',
            body: JSON.stringify(searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob()).then(blob => {
            if (blob.type == "application/json") {
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                    errFrameShow("danger", data.msg);
                }
                reader.readAsText(blob);
            } else {
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {// for IE
                    window.navigator.msSaveOrOpenBlob(blob, "系统参数.xlsx");
                } else {// for Non-IE (chrome, firefox etc.)
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = "系统参数.xlsx";
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }
            }
        }).catch(err => ({ err })).finally(() => {
            setIsLoad(false)
        });
    }
    
    const onSave = (values) => {
        messageShowHandler();
        setIsLoad(true);
        setSpinInfo("数据保存中...")
        let url = "";
        if(flag === "add") {
            url = "param/insert";
        } else {
            url = "param/update"
            values.paramId = selectedRowKeys[0];
            values.lastUpdatedDate = checkboxState[0].lastUpdatedDate;
        }
        values.menuFuncId = Config.funcInfo.funcId;
        Base.callBackApi(url, values).then((data) => {
            if ("SUCCESS" === data.msg) {
                search(pageSize, current, "Y");
                errFrameShow("success", "保存成功");
                setTabIndex("1");
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }

    const cancel = () => {
        messageShowHandler();
        clearSelect();
        setTabIndex("1");
    }

    const update = (model, e) => {
        e.stopPropagation();
        setCheckboxState([model]);
        setSelectedRowKeys([model.paramId]);
        initForm(model);
        setFlag("edit");
        setTabIndex("2");
    }

    const deleteOne = (model, e) => {
        e.stopPropagation();
        setCheckboxState([model]);
        setSelectedRowKeys([model.paramId]);
        setIsDeleteFrame(true);
    }

    //分页 页码切换变化查询
    const onChange = (currentNew, pageSizeNew) => {
        search(pageSizeNew, currentNew, "Y");
    }
    //分页 每页條數变化查询
    const onShowSizeChange = (currentNew, pageSizeNew) => {
        search(pageSizeNew, 1, "Y");
    }

    //列表选中记录
    const onSelectChange = (selectedRowKeys, selectedRows) => {
        setSelectedRowKeys(selectedRowKeys);
        setCheckboxState(selectedRows);
    }
    //清空列表选中
    const clearSelect = () => {
        setSelectedRowKeys([]);
        setCheckboxState([]);
    }

    const errFrameShow = (style, msg) => {
        setMessage(msg);
        setMessageStyle(style);
        setMessageShow(true);
        document.documentElement.scrollTop = 0;
    }

    const messageShowHandler = () => {
        setMessage("");
        setMessageShow(false);
    }
    const columns = [
        {
            title: '参数名称',
            dataIndex: 'segmentName',
            className: 'tableWidth10',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'segmentName') },
        },
        {
            title: '编码',
            dataIndex: 'code',
            className: 'tableWidth10',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'code') },
        },
        {
            title: '描述',
            dataIndex: 'shortDesc',
            className: 'tableWidth15',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'shortDesc') },
        },
        {
            title: '详细描述',
            dataIndex: 'longDesc',
            className: 'tableWidth12',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'longDesc') },
        },

        {
            title: (oldSearchModel.parmNam1 || '属性1'),
            dataIndex: 'parmValue1',
            className: 'tableWidth12',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'parmValue1') },
        },
        {
            title: (oldSearchModel.parmNam2 || '属性2'),
            dataIndex: 'parmValue2',
            className: 'tableWidth10',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'parmValue2') },
        },
        {
            title: '是否激活',
            dataIndex: 'activeInd',
            className: 'tableWidth10',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'activeInd') },
            render: (text) => ContextHandler.getMapValue('inds', text)
        },
        {
            title: '序号',
            dataIndex: 'dispSeq',
            className: 'tableWidth8 tableRightAlignment',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'dispSeq') },
        },
        {
            title: '操作',
            dataIndex: '',
            className: 'tableWidth13 tableRightAlignment',
            render: (text, record) => <div className='sysOperation proBrandEstateOperation'>
                <div className='sysUpdate' onClick={(e) => update(record ,e)}><EditOutlined /> 修改</div>
                <div className='sysDelete' onClick={(e) => deleteOne(record ,e)}><DeleteOutlined /> 删除</div>
            </div>
        },
    ];

    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
        type: 'radio',
    };

    return (
        <div id="container" className="template">
            <div className='breadcrumb'>
                <HomeOutlined /> / 系统管理 / <span className='breadcrumbTitle'>系统参数</span>
            </div>
            {/* 提示信息弹框 */}
            {messageShow ? <Message style={messageStyle} content={message} close={messageShowHandler}/> : ''}
            <div className='templateMain'>
                <div className="sysModelTitle">系统参数</div>
                <Spin spinning={isLoad} size="large" tip={spinInfo}>
                    {/* 删除弹框 */}
                    {isDeleteFrame ? <PopupFrame close={deleteFrameClose}/> : null}
                    {/* 审核彈框 */}
                    <Audit visible={isAudit} auditModel={auditModel} close={auditCancel}/>
                    <Tabs activeKey={tabIndex} onChange={(e) => handleSelect(e)}>
                        <TabPane key="1" tab="列表">
                            <Form form={form} onFinish={onFinish}>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysTitle mustFill">参数名称</div>
                                        <div className="sysSerchInput">
                                            <Form.Item
                                                name="segment"
                                                rules={[
                                                    { required: true, message: '参数名称不能为空' },
                                                ]}
                                            >
                                                <Select className="sysSelect" onChange={(e) => searchChange(e)}>
                                                    <Option value=''></Option>
                                                    {paramSegmentList.map((item) => <Option key={item.segment} value={item.segment}>{item.shortDesc}</Option>)}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='sysSearchButton'>
                                        <Button className="sysBtn btnBule" type="primary" htmlType="submit" hidden={Config.funcInfo.canSelect === "N"} icon={<SearchOutlined />}>查询</Button>
                                        <Button className="sysBtn" onClick={reset} icon={<RedoOutlined />}>重置</Button>
                                    </div>
                                </div>
                            </Form>
                            <div className="sysLineEight"></div>
                            <Space size={[5, 5]} wrap className="buttonRelative">
                                <Button className='sysBtn btnBule' type="primary" onClick={add} hidden={Config.funcInfo.canInsert === "N"} icon={<PlusCircleOutlined />}>新增</Button>
                                {tables.length == 0 ? null :
                                    <>
                                        <Button className='sysBtn btnBule' type="primary" onClick={audit} hidden={Config.funcInfo.canAudit === "N"} icon={<EyeOutlined />}>日志</Button>
                                        <Button className='sysBtn btnBule' type="primary" onClick={downloadExcel} hidden={Config.funcInfo.canDownload === "N"} icon={<DownloadOutlined />}>导出Excel表格</Button>
                                    </>
                                }
                            </Space>
                            <div className="sysTable" id="tableOne">
                                <Table
                                    rowSelection={rowSelection} //选择
                                    rowKey="paramId"
                                    columns={columns} //列名
                                    dataSource={tables} //数据
                                    pagination={{  //分页
                                        showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                        onChange: onChange,            //上页下页或者跳页方法
                                        onShowSizeChange: onShowSizeChange,    //每页显示多少条数据方法
                                        total: tablesCount,             //总共多少条数据
                                        current: current,         //当前页
                                        pageSize: pageSize,       //每页显示多少条数据
                                        defaultCurrent: 1,                               //默认当前页为1
                                        size:"small"
                                    }}
                                    onRow={(record) => {
                                        return {
                                            onClick: event => Base.selectRadioRowNew.call(this, record, 'paramId', selectedRowKeys, setCheckboxState, setSelectedRowKeys),
                                        };
                                    }}
                                />
                            </div>
                        </TabPane>
                        <TabPane tab="系统参数" key="2" forceRender={true}>
                            <Form form={form2} onFinish={onSave}>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">参数名称</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="segment"
                                                rules={[
                                                    { required: true, message: '参数名称不能为空' },
                                                ]}
                                            >
                                                <Select placeholder="参数名称" disabled className='sysSelect'>
                                                    <Option value=''></Option>
                                                    {paramSegmentList.map((item) => <Option key={item.segment} value={item.segment}>{item.shortDesc}</Option>)}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">编码</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="code"
                                                rules={[
                                                    { required: true, message: '编码不能为空' },
                                                ]}
                                                getValueFromEvent={(e) => Base.codeChange(e, 30)}
                                            >
                                                <Input type="text" placeholder="编码" maxLength={30} disabled={flag === "edit"}/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">描述</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="shortDesc"
                                                rules={[
                                                    { required: true, message: '描述不能为空' },
                                                ]}
                                            >
                                                <Input type="text" placeholder="描述" maxLength={100}/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam">详细描述</div>
                                        <div className="sysInputs">
                                            <Form.Item
                                                name="longDesc"
                                            >
                                                <TextArea placeholder="详细描述" maxLength={250} rows={3}/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">{oldSearchModel.parmNam1 || '属性1'}</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="parmValue1"
                                                rules={[
                                                    { required: true, message: (oldSearchModel.parmNam1 || '属性1') + '不能为空' },
                                                ]}
                                            >
                                                <Input type="text" placeholder={oldSearchModel.parmNam1 || '属性1'} maxLength={100}/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='sysCol'>
                                        <div className="sysParam">{oldSearchModel.parmNam2 || '属性2'}</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="parmValue2"
                                            >
                                                <Input type="text" placeholder={oldSearchModel.parmNam2 || '属性2'} maxLength={100}/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam">{oldSearchModel.parmNam3 || '属性3'}</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="parmValue3"
                                            >
                                                <Input type="text" placeholder={oldSearchModel.parmNam3 || '属性3'} maxLength={100}/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='sysCol'>
                                        <div className="sysParam">{oldSearchModel.parmNam4 || '属性4'}</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="parmValue4"
                                            >
                                                <Input type="text" placeholder={oldSearchModel.parmNam4 || '属性4'} maxLength={100}/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam">{oldSearchModel.parmNam5 || '属性5'}</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="parmValue5"
                                            >
                                                <Input type="text" placeholder={oldSearchModel.parmNam5 || '属性5'} maxLength={100}/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='sysCol'>
                                        <div className="sysParam">{oldSearchModel.parmNam6 || '属性6'}</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="parmValue6"
                                            >
                                                <Input type="text" placeholder={oldSearchModel.parmNam6 || '属性6'} maxLength={100}/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam">是否激活</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="activeInd"
                                                rules={[
                                                    { required: true, message: '是否激活不能为空' },
                                                ]}
                                            >
                                                <Select placeholder="是否激活" className='sysSelect'>
                                                    {context.inds.map((item) => <Option key={item.value} value={item.value}>{item.label}</Option>)}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">序号</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="dispSeq"
                                                rules={[
                                                    { required: true, validator: (rule, value) => Base.checkNbrOrNull(value, null, 999999999) },
                                                ]}
                                            >
                                                <InputNumber className='sysInputNumber' placeholder="序号" precision={0} maxLength={9}/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className="sysSaveButton">
                                    <Button className="sysBtn btnBule" type="primary" disabled={isLoad} htmlType="submit" icon={<CheckCircleOutlined />}>保存</Button>
                                    <Button className="sysBtn" onClick={cancel} icon={<CloseCircleOutlined />}>取消</Button>
                                </div>
                            </Form>
                        </TabPane>
                    </Tabs>
                </Spin>
            </div>
        </div>
    )

}

export default SysParam;