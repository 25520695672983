import React, { useState, useEffect } from 'react';
import { Button, Spin, Form, Input, Upload, Modal, Checkbox, DatePicker, InputNumber, Select } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, PlusOutlined } from '@ant-design/icons';
import Message from '../../component/message/message';//提示信息弹框
import PropTypes from 'prop-types';//props传参检查库
import moment from 'moment';//日期格式化导包
import { Config } from '../../public/js/config';
import { Base } from '../../public/js/base';
import ImgCrop from 'antd-img-crop';

const { TextArea } = Input;
const { Option } = Select;

const RoomModal = (props) =>{
    
    const imageNum = 5;
    const homeGoodsList = [
        { label: "床", value: "床" },
        { label: "衣柜", value: "衣柜" },
        { label: "灯具", value: "灯具" },
        { label: "桌椅", value: "桌椅" },
        { label: "冰箱", value: "冰箱" },
        { label: "插座", value: "插座" },
        { label: "空调", value: "空调" },
        { label: "洗衣机", value: "洗衣机" },
        { label: "烘干机", value: "烘干机" },
        { label: "烤箱", value: "烤箱" },
        { label: "灶台", value: "灶台" },
        { label: "油烟机", value: "油烟机" },
        { label: "微波炉", value: "微波炉" },
        { label: "网络设备", value: "网络设备" },
    ];
    const tenancyList = [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        24,
        36
    ];
    const [roomForm] = Form.useForm();
    const [isLoad, setIsLoad] = useState(false);
    const [spinInfo, setSpinInfo] = useState("");
    const [messageShow, setMessageShow] = useState(false);
    const [messageStyle, setMessageStyle] = useState("");
    const [message, setMessage] = useState("");
    const [imageFile, setImageFile] = useState([]);
    const [houseTypeFile, setHouseTypeFile] = useState([]);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);

    useEffect(() => {
        let model = props.model;
        initFormRoom(model);
        setImageFile(
            model.roomImg?.split(";")
                ?.map(item => {
                    return {
                        url: Config.proBrandEstateRoomImg + item,
                        originUrl: item,
                        thumbUrl: Config.proBrandEstateRoomPath + item,
                        imgStatus: "OLD"
                    };
                })
            || []
        );
        setHouseTypeFile(
            model.houseTypeImg?.split(";")
                ?.map(item => {
                    return {
                        url: Config.proBrandEstateRoomImg + item,
                        originUrl: item,
                        thumbUrl: Config.proBrandEstateRoomPath + item,
                        imgStatus: "OLD"
                    };
                })
            || []
        );
    }, []);

    const initFormRoom = (model = {}) => {
        roomForm.resetFields();
        roomForm.setFieldsValue({
            roomType: model.roomType ?? '',
            roomDesc: model.roomDesc ?? '',
            homeGoodsPublic: model.homeGoodsPublic?.split('|') ?? [],
            homeGoodsRoom: model.homeGoodsRoom?.split('|') ?? [],
            homeGoodsKitchen: model.homeGoodsKitchen?.split('|') ?? [],
            homeGoodsBathroom: model.homeGoodsBathroom?.split('|') ?? [],
            homeGoodsBalcony: model.homeGoodsBalcony?.split('|') ?? [],
            homeGoodsStorage: model.homeGoodsStorage?.split('|') ?? [],
            tenancy: model.tenancy ?? '',
            leaseStartDate: model.leaseStartDate ? moment(new Date(model.leaseStartDate), Config.dateFormat) : null,
            payMode: model.payMode ?? '',
            rentalPrice: model.rentalPrice ?? '',
        });
    }

    /**查看图片 */
    const handlePreview = (file) => {
        setPreviewImage(file.url || file.originUrl);
        setPreviewVisible(true);
    }

    const checkImg = (value) => {
        if(!!imageFile && imageFile.length > 0) {
            if(value) {
                let list = value.fileList;
                if(list.find(item => item.status === "uploading")) {
                    return Promise.reject("请等待图片上传完成");
                }
                if(list.find(item => item.status === "error")) {
                    return Promise.reject("请先处理测试上传失败的图片");
                }
                // if(imageFile.find(item => item.status === "irregular")) {
                //     return Promise.reject("图片尺寸不符合规则");
                // }
            }
            return Promise.resolve();
        }
        return Promise.reject("图片数量必须大于0");
    }

    const checkHouseTypeImg = (value) => {
        if(!!houseTypeFile && houseTypeFile.length > 0) {
            if(value) {
                let list = value.fileList;
                if(list.find(item => item.status === "uploading")) {
                    return Promise.reject("请等待图片上传完成");
                }
                if(list.find(item => item.status === "error")) {
                    return Promise.reject("请先处理测试上传失败的图片");
                }
                // if(houseTypeFile.find(item => item.status === "irregular")) {
                //     return Promise.reject("图片尺寸不符合规则");
                // }
            }
            return Promise.resolve();
        }
        return Promise.reject("图片数量必须大于0");
    }

    const handleChange = (file) => {
        let fileList = file.fileList;
        file = file.file;
        if(file.status === "removed") {
            let index = imageFile.findIndex(item => item.uid == file.uid);
            let fileList = JSON.parse(JSON.stringify(imageFile));
            fileList.splice(index, 1)
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = (e) => { 
                var img = document.createElement("img");
                img.src = e.target.result;
                img.onload = function () {
                    if(img.width < 400 || img.height < 400 || (img.width / img.height) !== 1) {
                        fileList[fileList.length - 1].status = "irregular";
                        roomForm.validateFields(['img']);
                    }
                }
                fileList[fileList.length - 1].originUrl = e.target.result; 
                fileList[fileList.length - 1].imgStatus = "NEW";
            }
        }
        setImageFile(fileList);
    }

    const houseTypeChange = (file) => {
        let fileList = file.fileList;
        file = file.file;
        if(file.status === "removed") {
            let index = houseTypeFile.findIndex(item => item.uid == file.uid);
            let fileList = JSON.parse(JSON.stringify(houseTypeFile));
            fileList.splice(index, 1)
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = (e) => { 
                var img = document.createElement("img");
                img.src = e.target.result;
                img.onload = function () {
                    if(img.width < 400 || img.height < 400 || (img.width / img.height) !== 1) {
                        fileList[fileList.length - 1].status = "irregular";
                        roomForm.validateFields(['houseTypeImg']);
                    }
                }
                fileList[fileList.length - 1].originUrl = e.target.result; 
                fileList[fileList.length - 1].imgStatus = "NEW";
            }
        }
        setHouseTypeFile(fileList);
    }

    const errFrameShow = (style, msg) => {
        setMessageStyle(style);
        setMessage(msg);
        setMessageShow(true);
    }

    const messageShowHandler = () => {
        setMessage("");
        setMessageShow(false);
    }

    const save = () => {
        roomForm.submit();
    }

    const onFinish = (values) => {
        messageShowHandler();
        setIsLoad(true);
        setSpinInfo("数据保存中...")
        let url = "";
        if(props.roomFlag === "add") {
            url = "proBrandEstate/insertRoom";
        } else {
            url = "proBrandEstate/updateRoom"
            values.proBrandEstateRoomId = props.model.proBrandEstateRoomId;
            values.lastUpdatedDate = props.model.lastUpdatedDate;
        }
        let imageUploadModelList = imageFile.map(item => {
            return {
                status: item.imgStatus,
                url: item.originUrl,
            };
        });
        let houseTypeUploadList = houseTypeFile.map(item => {
            return {
                status: item.imgStatus,
                url: item.originUrl,
            };
        });
        values.proBrandEstateId = props.id;
        values.imageUploadModelList = imageUploadModelList;
        values.houseTypeUploadList = houseTypeUploadList;
        values.homeGoodsPublic = values.homeGoodsPublic?.join('|');
        values.homeGoodsRoom = values.homeGoodsRoom?.join('|');
        values.homeGoodsKitchen = values.homeGoodsKitchen?.join('|');
        values.homeGoodsBathroom = values.homeGoodsBathroom?.join('|');
        values.homeGoodsBalcony = values.homeGoodsBalcony?.join('|');
        values.homeGoodsStorage = values.homeGoodsStorage?.join('|');
        values.menuFuncId = Config.funcInfo.funcId;
        Base.callBackApi(url, values).then((data) => {
            if ("SUCCESS" === data.msg) {
                props.onClose(true);
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }

    const onCancel = () => {
        props.onClose(false);
    }

    return(
        <Spin size="large" spinning={isLoad} tip={spinInfo}>
            <Modal
                visible={true}//控制弹框是否显示
                title="新增/修改房型"//标题
                centered//垂直居中
                destroyOnClose={true}//关闭即销毁？
                closable//是否显示右上角的关闭按钮
                maskClosable={false}//点击蒙层是否允许关闭	
                // width={Config.equipmentInfo.type === 'iPad' ? '65%' : (Config.equipmentInfo.type === 'phone' ? '100%' : '55%')}//弹框宽度
                width={'1275px'}
                onCancel={onCancel}//点击取消触发事件
                footer={[
                    <Button key='save' className="sysBtn btnBule" hidden={props.roomFlag === "see"} disabled={isLoad} type="primary" onClick={save} icon={<CheckCircleOutlined />}>确定</Button>, 
                    <Button key='cancel' className="sysBtn" onClick={onCancel} icon={<CloseCircleOutlined />}>取消</Button>
                ]}
            >
                {messageShow ? <Message style={messageStyle} content={message} close={messageShowHandler} /> : ''}
                <Form form={roomForm} onFinish={onFinish}>
                    <div className='sysRow'>
                        <div className='sysCol'>
                            <div className="sysParam mustFill">房型</div>
                            <div className="sysInput">
                                <Form.Item
                                    name="roomType"
                                    rules={[
                                        { required: true, message: '房型不能为空' },
                                    ]}
                                >
                                    <Select className="sysSelect" disabled={props.roomFlag === "see"}>
                                        <Option value=""></Option>
                                        {props.roomTypeList?.map(item => <Option key={item.code} value={item.code}>{item.parmValue1}</Option>)}
                                    </Select>
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                    <div className='sysRow'>
                        <div className='sysCol'>
                            <div className="sysParam mustFill">租期(月)</div>
                            <div className="sysInput">
                                <Form.Item
                                    name="tenancy"
                                    rules={[
                                        { required: true, message: '租期不能为空' },
                                    ]}
                                >
                                    <Select className="sysSelect" disabled={props.roomFlag === "see"}>
                                        <Option value=""></Option>
                                        {tenancyList?.map(item => <Option key={item} value={item}>{item}</Option>)}
                                    </Select>
                                </Form.Item>
                            </div>
                        </div>
                        <div className='sysCol'>
                            <div className="sysParam mustFill">起租期</div>
                            <div className="sysInput">
                                <Form.Item
                                    name="leaseStartDate"
                                    rules={[
                                        { required: true, message: '起租期不能为空' },
                                    ]}
                                >
                                    <DatePicker className="sysSelect" placeholder="起租期" format={Config.dateFormat} disabled={props.roomFlag === "see"}/>
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                    <div className='sysRow'>
                        <div className='sysCol'>
                            <div className="sysParam">支付方式</div>
                            <div className="sysInput">
                                <Form.Item
                                    name="payMode"
                                >
                                    <Input type="text" placeholder="支付方式" maxLength={50}/>
                                </Form.Item>
                            </div>
                        </div>
                        <div className='sysCol'>
                            <div className="sysParam mustFill">租金</div>
                            <div className="sysInput">
                                <Form.Item
                                    name="rentalPrice"
                                    rules={[
                                        { required: true, validator: (rule, value) => Base.checkNbrOrNull(value, null, 99999999.99) }
                                    ]}
                                >
                                    <InputNumber className='sysInputNumber' placeholder="租金" precision={2} maxLength={11} disabled={props.roomFlag === "see"}/>
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                    <div className='sysRow'>
                        <div className='sysCol'>
                            <div className="sysParam">房间概述</div>
                            <div className="sysInputs">
                                <Form.Item
                                    name="roomDesc"
                                >
                                    <TextArea placeholder="房间概述" maxLength={200} rows={3} disabled={props.roomFlag === "see"}/>
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                    <div className='sysRow'>
                        <div className='sysCol'>
                            <div className="sysParam mustFill">房间图片</div>
                            <div className="sysInputs">
                                <Form.Item 
                                    name="img"
                                    rules={[
                                        { required: true, validator: (rule, value) => checkImg(value) }
                                    ]}
                                >
                                    <ImgCrop rotationSlider aspect={1/1}>
                                        <Upload
                                            action={Config.uploadTestServer} 
                                            key={"1"}
                                            accept=".png, .jpg, .jpeg"
                                            listType="picture-card"
                                            fileList={imageFile}
                                            onPreview={handlePreview}
                                            onChange={(file) => handleChange(file)}>
                                            {imageFile.length >= imageNum || props.roomFlag === "see" ? null : <div className='plusOutBox'>
                                                <PlusOutlined />
                                                <div>添加图片</div>
                                                <div className='plusOutDesc'>(推荐上传宽高大于等于400*400像素的1:1尺寸的图片)</div>
                                            </div>}
                                        </Upload>
                                    </ImgCrop>
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                    <div className='sysRow'>
                        <div className='sysCol'>
                            <div className="sysParam mustFill">户型图片</div>
                            <div className="sysInputs">
                                <Form.Item 
                                    name="houseTypeImg"
                                    rules={[
                                        { required: true, validator: (rule, value) => checkHouseTypeImg(value) }
                                    ]}
                                >
                                    <ImgCrop rotationSlider aspect={1/1}>
                                        <Upload
                                            action={Config.uploadTestServer} 
                                            key={"1"}
                                            accept=".png, .jpg, .jpeg"
                                            listType="picture-card"
                                            fileList={houseTypeFile}
                                            onPreview={handlePreview}
                                            onChange={(file) => houseTypeChange(file)}>
                                            {houseTypeFile.length >= imageNum || props.roomFlag === "see" ? null : <div className='plusOutBox'>
                                                <PlusOutlined />
                                                <div>添加图片</div>
                                                <div className='plusOutDesc'>(推荐上传宽高大于等于400*400像素的1:1尺寸的图片)</div>
                                            </div>}
                                        </Upload>
                                    </ImgCrop>
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                    <div className='sysRow'>
                        <div className='sysCol'>
                            <div className="sysParam">公共区域</div>
                            <div className="sysInputs">
                                <Form.Item
                                    name="homeGoodsPublic"
                                >
                                    <Checkbox.Group options={homeGoodsList} disabled={props.roomFlag === "see"}/>
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                    <div className='sysRow'>
                        <div className='sysCol'>
                            <div className="sysParam">房间内部</div>
                            <div className="sysInputs">
                                <Form.Item
                                    name="homeGoodsRoom"
                                >
                                    <Checkbox.Group options={homeGoodsList} disabled={props.roomFlag === "see"}/>
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                    <div className='sysRow'>
                        <div className='sysCol'>
                            <div className="sysParam">厨房</div>
                            <div className="sysInputs">
                                <Form.Item
                                    name="homeGoodsKitchen"
                                >
                                    <Checkbox.Group options={homeGoodsList} disabled={props.roomFlag === "see"}/>
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                    <div className='sysRow'>
                        <div className='sysCol'>
                            <div className="sysParam">卫浴</div>
                            <div className="sysInputs">
                                <Form.Item
                                    name="homeGoodsBathroom"
                                >
                                    <Checkbox.Group options={homeGoodsList} disabled={props.roomFlag === "see"}/>
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                    <div className='sysRow'>
                        <div className='sysCol'>
                            <div className="sysParam">阳台</div>
                            <div className="sysInputs">
                                <Form.Item
                                    name="homeGoodsBalcony"
                                >
                                    <Checkbox.Group options={homeGoodsList} disabled={props.roomFlag === "see"}/>
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                    <div className='sysRow'>
                        <div className='sysCol'>
                            <div className="sysParam">储物间</div>
                            <div className="sysInputs">
                                <Form.Item
                                    name="homeGoodsStorage"
                                >
                                    <Checkbox.Group options={homeGoodsList} disabled={props.roomFlag === "see"}/>
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                </Form>
                <Modal title="放大图" visible={previewVisible} footer={null} onCancel={() => setPreviewVisible(false)}>
                    <img alt="example" style={{ width: '100%' }} src={previewImage} />
                </Modal>
            </Modal>
        </Spin>
    )

}
RoomModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    model: PropTypes.object,
    id: PropTypes.string,
    roomFlag: PropTypes.string,
    roomTypeList: PropTypes.array
}

export default RoomModal;