import React, { useState, useEffect } from 'react';
import { Button, Table, Spin, Select, Input, Row, Col, DatePicker, Modal, Space } from 'antd';
import { SearchOutlined, RedoOutlined, DeleteOutlined, EyeOutlined,HomeOutlined, CloseCircleOutlined } from '@ant-design/icons';
import moment from 'moment';//日期格式化导包
import Message from '../../component/message/message';//提示信息弹框
import PopupFrame from '../../component/popupFrame/popupFrame';//删除弹框
import { Config } from '../../public/js/config';
import { Base } from '../../public/js/base';
import { ContextHandler } from '../../public/js/contextHandler';
import context from '../../public/js/context';

const { Option } = Select;
const { RangePicker } = DatePicker;

const SchedulingLog = (props) => {
    const [tables, setTables] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [checkboxState, setCheckboxState] = useState([]);
    const [messageShow, setMessageShow] = useState(false);
    const [messageStyle, setMessageStyle] = useState("error");
    const [message, setMessage] = useState("");
    const [isDeleteFrame, setIsDeleteFrame] = useState(false);
    const [deleteMsg, setDeleteMsg] = useState("");
    const [isLoad, setIsLoad] = useState(false);
    const [spinInfo, setSpinInfo] = useState("");
    const [current, setCurrent] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [tablesCount, setTablesCount] = useState(0);
    const [searchModel, setSearchModel] = useState({});
    const [oldSearchModel, setOldSearchModel] = useState({});
    const [jobGroupList, setJobGroupList] = useState([]);
    const [isView, setIsView] = useState(false);
    const [viewModel, setViewModel] = useState({});
    
    useEffect(() => {
        search(pageSize, 1, "N");
        searchJobGroup();
    }, []);

    const searchJobGroup = () => {
        Base.callBackApi("commonDropDown/searchSysParam", { segment: "SYS_JOB_GROUP" }).then((data) => {
            if ("SUCCESS" === data.msg) {
                setJobGroupList(data.object ?? []);
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { });
    }

    const search = (pageSizeNew, currentPageNew, flag, isShowMsg) => {
        if(Config.funcInfo.canSelect === "N") {
            return;
        }
        messageShowHandler();//清除提示信息
        clearSelect();//清除選中狀態
        let model = {};
        if ("Y" === flag) {//是否使用原查詢條件
            model = oldSearchModel;
        } else {
            model = searchModel;
        }
        model.pageSize = pageSizeNew;
        model.page = currentPageNew;
        setIsLoad(true);
        setSpinInfo("查找中...")
        Base.callBackApi("sysJobLog/search", model).then((data) => {
            if ("SUCCESS" == data.msg) {
                if (Base.isEmpty(data.object.list) && isShowMsg) {
                    errFrameShow("info", '找不到符合条件记录.');
                }
                if("N" === flag) {
                    setOldSearchModel(JSON.parse(JSON.stringify(model)));
                }
                setTablesCount(data?.object?.total ?? 0);
                setTables(data?.object?.list ?? []);
                setCurrent(currentPageNew);
                setPageSize(pageSizeNew);
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => ({ err })).finally(() => {
            setIsLoad(false);
        });
    }

    const reset = () => {
        messageShowHandler();
        clearSelect();
        setSearchModel({});
        setOldSearchModel({});
        setTablesCount(0);
        setTables([]);
        setPageSize(10);
    }

    const searchChange = (key, value) => {
        setSearchModel(model =>{
            let newModel = {};
            if(key === "time") {
                newModel.beginTime = value[0];
                newModel.endTime = value[1];
                return {...model,...{beginTime:value[0],endTime:value[1]}};
            }else{
                return {...model,...{[key]:value}};
            }
        });
    }

    const deleteFrameHandler = () => {
        messageShowHandler();
        if(!selectedRowKeys || selectedRowKeys.length === 0) {
            errFrameShow("error", "请选择至少一条数据");
        } else {
            setDeleteMsg("您确认要删除吗？");
            setIsDeleteFrame(true);
        }
    }

    const clearFrameHandler = () => {
        messageShowHandler();
        setDeleteMsg("是否确认清空所有调度日志?");
        setIsDeleteFrame(true);
    }

    const deleteFrameClose = (state) => {
        if(state) {
            if(Base.isEmpty(deleteMsg)) {
                deleteLog();
            } else {
                claerLog();
            }
        }
        clearSelect();
        setIsDeleteFrame(false);
    }

    const deleteLog = () => {
        let model = {
            menuFuncId: Config.funcInfo.funcId,
            jobLogIds: selectedRowKeys
        }
        setIsLoad(true);
        setSpinInfo("数据保存中...");
        Base.callBackApi("sysJobLog/delete", model).then((data) => {
            if ("SUCCESS" == data.msg) {
                search(pageSize, current, "Y");
                errFrameShow("success", "保存成功");
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => ({ err })).finally(() => {
            setIsLoad(false);
        });
    }

    const claerLog = () => {
        let model = {
            menuFuncId: Config.funcInfo.funcId,
        }
        setIsLoad(true);
        setSpinInfo("数据保存中...");
        Base.callBackApi("sysJobLog/clean", model).then((data) => {
            if ("SUCCESS" == data.msg) {
                search(pageSize, current, "Y");
                errFrameShow("success", "保存成功");
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => ({ err })).finally(() => {
            setIsLoad(false);
        });
    }

    const view = (model, e) => {
        e.stopPropagation();
        setViewModel(model);
        setIsView(true);
    }

    const viewClose = () => {
        setViewModel({});
        setIsView(false);
    }

    //分页 页码切换变化查询
    const onChange = (current, pageSize) => {
        search(pageSize, current, "Y");
    }
    //分页 每页條數变化查询
    const onShowSizeChange = (current, pageSize) => {
        search(pageSize, 1, "Y");
    }

    //列表1选中记录
    const onSelectChange = (keys, selectedRows) => {
        setSelectedRowKeys(keys);
        setCheckboxState(selectedRows);
    }

    //清空列表1选中
    const clearSelect = () => {
        setSelectedRowKeys([]);
        setCheckboxState([]);
    }

    /** 生成提示信息 */
    const errFrameShow = (style, msg) => {
        setMessageStyle(style);
        setMessage(msg);
        setMessageShow(true);
        document.documentElement.scrollTop = 0;//返回顶部
    }

    /** 清空提示信息 */
    const messageShowHandler = () => {
        setMessage("");
        setMessageShow(false);
    }

    const columns = [
        {
            title: '任务名称',
            dataIndex: 'jobName',
            className: 'tableWidth15',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'jobName') },
        },
        {
            title: '任务组名',
            dataIndex: 'jobGroup',
            className: 'tableWidth15',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'jobGroup') },
            render: (text) => getJobGroup(text)
        },
        {
            title: '调用目标字符串',
            dataIndex: 'invokeTarget',
            className: 'tableWidth15',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'invokeTarget') },
        },
        {
            title: '日志信息',
            dataIndex: 'jobMessage',
            className: 'tableWidth25',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'jobMessage') },
        },
        {
            title: '执行状态',
            dataIndex: 'status',
            className: 'tableWidth10',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'status') },
            render: (text) => ContextHandler.getMapValue("executeStatusMap", text)
        },
        {
            title: '执行时间',
            dataIndex: 'createdDate',
            className: 'tableWidth12',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'createdDate') },
            render: (text) => moment(text).format(Config.dateFormatALL)
        },
        /*eslint-disable*/
        {
            title: '操作',
            dataIndex: 'jobLogId',
            className: 'tableWidth8 tableRightAlignment',
            render: (text, record) => <div className='sysOperation proBrandEstateOperation'>
                 <div className='sysOperation' onClick={(e) => view(record ,e)}><EyeOutlined /> &nbsp;详情</div>
            </div>
        },
        /*eslint-disable*/
    ]

    const getJobGroup = (jobGroup) => {
        let model = jobGroupList.find(item => item.code == jobGroup);
        if(model) {
            return model.parmValue1;
        }
        return "";
    }

    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
        hideDefaultSelections: true,
    };

    return (
        <div id="container" className="template">
             <div className='breadcrumb'>
                <HomeOutlined /> / 系统管理 / <span className='breadcrumbTitle'>调度日志</span>
            </div>
            {/* 提示信息弹框 */}
            {messageShow ? <Message style={messageStyle} content={message} close={messageShowHandler} /> : ''}
            <div className='templateMain'>
                <div className="sysModelTitle">调度日志</div>
                {/* 删除弹框 */}
                {!isDeleteFrame ? '' : <PopupFrame close={deleteFrameClose} content={deleteMsg}/>}
                <Spin spinning={isLoad} size="large" tip={spinInfo}>
                    <div className='systabls'>
                        <div className='sysRow'>
                            <div className='sysCol'>
                                <div className="sysTitle">任务名称</div>
                                <div className="sysSerchInput">
                                    <Input type="text" placeholder="任务名称" value={searchModel.jobName}
                                        onChange={(e) => searchChange('jobName', e.target.value)}
                                        onKeyDown={(e) => e.key == 13 ? search(pageSize, 1, "N", true) : null} />
                                </div>
                            </div>
                            <div className='sysCol'>
                                <div className="sysTitle">任务组名</div>
                                <div className="sysSerchInput">
                                    <Select className="sysSelect" placeholder="任务组名"
                                        value={searchModel.jobGroup || ''}
                                        onChange={(e) => searchChange('jobGroup', e)}
                                    >
                                        <Option value=""></Option>
                                        {jobGroupList.map((item) => <Option key={item.code} value={item.code}>{item.parmValue1}</Option>)}
                                    </Select>
                                </div>
                            </div>
                            <div className='sysCol'>
                                <div className="sysTitle">执行状态</div>
                                <div className="sysSerchInput">
                                    <Select className="sysSelect" placeholder="执行状态"
                                        value={searchModel.status || ''}
                                        onChange={(e) => searchChange('status', e)}
                                    >
                                        <Option value=""></Option>
                                        {context.executeStatusMap.map((item) => <Option key={item.value} value={item.value}>{item.label}</Option>)}
                                    </Select>
                                </div>
                            </div>
                            <div className='sysCol'>
                                <div className="sysTitle">执行时间</div>
                                <div className="sysSerchInput">
                                    <RangePicker onChange={(e) => searchChange('time', e)} format={Config.dateFormat}
                                        value={[searchModel.beginTime,searchModel.endTime]}/>
                                </div>
                            </div>
                            <div className="sysSearchButton">
                                <Button className="sysBtn btnBule" type="primary" onClick={() => search(pageSize, 1, "N", true)} icon={<SearchOutlined />}>查询</Button>
                                <Button className="sysBtn" onClick={reset} icon={<RedoOutlined />}>重置</Button>
                            </div>
                        </div>
                        <div className="sysLineEight"></div>
                        <Space size={[5, 5]} wrap className="buttonRelative">
                            {tables.length == 0 ? null :
                                <>
                                    <Button className="sysBtn btnBule" type="primary" onClick={deleteFrameHandler} hidden={Config.funcInfo.canDelete === "N"} icon={<DeleteOutlined />}>删除</Button>
                                    <Button className="sysBtn btnBule" type="primary" onClick={clearFrameHandler} hidden={Config.funcInfo.canDelete === "N"} icon={<DeleteOutlined />}>清空</Button>
                                </>
                            }
                        </Space>
                        <div className="sysTable" id="tableOne">
                            <Table
                                rowSelection={rowSelection} //选择
                                rowKey="jobLogId"
                                columns={columns} //列名
                                dataSource={tables} //数据
                                pagination={{  //分页
                                    showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                    onChange: onChange,                        //上页下页或者跳页方法
                                    onShowSizeChange: onShowSizeChange,        //每页显示多少条数据方法
                                    total: tablesCount,             //总共多少条数据
                                    current: current,                //当前页
                                    pageSize: pageSize,              //每页显示多少条数据
                                    defaultCurrent: 1,                               //默认当前页为1
                                    size:"small"
                                }}
                                onRow={(record) => {
                                    return {
                                        onClick: event => Base.selectRowNew.call(this, record, 'jobLogId', checkboxState, selectedRowKeys, setCheckboxState, setSelectedRowKeys), // 点击行
                                    };
                                }}
                            />
                        </div>
                    </div>
                </Spin>
                <Modal
                    visible={isView}//控制弹框是否显示
                    title="调度日志详情"//标题
                    centered//垂直居中
                    destroyOnClose={true}//关闭即销毁？
                    closable//是否显示右上角的关闭按钮
                    maskClosable={false}//点击蒙层是否允许关闭	
                    width={Config.equipmentInfo.type === 'iPad' ? '65%' : (Config.equipmentInfo.type === 'phone' ? '100%' : '40%')}//弹框宽度
                    onCancel={viewClose}//点击取消触发事件
                    footer={
                        <Button key='cancel' className="buttonWhiteStyle" onClick={viewClose} icon={<CloseCircleOutlined />}>关闭</Button>
                    }
                >
                    <Row gutter={30}>
                        <Col xs={8} sm={4} md={4} lg={4} className="sysTitle">任务名称</Col>
                        <Col xs={16} sm={8} md={8} lg={8} className="sysTitle">{viewModel.jobName}</Col>
                        <Col xs={8} sm={4} md={4} lg={4} className="sysTitle">任务分組</Col>
                        <Col xs={16} sm={8} md={8} lg={8} className="sysTitle">{getJobGroup(viewModel.jobGroup)}</Col>
                    </Row>
                    <Row gutter={30}>
                        <Col xs={8} sm={4} md={4} lg={4} className="sysTitle">执行状态</Col>
                        <Col xs={16} sm={8} md={8} lg={8} className="sysTitle">{ContextHandler.getMapValue("executeStatusMap", viewModel.status)}</Col>
                        <Col xs={8} sm={4} md={4} lg={4} className="sysTitle">执行时间</Col>
                        <Col xs={16} sm={8} md={8} lg={8} className="sysTitle">{moment(viewModel.createdDate).format(Config.dateFormatALL)}</Col>
                    </Row>
                    <Row gutter={30}>
                        <Col xs={8} sm={4} md={4} lg={4} className="sysTitle">调用方法</Col>
                        <Col xs={16} sm={20} md={20} lg={20} className="sysTitle">{viewModel.invokeTarget}</Col>
                    </Row>
                    <Row gutter={30}>
                        <Col xs={8} sm={4} md={4} lg={4} className="sysTitle">日志信息</Col>
                        <Col xs={16} sm={20} md={20} lg={20} className="sysTitle">{viewModel.jobMessage}</Col>
                    </Row>
                    <Row gutter={30}>
                        <Col xs={8} sm={4} md={4} lg={4} className="sysTitle">异常</Col>
                        <Col xs={16} sm={20} md={20} lg={20} className="sysTitle">{viewModel.exceptionInfo}</Col>
                    </Row>
                </Modal>
            </div>
        </div>
    )

}

export default SchedulingLog;