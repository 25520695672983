import React, { useState, useEffect } from 'react';
import { Button, Table, Form, Spin, Input, Tabs, Space, Upload, Modal, Popover ,Checkbox} from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, SearchOutlined, RedoOutlined, PlusCircleOutlined, EditOutlined, DeleteOutlined, EyeOutlined, PlusOutlined, HomeOutlined } from '@ant-design/icons';
import Message from '../../component/message/message';//提示信息弹框
import PopupFrame from '../../component/popupFrame/popupFrame';//删除弹框
import Audit from '../../component/audit/audit';//审核彈框
import { Config } from '../../public/js/config';
import { Base } from '../../public/js/base';
import ImgCrop from 'antd-img-crop';
import './proBrand.css';

const { TabPane } = Tabs;
const { TextArea } = Input;

const ProBrand = (props) => {

    const logoNum = 1;
    const imageNum = 3;
    const [form] = Form.useForm();
    const [isLoad, setIsLoad] = useState(false);
    const [spinInfo, setSpinInfo] = useState("");
    const [isAudit, setIsAudit] = useState(false);
    const [auditModel, setAuditModel] = useState({});
    const [message, setMessage] = useState("");
    const [messageShow, setMessageShow] = useState(false);
    const [messageStyle, setMessageStyle] = useState("");
    const [tabIndex, setTabIndex] = useState("1");
    const [searchModel, setSearchModel] = useState({apartmentStatus: "Y"});
    const [oldSearchModel, setOldSearchModel] = useState({});
    const [tables, setTables] = useState([]);
    const [tablesCount, setTablesCount] = useState(0);
    const [current, setCurrent] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [checkboxState, setCheckboxState] = useState([]);
    const [type, setType] = useState("");
    const [content, setContent] = useState("");
    const [frameType, setFrameType] = useState("");
    const [isPopupFrame, setIsPopupFrame] = useState(false);
    const [flag, setFlag] = useState("");
    const [logoFile, setLogoFile] = useState([]);
    const [imageFile, setImageFile] = useState([]);
    const [headFile, setHeadFile] = useState([]);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);
    const [wechatImgFile, setWechatImgFile] = useState([]);
    const [contactLanguageList, setContactLanguageList] = useState([
        { label: "普通话", value: "普通话" },
        { label: "粤语",   value: "粤语" },
        { label: "英语",   value: "英语" },
        { label: "其他",   value: "其他" },
    ]);

    useEffect(() => {
        search(pageSize, 1, "N");
    }, []);

    const search = (pageSizeNew, currentPageNew, flag, isShowMsg) => {
        if(Config.funcInfo.canSelect === "N") {
            return;
        }
        messageShowHandler();  // 清除提示信息
        clearSelect(); // 清除选中状态
        let model = {};
        if ("Y" === flag) {     // 翻页
            model = oldSearchModel;
        } else {
            model = JSON.parse(JSON.stringify(searchModel));
        }
        model.page = currentPageNew;
        model.pageSize = pageSizeNew;
        setIsLoad(true);
        setSpinInfo("查询中...");
        Base.callBackApi("proBrand/search", model).then((data) => {
            if ("SUCCESS" === data.msg) {
                if (!data?.object?.list && isShowMsg) {
                    errFrameShow("info", '找不到符合条件记录.');
                }
                if("N" === flag) {
                    setOldSearchModel(JSON.parse(JSON.stringify(model)));
                }
                setTablesCount(data?.object?.total ?? 0);
                setTables(data?.object?.list ?? []);
                setCurrent(currentPageNew);
                setPageSize(pageSizeNew);
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }

    const searchChange = (key, value) => {
        messageShowHandler();
        setSearchModel(model =>{
            return {...model,...{[key]:value}};
        });
    }
    
    const reset = () => {
        messageShowHandler();
        clearSelect();
        setSearchModel({});
        setOldSearchModel({});
        setTablesCount(0);
        setTables([]);
        setPageSize(10);
    }

    // Tab页切换
    const handleSelect = (key) => {
        if (key) {
            if(key === "2") {
                if(Config.funcInfo.canUpdate === "Y" && selectedRowKeys.length > 0) {
                    edit();
                } else if(Config.funcInfo.canInsert === "Y") {
                    add();
                }
            } else if(tabIndex !== "1") {
                cancel();
            }
        }
    }

    const add = () => {
        messageShowHandler();
        initForm();
        setLogoFile([]);
        setImageFile([]);
        setWechatImgFile([]);
        setHeadFile([]);
        setFlag("add");
        setTabIndex("2");
    }

    const edit = () => {
        messageShowHandler();
        if(!selectedRowKeys || selectedRowKeys.length !== 1) {
            errFrameShow("error", "请选择一条数据");
            return;
        }
        let model = checkboxState[0];
        initForm(model);
        setLogoFile([{
            url: Config.proBrandImg + model.brandLogo,
            originUrl: model.brandLogo,
            thumbUrl: Config.proBrandPath + model.brandLogo,
            imgStatus: "OLD"
        }]);
        setImageFile(
            model.brandImg?.split(";")
                ?.map(item => {
                    return {
                        url: Config.proBrandImg + item,
                        originUrl: item,
                        thumbUrl: Config.proBrandPath + item,
                        imgStatus: "OLD"
                    };
                })
            || []
        );
        if(model.contactImg) {
            setHeadFile([{
                url: Config.proBrandImg + model.contactImg,
                originUrl: model.contactImg,
                thumbUrl: Config.proBrandImg + model.contactImg,
                imgStatus: "OLD"
            }]);
        } else {
			setHeadFile([]);
		}
        if(model.wechat) {
            setWechatImgFile(
                model.wechat?.split(";")
                    ?.map(item => {
                        return {
                            url: Config.weChatBusinessCard + item,
                            originUrl: item,
                            thumbUrl: Config.weChatBusinessCard + item,
                            imgStatus: "OLD"
                        };
                    })
                || []
            );
        }
        setFlag("edit");
        setTabIndex("2");
    }

    const update = (model, e) => {
        e.stopPropagation();
        messageShowHandler();
        setCheckboxState([model]);
        setSelectedRowKeys([model.proBrandId]);
        initForm(model);
        setLogoFile([{
            url: Config.proBrandImg + model.brandLogo,
            originUrl: model.brandLogo,
            thumbUrl: Config.proBrandPath + model.brandLogo,
            imgStatus: "OLD"
        }])
        setImageFile(
            model.brandImg?.split(";")
                ?.map(item => {
                    return {
                        url: Config.proBrandImg + item,
                        originUrl: item,
                        thumbUrl: Config.proBrandPath + item,
                        imgStatus: "OLD"
                    };
                })
            || []
        );
        if(model.contactImg) {
            setHeadFile([{
                url: Config.proBrandImg + model.contactImg,
                originUrl: model.contactImg,
                thumbUrl: Config.proBrandImg + model.contactImg,
                imgStatus: "OLD"
            }]);
        }
        if(model.wechat) {
            setWechatImgFile(
                model.wechat?.split(";")
                    ?.map(item => {
                        return {
                            url: Config.weChatBusinessCard + item,
                            originUrl: item,
                            thumbUrl: Config.weChatBusinessCard + item,
                            imgStatus: "OLD"
                        };
                    })
                || []
            );
        }
        setFlag("edit");
        setTabIndex("2");
    }

    const initForm = (model = {}) => {
        form.resetFields();
        form.setFieldsValue({
            brandTitle: model.brandTitle ?? '',
            brandDesc: model.brandDesc ?? '',
            contactTitle: model.contactTitle ?? '',
            contactPhone: model.contactPhone ?? '',
            contactLanguage: model.contactLanguage?.split('|') ?? [],
        });
    }

    const deleteOne = (model, e) => {
        e.stopPropagation();
        setCheckboxState([model]);
        setSelectedRowKeys([model.proBrandId]);
        setType();
        setContent();
        setFrameType("delete");
        setIsPopupFrame(true);
    }

    const popupFrameClose = (state) => {
        if(state) {
            if(frameType === "delete") {
                deleteData();
            }
        }
        setIsPopupFrame(false);
    }

    const deleteData = () => {
        setIsLoad(true);
        setSpinInfo("数据保存中...");
        let deleteModel = checkboxState[0];
        deleteModel.menuFuncId = Config.funcInfo.funcId;
        Base.callBackApi("proBrand/delete", deleteModel).then((data) => {
            if ("SUCCESS" === data.msg) {
                search(pageSize, current, "Y", false);
                errFrameShow("success", "刪除成功");
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }

    const audit = () => {
        messageShowHandler();//清空提示信息
        if(!checkboxState || checkboxState.length !== 1) {
            errFrameShow("error", "请选择一条数据");
            return;
        }
        setAuditModel(checkboxState[0]);
        setIsAudit(true);
    }

    const auditCancel = () => {
        setIsAudit(false);
    }

    /**查看图片 */
    const handlePreview = (file) => {
        setPreviewImage(file.url || file.originUrl);
        setPreviewVisible(true);
    }

    const checkLogo = (value) => {
        if(!!logoFile && logoFile.length > 0) {
            if(value) {
                let list = value.fileList;
                if(list.find(item => item.status === "uploading")) {
                    return Promise.reject("请等待图片上传完成");
                }
                if(list.find(item => item.status === "error")) {
                    return Promise.reject("请先处理测试上传失败的图片");
                }
                // if(logoFile.find(item => item.status === "irregular")) {
                //     return Promise.reject("图片尺寸不符合规则");
                // }
            }
            return Promise.resolve();
        }
        return Promise.reject("图片数量必须大于0");
    }

    const checkImg = (value) => {
        if(!!imageFile && imageFile.length > 0) {
            if(value) {
                let list = value.fileList;
                if(list.find(item => item.status === "uploading")) {
                    return Promise.reject("请等待图片上传完成");
                }
                if(list.find(item => item.status === "error")) {
                    return Promise.reject("请先处理测试上传失败的图片");
                }
                // if(imageFile.find(item => item.status === "irregular")) {
                //     return Promise.reject("图片尺寸不符合规则");
                // }
            }
            return Promise.resolve();
        }
        return Promise.reject("图片数量必须大于0");
    }

    const checkHead = (value) => {
        if(!!headFile && headFile.length > 0) {
            if(value) {
                let list = value.fileList;
                if(list.find(item => item.status === "uploading")) {
                    return Promise.reject("请等待图片上传完成");
                }
                if(list.find(item => item.status === "error")) {
                    return Promise.reject("请先处理测试上传失败的图片");
                }
            }
            return Promise.resolve();
        }
        return Promise.reject("图片数量必须大于0");
    }

    const checkWechat = (value) => {
        if(!!wechatImgFile && wechatImgFile.length > 0) {
            if(value) {
                let list = value.fileList;
                if(list.find(item => item.status === "uploading")) {
                    return Promise.reject("请等待图片上传完成");
                }
                if(list.find(item => item.status === "error")) {
                    return Promise.reject("请先处理测试上传失败的图片");
                }
            }
            return Promise.resolve();
        }
        return Promise.reject("图片数量必须大于0");
    }

    const logoHandleChange = (file) => {
        let fileList = file.fileList;
        file = file.file;
        if(file.status === "removed") {
            fileList = [];
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = (e) => { 
                var img = document.createElement("img");
                img.src = e.target.result;
                img.onload = function () {
                    if(img.width < 360 || img.height < 360 || (img.width / img.height) !== 1) {
                        fileList[0].status = "irregular";
                        form.validateFields(['logo']);
                    }
                }
                fileList[0].originUrl = e.target.result; 
                fileList[0].imgStatus = "NEW";
            }
        }
        setLogoFile(fileList);
    }

    const handleChange = (file) => {
        let fileList = file.fileList;
        file = file.file;
        if(file.status === "removed") {
            let index = imageFile.findIndex(item => item.uid == file.uid);
            let fileList = JSON.parse(JSON.stringify(imageFile));
            fileList.splice(index, 1)
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = (e) => {
                var img = document.createElement("img");
                img.src = e.target.result;
                img.onload = function () {
                    if(img.width < 400 || img.height < 400 || (img.width / img.height) !== 1) {
                        fileList[fileList.length - 1].status = "irregular";
                        form.validateFields(['img']);
                    }
                }
                fileList[fileList.length - 1].originUrl = e.target.result;
                fileList[fileList.length - 1].imgStatus = "NEW";
            }
        }
        setImageFile(fileList);
    }

    const headHandleChange = (file) => {
        let fileList = file.fileList;
        file = file.file;
        if(file.status === "removed") {
            fileList = [];
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = (e) => { 
                fileList[0].originUrl = e.target.result; 
                fileList[0].imgStatus = "NEW";
            }
        }
        setHeadFile(fileList);
    }

    
    const wechatImgChange = (file) => {
        let fileList = file.fileList;
        file = file.file;
        if(file.status === "removed") {
            let index = imageFile.findIndex(item => item.uid == file.uid);
            let fileList = JSON.parse(JSON.stringify(imageFile));
            fileList.splice(index, 1)
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = (e) => { 
                fileList[fileList.length - 1].originUrl = e.target.result; 
                fileList[fileList.length - 1].imgStatus = "NEW";
            }
        }
        setWechatImgFile(fileList);
    }

    const onFinish = (values) => {
        messageShowHandler();
        setIsLoad(true);
        setSpinInfo("数据保存中...")
        let url = "";
        if(flag === "add") {
            url = "proBrand/insert";
        } else {
            url = "proBrand/update"
            values.proBrandId = checkboxState[0].proBrandId;
            values.lastUpdatedDate = checkboxState[0].lastUpdatedDate;
        }
        values.imageUploadModel = {
            status: logoFile[0].imgStatus,
            url: logoFile[0].originUrl,
        };
        let imageUploadModelList = imageFile.map(item => {
            return {
                status: item.imgStatus,
                url: item.originUrl,
            };
        });
        if(!!wechatImgFile && wechatImgFile.length > 0){
            values.wechatImgFileList = wechatImgFile.map(item => {
                return {
                    status: item.imgStatus,
                    url: item.originUrl,
                };
            });
            
        }
        values.imageUploadModelList = imageUploadModelList;
        if(!!headFile && headFile.length > 0) {
            values.contactImgModel = {
                status: headFile[0].imgStatus,
                url: headFile[0].originUrl,
            };
        }
        values.contactLanguage = values.contactLanguage.join('|');
        values.menuFuncId = Config.funcInfo.funcId;
        Base.callBackApi(url, values).then((data) => {
            if ("SUCCESS" === data.msg) {
                search(pageSize, current, "Y", false);
                cancel();
                errFrameShow("success", "保存成功");
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }

    const cancel = () => {
        messageShowHandler();
        clearSelect();
        setLogoFile([]);
        setImageFile([]);
        setHeadFile([]);
        setTabIndex("1");
    }

    //分页 页码切换变化查询
    const onChange = (currentNew, pageSizeNew) => {
        search(pageSizeNew, currentNew, "Y");
    }
    //分页 每页條數变化查询
    const onShowSizeChange = (currentNew, pageSizeNew) => {
        search(pageSizeNew, 1, "Y");
    }

    //列表选中记录
    const onSelectChange = (selectedRowKeys, selectedRows) => {
        setSelectedRowKeys(selectedRowKeys);
        setCheckboxState(selectedRows);
    }
    //清空列表选中
    const clearSelect = () => {
        setSelectedRowKeys([]);
        setCheckboxState([]);
    }

    const errFrameShow = (style, msg) => {
        setMessage(msg);
        setMessageStyle(style);
        setMessageShow(true);
        document.documentElement.scrollTop = 0;
    }

    const messageShowHandler = () => {
        setMessage("");
        setMessageShow(false);
    }

    const wechatButton = (
        <div>
            <PlusOutlined />
            <div>添加二维码</div>
        </div>
    );


    /*eslint-disable*/
    const columns = [
        {
            title: '品牌Logo',
            dataIndex: 'brandLogo',
            className: 'tableWidth8',
            render: (text) => <img className="proBrandLogo" alt='' src={Config.proBrandPath + text}/>
        },
        {
            title: '品牌名称',
            dataIndex: 'brandTitle',
            className: 'tableWidth8',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'brandTitle') },
        },
        {
            title: '品牌介绍',
            dataIndex: 'brandDesc',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'brandDesc') },
            render: (text) => <Popover className='popover' content={text}>
                <div className='proBrandDesc'>{text}</div>
            </Popover>
        },
        {
            title: '联络人',
            dataIndex: 'contactTitle',
            className: 'tableWidth8',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'contactTitle') },
        },
        {
            title: '联络人电话',
            dataIndex: 'contactPhone',
            className: 'tableWidth8',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'contactPhone') },
        },
        {
            title: '操作',
            dataIndex: '',
            className: 'tableWidth14 tableRightAlignment',
            render: (text, record) => <div className='sysOperation proBrandEstateOperation'>
                <div className='sysUpdate' onClick={(e) => update(record ,e)}><EditOutlined /> 修改</div>
                <div className='sysDelete' onClick={(e) => deleteOne(record,e)}><DeleteOutlined /> 删除</div>
            </div>
        },
    ];
    /*eslint-enable*/

    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
        type: 'radio',
    }

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div>添加图片</div>
        </div>
    );

    return (
        <div id="container" className="template proBrand">
            <div className='breadcrumb'>
                <HomeOutlined /> /  品牌 / <span className='breadcrumbTitle'>品牌</span>
            </div>
            {messageShow ? <Message style={messageStyle} content={message} close={messageShowHandler}/> : ''}
            <div className='templateMain'>
                <div className="sysModelTitle">品牌</div>
                <Spin spinning={isLoad} size="large" tip={spinInfo}>
                    {/* 删除弹框 */}
                    {isPopupFrame && <PopupFrame close={popupFrameClose} type={type} content={content} />}
                    {/* 审核彈框 */}
                    <Audit visible={isAudit} auditModel={auditModel} close={auditCancel} />
                    <Tabs activeKey={tabIndex} onChange={(e) => handleSelect(e)}>
                        <TabPane tab="列表" key="1">
                            <div className='sysRow'>
                                <div className='sysCol'>
                                    <div className="sysTitle">品牌名称</div>
                                    <div className="sysSerchInput">
                                        <Input type="text" placeholder="品牌名称" maxLength={50} value={searchModel.brandTitle}
                                            onChange={(e) => searchChange("brandTitle", e.target.value)}
                                            onKeyDown={(e) => e.key === "Enter" ? search(pageSize, 1, "N", true) : null} />
                                    </div>
                                </div>
                                <div className='sysSearchButton'>
                                    <Button className="sysBtn btnBule" type="primary" onClick={() => search(pageSize, 1, "N", true)} hidden={Config.funcInfo.canSelect === "N"} icon={<SearchOutlined />}>查询</Button>
                                    <Button className="sysBtn" onClick={reset} icon={<RedoOutlined />}>重置</Button>
                                </div>
                            </div>
                            <div className="sysLineEight"></div>
                            <Space size={[5, 5]} wrap className="sysButtonRelative">
                                <Button className="sysBtn btnBule" type="primary" onClick={add} hidden={Config.funcInfo.canInsert === "N"} icon={<PlusCircleOutlined />}>新增</Button>
                                {tables.length == 0 ? null :
                                    <>
                                        <Button className="sysBtn btnBule" type="primary" onClick={audit} hidden={Config.funcInfo.canAudit === "N"} icon={<EyeOutlined />}>日志</Button>
                                    </>
                                }
                            </Space>
                            <div className="sysTable" id="tableOne">
                                <Table
                                    rowSelection={rowSelection} //选择
                                    rowKey="proBrandId"
                                    columns={columns} //列名
                                    dataSource={tables} //数据
                                    pagination={{  //分页
                                        showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                        onChange: onChange,                        //上页下页或者跳页方法
                                        onShowSizeChange: onShowSizeChange,        //每页显示多少条数据方法
                                        total: tablesCount,             //总共多少条数据
                                        current: current,                //当前页
                                        pageSize: pageSize,              //每页显示多少条数据
                                        defaultCurrent: 1,                               //默认当前页为1
                                        size:"small"
                                    }}
                                    onRow={(record) => {
                                        return {
                                            onClick: event => Base.selectRadioRowNew.call(this, record, 'proBrandId', selectedRowKeys, setCheckboxState, setSelectedRowKeys),
                                        };
                                    }}
                                />
                            </div>
                        </TabPane>
                        <TabPane tab="品牌" key="2" forceRender={true}>
                            <Form form={form} onFinish={onFinish}>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">品牌名称</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="brandTitle"
                                                rules={[
                                                    { required: true, message: '品牌名称不能为空' },
                                                ]}
                                            >
                                                <Input type="text" placeholder="品牌名称" maxLength={50}/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam">品牌介绍</div>
                                        <div className="sysInputs">
                                            <Form.Item
                                                name="brandDesc"
                                            >
                                                <TextArea placeholder="品牌介绍" maxLength={200} rows={3}/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">品牌Logo</div>
                                        <div className="sysInputs">
                                            <Form.Item 
                                                name="logo"
                                                rules={[
                                                    { required: true, validator: (rule, value) => checkLogo(value) }
                                                ]}
                                            >
                                                <ImgCrop rotationSlider aspect={1/1}>
                                                    <Upload
                                                        action={Config.uploadTestServer} 
                                                        key={"1"}
                                                        accept=".png, .jpg, .jpeg"
                                                        listType="picture-card"
                                                        fileList={logoFile}
                                                        onPreview={handlePreview}
                                                        onChange={(file) => logoHandleChange(file)}>
                                                        {logoFile.length >= logoNum ? null : <div className='plusOutBox'>
                                                            <PlusOutlined />
                                                            <div>添加图片</div>
                                                            <div className='plusOutDesc'>(推荐上传宽高大于等于360*360像素的1:1尺寸的图片)</div>
                                                        </div>}
                                                    </Upload>
                                                </ImgCrop>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">品牌图片</div>
                                        <div className="sysInputs">
                                            <Form.Item 
                                                name="img"
                                                rules={[
                                                    { required: true, validator: (rule, value) => checkImg(value) }
                                                ]}
                                            >
                                                <ImgCrop rotationSlider aspect={1/1}>
                                                    <Upload
                                                        action={Config.uploadTestServer} 
                                                        key={"1"}
                                                        accept=".png, .jpg, .jpeg"
                                                        listType="picture-card"
                                                        fileList={imageFile}
                                                        onPreview={handlePreview}
                                                        onChange={(file) => handleChange(file)}>
                                                        {imageFile.length >= imageNum ? null : <div className='plusOutBox'>
                                                            <PlusOutlined />
                                                            <div>添加图片</div>
                                                            <div className='plusOutDesc'>(推荐上传宽高大于等于400*400像素的1:1尺寸的图片)</div>
                                                        </div>}
                                                    </Upload>
                                                </ImgCrop>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <h4 className='box'>联络人信息</h4>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">联络人</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="contactTitle"
                                                rules={[
                                                    { required: true, message: '联络人不能为空' },
                                                ]}
                                            >
                                                <Input type="text" placeholder="联络人" maxLength={50}/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">电话</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="contactPhone"
                                                rules={[
                                                    { required: true, message: '电话不能为空' },
                                                ]}
                                            >
                                                <Input type="text" placeholder="电话" maxLength={30}/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">沟通语言</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="contactLanguage"
                                                rules={[
                                                    { required: true, message: '沟通语言不能为空' },
                                                ]}
                                            >
                                                <Checkbox.Group options={contactLanguageList}  />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">头像</div>
                                        <div className="sysInput">
                                            <Form.Item 
                                                name="logo"
                                                rules={[
                                                    { required: true, validator: (rule, value) => checkHead(value) }
                                                ]}
                                            >
                                                <Upload
                                                    action={Config.uploadTestServer} 
                                                    key={"1"}
                                                    accept=".png, .jpg, .jpeg"
                                                    listType="picture-card"
                                                    fileList={headFile}
                                                    onPreview={handlePreview}
                                                    onChange={(file) => headHandleChange(file)}>
                                                    {headFile.length >= logoNum ? null : uploadButton}
                                                </Upload>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">微信二维码</div>
                                        <div className="sysInput">
                                            <Form.Item 
                                                name="wechat"
                                                rules={[
                                                    { required: true, validator: (rule, value) => checkWechat(value) }
                                                ]}
                                            >
                                                <Upload
                                                    action={Config.uploadTestServer} 
                                                    key={"1"}
                                                    accept=".png, .jpg, .jpeg"
                                                    listType="picture-card"
                                                    fileList={wechatImgFile}
                                                    onPreview={handlePreview}
                                                    onChange={(file) => wechatImgChange(file)}>
                                                    {wechatImgFile.length >= 1 ? null : wechatButton}
                                                </Upload>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className="sysSaveButton">
                                    <Button className="sysBtn btnBule" type="primary" disabled={isLoad} htmlType="submit" icon={<CheckCircleOutlined />}>保存</Button>
                                    <Button className="sysBtn" onClick={cancel} icon={<CloseCircleOutlined />}>取消</Button>
                                </div>
                            </Form>
                            <Modal title="放大图" visible={previewVisible} footer={null} onCancel={() => setPreviewVisible(false)}>
                                <img alt="example" style={{ width: '100%' }} src={previewImage} />
                            </Modal>
                        </TabPane>
                    </Tabs>
                </Spin>
            </div>
        </div>
    )

}

export default ProBrand;