import React, { useState, useEffect } from 'react';

import { Tree, Button, Row, Spin } from 'antd';
import { CheckCircleOutlined, CloseOutlined,HomeOutlined, DownOutlined, UpOutlined, RightOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';//props传参检查库

import { Config } from '../../public/js/config';
import { Base } from '../../public/js/base';
import Message from '../../component/message/message';
import './sysUserFavorite.css'

const UserFavorite = (props) => {

    const [isLoad, setIsLoad] = useState(false);
    const [spinInfo, setSpinInfo] = useState("");
    const [messageShow, setMessageShow] = useState(false);
    const [messageStyle, setMessageStyle] = useState("");
    const [message, setMessage] = useState("");
    const [treeData, setTreeData] = useState([]);
    const [selectionList, setSelectionList] = useState([]);
    const [rightList, setRightList] = useState([]);

    useEffect(() => {
      searchData();
      search();
    }, []);

  const search = () => {
      messageShowHandler();
      let model = {
        userId: Config.userInfo.userId,
      }
      Base.callBackApi("userFavorite/search", model).then((data) => {
        if ("SUCCESS" === data.msg) {
          let checkedList = [];
          let list = data.object;
          for (let item of list) {
            checkedList.push(item.funcId)
          }
          setRightList(list);
          setSelectionList(checkedList)
        } else {
          errFrameShow("error", data.msg);
        }
    }).catch(err => { err });
  }

  const searchData = () => {
    let model = { roleId: Config.userInfo.defaultRoleId }
    Base.callBackApi("userFavorite/searchUserAllFunc", model).then((data) => {
        if ("SUCCESS" === data.msg) {
          setTreeData(data?.object);
        } else {
          errFrameShow("error", data.msg);
        }
    }).catch(err => { err });
  }

  const onCheck = (e) => {
    messageShowHandler();
    setSelectionList(e);
  }

  const toRight = () => {
    messageShowHandler();
    let list = [];
    selectionList.forEach(item => {
      treeData.forEach(treeItem => {
        if (Base.isNotEmpty(treeItem.children)) {
          let menu2List = treeItem.children;
          menu2List.forEach(menu2Item => {
            if (menu2Item.children != null) {
              let menu3List = menu2Item.children
              menu3List.forEach(menu3Item => {
                if (menu3Item.funcId == item) {
                  menu3Item.super1Url = menu2Item.funcId
                  menu3Item.super2Url = treeItem.funcId
                  list.push(menu3Item)
                }
              })
            } else {
              if (menu2Item.funcId == item) {
                menu2Item.super1Url = treeItem.funcId
                list.push(menu2Item)
              }
            }
          });
        }
      });
    });
    setRightList(list);
  }

  const cancel = (item) => {
    messageShowHandler();
    let selectionListData = JSON.parse(JSON.stringify(selectionList));
    let rightListData = JSON.parse(JSON.stringify(rightList));
    for (let i = rightListData.length - 1; i >= 0; i--) {
      if (rightListData[i].funcId == item.funcId) {
        rightListData.splice(i, 1)
      }
    }
    for (let j = selectionListData.length - 1; j >= 0; j--) {
      if (selectionListData[j] == item.funcId || selectionListData[j] == item.super1Url || selectionListData[j] == item.super2Url) {
        selectionListData.splice(j, 1);
      }
    }
    setRightList(rightListData);
    setSelectionList(selectionListData);
  }

const up = (item, index) => {
    messageShowHandler();
    if (index == 0) {
      return;
    }
    let list = JSON.parse(JSON.stringify(rightList));
    let firstList = [];
    firstList.push(list[index]);
    list.splice(index, 1);
    firstList = firstList.concat(list);
    setRightList(firstList);
  }

const down = (item, index) => {
    messageShowHandler();
    let list = JSON.parse(JSON.stringify(rightList));
    if (index == list.length - 1) {
      return;
    }
    list.splice(index, 1);
    list.push(item);
    setRightList(list);
  }

const save = () => {
    let model = {
      userId: Config.userInfo.userId,
      funcList: rightList,
      menuFuncId: Config.funcInfo.funcId,
    }
    messageShowHandler();
    setIsLoad(true);
    setSpinInfo("数据保存中...")
    Base.callBackApi("userFavorite/setUp", model).then((data) => {
      if ("SUCCESS" === data.msg) {
        errFrameShow("success", "保存成功");
      } else {
        errFrameShow("error", data.msg);
      }
  }).catch(err => { }).finally(() => {
        setIsLoad(false);
        props.searchMenu();//刷新menu
      });
  }

  const errFrameShow = (style, msg) => {
    setMessage(msg);
    setMessageStyle(style);
    setMessageShow(true);
    document.documentElement.scrollTop = 0;
  }

  const messageShowHandler = () => {
      setMessage("");
      setMessageShow(false);
  }

  return (
    <div className="sysUserFavorite">
      <div id="container" className="template">
        <div className='breadcrumb'>
          <HomeOutlined /> / 我的最爱 / <span className='breadcrumbTitle'>设置最爱</span>
        </div>
        {/* 错误信息弹框 */}
        {messageShow ? <Message style={messageStyle} content={message} close={messageShowHandler.bind(this)} /> : ''}
        <div className='templateMain'>
          <div className="sysModelTitle">设置最爱</div>
          {/* <Tabs className="sysUserFavoriteTabs" activeKey={tabIndex} onChange={(e) => handleSelect(e)}> */}
          <Spin spinning={isLoad} size="large" tip={spinInfo}>
            <Row>
              <div className="sysUserFavoriteShuttle">
                <div className="sysUserFavoriteSelect">
                  <p className="sysUserFavoriteTitle">模块选择</p>
                  <div className="sysUserFavoriteLeft">
                    {Base.isNotEmpty(treeData) ?
                      <Tree
                        key={"funcId"}
                        checkable
                        defaultExpandAll
                        checkedKeys={selectionList}
                        onCheck={(e) => onCheck(e)}
                        treeData={treeData}
                      />
                      : null}
                  </div>
                </div>
                <div className="sysUserFavoriteTransmit">
                  <div className="sysUserFavoriteTransmitRight">
                    <RightOutlined hidden={Config.funcInfo.canInsert === "N"} onClick={() => toRight()} />
                  </div>
                </div>
                <div className="sysUserFavoriteFavourite">
                  <p className="sysUserFavoriteTitle">最爱</p>
                  <div className="sysUserFavoriteRight">
                    <div className="sysUserFavoriteaaa">
                      {rightList.map((item, index) => {
                        return (
                          <div key={item.funcId} className="sysUserFavoriteFunc">
                            <div className="sysUserFavoriteFuncName">{item.funcName}</div>
                            <div hidden={Config.funcInfo.canUpdate === "N"} className="sysUserFavoriteDown" onClick={() => down(item, index)}><DownOutlined /><div className="down">置底</div></div>
                            <div hidden={Config.funcInfo.canUpdate === "N"} className="sysUserFavoriteUp" onClick={() => up(item, index)}><UpOutlined /><div className="up">置顶</div></div>
                            <div hidden={Config.funcInfo.canDelete === "N"} className="sysUserFavoriteClose" onClick={() => cancel(item)}><CloseOutlined /></div>
                          </div>)
                      })
                      }
                    </div>
                  </div>
                </div>
                <div className="sysUserFavoriteButton">
                  <Button className="sysUserFavoriteButtonRight sysBtn btnBule" type="primary" disabled={isLoad} icon={<CheckCircleOutlined />} onClick={() => { save() }}>保存</Button>
                </div>
              </div>
            </Row>
          </Spin>
          {/* </Tabs> */}
        </div>
      </div>
    </div>
  );
}

UserFavorite.propTypes = {
  searchMenu: PropTypes.func.isRequired,
}
export default UserFavorite;