import React, { useState, useEffect } from 'react';
import { Button, Table, Form, Radio, Spin, Select, Input, Tabs,Switch, Space } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, SearchOutlined, HomeOutlined ,RedoOutlined, PlusCircleOutlined, EditOutlined, DeleteOutlined, EyeOutlined, CaretRightOutlined } from '@ant-design/icons';
import Message from '../../component/message/message';//提示信息弹框
import PopupFrame from '../../component/popupFrame/popupFrame';//删除弹框
import Audit from '../../component/audit/audit';//审核彈框
import { Config } from '../../public/js/config';
import { Base } from '../../public/js/base';
import context from '../../public/js/context';

const { TabPane } = Tabs;
const { Option } = Select;

const TimedTask = (props) => {
    const [form] = Form.useForm();
    const [tabIndex, setTabIndex] = useState("1");
    const [tables, setTables] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [checkboxState, setCheckboxState] = useState([]);
    const [messageShow, setMessageShow] = useState(false);
    const [messageStyle, setMessageStyle] = useState("error");
    const [message, setMessage] = useState("");
    const [isAudit, setIsAudit] = useState(false);
    const [isDeleteFrame, setIsDeleteFrame] = useState(false);
    const [isLoad, setIsLoad] = useState(false);
    const [spinInfo, setSpinInfo] = useState("");
    const [current, setCurrent] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [tablesCount, setTablesCount] = useState(0);
    const [searchModel, setSearchModel] = useState({});
    const [oldSearchModel, setOldSearchModel] = useState({});
    const [auditModel, setAuditModel] = useState({});
    const [jobGroupList, setJobGroupList] = useState([]);
    const [flag, setFlag] = useState("");
    const [executeOnceJobId, setExecuteOnceJobId] = useState(null);
    const [deleteMsg, setDeleteMsg] = useState("您确认要删除吗？");
    const [popupType, setPopupType] = useState("");
    const [jobModel, setJobModel] = useState({});
    
    useEffect(() => {
        search(pageSize, 1, "N");
        searchJobGroup();
    }, []);

    const searchJobGroup = () => {
        Base.callBackApi("commonDropDown/searchSysParam", { segment: "SYS_JOB_GROUP" }).then((data) => {
            if ("SUCCESS" === data.msg) {
                setJobGroupList(data.object);
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { });
    }

    const search = (pageSizeNew, currentPageNew, flag, isShowMsg) => {
        if(Config.funcInfo.canSelect === "N") {
            return;
        }
        messageShowHandler();//清除提示信息
        clearSelect();//清除選中状态
        let model = {};
        if ("Y" === flag) {//是否使用原查询條件
            model = oldSearchModel;
        } else {
            model = searchModel;
        }
        model.pageSize = pageSizeNew;
        model.page = currentPageNew;
        setIsLoad(true);
        setSpinInfo("查找中...")
        Base.callBackApi("sysJob/search", model).then((data) => {
            if ("SUCCESS" == data.msg) {
                if (Base.isEmpty(data.object.list) && isShowMsg) {
                    errFrameShow("info", '找不到符合条件记录.');
                }
                if("N" === flag) {
                    setOldSearchModel(JSON.parse(JSON.stringify(model)));
                }
                setTablesCount(data?.object?.total ?? 0);
                setTables(data?.object?.list ?? []);
                setCurrent(currentPageNew);
                setPageSize(pageSizeNew);
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => ({ err })).finally(() => {
            setIsLoad(false);
        });
    }

    const reset = () => {
        messageShowHandler();
        clearSelect();
        setSearchModel({});
        setOldSearchModel({});
        setTablesCount(0);
        setTables([]);
        setPageSize(10);
    }

    const searchChange = (key, value) => {
        messageShowHandler();
        setSearchModel(model =>{
            return {...model,...{[key]:value}};
        });
    }

    const resetFrom = (model = {}) => {
        form.setFieldsValue({
            jobName: model.jobName ?? '',
            jobGroup: model.jobGroup ?? '',
            invokeTarget: model.invokeTarget ?? '',
            cronExpression: model.cronExpression ?? '',
            misfirePolicy: model.misfirePolicy ?? '',
            concurrent: model.concurrent ?? '',
            status: model.status ?? '',
        });
    }

    const add = () => {
        messageShowHandler();
        setTabIndex("2");
        setFlag("add");
        resetFrom({
            misfirePolicy: 1,
            concurrent: "N",
            status: "Y"
        });
    }

    const edit = () => {
        messageShowHandler();
        if(!checkboxState || checkboxState.length !== 1) {
            errFrameShow("error", "请选择一条数据");
        } else {
            setTabIndex("2");
            setFlag("edit");
            resetFrom(checkboxState[0]);
        }
    }

    const update = (model, e) => {
        e.stopPropagation();
        setCheckboxState([model]);
        setSelectedRowKeys([model.jobId]);
        resetFrom(model);
        setFlag("edit");
        setTabIndex("2");
    }

    const deleteFrameHandler = () => {
        messageShowHandler();
        if(!selectedRowKeys || selectedRowKeys.length === 0) {
            errFrameShow("error", "请选择至少一条数据");
        } else {
            setDeleteMsg("您确认要删除吗？");
            setPopupType("delete")
            setIsDeleteFrame(true);
        }
    }

    const deleteFrameClose = (state) => {
        if(state) {
            if(popupType === "delete") {
                deleteJob();
            } else if(popupType === "execute") {
                executeOnce();
            } else if(popupType === "change") {
                changeStatus();
            }
        }
        clearSelect();
        setIsDeleteFrame(false);
    }

    const deleteJob = () => {
        let model = {
            menuFuncId: Config.funcInfo.funcId,
            jobIds: selectedRowKeys
        }
        setIsLoad(true);
        setSpinInfo("数据保存中...");
        Base.callBackApi("sysJob/delete", model).then((data) => {
            if ("SUCCESS" == data.msg) {
                search(pageSize, current, "Y");
                errFrameShow("success", "保存成功");
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => ({ err })).finally(() => {
            setIsLoad(false);
        });
    }

    const audit = () => {
        messageShowHandler();
        if(!checkboxState || checkboxState.length !== 1) {
            errFrameShow("error", "请选择一条数据");
        } else {
            setAuditModel(checkboxState[0]);
            setIsAudit(true);
        }
    }

    const auditCancel = () => {
        setIsAudit(false);
    }

    const handleSelect = (key) => {
        messageShowHandler();//清除提示信息
        if (key !== undefined) {
            if (key === "2") {
                if(checkboxState.length === 1 && Config.funcInfo.canUpdate === "Y") {
                    edit();
                } else if(Config.funcInfo.canInsert === "Y") {
                    add();
                }
            } else {
                setTabIndex("1");
            }
        }
    }
    
    const onFinish = (values) => {
        values.menuFuncId = Config.funcInfo.funcId;
        setIsLoad(true);
        setSpinInfo("数据保存中...");
        let url = flag === "add" ? "insert" : "update";
        if(flag === "edit") {
            values.jobId = selectedRowKeys[0];
        }
        Base.callBackApi("sysJob/" + url, values).then((data) => {
            if ("SUCCESS" == data.msg) {
                cancel();
                search(pageSize, current, "Y", false);
                errFrameShow("success", "保存成功");
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => ({ err })).finally(() => {
            setIsLoad(false);
        });
    };


    const cancel = () => {
        messageShowHandler();
        clearSelect();
        setTabIndex("1");
    }

    const changeStatusPopup = (checked, model) => {
        messageShowHandler();
        if(checked) {
            model.status = "Y";
        } else {
            model.status = "N";
        }
        setJobModel(model);
        setDeleteMsg('确认要' + (checked ? '"启用""' : '"停用""') + model.jobName + '"任务吗?');
        setPopupType("change");
        setIsDeleteFrame(true);
    }

    const changeStatus = () => {
        let model = jobModel;
        model.menuFuncId = Config.funcInfo.funcId;
        setIsLoad(true);
        setSpinInfo("数据保存中...");
        Base.callBackApi("sysJob/changeStatus", model).then((data) => {
            if ("SUCCESS" == data.msg) {
                search(pageSize, current, "Y");
                errFrameShow("success", "保存成功");
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => ({ err })).finally(() => {
            setIsLoad(false);
        });
    }

    const executeOncePopup = (model, e) => {
        e.stopPropagation();
        messageShowHandler();
        setExecuteOnceJobId(model.jobId);
        setDeleteMsg('确认要立即执行一次"' + model.jobName + '"任务吗?');
        setPopupType("execute");
        setIsDeleteFrame(true);
    }

    const executeOnce = () => {
        setIsLoad(true);
        setSpinInfo("任务执行中...");
        Base.callBackApi("sysJob/run", executeOnceJobId).then((data) => {
            if ("SUCCESS" == data.msg) {
                errFrameShow("success", "执行成功");
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => ({ err })).finally(() => {
            setIsLoad(false);
        });
    }

    //分页 页码切换变化查询
    const onChange = (current, pageSize) => {
        search(pageSize, current, "Y");
    }
    //分页 每页條數变化查询
    const onShowSizeChange = (current, pageSize) => {
        search(pageSize, 1, "Y");
    }

    //列表1选中记录
    const onSelectChange = (keys, selectedRows) => {
        setSelectedRowKeys(keys);
        setCheckboxState(selectedRows);
    }

    //清空列表1选中
    const clearSelect = () => {
        setSelectedRowKeys([]);
        setCheckboxState([]);
    }

    /** 生成提示信息 */
    const errFrameShow = (style, msg) => {
        setMessageStyle(style);
        setMessage(msg);
        setMessageShow(true);
        document.documentElement.scrollTop = 0;//返回顶部
    }

    /** 清空提示信息 */
    const messageShowHandler = () => {
        setMessage("");
        setMessageShow(false);
    }

    const options = [
        { label: '立即执行', value: 1 },
        { label: '执行一次', value: 2 },
        { label: '放弃执行', value: 3 },
    ]

    /*eslint-disable*/
    const columns = [
        {
            title: '任务名称',
            dataIndex: 'jobName',
            className: 'tableWidth15',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'jobName') },
        },
        {
            title: '任务组名',
            dataIndex: 'jobGroup',
            className: 'tableWidth15',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'jobGroup') },
            render: (text) => getJobGroup(text)
        },
        {
            title: '调用目标字符串',
            dataIndex: 'invokeTarget',
            className: 'tableWidth20',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'invokeTarget') },
        },
        {
            title: 'cron执行表达式',
            dataIndex: 'cronExpression',
            className: 'tableWidth20',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'cronExpression') },
        },
        {
            title: '状态',
            dataIndex: 'status',
            className: 'tableWidth15',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'status') },
            render: (text, record) => <Switch checked={text === "Y"} onChange={(e) => changeStatusPopup(e, JSON.parse(JSON.stringify(record)))}/>
        },
        {
            title: '操作',
            dataIndex: '',
            className: 'tableWidth15 tableRightAlignment',
            render: (text, record) => <div className='sysOperation proBrandEstateOperation'>
                <div className='sysOperation' onClick={(e) => executeOncePopup(record, e)}><CaretRightOutlined /> 执行一次</div>
                <div className='sysUpdate' onClick={(e) => update(record, e)}><EditOutlined /> 修改</div>
            </div>
        },
    ]
    /*eslint-enable*/

    const getJobGroup = (jobGroup) => {
        let model = jobGroupList.find(item => item.code == jobGroup);
        if(model) {
            return model.parmValue1;
        }
        return "";
    }

    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
        hideDefaultSelections: true,
    };

    return (
        <div id="container" className="template">
            <div className='breadcrumb'>
                <HomeOutlined /> / 系统管理 / <span className='breadcrumbTitle'>定时任务</span>
            </div>
            {/* 提示信息弹框 */}
            {messageShow ? <Message style={messageStyle} content={message} close={messageShowHandler} /> : ''}
            <div className='templateMain'>
                <div className="sysModelTitle">定时任务</div> 
                {/* 删除弹框 */}
                {!isDeleteFrame ? '' : <PopupFrame close={deleteFrameClose} content={deleteMsg}/>}
                {/* 审核彈框 */}
                <Audit visible={isAudit} auditModel={auditModel} close={auditCancel} />
                <Spin spinning={isLoad} size="large" tip={spinInfo}>
                    <Tabs activeKey={tabIndex} onChange={(e) => handleSelect(e)}>
                        <TabPane tab="列表" key="1">
                            <div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysTitle">任务名称</div>
                                        <div className="sysSerchInput">
                                            <Input type="text" placeholder="任务名称" value={searchModel.jobName}
                                                onChange={(e) => searchChange('jobName', e.target.value)}
                                                onKeyDown={(e) => e.key == 13 ? search(pageSize, 1, "N", true) : null} />
                                        </div>
                                    </div>
                                    <div className='sysCol'>
                                        <div className="sysTitle">任务组名</div>
                                        <div className="sysSerchInput">
                                            <Select className='sysSelect' placeholder="任务组名"
                                                value={searchModel.jobGroup}
                                                onChange={(e) => searchChange('jobGroup', e)}
                                            >
                                                <Option value=""></Option>
                                                {jobGroupList.map((item) => <Option key={item.code} value={item.code}>{item.parmValue1}</Option>)}
                                            </Select>
                                        </div>
                                    </div>
                                    <div className='sysCol'>
                                        <div className="sysTitle">任务状态</div>
                                        <div className="sysSerchInput">
                                            <Select className="sysSelect" placeholder="任务状态"
                                                value={searchModel.status}
                                                onChange={(e) => searchChange('status', e)}
                                            >
                                                <Option value=""></Option>
                                                {context.jobStatusMap.map((item) => <Option key={item.value} value={item.value}>{item.label}</Option>)}
                                            </Select>
                                        </div>
                                    </div>
                                    <div className='sysSearchButton'>
                                        <Button className="sysBtn btnBule" type="primary" onClick={() => search(pageSize, 1, "N", true)} icon={<SearchOutlined />}>查询</Button>
                                        <Button className="sysBtn" onClick={reset} icon={<RedoOutlined />}>重置</Button>
                                    </div>
                                </div>
                                <div className="sysLineEight"></div>
                                <Space size={[5, 5]} wrap className="sysButtonRelative">
                                    <Button className="sysBtn btnBule" type="primary" onClick={add} hidden={Config.funcInfo.canInsert === "N"} icon={<PlusCircleOutlined />}>新增</Button>
                                    {tables.length == 0 ? null :
                                        <>
                                            <Button className="sysBtn btnBule" type="primary" onClick={deleteFrameHandler} hidden={Config.funcInfo.canDelete === "N"} icon={<DeleteOutlined />}>删除</Button>
                                            <Button className="sysBtn btnBule" type="primary" onClick={audit} hidden={Config.funcInfo.canAudit === "N"} icon={<EyeOutlined />}>日志</Button>
                                        </>
                                    }
                                </Space>
                                <div className="sysTable" id="tableOne">
                                    <Table
                                        rowSelection={rowSelection} //选择
                                        rowKey="jobId"
                                        columns={columns} //列名
                                        dataSource={tables} //数据
                                        pagination={{  //分页
                                            showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                            onChange: onChange,                        //上页下页或者跳页方法
                                            onShowSizeChange: onShowSizeChange,        //每页显示多少条数据方法
                                            total: tablesCount,             //总共多少条数据
                                            current: current,                //当前页
                                            pageSize: pageSize,              //每页显示多少条数据
                                            defaultCurrent: 1,                               //默认当前页为1
                                            size:"small"
                                        }}
                                        onRow={(record) => {
                                            return {
                                                onClick: event => Base.selectRowNew.call(this, record, 'jobId', checkboxState, selectedRowKeys, setCheckboxState, setSelectedRowKeys), // 点击行
                                            };
                                        }}
                                    />
                                </div>
                            </div>
                        </TabPane>
                        <TabPane tab="定时任务" key="2" forceRender={true}>
                            <div className="sysSavePageWidth">
                                <Form form={form} name="sysJob" onFinish={onFinish}>
                                    <div className='sysRow'>
                                        <div className='sysCol'>
                                            <div className="sysParam mustFill">任务名称</div>
                                            <div className="sysInput">
                                                <Form.Item
                                                    name="jobName"
                                                    rules={[// 声明式验证: 直接使用别人定义好的验证规则进行验证
                                                        { required: true, message: '任务名称不能为空' },
                                                    ]}
                                                >
                                                    <Input type="text" placeholder="任务名称" maxLength={64}/>
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className='sysCol'>
                                            <div className="sysParam mustFill">任务分组</div>
                                            <div className="sysInput">
                                            <Form.Item
                                                name="jobGroup"
                                            >
                                                <Select className="sysSelect" placeholder="任务分组">
                                                    {jobGroupList.map((item) => <Option key={item.code} value={item.code}>{item.parmValue1}</Option>)}
                                                </Select>
                                            </Form.Item>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='sysRow'>
                                        <div className='sysCol'>
                                            <div className="sysParam mustFill">调用方法</div>
                                            <div className="sysInput">
                                                <Form.Item
                                                    name="invokeTarget"
                                                    rules={[// 声明式验证: 直接使用别人定义好的验证规则进行验证
                                                        { required: true, message: '調用方法不能为空' },
                                                    ]}
                                                >
                                                    <Input type="text" placeholder="調用方法" maxLength={500}/>
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className='sysCol'>
                                            <div className="sysParam mustFill">cron表达式</div>
                                            <div className="sysInput">
                                                <Form.Item
                                                    name="cronExpression"
                                                    rules={[// 声明式验证: 直接使用别人定义好的验证规则进行验证
                                                        { required: true, message: 'cron表达式不能为空' },
                                                    ]}
                                                >
                                                    <Input type="text" placeholder="cron表达式" maxLength={255}/>
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='sysRow'>
                                        <div className='sysCol'>
                                            <div className="sysParam mustFill">错误策略</div>
                                            <div className="sysInput">
                                                <Form.Item
                                                    name="misfirePolicy"
                                                >
                                                    <Radio.Group options={options} optionType="button" buttonStyle="solid"/>
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='sysRow'>
                                        <div className='sysCol'>
                                            <div className="sysParam mustFill">是否并发</div>
                                            <div className="sysInput">
                                                <Form.Item
                                                    name="concurrent"
                                                >
                                                    <Radio.Group options={context.concurrentMap} optionType="button" buttonStyle="solid"/>
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className='sysCol'>
                                            <div className="sysParam mustFill">状态</div>
                                            <div className="sysInput">
                                                <Form.Item
                                                    name="status"
                                                >
                                                    <Radio.Group options={context.jobStatusMap}/>
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sysSaveButton">
                                        <Button className="sysBtn btnBule" type="primary" disabled={isLoad} htmlType="submit" icon={<CheckCircleOutlined />}>保存</Button>
                                        <Button className="sysBtn" onClick={cancel} icon={<CloseCircleOutlined />}>取消</Button>
                                    </div>
                                </Form>
                            </div>
                        </TabPane>
                    </Tabs>
                </Spin>
            </div>
        </div>
    )

}

export default TimedTask;