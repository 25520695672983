import React, { useState, useEffect } from 'react';
import { Button, Spin, Input, Row, Col, Modal, Form, Radio } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, } from '@ant-design/icons';
import PropTypes from 'prop-types';//props传参检查库
import { Config } from '../../public/js/config';
import { Base } from '../../public/js/base';
import { ContextHandler } from '../../public/js/contextHandler';
import context from '../../public/js/context';

const { TextArea } = Input;
const ReviewPopup = (props) => {

    const [form] = Form.useForm();
    const [isLoad, setIsLoad] = useState(false);
    const [spinInfo, setSpinInfo] = useState("");

    const save = () => {
        form.submit();
    }

    const onFinish = (values) => {
        props.close(true, values);
    }

    const onCancel = () => {
        props.close(false);
    }

    return(
        <Spin size="large" spinning={isLoad} tip={spinInfo}>
            <Modal
                visible={true}//控制弹框是否显示
                title="批量审批"//标题
                centered//垂直居中
                destroyOnClose={true}//关闭即销毁？
                closable//是否显示右上角的关闭按钮
                maskClosable={false}//点击蒙层是否允许关闭	
                width={Config.equipmentInfo.type === 'iPad' ? '65%' : (Config.equipmentInfo.type === 'phone' ? '100%' : '35%')}//弹框宽度
                onCancel={onCancel}//点击取消触发事件
                footer={[
                    <Button key='save' className='sysBtn btnBule' type="primary" onClick={save} icon={<CheckCircleOutlined />}>确定</Button>, 
                    <Button key='cancel' className="sysBtn" onClick={onCancel} icon={<CloseCircleOutlined />}>取消</Button>
                ]}
            >
                <Form
                    form={form} onFinish={onFinish}
                >
                    <div className='sysRow'>
                        <div className='sysCol'>
                            <div className="sysTitleMax mustFill">审批状态</div>
                            <div className="sysInput">
                                <Form.Item name="auditStatus"
                                    rules={[
                                        { required: true, message: '审批状态不能为空' }
                                    ]}
                                >
                                    <Radio.Group name="radiogroup">
                                        {context.auditStatusMap.map((item) => <Radio key={item.value} value={item.value}>{item.label}</Radio>)}
                                    </Radio.Group>
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                    <div className='sysRow'>
                        <div className='sysCol'>
                            <div className="sysTitleMax">审批意见</div>
                            <div className="sysInput">
                                <Form.Item name="auditRemark"
                                    rules={[
                                        { required: false, message: '请填写审批意见' }
                                    ]}
                                >
                                    <TextArea rows={3} maxLength={200} />
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                </Form>
            </Modal>
        </Spin>
    )

}
ReviewPopup.propTypes = {
    close: PropTypes.func.isRequired,
}

export default ReviewPopup;