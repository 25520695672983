import React from 'react';

const NoaMatch = (props) => {

    return (
        <div>
          404
        </div>
    );

}

export default NoaMatch;