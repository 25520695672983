import React, { useState, useEffect } from 'react';
import { Button, Table, Spin, Select, Input, Tabs, DatePicker, Upload, Modal ,Space ,Form} from 'antd';
import { SearchOutlined, RedoOutlined, DeleteOutlined, EyeOutlined,HomeOutlined, CloseCircleOutlined } from '@ant-design/icons';
import moment from 'moment';//日期格式化导包
import Message from '../../component/message/message';//提示信息弹框
import PopupFrame from '../../component/popupFrame/popupFrame';//删除弹框
import { Config } from '../../public/js/config';
import { Base } from '../../public/js/base';
import { ContextHandler } from '../../public/js/contextHandler';
import context from '../../public/js/context';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

const UserCollect = (props) => {
    const [form] = Form.useForm();
    const [tables, setTables] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [checkboxState, setCheckboxState] = useState([]);
    const [messageShow, setMessageShow] = useState(false);
    const [messageStyle, setMessageStyle] = useState("error");
    const [message, setMessage] = useState("");
    const [isLoad, setIsLoad] = useState(false);
    const [spinInfo, setSpinInfo] = useState("");
    const [current, setCurrent] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [tablesCount, setTablesCount] = useState(0);
    const [searchModel, setSearchModel] = useState({});
    const [oldSearchModel, setOldSearchModel] = useState({});
    const [tabIndex, setTabIndex] = useState("1");
    const [imageFile, setImageFile] = useState([]);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);
    
    useEffect(() => {
        search(pageSize, 1, "N");
    }, []);

    const search = (pageSizeNew, currentPageNew, flag, isShowMsg) => {
        if(Config.funcInfo.canSelect === "N") {
            return;
        }
        messageShowHandler();//清除提示信息
        clearSelect();//清除選中狀態
        let model = {};
        if ("Y" === flag) {//是否使用原查詢條件
            model = oldSearchModel;
        } else {
            model = searchModel;
        }
        model.pageSize = pageSizeNew;
        model.page = currentPageNew;
        setIsLoad(true);
        setSpinInfo("查找中...")
        Base.callBackApi("userCollect/searchCollectList", model).then((data) => {
            if ("SUCCESS" == data.msg) {
                if (Base.isEmpty(data.object.list) && isShowMsg) {
                    errFrameShow("info", '找不到符合条件记录.');
                }
                if("N" === flag) {
                    setOldSearchModel(JSON.parse(JSON.stringify(model)));
                }
                setTablesCount(data?.object?.total ?? 0);
                let list = data?.object?.list;
                for(let i in list){
                    if(list[i].pbeNum == 0 && list[i].phsNum == 0){
                        list[i].states = "N"
                    }else{
                        list[i].states = "Y"
                    }
                }
                setTables(list);
                setCurrent(currentPageNew);
                setPageSize(pageSizeNew);
                console.log(list)
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => ({ err })).finally(() => {
            setIsLoad(false);
        });
    }

    const reset = () => {
        messageShowHandler();
        clearSelect();
        setSearchModel({});
        setOldSearchModel({});
        setTablesCount(0);
        setTables([]);
        setPageSize(10);
    }

    const searchChange = (key, value) => {
        setSearchModel(model =>{
            let newModel = {};
            if(key === "time") {
                newModel.beginTime = value[0];
                newModel.endTime = value[1];
                return {...model,...{beginTime:value[0],endTime:value[1]}};
            }else{
                return {...model,...{[key]:value}};
            }
        });
    }
    const view = (model ,e) => {
        console.log(model)
        e.stopPropagation();
        messageShowHandler();
        if(model.collectType == "WHOLE_RENT"){
            setImageFile(
                model.collectPicture?.split(";")
                    ?.map(item => {
                        return {
                            url: Config.proHouseSourceImg + item,
                            originUrl: item,
                            thumbUrl: Config.proHouseSourceImg + item,
                            imgStatus: "OLD"
                        };
                    })
                || []
            );
        }else{
            setImageFile(
                model.collectPicture?.split(";")
                    ?.map(item => {
                        return {
                            url: Config.proEstatePath + item,
                            originUrl: item,
                            thumbUrl: Config.proEstatePath + item,
                            imgStatus: "OLD"
                        };
                    })
                || []
            );
        }
        
        setCheckboxState([model]);
        setSelectedRowKeys([model.collectId]);
        initForm(model);
        setTabIndex("2");
    }

    const initForm = (model = {}) => {
        form.resetFields();
        form.setFieldsValue({
            userId: model.userId ?? '',
            userName: model.userName ?? '',
            houseName: model.houseName ?? '',
            collectTitle: model.collectTitle ?? '',
            collectType: model.collectType ?? '',
            rentalPrice: model.rentalPrice ?? '',
            usableArea: model.usableArea ?? '',
            areaId: model.areaId ? [model.province +  model.city +  model.area + ''] : '',
            collectTime:  model.collectTime?moment(new Date(model.collectTime), Config.dateFormatALL): null,
            states:model.states ?? '',
        });
    }

    //分页 页码切换变化查询
    const onChange = (current, pageSize) => {
        search(pageSize, current, "Y");
    }
    //分页 每页條數变化查询
    const onShowSizeChange = (current, pageSize) => {
        search(pageSize, 1, "Y");
    }
    
    const cancel = () => {
        messageShowHandler();
        clearSelect();
        setTabIndex("1");
    }


    // Tab页切换
    const handleSelect = (key) => {
        if (key) {
            if(key === "2" && Config.funcInfo.canUpdate === "Y") {
                // editPassword();
            } else if(tabIndex !== "1") {
                setTabIndex("1");
            }
        }
    }

    //清空列表1选中
    const clearSelect = () => {
        setSelectedRowKeys([]);
        setCheckboxState([]);
    }

    /**查看图片 */
    const handlePreview = (file) => {
        setPreviewImage(file.url || file.originUrl);
        setPreviewVisible(true);
    }
    

    /** 生成提示信息 */
    const errFrameShow = (style, msg) => {
        setMessageStyle(style);
        setMessage(msg);
        setMessageShow(true);
        document.documentElement.scrollTop = 0;//返回顶部
    }

    /** 清空提示信息 */
    const messageShowHandler = () => {
        setMessage("");
        setMessageShow(false);
    }

    const columns = [
        {
            title: '账号',
            dataIndex: 'userId',
            className: 'tableWidth15',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'userId') },
        },
        {
            title: '用户名',
            dataIndex: 'userName',
            className: 'tableWidth15',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'userName') },
        },
        {
            title: '标题',
            dataIndex: 'collectTitle',
            className: 'tableWidth15',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'collectTitle') },
        },
        {
            title: '收藏类型',
            dataIndex: 'collectType',
            className: 'tableWidth15',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'collectType') },
            render: (text) => ContextHandler.getMapValue("collectType", text)
        },
        {
            title: '收藏时间',
            dataIndex: 'collectTime',
            className: 'tableWidth20',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'collectTime') },
            render: (text) => moment(text).format(Config.dateFormatALL)
        },
        {
            title: '收藏状态',
            dataIndex: 'states',
            className: 'tableWidth12',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'states') },
            render: (text) => ContextHandler.getMapValue("collectState", text)
        },
        {
            title: '操作',
            dataIndex: '',
            className: 'tableWidth8 tableRightAlignment',
            render: (text, record) => <div className='sysOperation proBrandEstateOperation'>
                <div className='sysUpdate' onClick={(e) => view(record ,e)}>查看详情 </div>
            </div>
        },
    ]

    return (
        <div id="container" className="template">
             <div className='breadcrumb'>
                <HomeOutlined /> / 其他 / <span className='breadcrumbTitle'>用户收藏</span>
            </div>
            {/* 提示信息弹框 */}
            {messageShow ? <Message style={messageStyle} content={message} close={messageShowHandler} /> : ''}
            <div className='templateMain'>
                <div className="sysModelTitle">用户收藏</div>
                <Spin spinning={isLoad} size="large" tip={spinInfo}>
                    <Tabs activeKey={tabIndex} onChange={(e) => handleSelect(e)}>
                        <TabPane key="1" tab="列表">
                            <div className='sysRow'>
                                <div className='sysCol'>
                                    <div className="sysTitles">账号</div>
                                    <div className="sysSerchInput">
                                        <Input type="text" placeholder="账号" value={searchModel.userIds}
                                            onChange={(e) => searchChange('userIds', e.target.value)}
                                            onKeyDown={(e) => e.key === "Enter" ? search(pageSize, 1, "N", true) : null} />
                                    </div>
                                </div>
                                <div className='sysCol'>
                                    <div className="sysTitle">收藏类型</div>
                                    <div className="sysSerchInput">
                                        <Select className="sysSelect" placeholder="收藏类型"
                                            value={searchModel.collectType || ''}
                                            onChange={(e) => searchChange('collectType', e)}
                                        >
                                            <Option value=""></Option>
                                            {context.collectType.map((item) => <Option key={item.value} value={item.value}>{item.label}</Option>)}
                                        </Select>
                                    </div>
                                </div>
                                <div className='sysCols'>
                                    <div className="sysTitles">收藏时间</div>
                                    <div className="sysSerchInputTime">
                                        <DatePicker placeholder="开始时间"
                                            className="parts-numberInput datePickerAllWidth"
                                            allowClear={false}
                                            showTime={true}
                                            value={searchModel.startTime || ''}
                                            onChange={(value) => searchChange("startTime", value)}
                                            format={Config.dateFormatALL} />
                                    </div>
                                </div>
                                <div className='sysCols'>
                                    <div className="sysTitleTime">—</div>
                                    <div className="sysSerchInputTime">
                                        <DatePicker placeholder="结束时间"
                                            className="parts-numberInput datePickerAllWidth"
                                            allowClear={false}
                                            showTime={true}
                                            value={searchModel.endTime || ''}
                                            onChange={(value) => searchChange("endTime", value)}
                                            format={Config.dateFormatALL} />
                                    </div>
                                </div>
                            </div>
                            <div className='sysRow'>
                                <div className='sysCol'>
                                    <div className="sysTitles">收藏状态</div>
                                    <div className="sysSerchInput">
                                        <Select className="sysSelect" placeholder="收藏状态"
                                            value={searchModel.states || ''}
                                            onChange={(e) => searchChange('states', e)}
                                        >
                                            <Option value=""></Option>
                                            {context.collectState.map((item) => <Option key={item.value} value={item.value}>{item.label}</Option>)}
                                        </Select>
                                    </div>
                                </div>
                                <div className="sysSearchButton">
                                    <Button className="sysBtn btnBule" type="primary" onClick={() => search(pageSize, 1, "N", true)} icon={<SearchOutlined />}>查询</Button>
                                    <Button className="sysBtn" onClick={reset} icon={<RedoOutlined />}>重置</Button>
                                </div>
                            </div>
                            <div className="sysLineEight"></div>
                            <Space size={[5, 5]} wrap className="buttonRelative">
                                {tables.length == 0 ? null :
                                    <>
                                        {/* <Button className="sysBtn btnBule" type="primary" onClick={deleteFrameHandler} hidden={Config.funcInfo.canDelete === "N"} icon={<DeleteOutlined />}>删除</Button>
                                        <Button className="sysBtn btnBule" type="primary" onClick={clearFrameHandler} hidden={Config.funcInfo.canDelete === "N"} icon={<DeleteOutlined />}>清空</Button> */}
                                    </>
                                }
                            </Space>
                            <div className="sysTable" id="tableOne">
                                <Table
                                    // rowSelection={rowSelection} //选择
                                    rowKey="collectId"
                                    columns={columns} //列名
                                    dataSource={tables} //数据
                                    pagination={{  //分页
                                        showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                        onChange: onChange,                        //上页下页或者跳页方法
                                        onShowSizeChange: onShowSizeChange,        //每页显示多少条数据方法
                                        total: tablesCount,             //总共多少条数据
                                        current: current,                //当前页
                                        pageSize: pageSize,              //每页显示多少条数据
                                        defaultCurrent: 1,                               //默认当前页为1
                                        size:"small"
                                    }}
                                    onRow={(record) => {
                                        return {
                                            onClick: event => Base.selectRowNew.call(this, record, 'collectId', checkboxState, selectedRowKeys, setCheckboxState, setSelectedRowKeys), // 点击行
                                        };
                                    }}
                                />
                            </div>
                        </TabPane>
                        <TabPane key="2" tab="详情" forceRender={true}>
                            <Form form={form}>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam">账号</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="userId"
                                            >
                                                <Input type="text" placeholder="账号" maxLength={20} disabled/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='sysCol'>
                                        <div className="sysParam">用戶名</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="userName"
                                            >
                                                <Input type="text" placeholder="用戶名" disabled/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam">收藏类型</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="collectType"
                                            >
                                                <Select disabled>
                                                    <Option value=""></Option>
                                                    {context.collectType.map(item => <Option key={item.value} value={item.value}>{item.label}</Option>)}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='sysCol'>
                                        <div className="sysParam">状态</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="states"
                                            >
                                                <Select disabled>
                                                    <Option value=""></Option>
                                                    {context.collectState.map(item => <Option key={item.value} value={item.value}>{item.label}</Option>)}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>             
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam">标题</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="collectTitle"
                                            >
                                                <Input type="text" placeholder="标题" disabled/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='sysCol'>
                                        <div className="sysParam">屋苑名称</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="houseName"
                                            >
                                                <Input type="text" placeholder="屋苑名称" maxLength={20} disabled/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam">出租价格(HKD)</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="rentalPrice"
                                            >
                                                <Input type="text" placeholder="出租价格" maxLength={20} disabled/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='sysCol'>
                                        <div className="sysParam">实用面积(平方尺)</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="usableArea"
                                            >
                                                <Input type="text" placeholder="收藏类型" disabled/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam">地区</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="areaId"
                                            >
                                                <Input type="text" placeholder="地区" disabled/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='sysCol'>
                                        <div className="sysParam">收藏时间</div>
                                        <div className="sysInput">
                                            <Form.Item 
                                                name="collectTime" auditRemark
                                            >
                                                <DatePicker
                                                    placeholder="收藏时间"
                                                    format={Config.dateFormatALL}
                                                    showTime={true}
                                                    className="pd_100percent"
                                                    disabled={true}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCols'>
                                        <div className="sysParam">图片</div>
                                        <div className="collectImg">
                                            <Form.Item
                                                name="collectTime"
                                            >
                                               <Upload
                                                    action={Config.uploadTestServer} 
                                                    key={"1"}
                                                    accept=".png, .jpg, .jpeg"
                                                    listType="picture-card"
                                                    fileList={imageFile}
                                                    onPreview={handlePreview}
                                                    disabled
                                                >
                                                </Upload>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className="sysSaveButton">
                                    <Button className="sysBtn" onClick={cancel} icon={<CloseCircleOutlined />}>取消</Button>
                                </div>
                            </Form>
                            <Modal title="放大图" visible={previewVisible} footer={null} onCancel={() => setPreviewVisible(false)}>
                                <img alt="example" style={{ width: '100%' }} src={previewImage} />
                            </Modal>
                        </TabPane>
                    </Tabs>
                </Spin>
            </div>
        </div>
    )

}

export default UserCollect;