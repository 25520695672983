import React, { useState, useEffect } from 'react';
import { Button, Table, Spin, Select, Input, Row, Col, DatePicker, Modal, Space } from 'antd';
import { SearchOutlined, RedoOutlined, DeleteOutlined, EyeOutlined,HomeOutlined, CloseCircleOutlined } from '@ant-design/icons';
import moment from 'moment';//日期格式化导包
import Message from '../../component/message/message';//提示信息弹框
import PopupFrame from '../../component/popupFrame/popupFrame';//删除弹框
import { Config } from '../../public/js/config';
import { Base } from '../../public/js/base';
import { ContextHandler } from '../../public/js/contextHandler';
import context from '../../public/js/context';

const { Option } = Select;
const { RangePicker } = DatePicker;

const Square = (props) => {
    const [tables, setTables] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [checkboxState, setCheckboxState] = useState([]);
    const [messageShow, setMessageShow] = useState(false);
    const [messageStyle, setMessageStyle] = useState("error");
    const [message, setMessage] = useState("");
    const [isDeleteFrame, setIsDeleteFrame] = useState(false);
    const [deleteMsg, setDeleteMsg] = useState("");
    const [isLoad, setIsLoad] = useState(false);
    const [spinInfo, setSpinInfo] = useState("");
    const [current, setCurrent] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [tablesCount, setTablesCount] = useState(0);
    const [searchModel, setSearchModel] = useState({});
    const [oldSearchModel, setOldSearchModel] = useState({});
    
    useEffect(() => {
        search(pageSize, 1, "N");
    }, []);

    const search = (pageSizeNew, currentPageNew, flag, isShowMsg) => {
        if(Config.funcInfo.canSelect === "N") {
            return;
        }
        messageShowHandler();//清除提示信息
        clearSelect();//清除選中狀態
        let model = {};
        if ("Y" === flag) {//是否使用原查詢條件
            model = oldSearchModel;
        } else {
            model = searchModel;
        }
        model.pageSize = pageSizeNew;
        model.page = currentPageNew;
        setIsLoad(true);
        setSpinInfo("查找中...")
        Base.callBackApi("square/searchList", model).then((data) => {
            if ("SUCCESS" == data.msg) {
                if (Base.isEmpty(data.object.list) && isShowMsg) {
                    errFrameShow("info", '找不到符合条件记录.');
                }
                if("N" === flag) {
                    setOldSearchModel(JSON.parse(JSON.stringify(model)));
                }
                setTablesCount(data?.object?.total ?? 0);
                setTables(data?.object?.list ?? []);
                setCurrent(currentPageNew);
                setPageSize(pageSizeNew);
                console.log(tables)
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => ({ err })).finally(() => {
            setIsLoad(false);
        });
    }

    const reset = () => {
        messageShowHandler();
        clearSelect();
        setSearchModel({});
        setOldSearchModel({});
        setTablesCount(0);
        setTables([]);
        setPageSize(10);
    }

    const searchChange = (key, value) => {
        setSearchModel(model =>{
            let newModel = {};
            if(key === "time") {
                newModel.beginTime = value[0];
                newModel.endTime = value[1];
                return {...model,...{beginTime:value[0],endTime:value[1]}};
            }else{
                return {...model,...{[key]:value}};
            }
        });
    }

    //分页 页码切换变化查询
    const onChange = (current, pageSize) => {
        search(pageSize, current, "Y");
    }
    //分页 每页條數变化查询
    const onShowSizeChange = (current, pageSize) => {
        search(pageSize, 1, "Y");
    }

    //列表1选中记录
    const onSelectChange = (keys, selectedRows) => {
        setSelectedRowKeys(keys);
        setCheckboxState(selectedRows);
    }

    //清空列表1选中
    const clearSelect = () => {
        setSelectedRowKeys([]);
        setCheckboxState([]);
    }

    /** 生成提示信息 */
    const errFrameShow = (style, msg) => {
        setMessageStyle(style);
        setMessage(msg);
        setMessageShow(true);
        document.documentElement.scrollTop = 0;//返回顶部
    }

    /** 清空提示信息 */
    const messageShowHandler = () => {
        setMessage("");
        setMessageShow(false);
    }

    const columns = [
        {
            title: '发帖人账号',
            dataIndex: 'userId',
            className: 'tableWidth15',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'userId') },
        },
        {
            title: '发帖人姓名',
            dataIndex: 'userName',
            className: 'tableWidth15',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'userName') },
        },
        {
            title: '发帖类型',
            dataIndex: 'type',
            className: 'tableWidth10',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'type') },
            render: (text) => ContextHandler.getMapValue("squareType", text)
        },
        {
            title: '标题',
            dataIndex: 'title',
            className: 'tableWidth36',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'title') },
        },
        {
            title: '发帖时间',
            dataIndex: 'publishTime',
            className: 'tableWidth12',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'publishTime') },
            render: (text) => moment(text).format(Config.dateFormatALL)
        },
        {
            title: '微信号',
            dataIndex: 'wechatNumber',
            className: 'tableWidth12',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'wechatNumber') },
        },
        /*eslint-disable*/
        // {
        //     title: '操作',
        //     dataIndex: 'jobLogId',
        //     className: 'tableWidth8',
        //     render: (text, record) => <div className='sysOperation'>
        //          <div className='sysOperation' onClick={(e) => view(record ,e)}><EyeOutlined /> &nbsp;详情</div>
        //     </div>
        // },
        /*eslint-disable*/
    ]

    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
        hideDefaultSelections: true,
    };

    return (
        <div id="container" className="template">
             <div className='breadcrumb'>
                <HomeOutlined /> / 其他 / <span className='breadcrumbTitle'>广场</span>
            </div>
            {/* 提示信息弹框 */}
            {messageShow ? <Message style={messageStyle} content={message} close={messageShowHandler} /> : ''}
            <div className='templateMain'>
                <div className="sysModelTitle">广场</div>
                {/* 删除弹框 */}
                {/* {!isDeleteFrame ? '' : <PopupFrame close={deleteFrameClose} content={deleteMsg}/>} */}
                <Spin spinning={isLoad} size="large" tip={spinInfo}>
                    <div className='systabls'>
                        <div className='sysRow'>
                            <div className='sysCol'>
                                <div className="sysTitles">发帖类型</div>
                                <div className="sysSerchInput">
                                    <Select className="sysSelect" placeholder="发帖类型"
                                        value={searchModel.type || ''}
                                        onChange={(e) => searchChange('type', e)}
                                    >
                                        <Option value=""></Option>
                                        {context.squareType.map((item) => <Option key={item.value} value={item.value}>{item.label}</Option>)}
                                    </Select>
                                </div>
                            </div>
                            
                            <div className="sysSearchButton">
                                <Button className="sysBtn btnBule" type="primary" onClick={() => search(pageSize, 1, "N", true)} icon={<SearchOutlined />}>查询</Button>
                                <Button className="sysBtn" onClick={reset} icon={<RedoOutlined />}>重置</Button>
                            </div>
                        </div>
                        <div className="sysLineEight"></div>
                        <Space size={[5, 5]} wrap className="buttonRelative">
                            {tables.length == 0 ? null :
                                <>
                                    {/* <Button className="sysBtn btnBule" type="primary" onClick={deleteFrameHandler} hidden={Config.funcInfo.canDelete === "N"} icon={<DeleteOutlined />}>删除</Button>
                                    <Button className="sysBtn btnBule" type="primary" onClick={clearFrameHandler} hidden={Config.funcInfo.canDelete === "N"} icon={<DeleteOutlined />}>清空</Button> */}
                                </>
                            }
                        </Space>
                        <div className="sysTable" id="tableOne">
                            <Table
                                // rowSelection={rowSelection} //选择
                                rowKey="squareId"
                                columns={columns} //列名
                                dataSource={tables} //数据
                                pagination={{  //分页
                                    showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                    onChange: onChange,                        //上页下页或者跳页方法
                                    onShowSizeChange: onShowSizeChange,        //每页显示多少条数据方法
                                    total: tablesCount,             //总共多少条数据
                                    current: current,                //当前页
                                    pageSize: pageSize,              //每页显示多少条数据
                                    defaultCurrent: 1,                               //默认当前页为1
                                    size:"small"
                                }}
                                onRow={(record) => {
                                    return {
                                        onClick: event => Base.selectRowNew.call(this, record, 'squareId', checkboxState, selectedRowKeys, setCheckboxState, setSelectedRowKeys), // 点击行
                                    };
                                }}
                            />
                        </div>
                    </div>
                </Spin>
            </div>
        </div>
    )

}

export default Square;