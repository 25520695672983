
import React, { useState, useEffect } from 'react';
import { Button, DatePicker, Input ,Table, Form, Spin, Select, Tabs, Space, InputNumber ,Upload ,Modal} from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined,HomeOutlined, SearchOutlined,PlusOutlined, RedoOutlined,  EditOutlined, DeleteOutlined,PlusCircleOutlined,EyeOutlined} from '@ant-design/icons';
import Message from '../../component/message/message';//提示信息弹框
import Audit from '../../component/audit/audit';//審核彈框
import { Config } from '../../public/js/config';
import { Base } from '../../public/js/base';
import PopupFrame from '../../component/popupFrame/popupFrame';
import { ContextHandler } from '../../public/js/contextHandler';
import context from '../../public/js/context';

const { Option } = Select;
const { TabPane } = Tabs;
const { TextArea } = Input;
 
const HkLifeGuide = (props) => {
    const [form] = Form.useForm();
    const [tabIndex, setTabIndex] = useState("1");
    const [searchModel, setSearchModel] = useState({});
    const [oldSearchModel, setOldSearchModel] = useState({});
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [checkboxState, setCheckboxState] = useState([]);
    const [tables, setTables] = useState([]);
    const [tablesCount, setTablesCount] = useState(0);
    const [current, setCurrent] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [messageShow, setMessageShow] = useState(false);
    const [messageStyle, setMessageStyle] = useState("error");
    const [message, setMessage] = useState("");
    const [auditModel, setAuditModel] = useState({});
    const [isAudit, setIsAudit] = useState(false);
    const [spinInfo, setSpinInfo] = useState("");
    const [isLoad, setIsLoad] = useState(false);
    const [isLeave, setIsLeave] = useState(false);
    const [flag, setFlag] = useState("");
    const [isDeleteFrame,setIsDeleteFrame] = useState(false);
    const [imageFile, setImageFile] = useState([]);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);
    
    useEffect(() => {
        search(pageSize, 1, "N");      
    }, []);

    const search = (pageSizeNew, current, flag, isShowMsg) => {
        if(Config.funcInfo.canSelect === "N") {
            return;
        }
        messageShowHandler();
        clearSelect();//清除選中狀態
        let model = {};
        if ("Y" === flag) {//翻頁
            model = oldSearchModel;
        } else {
            model = searchModel;
        }
        model.pageSize = pageSizeNew;
        model.page = current;
        Base.callBackApi("newsManagement/search", model).then((data) => {
            if ("SUCCESS" === data.msg) {
                console.log(data.object.list)
                if (!data.object.list && isShowMsg) {
                    errFrameShow("info", '找不到符合条件记录.');
                }
                if("N" === flag) {
                    setOldSearchModel(JSON.parse(JSON.stringify(model)));
                } 
                setTablesCount(data?.object?.total ?? 0);
                setTables(data?.object?.list ?? []);
                setCurrent(current);
                setPageSize(pageSizeNew);
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }

   
    // Tab页切换
    const handleSelect = (key) => {
        if (key) {
            if(key === "2") {
                // edit();
            }else if(tabIndex !== "1") {
                setTabIndex("1");
            }
        }
    }

    const add = () => {
        setFlag("add");
        messageShowHandler();//清空提示信息
        initForm({ status: "Y"});
        setImageFile([]);
        setTabIndex("2");
    }

    const update = (model, e) => {
        e.stopPropagation();
        setCheckboxState([model]);
        setSelectedRowKeys([model.newsManagementId]);
        initForm(model);
        setFlag("edit");
        setTabIndex("2");
    }

    const deleteOne = (model, e) => {
        e.stopPropagation();
        setCheckboxState([model]);
        setSelectedRowKeys([model.newsManagementId]);
        setIsDeleteFrame(true);
    }

    //审核
    const audit = () => {
        messageShowHandler();//清空提示信息
        if (!checkboxState|| checkboxState.length !== 1) {
            errFrameShow("error", "请选择一条数据");
            return;
        }
        setAuditModel(checkboxState[0]);
        setIsAudit(true);
    }

    const handleChange = (file) => {
        let fileList = file.fileList;
        file = file.file;
        if(file.status === "removed") {
            fileList = [];
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = (e) => { 
                fileList[fileList.length - 1].originUrl = e.target.result; 
                fileList[fileList.length - 1].imgStatus = "NEW";
            }
        }
        setImageFile(fileList);
    }

    const deleteFrameClose = (state) =>{
        if(state){
            deleteData();
        }
        setIsDeleteFrame(false);
    }

    const deleteData = () =>{
        setIsLoad(true);
        setSpinInfo("数据删除中...");
        let deleteModel = checkboxState[0];
        deleteModel.menuFuncId = Config.funcInfo.funcId;
        console.log(deleteModel);
         Base.callBackApi("newsManagement/delete",deleteModel).then((data) =>{
            if("SUCCESS" === data.msg){
                search(pageSize,current,"Y",false);
                errFrameShow("success","删除成功");
            } else {
                errFrameShow("error",data.msg);
            }
        }).catch(err =>{}).finally(() => setIsLoad(false));
    }

    // 初始化表单
    const initForm = (model = {}) => {   
        form.resetFields();
        form.setFieldsValue({
            newsTitle: model.newsTitle ?? "",
            dispSeq:   model.dispSeq ?? "",
            newsUrl: model.newsUrl ??"",
            original: model.original ??"",
            status: model.status ??"",
        });
        setImageFile(
            model.newsImg?.split(";")
                ?.map(item => {
                    return {
                        url: Config.hkLifeGuideImg + item,
                        originUrl: item,
                        thumbUrl: Config.hkLifeGuideImg + item,
                        imgStatus: "OLD"
                    };
                })
            || []
        );

    }

    const searchChange = (k,e) => {
        messageShowHandler();
        setSearchModel(newSearchModel =>{
            return {...newSearchModel,...{[k]:e}};
        });
    }
     //重置
     const reset = () => {
        messageShowHandler();
        clearSelect();
        setSearchModel({});
        setOldSearchModel({});
        setTablesCount(0);
        setTables([]);
        setPageSize(10);
    }

    // 取消
    const cancel = () => {
        messageShowHandler();
        clearSelect();
        setTabIndex("1");
    }

    // 监听事件判断保存还是修改
    const onSave = (values) => {
        console.log(values);
        messageShowHandler();
        setIsLoad(true);
        setSpinInfo("数据保存中...")
        let url = "";           
        console.log(flag);
      
        if(flag === "add") {
            url = "newsManagement/insert";
        } else {
            url = "newsManagement/update"
            values.newsManagementId = selectedRowKeys[0];
            values.lastUpdatedDate = checkboxState[0].lastUpdatedDate;
        }
        values.menuFuncId = Config.funcInfo.funcId;
        let imageUploadModelList = imageFile.map(item => {
            return {
                status: item.imgStatus,
                url: item.originUrl,
            };
        });
        values.newsImgList = imageUploadModelList;
        console.log(values)
        Base.callBackApi(url, values).then((data) => {
            if ("SUCCESS" === data.msg) {
                search(pageSize, current, "Y", false);
                errFrameShow("success", "保存成功");
                setTabIndex("1");
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
      
    }

      /**查看图片 */
    const handlePreview = (file) => {
        setPreviewImage(file.url || file.originUrl);
        setPreviewVisible(true);
    }

    const checkImg = (value) => {
        console.log(value);
        if(!!imageFile && imageFile.length > 0) {
            if(value) {
                let list = value.fileList;
                if(list.find(item => item.status === "uploading")) {
                    return Promise.reject("请等待图片上传完成");
                }
                if(list.find(item => item.status === "error")) {
                    return Promise.reject("请先处理测试上传失败的图片");
                }
                if(imageFile.find(item => item.status === "irregular")) {
                    return Promise.reject("图片尺寸不符合规则");
                }
            }
            return Promise.resolve();
        }
        return Promise.reject("图片数量必须大于0");
    }

    const leaveFrameClose = (state) => {
        if(state) {
            cancel();
        }
        setIsLeave(false);
    }

    //分页 页码切换变化查询
    const onChange = (currentNew, pageSizeNew) => {
        search(pageSizeNew, currentNew, "Y");
    }

    //分页 每页條數变化查询
    const onShowSizeChange = (currentNew, pageSizeNew) => {
        search(pageSizeNew, 1, "Y");
    }

    //表格中被选中数据
    const onSelectChange = (selectedRowKeys, selectedRows) => {
        setSelectedRowKeys(selectedRowKeys);
        setCheckboxState(selectedRows);
    }

    //清空列表选中
    const clearSelect = () => {
        setSelectedRowKeys([]);
        setCheckboxState([])
    }

    /** 生成提示信息 */
    const errFrameShow = (style, msg) => {
        setMessage(msg);
        setMessageStyle(style);
        setMessageShow(true);
        document.documentElement.scrollTop = 0;//返回顶部
    }

    /** 清空提示信息 */
    const messageShowHandler = () => {
        setMessageShow(false);
        setMessage("");
    }

    // 审核弹窗
    const auditCancel = () => {
        setIsAudit(false);
    }

    // 页面表格显示字段
    /*eslint-disable*/
    const columns = [
        {
            title: '文章题目',
            dataIndex: 'newsTitle',
            className: 'tableWidth15',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'newsTitle') },
        },
        {
            title: '原创 ',
            dataIndex: 'original',
            className: 'tableWidth15',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'original')},
        },
        {
            title: '图片',
            dataIndex: 'newsImg',
            className: 'tableWidth15',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'newsImg') },
            render: (text) => <img className="apartmentTableImg" alt='' src={Config.hkLifeGuideImg + text}/>
        },
        {
            title: 'url',
            dataIndex: 'newsUrl',
            className: 'tableWidth22',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'newsUrl') },
        },
        {
            title: '显示状态',
            dataIndex: 'status',
            className: 'tableWidth10',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'status') },
            render: (text) => ContextHandler.getMapValue('hiddenOrDisplayMap', text)
        },
        {
            title: '序号',
            dataIndex: 'dispSeq',
            className: 'tableRightAlignment tableWidth8',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'dispSeq') },
        },
        {
            title: '操作',
            dataIndex: '',
            className: 'tableWidth15 tableRightAlignment',
            render: (text, record) => <div className='sysOperation proBrandEstateOperation'>
                <div className='sysUpdate' onClick={(e) => update(record, e)}><EditOutlined /> 修改</div>
                <div className='sysDelete' onClick={(e) => deleteOne(record, e)}><DeleteOutlined /> 删除</div>
            </div>
        },
    ]
    /*eslint-enable*/
  
    //选中记录方法
    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
        type: 'radio',
    }

    return (
        <div id="container" className="template">
            <div className='breadcrumb'>
                <HomeOutlined /> / 系统管理 / <span className='breadcrumbTitle'>香港生活指南</span>
            </div>
            {/* 提示信息弹框 */}
            {messageShow ? <Message style={messageStyle} content={message} close={messageShowHandler} /> : ''}
            <div className='templateMain'>
                <div className="sysModelTitle">香港生活指南</div>
                <Spin spinning={isLoad} size="large" tip={spinInfo}>
                    {/*删除弹框*/}
                    {isDeleteFrame ? <PopupFrame close={deleteFrameClose}/>: null}
                    {isLeave ? <PopupFrame close={leaveFrameClose} type='leave' /> : null}
                    {/* 审核彈框 */}
                    <Audit visible={isAudit} auditModel={auditModel} close={auditCancel} />
                    <Tabs activeKey={tabIndex} onChange={(e) => handleSelect(e)}>
                        <TabPane key="1" tab="列表">
                            <div className='sysRow'>
                                <div className='sysCol'>
                                    <div className="sysTitle">文章题目</div>
                                    <div className="sysSerchInput">
                                        <Input type="text" placeholder="文章题目"
                                            value={searchModel.newsTitle || ''} 
                                            maxLength={200}
                                            onChange={(e) => searchChange('newsTitle', e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='sysCol'>
                                    <div className="sysTitle">显示状态</div>
                                    <div className="sysSerchInput">
                                        <Select placeholder="显示状态" className="sysSelect" value={searchModel.status || ''}
                                                onChange={(e) => searchChange('status',e)}
                                        >
                                            <Option value=""></Option>
                                            {context.hiddenOrDisplayMap.map((item) => <Option key={item.value} value={item.value}>{item.label}</Option>)}
                                        </Select>
                                    </div>
                                </div>
                                <div className='sysSearchButton'>
                                    <Button className="sysBtn btnBule" type="primary" htmlType="submit" hidden={Config.funcInfo.canSelect === "N"} onClick={() => search(pageSize, 1, "N", true)} icon={<SearchOutlined />}>查询</Button>
                                    <Button className="sysBtn" onClick={reset} icon={<RedoOutlined />}>重置</Button>
                                </div>
                            </div>
                            <div className="sysLineEight"></div>
                            <Space size={[5, 5]} wrap className="buttonRelative">
                                <>
                                    <Button className='sysBtn btnBule' type="primary" onClick={() =>{add()}} hidden={Config.funcInfo.canInsert === "N"} icon={<PlusCircleOutlined />}>新增</Button>
                                    <Button className='sysBtn btnBule' type="primary" onClick={audit} hidden={Config.funcInfo.canAudit === "N"} icon={<EyeOutlined />}>日志</Button>
                                </>
                            </Space>
                            <div className="sysTable" id="tableOne">
                                <Table
                                    rowSelection={rowSelection} //选择
                                    rowKey="newsManagementId"
                                    columns={columns} //列名
                                    dataSource={tables} //数据
                                    pagination={{  //分页
                                        showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                        onChange: onChange,            //上页下页或者跳页方法
                                        onShowSizeChange: onShowSizeChange,    //每页显示多少条数据方法
                                        total: tablesCount,             //总共多少条数据
                                        current: current,         //当前页
                                        pageSize: pageSize,       //每页显示多少条数据
                                        defaultCurrent: 1,                               //默认当前页为1
                                        size:"small"
                                    }}
                                    onRow={(record) => {
                                        return {
                                            onClick: event => Base.selectRadioRowNew.call(this, record, 'newsManagementId', selectedRowKeys, setCheckboxState, setSelectedRowKeys),
                                        };
                                    }}
                                />
                            </div>
                        </TabPane>
                        <TabPane tab="香港生活指南" key="2" forceRender={true}>
                            <Form form={form} onFinish={onSave}>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">文章题目</div>
                                        <div className="sysInputs">
                                            <Form.Item
                                                name="newsTitle"
                                                rules={[
                                                    { required: true, message: '文章题目不能为空' },
                                                ]}
                                            >
                                                <TextArea placeholder="文章题目" maxLength={200} rows={2}/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">原创</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="original"
                                                rules={[
                                                    { required: true, message: '原创不能为空' },
                                                ]}
                                            >
                                                <Input type="text" placeholder="原创" maxLength={70} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">显示状态</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name = "status"
                                                rules={[
                                                    { required: true, message: '显示状态不能为空' },
                                                ]}
                                                >
                                                <Select placeholder="显示状态" className="sysSelect" value={searchModel.activeInd || ''}
                                                    onChange={(e) => searchChange('activeInd',e)}
                                                >
                                                    {context.hiddenOrDisplayMap.map((item) => <Option key={item.value} value={item.value}>{item.label}</Option>)}
                                                </Select> 
                                            </Form.Item>  
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">url</div>
                                        <div className="sysInputs">
                                            <Form.Item
                                                name="newsUrl"
                                                rules={[
                                                    { required: true, message: 'url不能为空' },
                                                ]}
                                            >
                                                <TextArea placeholder="url" maxLength={400} rows={2}/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">序号</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="dispSeq"
                                                rules={[
                                                    { required: true, validator:(rule,value) =>Base.checkNbr(value,null,999999999)},
                                                ]}
                                            >
                                                <InputNumber type="sysInputNumbers" placeholder="序号" precision={0} maxLength ={9} className="sysRoleInputNumber"/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">图片</div>
                                        <div className="sysInputs">
                                            <Form.Item 
                                                name="newsImg"
                                                rules={[
                                                    { required: true, validator: (rule, value) => checkImg(value) }
                                                ]}
                                            >
                                                <Upload
                                                    action={Config.uploadTestServer} 
                                                    key={"1"}
                                                    accept=".png, .jpg, .jpeg"
                                                    listType="picture-card"
                                                    fileList={imageFile}
                                                    onPreview={handlePreview}
                                                    onChange={(file) => handleChange(file)}>
                                                    {imageFile.length >= 1 ? null : <div className='plusOutBox'>
                                                        <PlusOutlined />
                                                        <div>添加图片</div>
                                                        {/* <div className='plusOutDesc'>(宽高大于等于400*240像素的5:3尺寸的图片)</div> */}
                                                    </div>}
                                                </Upload>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <Modal title="放大图" visible={previewVisible} footer={null} onCancel={() => setPreviewVisible(false)}>
                                    <img alt="example" style={{ width: '100%' }} src={previewImage} />
                                </Modal>
                                <div className="sysSaveButton">
                                    <Button className="sysBtn btnBule" type="primary" disabled={isLoad} htmlType="submit" icon={<CheckCircleOutlined />}>保存</Button>
                                    <Button className="sysBtn" onClick={cancel} icon={<CloseCircleOutlined />}>取消</Button>
                                </div>
                            </Form>
                        </TabPane>
                    </Tabs>
                </Spin>
            </div>
        </div>
    )
}
export default HkLifeGuide;



