import React, { useState, useEffect } from 'react';
import { Alert, Row, Col } from 'antd';
import PropTypes from 'prop-types';//props传参检查库
import './message.css';

/**
 * 消息提示组件
 * props传参如下
 * @param style:消息提示类型,样式 ['error','success','warning','info']
 *                              error:错误提示框，红色样式
 *                              success:成功提示框，绿色样式
 *                              warning:警告提示框，黄色样式
 *                              info:普通提示框，蓝色样式
 * @param title:消息标题
 * @param content:消息内容（如果需要换行，在换行的字符中加 ";" 即可实现）
 * @param close:关闭弹框时修改弹框显示状态的回调方法
 */
const Message = (props) => {

    const [style, setStyle] = useState("");
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [type, setType] = useState("messageShowStyle");

    useEffect(() => {
        if(props.messageModel === "login"){
            setType("loginMessageShowStyle")
        }
        let style = props?.style ?? 'success';
        let title = props?.title;
        let content = props?.content;
        if (content == null || content.trim() === '') {
            throw new Error('消息提示内容不能为空');
        }
        if (typeof props.close !== "function") {
            throw new Error('请传入关闭弹框的回调方法');
        }
        setStyle(style);
        titleHandler(props);
        contentHandler(content);
    }, []);

    /**
     * 标题默认值处理
     * @param title 标题
     * @param style 样式
     */
    const titleHandler = ({ title, style }) => {
        if (title == null || title.trim() === '') {
            switch (style) {
                case 'error': title = '错误';
                    break;
                case 'success': title = '提示';
                    break;
                case 'warning': title = '警告';
                    break;
                default: title = '提示';
                    break;
            }
        }
        setTitle(title);
    }

    /**
     * 消息内容换行处理
     * @param {消息内容} content 
     */
    const contentHandler = (content) => {
        let arr = content.split(';');
        if (arr.length > 1) {
            let index = 1;
            content = '';
            arr.map((item) => {
                if (item.trim() !== '') {
                    content += index++ + '.' + item + '\n'
                }
            });
        }
        setContent(content);
    }

    return (
        <Row className={type}>
            <Col xs={24} md={24} className={style}>        
                <Alert 
                    className='messageAlert'
                    message={title}
                    description={content}
                    type={style}
                    // showIcon
                    closable
                />
            </Col>
        </Row>
    )

}

Message.propTypes = {
    style: PropTypes.oneOf(['error', 'success', 'warning', 'info'])
    ,title: PropTypes.string
    ,messageModel: PropTypes.string
    ,content: PropTypes.string.isRequired
    ,close: PropTypes.func.isRequired
};

export default Message;