
import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Button, DatePicker, Input, Radio ,Table, Form,Checkbox, Spin, Select, Tabs, Upload, Col, Space,TimePicker,Modal,Image  } from 'antd';
import { CheckCircleOutlined, HomeOutlined ,CloseCircleOutlined, SearchOutlined, RedoOutlined, FormOutlined, EditOutlined, DeleteOutlined, EyeOutlined, DownloadOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';//日期格式化导包
import Message from '../../component/message/message';//提示信息弹框
import Audit from '../../component/audit/audit';//審核彈框
import { Config } from '../../public/js/config';
import { Base } from '../../public/js/base';
import PropTypes from 'prop-types';
import PopupFrame from '../../component/popupFrame/popupFrame';
import ReviewPopup from '../../component/reviewPopup/reviewPopup';
import { ContextHandler } from '../../public/js/contextHandler';
import context from '../../public/js/context';
import ImgCrop from 'antd-img-crop';

const { Option } = Select;
const { TabPane } = Tabs;
 
const AgentDataApprove = (props) => {
    const history = useHistory();
    const [form] = Form.useForm();
    const { TextArea } = Input;
    const [tabIndex, setTabIndex] = useState("1");
    const [searchModel, setSearchModel] = useState({auditStatus:"SUBMIT"});
    const [oldSearchModel, setOldSearchModel] = useState({});
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [checkboxState, setCheckboxState] = useState([]);
    const [tables, setTables] = useState([]);
    const [tablesCount, setTablesCount] = useState(0);
    const [current, setCurrent] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [messageShow, setMessageShow] = useState(false);
    const [messageStyle, setMessageStyle] = useState("error");
    const [message, setMessage] = useState("");
    const [auditModel, setAuditModel] = useState({});
    const [isAudit, setIsAudit] = useState(false);
    const [spinInfo, setSpinInfo] = useState("");
    const [isLoad, setIsLoad] = useState(false);
    const [isLeave, setIsLeave] = useState(false);
    const [flag, setFlag] = useState("add");
    const [auditStatus, setAuditStatus] = useState("");
    const [isReviewPopup , setIsReviewPopup] = useState(false);
    const [contactLanguageList, setContactLanguageList] = useState([
        { label: "普通话", value: "普通话" },
        { label: "粤语",   value: "粤语" },
        { label: "英语",   value: "英语" },
        { label: "其他",   value: "其他" },
    ]);
    const [type, setType] = useState("");
    const [content, setContent] = useState("");
    const [frameType, setFrameType] = useState("");
    const [isPopupFrame, setIsPopupFrame] = useState(false);
    const [agentImgFile, setAgentImgFile] = useState([]);
    const [businessImgFile, setBusinessImgFile] = useState([]);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [logoImgFile, setLogoImgFile] = useState([]);
    const [previewImage, setPreviewImage] = useState(null);
    const [isDisable, setIsDisable] = useState(true);

    useEffect(() => {
        search(pageSize, 1, "N");
    }, []);

    const search = (pageSizeNew, current, flag, isShowMsg) => {
        if(Config.funcInfo.canSelect === "N") {
            return;
        }
        messageShowHandler();
        clearSelect();//清除選中狀態
        let model = {};
        if ("Y" === flag) {//翻頁
            model = oldSearchModel;
        } else {
            model = searchModel;
        }
        model.pageSize = pageSizeNew;
        model.page = current;
        setIsLoad(true);
        setSpinInfo("查询中...");
        Base.callBackApi("propertyAgent/search", model).then((data) => {
            if ("SUCCESS" === data.msg) {
                if (!data.object.list && isShowMsg) {
                    errFrameShow("info", '找不到符合条件记录.');
                }
                if("N" === flag) {
                    setOldSearchModel(JSON.parse(JSON.stringify(model)));
                } 
                setTablesCount(data?.object?.total ?? 0);
                setCurrent(current);
                setTables(data?.object?.list)
                setPageSize(pageSizeNew);
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }

    // Tab页切换
    const handleSelect = (key) => {
        if (key) {
            if(key === "2") {
                edit();
                if(checkboxState.length === 1 && Config.funcInfo.canUpdate === "Y") {
                    edit();
                } else if(Config.funcInfo.canInsert === "Y") {
                    add();
                }
            }else if(tabIndex !== "1") {
                setTabIndex("1");
            }
        }
    }

    const add = () => {
        messageShowHandler();//清空提示信息
        initForm({ activeInd: "Y"});
        setAgentImgFile([])
        setBusinessImgFile([])
        setFlag("add");
        setTabIndex("2");
    }

    //修改
    const edit = () => {
        messageShowHandler();//清空提示信息
        if (checkboxState == null || checkboxState.length !== 1) {
            errFrameShow("error", "请选择一条数据");
            return
        }
        let model = checkboxState[0];
        setFlag("edit");
        setAuditStatus(model.auditStatus);
        initForm(model);
        setTabIndex("2");
    }

    const watch = (model, e) => {
        messageShowHandler();//清空提示信息
        e.stopPropagation();
        setFlag("view");
        setIsDisable(true)
        setCheckboxState(model);
        setSelectedRowKeys([model.propertyAgentId]);
        setAuditStatus(model.auditStatus);
        initForm(model);
        if(model.businessRegPhoto) {
            setBusinessImgFile(
                model.businessRegPhoto?.split(";")
                    ?.map(item => {
                        return {
                            url: Config.propertyAgent + item,
                            originUrl: item,
                            thumbUrl: Config.propertyAgent + item,
                            imgStatus: "OLD"
                        };
                    })
                || []
            );
        }
        if(model.agentLicensePhoto) {
            setAgentImgFile(
                model.agentLicensePhoto?.split(";")
                    ?.map(item => {
                        return {
                            url: Config.propertyAgent + item,
                            originUrl: item,
                            thumbUrl: Config.propertyAgent + item,
                            imgStatus: "OLD"
                        };
                    })
                || []
            );
        }
        if(model.comanyLogo) {
            setLogoImgFile(
                model.comanyLogo?.split(";")
                    ?.map(item => {
                        return {
                            url: Config.propertyAgent + item,
                            originUrl: item,
                            thumbUrl: Config.propertyAgent + item,
                            imgStatus: "OLD"
                        };
                    })
                || []
            );
        }
        setTabIndex("2");
    }
    
    const updateOne = (model, e) => {
        messageShowHandler();//清空提示信息
        e.stopPropagation();
        setCheckboxState(model);
        setSelectedRowKeys([model.propertyAgentId]);
        setIsDisable(false)
        setAuditStatus(model.auditStatus);
        initForm(model);
        if(model.businessRegPhoto) {
            setBusinessImgFile(
                model.businessRegPhoto?.split(";")
                    ?.map(item => {
                        return {
                            url: Config.propertyAgent + item,
                            originUrl: item,
                            thumbUrl: Config.propertyAgent + item,
                            imgStatus: "OLD"
                        };
                    })
                || []
            );
        }
        if(model.agentLicensePhoto) {
            setAgentImgFile(
                model.agentLicensePhoto?.split(";")
                    ?.map(item => {
                        return {
                            url: Config.propertyAgent + item,
                            originUrl: item,
                            thumbUrl: Config.propertyAgent + item,
                            imgStatus: "OLD"
                        };
                    })
                || []
            );
        }
        if(model.comanyLogo) {
            setLogoImgFile(
                model.comanyLogo?.split(";")
                    ?.map(item => {
                        return {
                            url: Config.propertyAgent + item,
                            originUrl: item,
                            thumbUrl: Config.propertyAgent + item,
                            imgStatus: "OLD"
                        };
                    })
                || []
            );
        }
        setFlag("update");
        setTabIndex("2");
    }

    const update = (model, e) => {
        setIsDisable(true)
        messageShowHandler();//清空提示信息
        e.stopPropagation();
        setCheckboxState(model);
        setSelectedRowKeys([model.propertyAgentId]);
        setAuditStatus(model.auditStatus);
        initForm(model);
        if(model.businessRegPhoto) {
            setBusinessImgFile(
                model.businessRegPhoto?.split(";")
                    ?.map(item => {
                        return {
                            url: Config.propertyAgent + item,
                            originUrl: item,
                            thumbUrl: Config.propertyAgent + item,
                            imgStatus: "OLD"
                        };
                    })
                || []
            );
        }
        if(model.agentLicensePhoto) {
            setAgentImgFile(
                model.agentLicensePhoto?.split(";")
                    ?.map(item => {
                        return {
                            url: Config.propertyAgent + item,
                            originUrl: item,
                            thumbUrl: Config.propertyAgent + item,
                            imgStatus: "OLD"
                        };
                    })
                || []
            );
        }
        if(model.comanyLogo) {
            setLogoImgFile(
                model.comanyLogo?.split(";")
                    ?.map(item => {
                        return {
                            url: Config.propertyAgent + item,
                            originUrl: item,
                            thumbUrl: Config.propertyAgent + item,
                            imgStatus: "OLD"
                        };
                    })
                || []
            );
        }
        setFlag("edit");
        setTabIndex("2");
    }

    const checkLogo = (value) => {
        if(!!logoImgFile && logoImgFile.length > 0) {
            if(value) {
                let list = value.fileList;
                if(list.find(item => item.status === "uploading")) {
                    return Promise.reject("请等待图片上传完成");
                }
                if(list.find(item => item.status === "error")) {
                    return Promise.reject("请先处理测试上传失败的图片");
                }
                if(logoImgFile.find(item => item.status === "irregular")) {
                    return Promise.reject("图片尺寸不符合规则");
                }
            }
            return Promise.resolve();
        }
        return Promise.reject("图片数量必须大于0");
    }

    const checkBusiness = (value) => {
        if(!!businessImgFile && businessImgFile.length > 0) {
            if(value) {
                let list = value.fileList;
                if(list.find(item => item.status === "uploading")) {
                    return Promise.reject("请等待图片上传完成");
                }
                if(list.find(item => item.status === "error")) {
                    return Promise.reject("请先处理测试上传失败的图片");
                }
                if(logoImgFile.find(item => item.status === "irregular")) {
                    return Promise.reject("图片尺寸不符合规则");
                }
            }
            return Promise.resolve();
        }
        return Promise.reject("图片数量必须大于0");
    }

    const checkAgent = (value) => {
        if(!!agentImgFile && agentImgFile.length > 0) {
            if(value) {
                let list = value.fileList;
                if(list.find(item => item.status === "uploading")) {
                    return Promise.reject("请等待图片上传完成");
                }
                if(list.find(item => item.status === "error")) {
                    return Promise.reject("请先处理测试上传失败的图片");
                }
                if(logoImgFile.find(item => item.status === "irregular")) {
                    return Promise.reject("图片尺寸不符合规则");
                }
            }
            return Promise.resolve();
        }
        return Promise.reject("图片数量必须大于0");
    }


    // 初始化表单
    const initForm = (model = {}) => {
        form.resetFields();
        form.setFieldsValue({
            userId: model.userId ?? "",
            userName: model.userName ?? "",
            address: model.address?? "",
            agentLicense: model.agentLicense?? "",
            auditStatus: model.auditStatus == "SUBMIT" ? "" : model.auditStatus,
            companyName : model.companyName?? "",
            companyWebsite: model.companyWebsite ?? "" ,
            uploadTime:model.uploadTime ?? "",
            wechatNumber:model.wechatNumber ?? "",
            wechat:model.wechat ?? "",
            language:model.language ?? "",
            phone:model.phone ?? "",
            auditBy:model.auditBy ?? "",
            auditRemark:model.auditRemark ?? "",
            whatsApp:model.whatsApp && model.areaCode ? model.areaCode + ' ' + model.whatsApp : '', 
        });
    }

    const searchChange = (k,e) => {
        messageShowHandler();
        let newSearchModel = JSON.parse(JSON.stringify(searchModel));
        newSearchModel[k] = e;
        setSearchModel(newSearchModel);
    }
     //重置
     const reset = () => {
        messageShowHandler();
        clearSelect();
        setSearchModel({auditStatus:"SUBMIT"});
        setOldSearchModel({});
        setTablesCount(0);
        setTables([]);
        setPageSize(10);
    }

    const deleteOne = (model, e) => {
        e.stopPropagation();
        setCheckboxState([model]);
        setSelectedRowKeys([model.propertyAgentId]);
        setType();
        setContent();
        setFrameType("delete");
        setIsPopupFrame(true);
    }

    const popupFrameClose = (state) => {
        messageShowHandler();//清空提示信息
        if(state) {
            if(frameType === "delete") {
                deleteData();
            } else if(frameType === "leave") {
                cancel();
            } 
        }
        setIsPopupFrame(false);
    }

    const deleteData = () => {
        setIsLoad(true);
        setSpinInfo("数据保存中...");
        let deleteModel = checkboxState[0];
        deleteModel.menuFuncId = Config.funcInfo.funcId;
        Base.callBackApi("propertyAgent/delete", deleteModel).then((data) => {
            if ("SUCCESS" === data.msg) {
                search(pageSize, current, "Y", false);
                errFrameShow("success", "刪除成功");
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }

    const audit = () => {
        messageShowHandler();//清空提示信息
        if (!checkboxState|| checkboxState.length !== 1) {
            errFrameShow("error", "请选择一条数据");
            return;
        }
        setAuditModel(checkboxState[0]);
        setIsAudit(true);
    }

    // 取消
    const cancel = () => {
        messageShowHandler();
        clearSelect();
        setTabIndex("1");
    }

    const businessHandleChange = (file) => {
        let fileList = file.fileList;
        file = file.file;
        if(file.status === "removed") {
            fileList = [];
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = (e) => { 
                fileList[0].originUrl = e.target.result; 
                fileList[0].imgStatus = "NEW";
            }
        }
        setBusinessImgFile(fileList);
    }

    const logoImgChange = (file) => {
        let fileList = file.fileList;
        file = file.file;
        if(file.status === "removed") {
            fileList = [];
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = (e) => { 
                fileList[0].originUrl = e.target.result; 
                fileList[0].imgStatus = "NEW";
            }
        }
        setLogoImgFile(fileList);
    }
    
    const agentImgChange = (file) => {
        let fileList = file.fileList;
        file = file.file;
        if(file.status === "removed") {
            fileList = [];
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = (e) => { 
                fileList[fileList.length - 1].originUrl = e.target.result; 
                fileList[fileList.length - 1].imgStatus = "NEW";
            }
        }
        setAgentImgFile(fileList);
    }

    // 监听事件判断保存还是修改
    const onSave = (model) => {
        messageShowHandler();
        setIsLoad(true);
        setSpinInfo("数据保存中...")
        let url = "";
        if (flag === "edit") {
            url = "propertyAgent/update";
        }else {
            url = "propertyAgent/updatePropertyAgent";
            let logoUploadModelList = logoImgFile.map(item => {
                return {
                    status: item.imgStatus,
                    url: item.originUrl,
                };
            });
            model.logoUploadModelList = logoUploadModelList;
            let brUploadModelList = businessImgFile.map(item => {
                return {
                    status: item.imgStatus,
                    url: item.originUrl,
                };
            });
            model.brUploadModelList = brUploadModelList;
            let agencyUploadModelList = agentImgFile.map(item => {
                return {
                    status: item.imgStatus,
                    url: item.originUrl,
                };
            });
            model.agencyUploadModelList = agencyUploadModelList;
        }
        model.propertyAgentId = checkboxState.propertyAgentId;
        model.menuFuncId = Config.funcInfo.funcId;
        Base.callBackApi(url, model).then((data) => {
            if ("SUCCESS" == data.msg) {
                search(pageSize, current, "Y");
                if(flag === "edit"){
                    errFrameShow("success", "审批成功");
                }else{
                    errFrameShow("success", "保存成功");
                }
               
                setTabIndex("1");
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => {
            setIsLoad(false)
        });
    }

    const leaveFrameClose = (state) => {
        if(state) {
            cancel();
        }
        setIsLeave(false);
    }

    /**查看图片 */
    const handlePreview = (file) => {
        setPreviewImage(file.url || file.originUrl);
        setPreviewVisible(true);
    }

    //分页 页码切换变化查询
    const onChange = (currentNew, pageSizeNew) => {
        search(pageSizeNew, currentNew, "Y");
    }

    //分页 每页條數变化查询
    const onShowSizeChange = (currentNew, pageSizeNew) => {
        search(pageSizeNew, 1, "Y");
    }

    //表格中被选中数据
    const onSelectChange = (selectedRowKeys, selectedRows) => {
        setSelectedRowKeys(selectedRowKeys);
        setCheckboxState(selectedRows);
    }

    //清空列表选中
    const clearSelect = () => {
        setSelectedRowKeys([]);
        setCheckboxState([])
    }

    /** 生成提示信息 */
    const errFrameShow = (style, msg) => {
        setMessage(msg);
        setMessageStyle(style);
        setMessageShow(true);
        document.documentElement.scrollTop = 0;//返回顶部
    }

    /** 清空提示信息 */
    const messageShowHandler = () => {
        setMessageShow(false);
        setMessage("");
    }

    // 审批弹窗
    const auditCancel = () => {
        setIsAudit(false);
    }

    const batchAudit = () => {
        messageShowHandler();//清空提示信息
        if (!checkboxState || checkboxState.length < 1) {
            errFrameShow("error", "请至少选择一条数据");
            console.log(checkboxState)
            return;
        }
        let flag = false;
        checkboxState.map((item)=>{
            if(item.auditStatus !== "SUBMIT" ){
                flag = true
            }
        })
        if(flag) {
            errFrameShow("error", "只有未审批的数据才能进行审批");
            return;
        }
        setIsReviewPopup(true);
    }

    const reviewPopupClose = (flag, model) => {
        if(flag) {
            svaeList(model);
        }
        setIsReviewPopup(false);
    }

    const svaeList = (model) => {
        messageShowHandler();
        let list = JSON.parse(JSON.stringify(checkboxState));
        list.map((item)=>{
            item.menuFuncId = Config.funcInfo.funcId;
        })
        model.propertyAgentList = list;
        setIsLoad(true);
        setSpinInfo("数据保存中...")
        Base.callBackApi("propertyAgent/updateList", model).then((data) => {
            if ("SUCCESS" === data.msg) {
                search(pageSize, current, "Y");
                if(data.object) {
                    errFrameShow("error", data.object);
                } else {
                    errFrameShow("success", "批量审批成功");
                }
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => {
            setIsLoad(false)
        });
    }

    const jump = (model, e) => {
        props.selectMenu("SYS_MANAGE" ,"sysUser");
        history.push({
            pathname: '/main/sysUser',
            state: model,
        });
    }

    const jumpUser = () => {
        props.selectMenu("SYS_MANAGE" ,"sysUser");
        history.push({
            pathname: '/main/sysUser',
            state: checkboxState,
        });
    }
    

    // 页面表格显示字段
    /*eslint-disable*/
    const columns = [
        {
            title: '用户账号 ',
            dataIndex: 'userId',
            className: 'tableWidth7',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'userId')},
        },
        {
            title: '用户名 ',
            dataIndex: 'userName',
            className: 'tableWidth8',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'userName')},
            render: (text, record) => <a className='hyperlink' onClick={(e) => jump(record ,e)}>
                {text}
            </a>
        },
        {
            title: '地产公司logo',
            dataIndex: 'comanyLogo',
            className: 'tableWidth8',
            render: (text) => <img className="apartmentTableImg" alt='' src={Config.propertyAgent + text}/>
        },
        {
            title: '地产公司名',
            dataIndex: 'companyName',
            className: 'tableWidth15',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'companyName') },
        },
        {
            title: '地址',
            dataIndex: 'address',
            className: 'tableWidth15',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'address') },
        },
        {
            title: '联络人电话',
            dataIndex: 'phone',
            className: 'tableWidth11',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'phone') },
        },
        {
            title: '上传时间',
            dataIndex: 'uploadTime',
            className: 'tableWidth10',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'uploadTime') },
            render: (text) => Base.isEmpty(text)? null : moment(text).format(Config.dateFormatALL)
        },
        {
            title: '审批状态',
            dataIndex: 'auditStatus',
            className: 'tableWidth8',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'auditStatus') },
            render: (text) => ContextHandler.getMapValue('auditMap', text),
        },
        {
            title: '操作',
            dataIndex: '',
            className: 'tableWidth18 tableRightAlignment',
            render: (text, record) => <div className='sysOperation proBrandEstateOperation'>
                {
                    record.auditStatus == "SUBMIT" &&
                    <div className='sysOperation' onClick={(e) => update(record ,e)}><FormOutlined /> 审批</div>
                }
                <div className='sysUpdate' onClick={(e) => watch(record ,e)}><EyeOutlined /> 查看</div>
                <div className='sysUpdate' onClick={(e) => updateOne(record ,e)}><EditOutlined /> 修改</div>
                <div className='sysDelete' onClick={(e) => deleteOne(record ,e)}><DeleteOutlined /> 删除</div>
            </div>
        },
    ]
    /*eslint-enable*/

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div>添加图片</div>
        </div>
    );

    //选中记录方法
    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
    }

    return (
        <div id="container" className="template">
            <div className='breadcrumb'>
                <HomeOutlined /> / 品牌 / <span className='breadcrumbTitle'>地产资料审批</span>
            </div>
            {/* 提示信息弹框 */}
            {messageShow ? <Message style={messageStyle} content={message} close={messageShowHandler} /> : ''}
            {/* 删除弹框 */}
            {isPopupFrame && <PopupFrame close={popupFrameClose} type={type} content={content} />}
            <div className='templateMain'>
                <div className="sysModelTitle">地产资料审批</div>
                <Spin spinning={isLoad} size="large" tip={spinInfo}>
                    {isReviewPopup ? <ReviewPopup close={reviewPopupClose} /> : null}
                    {isLeave ? <PopupFrame close={leaveFrameClose} type='leave' /> : null}
                    {/* 审批彈框 */}
                    <Audit visible={isAudit} auditModel={auditModel} close={auditCancel} />
                    <Tabs activeKey={tabIndex} onChange={(e) => handleSelect(e)}>
                        <TabPane key="1" tab="列表">
                            <div className='sysRow'>
                                <div className='sysCol'>
                                    <div className="sysTitle">用户账号</div>
                                    <div className="sysSerchInput">
                                        <Input type="text" placeholder="用户账号"
                                            value={searchModel.userIds || ''} 
                                            maxLength={11}
                                            onChange={(e) => searchChange('userIds', e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='sysCol'>
                                    <div className="sysTitle">用户名</div>
                                    <div className="sysSerchInput">
                                        <Input type="text" placeholder="用户名"
                                            value={searchModel.userName || ''} 
                                            maxLength={11}
                                            onChange={(e) => searchChange('userName', e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='sysCol'>
                                    <div className="sysTitle">地产公司名</div>
                                    <div className="sysSerchInput">
                                        <Input type="text" placeholder="地产公司名"
                                            value={searchModel.companyName || ''} 
                                            maxLength={11}
                                            onChange={(e) => searchChange('companyName', e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='sysRow'>
                                <div className='sysCol'>
                                    <div className="sysTitle">审批状态</div>
                                    <div className="sysSerchInput">
                                        <Select placeholder="审批状态" className="sysSelect" value={searchModel.auditStatus || ''}
                                            onChange={(e) => searchChange('auditStatus',e)}
                                        >
                                            <Option value=""></Option>
                                            {context.auditMap.map((item) => <Option key={item.value} value={item.value}>{item.label}</Option>)}
                                        </Select>
                                    </div>
                                </div>
                                <div className='sysCols'>
                                    <div className="sysTitle">审批时间</div>
                                    <div className="sysSerchInputTime">
                                        <DatePicker placeholder="开始时间"
                                            className="parts-numberInput"
                                            showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss'), }}
                                            value={searchModel.auditStartDate ? moment(new Date(searchModel.auditStartDate), Config.dateFormatALL) : null}
                                            onChange={(value) => searchChange("auditStartDate", value)}
                                            format={Config.dateFormatALL} />
                                    </div>
                                </div>
                                <div className='sysCols'>
                                    <div className="sysTitleTime">至</div>
                                    <div className="sysSerchInputTime">
                                        <DatePicker placeholder="结束时间"
                                            className="parts-numberInput"
                                            showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss'), }}
                                            value={searchModel.auditEndDate ? moment(new Date(searchModel.auditEndDate), Config.dateFormatALL) : null}
                                            onChange={(value) => searchChange("auditEndDate", value)}
                                            format={Config.dateFormatALL} />
                                    </div>
                                </div>
                                <div className='sysSearchButton'>
                                    <Button className="sysBtn btnBule" type="primary" onClick={() => search(pageSize, 1, "N", true)} hidden={Config.funcInfo.canSelect === "N"} icon={<SearchOutlined />}>查询</Button>
                                    <Button className="sysBtn" onClick={reset} icon={<RedoOutlined />}>重置</Button>
                                </div>
                            </div>
                            <div className="sysLineEight"></div>
                            <Space size={[5, 5]} wrap className="buttonRelative">
                                {tables.length == 0 ? null :
                                    <>
                                        <Button type="primary" onClick={batchAudit} hidden={Config.funcInfo.canUpdate === "N"} icon={<EditOutlined />}>批量审批</Button>
                                        <Button type="primary" onClick={audit} hidden={Config.funcInfo.canAudit === "N"} icon={<EyeOutlined />}>日志</Button>
                                    </>
                                }
                            </Space>
                            <div className="sysTable" id="tableOne">
                                <Table
                                    rowSelection={rowSelection} //选择
                                    rowKey="propertyAgentId"
                                    columns={columns} //列名
                                    dataSource={tables} //数据
                                    pagination={{  //分页
                                        showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                        onChange: onChange,            //上页下页或者跳页方法
                                        onShowSizeChange: onShowSizeChange,    //每页显示多少条数据方法
                                        total: tablesCount,             //总共多少条数据
                                        current: current,         //当前页
                                        pageSize: pageSize,       //每页显示多少条数据
                                        defaultCurrent: 1,                               //默认当前页为1
                                        size:"small"
                                    }}
                                    onRow={(record) => {
                                        return {
                                            onClick: event => Base.selectRowNew.call(this, record, 'propertyAgentId', checkboxState, selectedRowKeys, setCheckboxState, setSelectedRowKeys), // 点击行
                                        };
                                    }}
                                />
                            </div>
                        </TabPane>
                        <TabPane key="2" tab="审批" forceRender={true}>
                            <Form
                                form={form} onFinish={onSave}
                            > 
                                <div className='wholeRentHouseSouce1'>
                                    <h4 className="blueBoxTitle2">地产代理公司资料</h4>
                                    <div className='sysRow'>
                                        <div className='sysCol'>
                                            <div className="sysParam">公司logo</div>
                                            <div className="sysInput">
                                                <Form.Item 
                                                    name="comanyLogo"
                                                    rules={[
                                                        { required: true, validator: (rule, value) => checkLogo(value) }
                                                    ]}
                                                >
                                                    <ImgCrop rotationSlider aspect={1/1}>
                                                        <Upload
                                                            disabled={isDisable} 
                                                            action={Config.uploadTestServer} 
                                                            key={"1"}
                                                            accept=".png, .jpg, .jpeg"
                                                            listType="picture-card"
                                                            fileList={logoImgFile}
                                                            onPreview={handlePreview}
                                                            onChange={(file) => logoImgChange(file)}>
                                                            {logoImgFile.length >= 1 ? null : <div className='plusOutBox'>
                                                                <PlusOutlined />
                                                                <div>添加图片</div>
                                                                <div className='plusOutDesc'>(推荐上传宽高大于等于60*60像素的1:1尺寸的图片)</div>
                                                            </div>}
                                                            {/* { logoImgFile.length >= 1 ? null : uploadButton} */}
                                                        </Upload>
                                                    </ImgCrop>
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className='sysCol'>
                                            <div className="sysParam">公司名</div>
                                            <div className="sysInput">
                                                <Form.Item name="companyName"
                                                    rules={[
                                                        {required: true, message: '公司名不能为空'}
                                                    ]}
                                                >
                                                    <Input type="text" placeholder="公司名" maxLength={15} disabled={isDisable} />
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='sysRow'>
                                        <div className='sysCol'>
                                            <div className="sysParam">地址</div>
                                            <div className="sysInput">
                                                <Form.Item name="address"
                                                    rules={[
                                                        {required: true, message: '地址不能为空'}
                                                    ]}
                                                >
                                                    <Input type="text" placeholder="地址" maxLength={100} disabled={isDisable} />
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className='sysCol'>
                                            <div className="sysParam">个人地产代理牌照号码</div>
                                            <div className="sysInput">
                                                <Form.Item name="agentLicense"
                                                    rules={[
                                                        {required: true, message: '个人地产代理牌照号码不能为空'}
                                                    ]}
                                                >
                                                    <Input type="text" placeholder="个人地产代理牌照号码" maxLength={15} disabled={isDisable} />
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='sysRow'>
                                        <div className='sysCol'>
                                            <div className="sysParam">公司网站</div>
                                            <div className="sysInput">
                                                <Form.Item name="companyWebsite"
                                                >
                                                    <Input type="text" placeholder="公司网站" maxLength={15} disabled={isDisable} />
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='sysRow'>
                                        <div className='sysCol'>
                                            <div className="sysParam">商业登记证（BR）</div>
                                            <div className="sysInput">
                                                <Form.Item 
                                                    name="businessRegPhoto"
                                                    rules={[
                                                        { required: true, validator: (rule, value) => checkBusiness(value) }
                                                    ]}
                                                >
                                                    <Upload
                                                        disabled={isDisable} 
                                                        action={Config.uploadTestServer} 
                                                        key={"1"}
                                                        accept=".png, .jpg, .jpeg"
                                                        listType="picture-card"
                                                        fileList={businessImgFile}
                                                        onPreview={handlePreview}
                                                        onChange={(file) => businessHandleChange(file)}>
                                                        {businessImgFile.length >= 1 ? null : uploadButton}
                                                    </Upload>
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className='sysCol'>
                                            <div className="sysParam">个人地产代理证</div>
                                            <div className="sysInput">
                                                <Form.Item 
                                                    name="agentLicensePhoto"
                                                    rules={[
                                                        { required: true, validator: (rule, value) => checkAgent(value) }
                                                    ]}
                                                >
                                                    <Upload
                                                        disabled={isDisable} 
                                                        action={Config.uploadTestServer} 
                                                        key={"1"}
                                                        accept=".png, .jpg, .jpeg"
                                                        listType="picture-card"
                                                        fileList={agentImgFile}
                                                        onPreview={handlePreview}
                                                        onChange={(file) => agentImgChange(file)}>
                                                        { agentImgFile.length >= 1 ? null : uploadButton}
                                                    </Upload>
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='wholeRentHouseSouce1'>
                                    <h4 className="blueBoxTitle2">联络人信息</h4>
                                    <div className='sysRow'>
                                        <div className='sysCol'>
                                            <div className="sysParam">头像</div>
                                            <div className="sysInput">
                                                {
                                                    checkboxState.headPortrait ?
                                                    <Image className='imgSrc' src={Config.imgServerHeadPortraitImgs + checkboxState.headPortrait}></Image>:
                                                    <img  className="propertyImg" alt="" src={Config.imgServerHeadPortraitImgs +"defaultHead.png"}/>
                                                }
                                            </div>
                                        </div>
                                        <div className='sysCol'>
                                            <div className="sysParam">联络人</div>
                                            <div className="sysInput">
                                                <Form.Item name="userName">
                                                    <Input type="text" placeholder="联络人" maxLength={15} disabled/>
                                                </Form.Item>
                                            </div>
                                            <a className='paddingLeft' onClick={jumpUser}>更多</a> 
                                        </div>
                                    </div>
                                    <div className='sysRow'>
                                        <div className='sysCol'>
                                            <div className="sysParam">电话</div>
                                            <div className="sysInput">
                                                <Form.Item name="phone">
                                                    <Input type="text" placeholder="电话" maxLength={15} disabled/>
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className='sysCol'>
                                            <div className="sysParam">沟通语言</div>
                                            <div className="sysInput">
                                                <Form.Item
                                                        name="language"
                                                    >
                                                    <Checkbox.Group options={contactLanguageList}  disabled/>
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='sysRow'>
                                        <div className='sysCol'>
                                            <div className="sysParam">WhatsApp</div>
                                            <div className="sysInput">
                                                <Form.Item name="whatsApp">
                                                    <Input type="text" placeholder="WhatsApp" maxLength={15} disabled/>
                                                </Form.Item>
                                            </div>
                                        </div>
                                        {
                                            checkboxState.wechat &&
                                            <div className='sysCol'>
                                                <div className='sysParam'>微信二维码</div>
                                                <div className="sysInput">
                                                    <Image src={Config.weChatBusinessCard + checkboxState.wechat} className='imgSrc'></Image>
                                                </div>
                                            </div>
                                         }
                                    </div>
                                </div>
                                {checkboxState.auditStatus =="SUBMIT"  && (flag =="view" || flag =="update")? null :
                                <div className='wholeRentHouseSouce1'>
                                    <h4 className="blueBoxTitle2">审批信息</h4>
                                    {checkboxState.auditStatus !="SUBMIT" ?
                                        <div className='sysRow'>
                                            <div className='sysCol'>
                                                <div className="sysParam">审批人</div>
                                                <div className="sysInput">
                                                    <Form.Item name="auditBy"
                                                    >
                                                        <Input type="text" placeholder="审批人" maxLength={12} disabled/>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className='sysCol'>
                                                <div className="sysParam">审批时间</div>
                                                <div className="sysInput">
                                                    <Input placeholder="审批时间" disabled value={moment(checkboxState.auditDate).format(Config.dateFormatALL)} />
                                                </div>
                                            </div>
                                        </div>:null
                                    }
                                    <div className='sysRow'>
                                        <div className='sysCol'>
                                            <div className="sysParam mustFill">审批状态</div>
                                            <div className="sysInput">
                                                <Form.Item name="auditStatus"
                                                    rules={[
                                                        {required: true, message: '审批结果不能为空'}
                                                    ]}
                                                >
                                                    <Radio.Group name="radiogroup" defaultValue={1} disabled={auditStatus =="SUBMIT"?false:true}>
                                                        {context.auditStatusMap.map((item) => <Radio key={item.value} value={item.value}>{item.label}</Radio>)}
                                                    </Radio.Group>
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='sysRow'>
                                        <div className='sysCol'>
                                            <div className="sysParam">审批意见</div>
                                            <div className="sysInputs">
                                                <Form.Item name="auditRemark"
                                                    rules={[
                                                        {required: false, message: '请填写审批意见'}
                                                    ]}
                                                >
                                                    <TextArea  maxLength={200} rows={3} disabled={auditStatus=="SUBMIT"  ?false:true} />
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                 }
                                <div className="sysSaveButton">
                                    {flag !="view" ?
                                        <>
                                            <Button className="sysBtn btnBule" type="primary" disabled={isLoad} htmlType="submit" icon={<CheckCircleOutlined />}>确认</Button>
                                        </>
                                        : null
                                    }
                                    <Button className="sysBtn" onClick={cancel} icon={<CloseCircleOutlined />}>取消</Button>
                                </div>
                            </Form>
                            <Modal title="放大图" visible={previewVisible} footer={null} onCancel={() => setPreviewVisible(false)}>
                                <img alt="example" style={{ width: '100%' }} src={previewImage} />
                            </Modal>
                        </TabPane>
                    </Tabs>
                </Spin>
            </div>
        </div>
    )
}
AgentDataApprove.propTypes = {
    selectMenu: PropTypes.func.isRequired,
}

export default AgentDataApprove;



