import React, { useState, useEffect } from 'react';
import { Button, Table, Form, Spin, Input, Tabs, Space, Upload, Modal, Popover, Checkbox, Tooltip, Cascader } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, SearchOutlined, RedoOutlined, PlusCircleOutlined, EditOutlined, DeleteOutlined, EyeOutlined, PlusOutlined, HomeOutlined ,UploadOutlined ,DownloadOutlined} from '@ant-design/icons';
import Message from '../../component/message/message';//提示信息弹框
import PopupFrame from '../../component/popupFrame/popupFrame';//删除弹框
import Audit from '../../component/audit/audit';//审核彈框
import { Config } from '../../public/js/config';
import { Base } from '../../public/js/base';
import SelectSchoolModal from './selectSchoolModal';
import ImgCrop from 'antd-img-crop';
import UploadEstateModal from './uploadEstateModal';
import './proEstate.css';

const { TabPane } = Tabs;
const { TextArea } = Input;

Math.formatFloat = function (f, digit) {
    var m = Math.pow(10, digit);
    return Math.round(f * m, 10) / m;
}

const ProEstate = (props) => {

    const imageNum = 5;
    const [form] = Form.useForm();
    const [isLoad, setIsLoad] = useState(false);
    const [spinInfo, setSpinInfo] = useState("");
    const [isAudit, setIsAudit] = useState(false);
    const [auditModel, setAuditModel] = useState({});
    const [message, setMessage] = useState("");
    const [messageShow, setMessageShow] = useState(false);
    const [messageStyle, setMessageStyle] = useState("");
    const [tabIndex, setTabIndex] = useState("1");
    const [searchModel, setSearchModel] = useState({apartmentStatus: "Y"});
    const [oldSearchModel, setOldSearchModel] = useState({});
    const [tables, setTables] = useState([]);
    const [tablesCount, setTablesCount] = useState(0);
    const [current, setCurrent] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [checkboxState, setCheckboxState] = useState([]);
    const [type, setType] = useState("");
    const [content, setContent] = useState("");
    const [frameType, setFrameType] = useState("");
    const [isPopupFrame, setIsPopupFrame] = useState(false);
    const [flag, setFlag] = useState("");
    const [imageFile, setImageFile] = useState([]);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);
    const [schoolList, setSchoolList] = useState(null);
    const [options, setOptions] = useState([]);
    const [nearbySchoolsList, setNearbySchoolsList] = useState([]);
    const [isSchoolModal, setIsSchoolModal] = useState(false);
    const [lastUpdatedDate, setLastUpdatedDate] = useState(null);
    const [estateTagList, setEstateTagList] = useState([]);
    const [isUpload, setIsUpload] = useState(false);

    const address = Form.useWatch('address', form);
    const areaId = Form.useWatch('areaId', form);

    useEffect(() => {
        searchSchoolList();
        searchEstateTagList();
        searchAllArea();
        search(pageSize, 1, "N");
    }, []);

    const searchSchoolList = () => {
        Base.callBackApi("commonDropDown/searchSysParam", { segment: "SCHOOL" }).then((data) => {
            if ("SUCCESS" === data.msg) {
                setSchoolList(data.object ?? []);
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { });
    }

    const searchEstateTagList = () => {
        Base.callBackApi("commonDropDown/searchSysParam", { segment: "ESTATE_TAG" }).then((data) => {
            if ("SUCCESS" === data.msg) {
                let list = data.object ?? [];
                setEstateTagList(list.map(item => {
                    return {
                        label: item.parmValue1,
                        value: item.code
                    }
                }));
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { });
    }

    const searchAllArea = () => {
        Base.callBackApi("commonDropDown/searchAllArea").then((data) => {
            if ("SUCCESS" === data.msg) {
                setOptions(data?.object ?? []);
                console.log(options)
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { });
    }

    const search = (pageSizeNew, currentPageNew, flag, isShowMsg) => {
        if(Config.funcInfo.canSelect === "N") {
            return;
        }
        messageShowHandler();  // 清除提示信息
        clearSelect(); // 清除选中状态
        let model = {};
        if ("Y" === flag) {     // 翻页
            model = oldSearchModel;
        } else {
            model = JSON.parse(JSON.stringify(searchModel));
        }
        model.page = currentPageNew;
        model.pageSize = pageSizeNew;
        setIsLoad(true);
        setSpinInfo("查询中...");
        Base.callBackApi("proEstate/search", model).then((data) => {
            if ("SUCCESS" === data.msg) {
                if (!data?.object?.list && isShowMsg) {
                    errFrameShow("info", '找不到符合条件记录.');
                }
                if("N" === flag) {
                    setOldSearchModel(JSON.parse(JSON.stringify(model)));
                }
                data?.object?.list?.forEach(item => {
                    if(item.estateImg) {
                        item.estateDefaultImg = item.estateImg.split(";")[0];
                    }
                })
                setTablesCount(data?.object?.total ?? 0);
                setTables(data?.object?.list ?? []);
                setCurrent(currentPageNew);
                setPageSize(pageSizeNew);
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }

    const searchChange = (key, value) => {
        messageShowHandler();
        setSearchModel(model => {
            return { ...model, ...{ [key] : value } };
        });
    }
    
    const reset = () => {
        messageShowHandler();
        clearSelect();
        setSearchModel({});
        setOldSearchModel({});
        setTablesCount(0);
        setTables([]);
        setPageSize(10);
    }

    // Tab页切换
    const handleSelect = (key) => {
        if (key) {
            if(key === "2") {
                if(Config.funcInfo.canUpdate === "Y" && selectedRowKeys.length > 0) {
                    edit();
                } else if(Config.funcInfo.canInsert === "Y") {
                    add();
                }
            } else if(tabIndex !== "1") {
                cancel();
            }
        }
    }

    const add = () => {
        messageShowHandler();
        initForm();

        let locationModel = {
            lat: 22.32004800000,
            lng: 114.17335500000,
            // key: Config.locationModel.key
        }
        window.locationModel = locationModel;
        setTimeout(() => { window.QMap(); }, 200); /* 地图 */
        initLocationModel();
        setImageFile([]);
        setNearbySchoolsList([]);
        setFlag("add");
        setTabIndex("2");
    }

    const edit = () => {
        messageShowHandler();
        if(!selectedRowKeys || selectedRowKeys.length !== 1) {
            errFrameShow("error", "请选择一条数据");
            return;
        }
        searchById(selectedRowKeys[0]);
    }

    const update = (model, e) => {
        e.stopPropagation();
        messageShowHandler();
        setCheckboxState([model]);
        setSelectedRowKeys([model.proEstateId]);
        searchById(model.proEstateId);
    }

    const searchById = (id) => {
        Base.callBackApi("proEstate/searchById", id).then((data) => {
            if ("SUCCESS" === data.msg) {
                let model = data.object ?? {};
                initForm(model);
                if(model.nearbySchools){
                    let array = model.nearbySchools?.split("|");
                    let newList = [];
                    for (let index = 0; index < array.length; index++) {
                        let ele = schoolList.find((item)=> item.code === array[index] );
                        newList.push(ele);
                    }
                    setNearbySchoolsList(newList);
                }else{
                    setNearbySchoolsList([]);
                }
                setImageFile(
                    model.estateImg?.split(";")
                        ?.map(item => {
                            return {
                                url: Config.proEstateImg + item,
                                originUrl: item,
                                thumbUrl: Config.proEstatePath + item,
                                imgStatus: "OLD"
                            };
                        })
                    || []
                );
                let locationModel = {
                    lat: model?.latitude ?? 22.32004800000,
                    lng: model?.longitude ?? 114.17335500000,
                    signModel: {
                        lat: model?.latitude ?? 22.32004800000,
                        lng: model?.longitude ?? 114.17335500000,
                    }
                }
                window.locationModel = locationModel;
                setTimeout(() => { window.QMap(); }, 200); /* 地图 */
                setLastUpdatedDate(model.lastUpdatedDate);
                initLocationModel();
                setFlag("edit");
                setTabIndex("2");
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { });
    }

    const initForm = (model = {}) => {
        form.resetFields();
        form.setFieldsValue({
            estateTitle: model.estateTitle ?? '',
            estateDesc: model.estateDesc ?? '',
            address: model.address ?? '',
            estateTag: model.estateTag?.split('|') ?? [],
            areaId: model.areaId ? [model.provinceId + '', model.cityId + '', model.areaId + ''] : '',
        });
    }

    /**初始化定位信息 */
    const initLocationModel = () =>{ 
        if(window.locationModel == null){
            setTimeout(() => {
                initLocationModel();
            }, 500);
        }else{
            Config.locationModel = window.locationModel;
        }
    };

    const deleteOne = (model, e) => {
        e.stopPropagation();
        setCheckboxState([model]);
        setSelectedRowKeys([model.proEstateId]);
        setType();
        setContent();
        setFrameType("delete");
        setIsPopupFrame(true);
    }

    const popupFrameClose = (state) => {
        if(state) {
            if(frameType === "delete") {
                deleteData();
            }
        }
        setIsPopupFrame(false);
    }

    const deleteData = () => {
        setIsLoad(true);
        setSpinInfo("数据保存中...");
        let deleteModel = checkboxState[0];
        deleteModel.menuFuncId = Config.funcInfo.funcId;
        Base.callBackApi("proEstate/delete", deleteModel).then((data) => {
            if ("SUCCESS" === data.msg) {
                search(pageSize, current, "Y", false);
                errFrameShow("success", "刪除成功");
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }

    const audit = () => {
        messageShowHandler();//清空提示信息
        if(!checkboxState || checkboxState.length !== 1) {
            errFrameShow("error", "请选择一条数据");
            return;
        }
        setAuditModel(checkboxState[0]);
        setIsAudit(true);
    }

    const auditCancel = () => {
        setIsAudit(false);
    }

    const closeSchoolModal = (flag, list) => {
        if(flag){
            let newList = nearbySchoolsList;
            for (let index = 0; index < list.length; index++) {
                let element = list[index];
                if(newList.findIndex(item=>item.code === element.code) < 0 ){
                    newList.push(element);
                }
            }
            setNearbySchoolsList(JSON.parse(JSON.stringify(newList)));
        }
        setIsSchoolModal(false);
    }

    const deleteSchool = (model) => {
        let newList = nearbySchoolsList.filter(item => item.code !== model.code);
        setNearbySchoolsList(JSON.parse(JSON.stringify(newList)));
    }
    
    const onChangeAddressA = (value) =>{
        console.log(value)
        if(value) {
            if(value.length < 3 || !value[2]) {
                form.setFieldsValue({
                    areaId: [],
                });
            }
        }
    }

    const searchAddress = () =>{
        if(areaId && areaId[2]){
            let model = {
                areaId:areaId[2]
                ,address:address
            }
            console.log(model)
            Base.callBackApi("proEstate/searchAddress", model).then((data) => {
                if ("SUCCESS" === data.msg) {
                    console.log(data.object)
                    let model = data.object;
                    let locationModel = {
                        lat: model?.lat ?? 22.32004800000,
                        lng: model?.lng ?? 114.17335500000,
                        signModel: {
                            lat: model?.lat ?? 22.32004800000,
                            lng: model?.lng ?? 114.17335500000,
                        }
                    }
                    window.locationModel = locationModel;
                    setTimeout(() => { window.QMap(); }, 200); /* 地图 */
                } else {
                    errFrameShow("error", data.msg);
                }
            }).catch(err => { }).finally(() => setIsLoad(false));
        }
    }

    /**查看图片 */
    const handlePreview = (file) => {
        setPreviewImage(file.url || file.originUrl);
        setPreviewVisible(true);
    }

    const checkImg = (value) => {
        if(!!imageFile && imageFile.length > 0) {
            if(value) {
                let list = value.fileList;
                if(list.find(item => item.status === "uploading")) {
                    return Promise.reject("请等待图片上传完成");
                }
                if(list.find(item => item.status === "error")) {
                    return Promise.reject("请先处理测试上传失败的图片");
                }
                // if(imageFile.find(item => item.status === "irregular")) {
                //     return Promise.reject("图片尺寸不符合规则");
                // }
            }
            return Promise.resolve();
        }
        return Promise.reject("图片数量必须大于0");
    }

    //上传Excel
    const uploadExcel = () => {
        messageShowHandler();//清空提示信息
        setIsUpload(true);
    }

    
    const upLoadModalClose = (flag ,data) => {
        if(flag) {
            errFrameShow("success", "上传成功");
        }
        setIsUpload(false);
    }

    const downloadExcel = () => {
        messageShowHandler();//清空提示信息
        setIsLoad(true);
        setSpinInfo("下载中...")
        fetch(Config.apiDownloadServer + "proEstate/exportReport", {
            method: 'POST',
            body: JSON.stringify(searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob()).then(blob => {
            if (blob.type == "application/json") {
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                    errFrameShow("danger", data.msg);
                }
                reader.readAsText(blob);
            } else {
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {// for IE
                    window.navigator.msSaveOrOpenBlob(blob, "屋苑/大厦.xlsx");
                } else {// for Non-IE (chrome, firefox etc.)
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = "屋苑/大厦.xlsx";
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }
            }
        }).catch(err => ({ err })).finally(() => {
            setIsLoad(false)
        });
    }

    const handleChange = (file) => {
        let fileList = file.fileList;
        file = file.file;
        if(file.status === "removed") {
            let index = imageFile.findIndex(item => item.uid == file.uid);
            let fileList = JSON.parse(JSON.stringify(imageFile));
            fileList.splice(index, 1)
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = (e) => { 
                var img = document.createElement("img");
                img.src = e.target.result;
                img.onload = function () {
                    if(img.width < 400 || img.height < 240 || Math.formatFloat(img.width / img.height, 3) !== Math.formatFloat(5 / 3, 3)) {
                        fileList[fileList.length - 1].status = "irregular";
                        form.validateFields(['img']);
                    }
                }
                fileList[fileList.length - 1].originUrl = e.target.result; 
                fileList[fileList.length - 1].imgStatus = "NEW";
            }
        }
        setImageFile(fileList);
    }

    const onFinish = (values) => {
        messageShowHandler();
        setIsLoad(true);
        if(!window.locationModel?.signModel?.lng || !window.locationModel?.signModel?.lat) {
            errFrameShow("error", "请选择地理位置");
            setIsLoad(false);
            return;
        }
        setSpinInfo("数据保存中...")
        let url = "";
        if(flag === "add") {
            url = "proEstate/insert";
        } else {
            url = "proEstate/update"
            values.proEstateId = checkboxState[0].proEstateId;
            values.lastUpdatedDate = lastUpdatedDate;
        }
        let newList = [];
        for (let index = 0; index < nearbySchoolsList.length; index++) {
            let ele = nearbySchoolsList[index];
            newList.push(ele.code);
        }
        values.nearbySchools = newList.join('|');
        let imageUploadModelList = imageFile.map(item => {
            return {
                status: item.imgStatus,
                url: item.originUrl,
            };
        });
        values.imageUploadModelList = imageUploadModelList;
        values.estateTag = values.estateTag?.join('|');
        values.areaId = values.areaId[2];
        values.longitude = window.locationModel?.signModel?.lng;
        values.latitude = window.locationModel?.signModel?.lat;
        values.menuFuncId = Config.funcInfo.funcId;
        Base.callBackApi(url, values).then((data) => {
            if ("SUCCESS" === data.msg) {
                search(pageSize, current, "Y", false);
                cancel();
                errFrameShow("success", "保存成功");
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }

    const cancel = () => {
        messageShowHandler();
        clearSelect();
        setImageFile([]);
        setTabIndex("1");
    }

    //分页 页码切换变化查询
    const onChange = (currentNew, pageSizeNew) => {
        search(pageSizeNew, currentNew, "Y");
    }
    //分页 每页條數变化查询
    const onShowSizeChange = (currentNew, pageSizeNew) => {
        search(pageSizeNew, 1, "Y");
    }

    //列表选中记录
    const onSelectChange = (selectedRowKeys, selectedRows) => {
        setSelectedRowKeys(selectedRowKeys);
        setCheckboxState(selectedRows);
    }
    //清空列表选中
    const clearSelect = () => {
        setSelectedRowKeys([]);
        setCheckboxState([]);
    }

    const errFrameShow = (style, msg) => {
        setMessage(msg);
        setMessageStyle(style);
        setMessageShow(true);
        document.documentElement.scrollTop = 0;
    }

    const messageShowHandler = () => {
        setMessage("");
        setMessageShow(false);
    }

    /*eslint-disable*/
    const columns = [
        {
            title: '屋苑/大厦图片',
            dataIndex: 'estateDefaultImg',
            className: 'tableWidth8',
            render: (text) => <img className="proEstateImg" alt='' src={Config.proEstatePath + text}/>
        },
        {
            title: '屋苑/大厦',
            dataIndex: 'estateTitle',
            className: 'tableWidth20',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'estateTitle') },
        },
        {
            title: '屋苑/大厦介绍',
            dataIndex: 'estateDesc',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'estateDesc') },
            render: (text) => <Popover className='popover' content={text}>
                <div className='proBrandDesc'>{text}</div>
            </Popover>
        },
        {
            title: '操作',
            dataIndex: '',
            className: 'tableWidth14 tableRightAlignment',
            render: (text, record) => <div className='sysOperation proBrandEstateOperation'>
                <div className='sysUpdate' onClick={(e) => update(record ,e)}><EditOutlined /> 修改</div>
                <div className='sysDelete' onClick={(e) => deleteOne(record,e)}><DeleteOutlined /> 删除</div>
            </div>
        },
    ];
    /*eslint-enable*/

    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
        type: 'radio',
    }

    return (
        <div id="container" className="template proEstate">
            <div className='breadcrumb'>
                <HomeOutlined /> /  品牌 / <span className='breadcrumbTitle'>屋苑/大厦</span>
            </div>
            {messageShow ? <Message style={messageStyle} content={message} close={messageShowHandler}/> : ''}
            <div className='templateMain'>
                <div className="sysModelTitle">屋苑/大厦</div>
                <Spin spinning={isLoad} size="large" tip={spinInfo}>
                    {/* 删除弹框 */}
                    {isPopupFrame && <PopupFrame close={popupFrameClose} type={type} content={content} />}
                    {/* 审核彈框 */}
                    <Audit visible={isAudit} auditModel={auditModel} close={auditCancel} />
                    {/* 选择学校弹窗 */}
                    {isSchoolModal && <SelectSchoolModal onClose={closeSchoolModal} selectList={nearbySchoolsList}/>}
                    {isUpload && <UploadEstateModal onClose={upLoadModalClose} />}
                    <Tabs activeKey={tabIndex} onChange={(e) => handleSelect(e)}>
                        <TabPane tab="列表" key="1">
                            <div className='sysRow'>
                                <div className='sysCol'>
                                    <div className="sysTitle">屋苑/大厦</div>
                                    <div className="sysSerchInput">
                                        <Input type="text" placeholder="屋苑/大厦" maxLength={50} value={searchModel.estateTitle}
                                            onChange={(e) => searchChange("estateTitle", e.target.value)}
                                            onKeyDown={(e) => e.key === "Enter" ? search(pageSize, 1, "N", true) : null} />
                                    </div>
                                </div>
                                <div className='sysSearchButton'>
                                    <Button className="sysBtn btnBule" type="primary" onClick={() => search(pageSize, 1, "N", true)} hidden={Config.funcInfo.canSelect === "N"} icon={<SearchOutlined />}>查询</Button>
                                    <Button className="sysBtn" onClick={reset} icon={<RedoOutlined />}>重置</Button>
                                </div>
                            </div>
                            <div className="sysLineEight"></div>
                            <Space size={[5, 5]} wrap className="sysButtonRelative">
                                <Button className="sysBtn btnBule" type="primary" onClick={add} hidden={Config.funcInfo.canInsert === "N"} icon={<PlusCircleOutlined />}>新增</Button>
                                {tables.length == 0 ? null :
                                    <>
                                        <Button className="sysBtn btnBule" type="primary" onClick={audit} hidden={Config.funcInfo.canAudit === "N"} icon={<EyeOutlined />}>日志</Button>
                                    </>
                                } 
                                <Button className="sysBtn btnBule" type="primary" onClick={downloadExcel} hidden={Config.funcInfo.canInsert === "N"} icon={<DownloadOutlined />}>下载</Button>
                                <Button className="sysBtn btnBule" type="primary" onClick={uploadExcel} hidden={Config.funcInfo.canInsert === "N"} icon={<UploadOutlined />}>上传</Button>
                            </Space>
                            <div className="sysTable" id="tableOne">
                                <Table
                                    rowSelection={rowSelection} //选择
                                    rowKey="proEstateId"
                                    columns={columns} //列名
                                    dataSource={tables} //数据
                                    pagination={{  //分页
                                        showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                        onChange: onChange,                        //上页下页或者跳页方法
                                        onShowSizeChange: onShowSizeChange,        //每页显示多少条数据方法
                                        total: tablesCount,             //总共多少条数据
                                        current: current,                //当前页
                                        pageSize: pageSize,              //每页显示多少条数据
                                        defaultCurrent: 1,                               //默认当前页为1
                                        size:"small"
                                    }}
                                    onRow={(record) => {
                                        return {
                                            onClick: event => Base.selectRadioRowNew.call(this, record, 'proEstateId', selectedRowKeys, setCheckboxState, setSelectedRowKeys),
                                        };
                                    }}
                                />
                            </div>
                        </TabPane>
                        <TabPane tab="屋苑/大厦" key="2" forceRender={true}>
                            <Form form={form} onFinish={onFinish}>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">屋苑/大厦</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="estateTitle"
                                                rules={[
                                                    { required: true, message: '屋苑/大厦不能为空' },
                                                ]}
                                            >
                                                <Input type="text" placeholder="屋苑/大厦" maxLength={50}/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam">屋苑/大厦标签</div>
                                        <div className="sysInputs">
                                            <Form.Item
                                                name="estateTag"
                                            >
                                                <Checkbox.Group options={estateTagList} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam">附近学校</div>
                                        <div className="sysInputs proEstateTreeDiv">
                                            <div className='treeDiv'>
                                                {(Base.isNotEmpty(nearbySchoolsList) && nearbySchoolsList.length > 0)?
                                                    nearbySchoolsList.map((item) => 
                                                        <Tooltip title={item.parmValue1} overlayClassName="lawTip" key={item.code}>
                                                            <span className='lawArticleItem' key={item.code} value={item.code}>
                                                                {item.parmValue1}
                                                                <CloseCircleOutlined className='closeImg' onClick={()=> deleteSchool(item)} />
                                                            </span>
                                                        </Tooltip>
                                                    )
                                                    :null
                                                }
                                            </div>
                                            <Button className="buttonYellowTwoStyle" type="primary" onClick={() => {setIsSchoolModal(true)}}>选择学校</Button>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">地区</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="areaId"
                                                rules={[
                                                    { required: true, message: '所在地区不能为空' },
                                                ]}
                                            >
                                                <Cascader options={options} onChange={onChangeAddressA} placeholder="请填写所在地区" />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='sysCol'>
                                        <div className="sysParam">详细地址</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="address"
                                            >
                                                <Input type="text" placeholder="详细地址" maxLength={100}  onBlur={searchAddress}/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam">屋苑/大厦介绍</div>
                                        <div className="sysInputs">
                                            <Form.Item
                                                name="estateDesc"
                                            >
                                                <TextArea placeholder="屋苑/大厦介绍" maxLength={4000} rows={3}/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">屋苑/大厦图片</div>
                                        <div className="sysInputs">
                                            <Form.Item 
                                                name="img"
                                                rules={[
                                                    { required: true, validator: (rule, value) => checkImg(value) }
                                                ]}
                                            >
                                                <ImgCrop rotationSlider aspect={5/3}>
                                                    <Upload
                                                        action={Config.uploadTestServer} 
                                                        key={"1"}
                                                        accept=".png, .jpg, .jpeg"
                                                        listType="picture-card"
                                                        fileList={imageFile}
                                                        onPreview={handlePreview}
                                                        onChange={(file) => handleChange(file)}>
                                                        {imageFile.length >= imageNum ? null : <div className='plusOutBox'>
                                                            <PlusOutlined />
                                                            <div>添加图片</div>
                                                            <div className='plusOutDesc'>(推荐上传宽高大于等于400*240像素的5:3尺寸的图片)</div>
                                                        </div>}
                                                    </Upload>
                                                </ImgCrop>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">地理位置</div>
                                        <div className="sysInputs">
                                            <div id="apartmentMap"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="sysSaveButton">
                                    <Button className="sysBtn btnBule" type="primary" disabled={isLoad} htmlType="submit" icon={<CheckCircleOutlined />}>保存</Button>
                                    <Button className="sysBtn" onClick={cancel} icon={<CloseCircleOutlined />}>取消</Button>
                                </div>
                            </Form>
                            <Modal title="放大图" visible={previewVisible} footer={null} onCancel={() => setPreviewVisible(false)}>
                                <img alt="example" style={{ width: '100%' }} src={previewImage} />
                            </Modal>
                        </TabPane>
                    </Tabs>
                </Spin>
            </div>
        </div>
    )

}

export default ProEstate;
    