import React, { useState, useEffect } from 'react';
import { Button,Modal, Form, Input, Spin, Select, Row, Col} from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Config } from '../../public/js/config';
import { Base } from '../../public/js/base';
import PropTypes from 'prop-types';//props传参检查库

import Message from '../../component/message/message';//提示信息弹框
import context from '../../public/js/context';
import './sysUser.css';

const { Option } = Select;

const RoleFromModal = (props) => {

    const [form] = Form.useForm();
    const [sysRoleList, setSysRoleList] = useState([]);
    const [messageShow, setMessageShow] = useState(false);
    const [messageStyle, setMessageStyle] = useState("");
    const [message, setMessage] = useState("");
    const [isLoad, setIsLoad] = useState(false);
    const sysRoleModel = props.model;

    useEffect(() => {
        searchRole();
        initForm(sysRoleModel);
    }, []);
    
    const searchRole = () => {
        Base.callBackApi("role/searchRole").then((data) => {
            if ("SUCCESS" === data.msg) {
                setSysRoleList(data.object ?? []);
            } else {
                this.errFrameShow("error", data.msg);
            }
        }).catch(err => { });
    }

    const initForm = (model) => {
        form.setFieldsValue({
            roleId: model.roleId ?? "",
            roleCode: model.roleCode ?? "",
            activeInd: model.activeInd ?? "",
            defaultInd: model.defaultInd ?? "",
        });
    }

    const roleIdChnage = (value) => {
        let model = sysRoleList.find(item => item.roleId == value);
        form.setFieldsValue({ roleCode: model?.roleCode ?? "" });
    }

    const defaultIndChnage = (value) => {
        if(value === "Y") {
            form.setFieldsValue({ activeInd: 'Y' });
        }
    }

    const activeIndChange = (value) => {
        if(value === "N") {
            form.setFieldsValue({ defaultInd: 'N' });
        }
    }

    const save = () => {
        form.submit();
    }

    const onFinish = (values) => {
        messageShowHandler();
        setIsLoad(true);
        let url = "";
        if(sysRoleModel?.userRoleId) {
            url = "user/updateUserRole";
            values.userRoleId = sysRoleModel?.userRoleId;
            values.lastUpdatedDate = sysRoleModel?.lastUpdatedDate;
        } else {
            url = "user/insertUserRole";
        }
        values.phone = props.phone;
        values.userId = props.userId;
        values.menuFuncId = Config.funcInfo.funcId;
        Base.callBackApi(url, values).then((data) => {
            if ("SUCCESS" === data.msg) {
                onCancel(true);
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }

    const onCancel = (flag = false) => {
        props.onClose(flag);
    }

    const errFrameShow = (style, msg) => {
        setMessage(msg);
        setMessageStyle(style);
        setMessageShow(true);
    }

    const messageShowHandler = () => {
        setMessage("");
        setMessageShow(false);
    }

    return(
        <Spin size="large" spinning={isLoad} tip={"数据保存中..."}>
            <Modal
                visible={true}//控制弹框是否显示
                title="角色 新增/修改框"//标题
                centered//垂直居中
                destroyOnClose={true}//关闭即销毁？
                closable//是否显示右上角的关闭按钮
                maskClosable={false}//点击蒙层是否允许关闭	
                // width={Config.equipmentInfo.type === 'iPad' ? '65%' : (Config.equipmentInfo.type === 'phone' ? '100%' : '50%')}//弹框宽度
                width={'890px'}
                onCancel={onCancel}//点击取消触发事件
                footer={[
                    <Button key='save'className="sysBtn btnBule"  type="primary" onClick={save} icon={<CheckCircleOutlined />}>保存</Button>, 
                    <Button key='cancel' className="sysBtn" onClick={onCancel} icon={<CloseCircleOutlined />}>取消</Button>
                ]}
            >
                {messageShow ? <Message style={messageStyle} content={message} close={messageShowHandler} /> : ''}
                <Form form={form} onFinish={onFinish}>
                    <div className='sysRow'>
                        <div className='sysCol'>
                            <div className="sysTitles">角色名称</div>
                            <div className="sysSerchInput">
                                <Form.Item
                                    name="roleId"
                                    rules={[
                                        { required: true,  message: '角色不能为空' },
                                    ]}
                                >
                                    <Select onChange={(value) => roleIdChnage(value)}className='sysSelect'>
                                        <Option value=""></Option>
                                        {sysRoleList.map((item) => <Option key={item.roleId} value={item.roleId}>{item.roleName}</Option>)}
                                    </Select>
                                </Form.Item>
                            </div>
                        </div>
                        <div className='sysColMin'>
                            <div className="sysTitleMax">登入默认角色</div>
                            <div className="sysSerchInput">
                                <Form.Item
                                    name="defaultInd"
                                    rules={[
                                        { required: true,  message: '登入默认角色不能为空' },
                                    ]}
                                >
                                    <Select onChange={(value) => defaultIndChnage(value)} className='sysSelect'>
                                        <Option value=""></Option>
                                        {context.inds.map((item) => <Option key={item.value} value={item.value}>{item.label}</Option>)}
                                    </Select>
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                    <div className='sysRow'>
                        <div className='sysCol'>
                            <div className="sysTitles">角色编号</div>
                            <div className="sysSerchInput">
                                <Form.Item
                                    name="roleCode"
                                >
                                    <Input type="text" placeholder="角色编号" disabled maxLength={50}/>
                                </Form.Item>
                            </div>
                        </div>
                        <div className='sysColMin'>
                            <div className="sysTitleMax">激活状态</div>
                            <div className="sysSerchInput">
                                <Form.Item
                                    name="activeInd"
                                    rules={[
                                        { required: true,  message: '激活状态不能为空' },
                                    ]}
                                >
                                    <Select onChange={(value) => activeIndChange(value)} className='sysSelect'>
                                        <Option value=""></Option>
                                        {context.inds.map((item) => <Option key={item.value} value={item.value}>{item.label}</Option>)}
                                    </Select>
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                </Form>
            </Modal>
        </Spin>
    )

}

RoleFromModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    phone: PropTypes.number.isRequired,
    userId:PropTypes.number.isRequired,
    model: PropTypes.object.isRequired,
}

export default RoleFromModal;