import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Button, Table, Form, InputNumber, Spin, Select, Input, Tabs, Space, Modal, Upload, Switch, Tooltip, Checkbox, Cascader } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, SearchOutlined, RedoOutlined, PlusCircleOutlined, EditOutlined, DeleteOutlined, EyeOutlined, PlusOutlined, HomeOutlined } from '@ant-design/icons';
import Message from '../../component/message/message';//提示信息弹框
import PopupFrame from '../../component/popupFrame/popupFrame';//删除弹框
import Audit from '../../component/audit/audit';//审核彈框
import { Config } from '../../public/js/config';
import { Base } from '../../public/js/base';
import ReactPlayer from 'react-player';
import context from '../../public/js/context';
import SelectSchoolModal from './selectSchoolModal';
import './apartment.css';

const { TabPane } = Tabs;
const { Option } = Select;
const ClassicEditor = window.ClassicEditor;

const Apartment = (props) => {

    const history = useHistory();
    const [form] = Form.useForm();
    const [isLoad, setIsLoad] = useState(false);
    const [spinInfo, setSpinInfo] = useState("");
    const [isAudit, setIsAudit] = useState(false);
    const [auditModel, setAuditModel] = useState({});
    const [messageShow, setMessageShow] = useState(false);
    const [messageStyle, setMessageStyle] = useState("");
    const [message, setMessage] = useState("");
    const [tabIndex, setTabIndex] = useState("1");
    const [searchModel, setSearchModel] = useState({apartmentStatus: "Y"});
    const [oldSearchModel, setOldSearchModel] = useState({});
    const [tables, setTables] = useState([]);
    const [tablesCount, setTablesCount] = useState(0);
    const [current, setCurrent] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [checkboxState, setCheckboxState] = useState([]);
    const [type, setType] = useState("");
    const [content, setContent] = useState("");
    const [frameType, setFrameType] = useState("");
    const [isPopupFrame, setIsPopupFrame] = useState(false);
    const [flag, setFlag] = useState("");
    const [imageFile, setImageFile] = useState([]);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);
    const [videoFile, setVideoFile] = useState([]);
    const [previewVideoVisible, setPreviewVideoVisible] = useState(false);
    const [previewVideo, setPreviewVideo] = useState(null);
    const [ckeditor, setCkeditor] = useState(null);
    const [checkList, setCheckList] = useState([
        { label: "近地铁", value: "近地铁" },
        { label: "单人间", value: "单人间" },
        { label: "双人间", value: "双人间" },
        { label: "多人间", value: "多人间" },
        { label: "独立卫浴", value: "独立卫浴" },
    ]);
    const [nearbySchoolsList, setNearbySchoolsList] = useState([]);
    const [serviceList, setServiceList] = useState([
        { label: "24小时前台", value: "24小时前台" },
        { label: "一对一客服", value: "一对一客服" },
        
        { label: "免费wifi", value: "免费wifi" },
        { label: "免费千兆wifi", value: "免费千兆wifi" },
        { label: "电子门锁", value: "电子门锁" },
        { label: "行李寄存", value: "行李寄存" },
        { label: "实时监控", value: "实时监控" },
        { label: "实时安保", value: "实时安保" },
        { label: "维修服务", value: "维修服务" },
        { label: "定时房间清洁", value: "定时房间清洁" },
        { label: "清洁服务", value: "清洁服务" },
        { label: "一梯一户", value: "一梯一户" },
        { label: "双阳台", value: "双阳台" },
        { label: "免费水网", value: "免费水网" },
        { label: "代收包裹", value: "代收包裹" },
        { label: "房间打扫", value: "房间打扫" },
        { label: "独立厨卫", value: "独立厨卫" },
        { label: "全套餐厨具", value: "全套餐厨具" },
        
    ]);
    const [communalList, setCommunalList] = useState([
        { label: "共享空间及厨房", value: "共享空间及厨房" },
        { label: "共享空间", value: "共享空间" },
        { label: "大型冰箱", value: "大型冰箱" },
        { label: "每层设中型冰箱", value: "每层设中型冰箱" },
        { label: "天台户外空间", value: "天台户外空间" },
        { label: "免费洗衣机", value: "免费洗衣机" },
        { label: "免费烘干机", value: "免费烘干机" },
        { label: "电视机", value: "电视机" },
        { label: "厨房", value: "厨房" },
        { label: "露台", value: "露台" },
        { label: "卫生间", value: "卫生间" },
        { label: "微波炉", value: "微波炉" },
        { label: "洗衣机", value: "洗衣机" },
        { label: "电梯", value: "电梯" },
        { label: "洗衣房", value: "洗衣房" },
    ]);
    const [cperipheralList, setCperipheralList] = useState([
        { label: "超市", value: "超市" },
        { label: "便利店", value: "便利店" },
        { label: "菜市场", value: "菜市场" },
        { label: "生活用品小店", value: "生活用品小店" },
        { label: "巴士站", value: "巴士站" },
        { label: "地铁", value: "地铁" },
        { label: "小食店", value: "小食店" },
        { label: "公共图书馆", value: "公共图书馆" },
        { label: "公共体育馆", value: "公共体育馆" },
        { label: "画廊", value: "画廊" },
        { label: "艺术展", value: "艺术展" },
        { label: "中西餐厅", value: "中西餐厅" },
        { label: "咖啡店", value: "咖啡店" },
    ]);
    const [isSchoolModal, setIsSchoolModal] = useState(false);
    const [apartmentModel, setApartmentModel] = useState({});
    const [schoolList, setSchoolList] = useState([]);
    const [updateStatusModel, setUpdateStatusModel] = useState({});
    const [options, setOptions] = useState([]);//地区
    const [areaId,setAreaId] = useState();//区域id

    useEffect(() => {
        searchSchoolList();
        queryAllArea();
        search(pageSize, 1, "N");
        ClassicEditor.create(document.querySelector('#editor'), {...Config.ckEditorConfig, ...{simpleUpload: { uploadUrl: Config.apiServer + "ckEditorUploadImg/uploadApartmentDescImg" }}})
        .then(editor => {
            setCkeditor(editor);
        }).catch(error => { console.log(error) });
    }, []);

    /**初始化定位信息 */
    const initLocationModel = () =>{ 
        if(window.locationModel == null){
            setTimeout(() => {
                initLocationModel();
            }, 500);
        }else{
            Config.locationModel = window.locationModel;
        }
    };

    const searchSchoolList = () => {
        Base.callBackApi("commonDropDown/searchSysParam", { segment: "SCHOOL" }).then((data) => {
            if ("SUCCESS" === data.msg) {
                setSchoolList(data.object ?? []);
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { });
    }

    //查询全部地区
    const queryAllArea = () =>{
        Base.callBackApi("apartment/queryAllArea").then((data) => {
            if ("SUCCESS" === data.msg) {
                setOptions(data?.object ?? []);
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { });        
    }

    const search = (pageSizeNew, currentPageNew, flag, isShowMsg) => {
        if(Config.funcInfo.canSelect === "N") {
            return;
        }
        messageShowHandler();  // 清除提示信息
        clearSelect(); // 清除选中状态
        let model = {};
        if ("Y" === flag) {     // 翻页
            model = oldSearchModel;
        } else {
            model = JSON.parse(JSON.stringify(searchModel));
        }
        model.page = currentPageNew;
        model.pageSize = pageSizeNew;
        setIsLoad(true);
        setSpinInfo("查询中...");
        Base.callBackApi("apartment/search", model).then((data) => {
            if ("SUCCESS" === data.msg) {
                if (!data?.object?.list && isShowMsg) {
                    errFrameShow("info", '找不到符合条件记录.');
                }
                if("N" === flag) {
                    setOldSearchModel(JSON.parse(JSON.stringify(model)));
                }
                data?.object?.list?.forEach(item => {
                    if(item.apartmentImg) {
                        item.apartmentDefaultImg = item.apartmentImg.split(";")[0];
                    }
                })
                setTablesCount(data?.object?.total ?? 0);
                setTables(data?.object?.list ?? []);
                setCurrent(currentPageNew);
                setPageSize(pageSizeNew);
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }

    const searchChange = (key, value) => {
        messageShowHandler();
        let model = JSON.parse(JSON.stringify(searchModel));
        model[key] = value;
        setSearchModel(model);
    }
    
    const reset = () => {
        messageShowHandler();
        clearSelect();
        setSearchModel({});
        setOldSearchModel({});
        setTablesCount(0);
        setTables([]);
        setPageSize(10);
    }

    // Tab页切换
    const handleSelect = (key) => {
        if (key) {
            if(key === "2") {
                if(checkboxState.length === 1 && Config.funcInfo.canUpdate === "Y") {
                    edit();
                } else if(Config.funcInfo.canInsert === "Y") {
                    add();
                }
            } else if(tabIndex !== "1") {
                setType("leave");
                setContent();
                setFrameType("leave");
                setIsPopupFrame(true);
            }
        }
    }

    const add = () => {
        messageShowHandler();
        initForm();
        ckeditor.setData('');

        let locationModel = {
            lat: 22.32004800000,
            lng: 114.17335500000,
            // key: Config.locationModel.key
        }
        window.locationModel = locationModel;
        setTimeout(() => { window.QMap(); }, 200); /* 地图 */
        initLocationModel(); /* 获取地图 */

        setImageFile([]);
        setVideoFile([]);
        setNearbySchoolsList([]);
        setFlag("add");
        setTabIndex("2");
    }

    const initForm = (model = {}) => {
        form.resetFields();
        form.setFieldsValue({
            apartmentTitle: model.apartmentTitle ?? '',
            apartmentType: model.apartmentType ?? '',
            houseName: model.houseName ?? '',
            houseTag: model.houseTag?.split('|') ?? '',
            rentalPrice: model.rentalPrice ?? '',
            aptServices: model.aptServices?.split('|') ?? '',
            communalFacilities: model.communalFacilities?.split('|') ?? '',
            peripheralFacilities: model.peripheralFacilities?.split('|') ?? '',
            addressA: [model.province,model.city,model.country] ??'' ,
        });
    }

    const edit = () => {
        messageShowHandler();
        if(!selectedRowKeys || selectedRowKeys.length !== 1) {
            errFrameShow("error", "请选择一条数据");
            return;
        }
        searchById(selectedRowKeys[0])
    }

    const update = (model, e) => {
        e.stopPropagation();
        messageShowHandler();
        setCheckboxState([model]);
        setSelectedRowKeys([model.apartmentId]);
        searchById(model.apartmentId)
    }

    const searchById = (id) => {
        Base.callBackApi("apartment/searchById", id).then((data) => {
            if ("SUCCESS" === data.msg) {
                let model = data.object ?? {};
                setApartmentModel(model);
                setAreaId(model?.areaId)
                initForm(model);
                ckeditor.setData(model.aptOverview);
                if(model.nearbySchools){
                    let array = model.nearbySchools?.split("|");
                    let newList = [];
                    for (let index = 0; index < array.length; index++) {
                        let ele = schoolList.find((item)=> item.code === array[index] );
                        newList.push(ele);
                    }
                    setNearbySchoolsList(newList);
                }
                setImageFile(
                    model.apartmentImg?.split(";")
                        ?.map(item => {
                            return {
                                url: Config.apartmentImg + item,
                                originUrl: item,
                                thumbUrl: Config.apartmentPath + item,
                                imgStatus: "OLD"
                            };
                        })
                    || []
                );
                if(model.apartmentVideo){
                    let str = model.apartmentVideo.replace("/","\\");
                    let arr = str.split("\\");
                    let newArr = arr[1].split(".");
                    let videoFile = [{
                        url: Config.apartmentVideo + model.apartmentVideo,
                        originUrl: model.apartmentVideo,
                        name: arr[1],
                        thumbUrl:Config.apartmentVideo + arr[0] + "\\" + "previewImg_" + newArr[0] + ".jpg",
                        imgStatus: "OLD"
                    }]
                    setVideoFile(videoFile);
                }else{
                    setVideoFile([]);
                }
                let locationModel = {
                    lat: model?.latitude ?? 22.32004800000,
                    lng: model?.longitude ?? 114.17335500000,
                    // key: Config.locationModel.key
                }
                window.locationModel = locationModel;
                setTimeout(() => { window.QMap(); }, 200); /* 地图 */
                initLocationModel(); /* 获取地图 */
                setFlag("edit");
                setTabIndex("2");
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }

    const popupFrameClose = (state) => {
        if(state) {
            if(frameType === "delete") {
                deleteData();
            } else if(frameType === "leave") {
                cancel();
            } else {
                updateStatus();
            }
        }
        setIsPopupFrame(false);
    }

    const deleteOne = (model, e) => {
        e.stopPropagation();
        setCheckboxState([model]);
        setSelectedRowKeys([model.apartmentId]);
        setType();
        setContent();
        setFrameType("delete");
        setIsPopupFrame(true);
    }

    const showTips = (flag, record) => {
        setUpdateStatusModel({
            apartmentId: record.apartmentId,
            apartmentStatus: flag ? 'Y' : 'N',
            lastUpdatedDate: record.lastUpdatedDate
        });
        setType();
        setContent("确定要" + (flag ? "上架" : "下架") + "该商品吗？");
        setFrameType("update");
        setIsPopupFrame(true);
    }

    const updateStatus = () => {
        setIsLoad(true);
        setSpinInfo("数据保存中...")
        updateStatusModel.menuFuncId = Config.funcInfo.funcId;
        Base.callBackApi("apartment/updateStatus", updateStatusModel).then((data) => {
            if ("SUCCESS" === data.msg) {
                search(pageSize, current, "Y", false);
                errFrameShow("success", "保存成功");
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }

    const deleteData = () => {
        setIsLoad(true);
        setSpinInfo("数据保存中...");
        let deleteModel = checkboxState[0];
        deleteModel.menuFuncId = Config.funcInfo.funcId;
        Base.callBackApi("apartment/delete", deleteModel).then((data) => {
            if ("SUCCESS" === data.msg) {
                search(pageSize, current, "Y", false);
                errFrameShow("success", "刪除成功");
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }

    const audit = () => {
        messageShowHandler();//清空提示信息
        if(!checkboxState || checkboxState.length !== 1) {
            errFrameShow("error", "请选择一条数据");
            return;
        }
        setAuditModel(checkboxState[0]);
        setIsAudit(true);
    }

    const auditCancel = () => {
        setIsAudit(false);
    }

    const cancel = () => {
        messageShowHandler();
        clearSelect();
        ckeditor.setData('');
        setVideoFile([]);
        setImageFile([]);
        setPreviewVisible(false);
        setPreviewImage(null);
        setTabIndex("1");
    }

    const onFinish = (values) => {
        messageShowHandler();
        setIsLoad(true);
        setSpinInfo("数据保存中...")
        let url = "";
        if(flag === "add") {
            url = "apartment/insert";
        } else {
            url = "apartment/update"
            values.apartmentId = apartmentModel.apartmentId;
            values.lastUpdatedDate = apartmentModel.lastUpdatedDate;
        }
        let videoUploadModelList = videoFile.map(item => {
            return {
                status: item.imgStatus,
                url: item.originUrl,
            };
        });
        values.videoUploadModelList = videoUploadModelList;
        let imageUploadModelList = imageFile.map(item => {
            return {
                status: item.imgStatus,
                url: item.originUrl,
            };
        });
        values.imageUploadModelList = imageUploadModelList;
        values.aptOverview = ckeditor.getData();
        values.menuFuncId = Config.funcInfo.funcId;
        let newList = [];
        for (let index = 0; index < nearbySchoolsList.length; index++) {
            let ele = nearbySchoolsList[index];
            newList.push(ele.code);
        }
        values.nearbySchools = newList.join('|');
        values.houseTag = values.houseTag.join('|');
        values.aptServices = values.aptServices.join('|');
        values.communalFacilities = values.communalFacilities.join('|');
        values.peripheralFacilities = values.peripheralFacilities.join('|');
        values.areaId = areaId;
        values.longitude = window.locationModel?.signModel?.lng;
        values.latitude = window.locationModel?.signModel?.lat;
        Base.callBackApi(url, values).then((data) => {
            if ("SUCCESS" === data.msg) {
                search(pageSize, current, "Y", false);
                ckeditor.setData('');
                errFrameShow("success", "保存成功");
                setTabIndex("1");
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }

    const handleChange = (file) => {
        let fileList = file.fileList;
        file = file.file;
        if(file.status === "removed") {
            let index = imageFile.findIndex(item => item.uid == file.uid);
            let fileList = JSON.parse(JSON.stringify(imageFile));
            fileList.splice(index, 1)
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = (e) => { 
                fileList[fileList.length - 1].originUrl = e.target.result; 
                fileList[fileList.length - 1].imgStatus = "NEW";
            }
        }
        setImageFile(fileList);
    }

    /**查看图片 */
    const handlePreview = (file) => {
        setPreviewImage(file.url || file.originUrl);
        setPreviewVisible(true);
    }

    const checkImg = (value) => {
        if(!!imageFile && imageFile.length > 0) {
            if(value) {
                let list = value.fileList;
                if(list.find(item => item.status === "uploading")) {
                    return Promise.reject("请等待图片上传完成");
                }
                if(list.find(item => item.status === "error")) {
                    return Promise.reject("请先处理测试上传失败的图片");
                }
            }
            return Promise.resolve();
        }
        return Promise.reject("图片数量必须大于0");
    }

    const checkVideo = (value) => {
        if(value) {
            let list = value.fileList;
            if(list.find(item => item.status === "uploading")) {
                return Promise.reject("请等待视频上传完成");
            }
            if(list.find(item => item.status === "error")) {
                return Promise.reject("请先处理测试上传失败的视频");
            }
            if(list.find(item => item.size > 5 * 1024 * 1024)) {
                return Promise.reject("请选择小于 5M 的视频文件");
            }
        }
        return Promise.resolve();
    }

    const handleVideoPreview = (file) => {
        setPreviewVideo(file.url || file.originUrl);
        setPreviewVideoVisible(true);
    }

    const handleVideoChange = (file) => {
        let fileList = file.fileList;
        file = file.file;
        if(file.status === "removed") {
            let index = imageFile.findIndex(item => item.uid == file.uid);
            let fileList = JSON.parse(JSON.stringify(imageFile));
            fileList.splice(index, 1)
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = (e) => { 
                fileList[fileList.length - 1].originUrl = e.target.result; 
                fileList[fileList.length - 1].imgStatus = "NEW";
            }
        }
        setVideoFile(fileList);
    }

    const closeSchoolModal = (flag, list) => {
        if(flag){
            let newList = nearbySchoolsList;
            for (let index = 0; index < list.length; index++) {
                let element = list[index];
                if(newList.findIndex(item=>item.code === element.code) < 0 ){
                    newList.push(element);
                }
            }
            setNearbySchoolsList(JSON.parse(JSON.stringify(newList)));
        }
        setIsSchoolModal(false);
    }

    const deleteSchool = (model) => {
        let newList = nearbySchoolsList.filter(item => item.code !== model.code);
        setNearbySchoolsList(JSON.parse(JSON.stringify(newList)));
    }

    const onChangeAddressA =(value,item) =>{
        if(item){
            if(!item[2]?.id){
                form.setFieldsValue({
                    addressA:[],
                });
            }
            setAreaId(item[2]?.id ?? 0);
        }
    }

    //分页 页码切换变化查询
    const onChange = (currentNew, pageSizeNew) => {
        search(pageSizeNew, currentNew, "Y");
    }
    //分页 每页條數变化查询
    const onShowSizeChange = (currentNew, pageSizeNew) => {
        search(pageSizeNew, 1, "Y");
    }

    //列表选中记录
    const onSelectChange = (selectedRowKeys, selectedRows) => {
        setSelectedRowKeys(selectedRowKeys);
        setCheckboxState(selectedRows);
    }
    //清空列表选中
    const clearSelect = () => {
        setSelectedRowKeys([]);
        setCheckboxState([]);
    }

    const errFrameShow = (style, msg) => {
        setMessage(msg);
        setMessageStyle(style);
        setMessageShow(true);
        document.documentElement.scrollTop = 0;
    }

    const messageShowHandler = () => {
        setMessage("");
        setMessageShow(false);
    }

    /*eslint-disable*/
    const columns = [
        {
            title: '品牌公寓图片',
            dataIndex: 'apartmentDefaultImg',
            render: (text) => <img className="apartmentTableImg" alt='' src={Config.apartmentPath + text}/>
        },
        {
            title: '品牌公寓名称',
            dataIndex: 'apartmentTitle',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'apartmentTitle') },
        },
        {
            title: '品牌公寓类型',
            dataIndex: 'apartmentType',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'apartmentType') },
        },
        {
            title: '屋苑或大厦名称',
            dataIndex: 'houseName',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'houseName') },
        },
        {
            title: '租金',
            dataIndex: 'rentalPrice',
            className: 'tableRightAlignment',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'rentalPrice') },
            render: (text) => Base.numberFormat(text, 2)
        },
        {
            title: '房源标签',
            dataIndex: 'houseTag',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'houseTag') },
        },
        {
            title: '状态',
            dataIndex: 'apartmentStatus',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'apartmentStatus') },
            render: (text, record) => <Switch checkedChildren="已上架" unCheckedChildren="未上架" checked={text === 'Y'} onChange={(e) => showTips(e, record)} />
        },
        {
            title: '操作',
            dataIndex: '',
            className: 'tableWidth14 tableRightAlignment',
            render: (text, record) => <div className='sysOperation proBrandEstateOperation'>
                <div className='sysUpdate' onClick={(e) => update(record ,e)}><EditOutlined /> 修改</div>
                <div className='sysDelete' onClick={(e) => deleteOne(record,e)}><DeleteOutlined /> 删除</div>
            </div>
        },
    ];
    /*eslint-enable*/

    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
        type: 'radio',
    }

    const uploadVideoButton = (
        <div>
            <Button icon={<PlusOutlined />}>添加公寓视频</Button>
        </div>
    );

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div>添加公寓图片</div>
        </div>
    );

    return(
        <div id="container" className="template apartment">
            <div className='breadcrumb'>
                <HomeOutlined /> /  其他 / <span className='breadcrumbTitle'>品牌公寓</span>
            </div>
            {messageShow ? <Message style={messageStyle} content={message} close={messageShowHandler}/> : ''}
            <div className='templateMain'>
                <div className="sysModelTitle">品牌公寓</div>
                <Spin spinning={isLoad} size="large" tip={spinInfo}>
                    {/* 删除弹框 */}
                    {isPopupFrame && <PopupFrame close={popupFrameClose} type={type} content={content} />}
                    {/* 审核彈框 */}
                    <Audit visible={isAudit} auditModel={auditModel} close={auditCancel} />
                    {/* 选择学校弹窗 */}
                    {isSchoolModal && <SelectSchoolModal onClose={closeSchoolModal} selectList={nearbySchoolsList}/>}
                    <Tabs activeKey={tabIndex} onChange={(e) => handleSelect(e)}>
                        <TabPane tab="列表" key="1">
                            <div className='sysRow'>
                                <div className='sysCol'>
                                    <div className="sysTitle">品牌公寓名称</div>
                                    <div className="sysSerchInput">
                                        <Input type="text" placeholder="品牌公寓名称" maxLength={20} value={searchModel.apartmentTitle}
                                            onChange={(e) => searchChange("apartmentTitle", e.target.value)}
                                            onKeyDown={(e) => e.key === "Enter" ? search(pageSize, 1, "N", true) : null} />
                                    </div>
                                </div>
                                <div className='sysCol'>
                                    <div className="sysTitle">品牌公寓类型</div>
                                    <div className="sysSerchInput">
                                        <Select className="sysSelect" value={searchModel.apartmentType || ''} onChange={(value) => searchChange('apartmentType', value)}>
                                            <Option value=""></Option>
                                            {context.apartmentTypeMap.map((item) => <Option key={item.value} value={item.value}>{item.label}</Option>)}
                                        </Select>
                                    </div>
                                </div>
                                <div className='sysCol'>
                                    <div className="sysTitle">状态</div>
                                    <div className="sysSerchInput">
                                        <Select className="sysSelect" value={searchModel.apartmentStatus || ''} onChange={(value) => searchChange('apartmentStatus', value)}>
                                            <Option value="">全部</Option>
                                            {context.productStatusMap.map(item => <Option key={item.value} value={item.value}>{item.label}</Option>)}
                                        </Select>
                                    </div>
                                </div>
                                <div className='sysSearchButton'>
                                    <Button className="sysBtn btnBule" type="primary" onClick={() => search(pageSize, 1, "N", true)} hidden={Config.funcInfo.canSelect === "N"} icon={<SearchOutlined />}>查询</Button>
                                    <Button className="sysBtn" onClick={reset} icon={<RedoOutlined />}>重置</Button>
                                </div>
                            </div>
                            <div className="sysLineEight"></div>
                            <Space size={[5, 5]} wrap className="sysButtonRelative">
                                <Button className="sysBtn btnBule" type="primary" onClick={add} hidden={Config.funcInfo.canInsert === "N"} icon={<PlusCircleOutlined />}>新增</Button>
                                {tables.length == 0 ? null :
                                    <>
                                        <Button className="sysBtn btnBule" type="primary" onClick={audit} hidden={Config.funcInfo.canAudit === "N"} icon={<EyeOutlined />}>日志</Button>
                                    </>
                                }
                            </Space>
                            <div className="sysTable" id="tableOne">
                                <Table
                                    rowSelection={rowSelection} //选择
                                    rowKey="apartmentId"
                                    columns={columns} //列名
                                    dataSource={tables} //数据
                                    pagination={{  //分页
                                        showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                        onChange: onChange,                        //上页下页或者跳页方法
                                        onShowSizeChange: onShowSizeChange,        //每页显示多少条数据方法
                                        total: tablesCount,             //总共多少条数据
                                        current: current,                //当前页
                                        pageSize: pageSize,              //每页显示多少条数据
                                        defaultCurrent: 1,                               //默认当前页为1
                                        size:"small"
                                    }}
                                    onRow={(record) => {
                                        return {
                                            onClick: event => Base.selectRadioRowNew.call(this, record, 'apartmentId', selectedRowKeys, setCheckboxState, setSelectedRowKeys),
                                        };
                                    }}
                                />
                            </div>
                        </TabPane>
                        <TabPane tab="品牌公寓" key="2" forceRender={true}>
                            <Form form={form} onFinish={onFinish}>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">品牌公寓名称</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="apartmentTitle"
                                                rules={[
                                                    { required: true, message: '品牌公寓名称不能为空' },
                                                ]}
                                            >
                                                <Input type="text" placeholder="品牌公寓名称" maxLength={50}/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">品牌公寓类型</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="apartmentType"
                                                rules={[
                                                    { required: true, message: '品牌公寓类型不能为空' },
                                                ]}
                                            >
                                                <Select className="sysSelect">
                                                    <Option value=""></Option>
                                                    {context.apartmentTypeMap.map((item) => <Option key={item.value} value={item.value}>{item.label}</Option>)}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">品牌公寓标签</div>
                                        <div className="sysInputs">
                                            <Form.Item
                                                name="houseTag"
                                                rules={[
                                                    { required: true, message: '品牌公寓标签不能为空' },
                                                ]}
                                            >
                                                <Checkbox.Group options={checkList}  />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam">附近学校</div>
                                        <div className="sysInputs apartmentTreeDiv">
                                            <div className='treeDiv'>
                                                {(Base.isNotEmpty(nearbySchoolsList) && nearbySchoolsList.length > 0)?
                                                    nearbySchoolsList.map((item) => 
                                                        <Tooltip title={item.parmValue1} overlayClassName="lawTip" key={item.code}>
                                                            <span className='lawArticleItem' key={item.code} value={item.code}>
                                                                {item.parmValue1}
                                                                <CloseCircleOutlined className='closeImg' onClick={()=> deleteSchool(item)} />
                                                            </span>
                                                        </Tooltip>
                                                    )
                                                    :null
                                                }
                                            </div>
                                            <Button className="buttonYellowTwoStyle" type="primary" onClick={() => {setIsSchoolModal(true)}}>选择学校</Button>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">租金</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="rentalPrice"
                                                rules={[
                                                    { required: true, validator: (rule, value) => Base.checkNbrOrNull(value, null, 99999999.99) }
                                                ]}
                                            >
                                                <InputNumber className='sysInputNumber' placeholder="租金" precision={2} maxLength={11} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">地区</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="addressA"
                                                rules={[
                                                    { required: true, message: '所在地区不能为空' },
                                                ]}
                                            >
                                                <Cascader options={options} onChange={onChangeAddressA} placeholder="请填写所在地区" />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">屋苑或大厦名称</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="houseName"
                                                rules={[
                                                    { required: true, message: '屋苑或大厦名称不能为空' },
                                                ]}
                                            >
                                                <Input type="text" placeholder="屋苑或大厦名称" maxLength={50}/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam">品牌公寓视频</div>
                                        <div className="sysInputs">
                                            <Form.Item 
                                                name="apartmentVideo"
                                                rules={[
                                                    { required: true, validator: (rule, value) => checkVideo(value) }
                                                ]}
                                            >
                                                <Upload
                                                    action={Config.uploadTestServer}
                                                    key={"2"}
                                                    className="videoUpload"
                                                    listType="picture"
                                                    maxCount={1}
                                                    fileList={videoFile}
                                                    onPreview={handleVideoPreview}
                                                    onChange={(file) => handleVideoChange(file)}>
                                                    {videoFile.length >= 1 ? null : uploadVideoButton }
                                                </Upload>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">品牌公寓图片</div>
                                        <div className="sysInputs">
                                            <Form.Item 
                                                name="img"
                                                rules={[
                                                    { required: true, validator: (rule, value) => checkImg(value) }
                                                ]}
                                            >
                                                <Upload
                                                    action={Config.uploadTestServer} 
                                                    key={"1"}
                                                    accept=".png, .jpg, .jpeg"
                                                    listType="picture-card"
                                                    fileList={imageFile}
                                                    onPreview={handlePreview}
                                                    onChange={(file) => handleChange(file)}>
                                                    {uploadButton}
                                                </Upload>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam">公寓概况</div>
                                        <div className="sysInputs">
                                            <div id="editor" className="editor"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">公寓服务</div>
                                        <div className="sysInputs">
                                            <Form.Item
                                                    name="aptServices"
                                                    rules={[
                                                        { required: true, message: '公寓服务标签不能为空' },
                                                    ]}
                                                >
                                                <Checkbox.Group options={serviceList}  />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">公共设施</div>
                                        <div className="sysInputs">
                                            <Form.Item
                                                    name="communalFacilities"
                                                    rules={[
                                                        { required: true, message: '公共设施标签不能为空' },
                                                    ]}
                                                >
                                                <Checkbox.Group options={communalList}  />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">周边设施</div>
                                        <div className="sysInputs">
                                            <Form.Item
                                                    name="peripheralFacilities"
                                                    rules={[
                                                        { required: true, message: '周边设施标签不能为空' },
                                                    ]}
                                                >
                                                <Checkbox.Group options={cperipheralList}  />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam">地理位置</div>
                                        <div className="sysInputs">
                                            <div id="apartmentMap"></div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="sysSaveButton">
                                    <Button className="sysBtn btnBule" type="primary" disabled={isLoad} htmlType="submit" icon={<CheckCircleOutlined />}>保存</Button>
                                    <Button className="sysBtn" onClick={cancel} icon={<CloseCircleOutlined />}>取消</Button>
                                </div>
                            </Form>
                            <Modal title="放大图" visible={previewVisible} footer={null} onCancel={() => setPreviewVisible(false)}>
                                <img alt="example" style={{ width: '100%' }} src={previewImage} />
                            </Modal>
                            {previewVideoVisible ?
                                <Modal title="视频预览" visible={true} footer={null} onCancel={() => setPreviewVideoVisible(false)}>
                                    <ReactPlayer 
                                        className="reactPlayer" 
                                        key={"1"}
                                        url={previewVideo}
                                        playing={true}
                                        width='100%'
                                        height="100%"
                                        controls
                                    ></ReactPlayer>
                                </Modal>
                                :null
                            }
                        </TabPane>
                    </Tabs>
                </Spin>
            </div>
        </div>
    )

}

export default Apartment;