import React, { useState, useEffect } from 'react';
import { Button, Table, Spin, Form, Input, Tabs, Space } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined,HomeOutlined, SearchOutlined, RedoOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Config } from "../../public/js/config";
import Message from '../../component/message/message';//提示信息弹框
import PopupFrame from '../../component/popupFrame/popupFrame';//删除弹框
import Audit from '../../component/audit/audit';//審核彈框
import { Base } from '../../public/js/base';
import { ContextHandler } from '../../public/js/contextHandler';
import './passwordManage.css';

const { TabPane } = Tabs;

const PasswordManage = (props) => {
    
    const [form] = Form.useForm();
    const [isLoad, setIsLoad] = useState(false);
    const [spinInfo, setSpinInfo] = useState("");
    const [isAudit, setIsAudit] = useState(false);
    const [auditModel, setAuditModel] = useState({});
    const [messageShow, setMessageShow] = useState(false);
    const [messageStyle, setMessageStyle] = useState("");
    const [message, setMessage] = useState("");
    const [tabIndex, setTabIndex] = useState("1");
    const [searchModel, setSearchModel] = useState({});
    const [oldSearchModel, setOldSearchModel] = useState({});
    const [tables, setTables] = useState([]);
    const [tablesCount, setTablesCount] = useState(0);
    const [current, setCurrent] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [checkboxState, setCheckboxState] = useState([]);
    const [isLeave, setIsLeave] = useState(false);

    useEffect(() => {
        search(pageSize, 1, "N");
    }, []);

    const search = (pageSizeNew, currentPageNew, flag, isShowMsg) => {
        if(Config.funcInfo.canSelect === "N") {
            return;
        }
        messageShowHandler();  // 清除提示信息
        clearSelect(); // 清除选中状态
        let model = {};
        if ("Y" === flag) {     // 翻页
            model = oldSearchModel;
        } else {
            model = searchModel;
        }
        model.page = currentPageNew;
        model.pageSize = pageSizeNew;
        setIsLoad(true);
        setSpinInfo("查询中...");
        Base.callBackApi("user/search", model).then((data) => {
            if ("SUCCESS" === data.msg) {
                if (!data?.object?.list && isShowMsg) {
                    errFrameShow("info", '找不到符合條件記錄.');
                }
                if("N" === flag) {
                    setOldSearchModel(JSON.parse(JSON.stringify(model)));
                }
                setTablesCount(data?.object?.total ?? 0);
                setTables(data?.object?.list ?? []);
                setCurrent(currentPageNew);
                setPageSize(pageSizeNew);
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }

    const searchChange = (key, value) => {
        messageShowHandler();
        setSearchModel(model =>{
            return {...model,...{[key]:value}};
        });
    }
    
    const reset = () => {
        messageShowHandler();
        clearSelect();
        setSearchModel({});
        setOldSearchModel({});
        setTablesCount(0);
        setTables([]);
        setPageSize(10);
    }

    // Tab页切换
    const handleSelect = (key) => {
        if (key) {
            if(key === "2" && Config.funcInfo.canUpdate === "Y") {
                editPassword();
            } else if(tabIndex !== "1") {
                setTabIndex("1");
            }
        }
    }

    const editPassword = () => {
        messageShowHandler();
        if(!selectedRowKeys || selectedRowKeys.length !== 1) {
            errFrameShow("error", "请选择一条数据");
            return;
        }
        let model = checkboxState[0];
        initForm(model);
        setTabIndex("2");
    }

    const update = (model,e) => {
        e.stopPropagation();
        messageShowHandler();
        setCheckboxState([model]);
        setSelectedRowKeys([model.userId]);
        initForm(model);
        setTabIndex("2");
    }

    const initForm = (model = {}) => {
        form.resetFields();
        form.setFieldsValue({
            userId: model.userId ?? '',
            userName: model.userName ?? '',
            newPwd: '',
            pwd: ''
        });
    }

    const audit = () => {
        messageShowHandler();//清空提示信息
        if(!checkboxState || checkboxState.length !== 1) {
            errFrameShow("error", "请选择一条数据");
            return;
        }
        setAuditModel(checkboxState[0]);
        setIsAudit(true);
    }

    const auditCancel = () => {
        setIsAudit(false);
    }

    const onFinish = (values) => {
        messageShowHandler();
        setIsLoad(true);
        setSpinInfo("数据保存中...")
        values.userId = selectedRowKeys[0];
        values.lastUpdatedDate = checkboxState[0].lastUpdatedDate;
        values.menuFuncId = Config.funcInfo.funcId;
        Base.callBackApi("user/updatePwd", values).then((data) => {
            if ("SUCCESS" === data.msg) {
                search(pageSize, current, "Y");
                errFrameShow("success", "保存成功");
                setTabIndex("1");
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }

    const cancel = () => {
        messageShowHandler();
        clearSelect();
        setTabIndex("1");
    }

    const leaveFrameClose = (state) => {
        if(state) {
            cancel();
        }
        setIsLeave(false);
    }

    //分页 页码切换变化查询
    const onChange = (currentNew, pageSizeNew) => {
        search(pageSizeNew, currentNew, "Y");
    }
    //分页 每页條數变化查询
    const onShowSizeChange = (currentNew, pageSizeNew) => {
        search(pageSizeNew, 1, "Y");
    }

    //列表选中记录
    const onSelectChange = (selectedRowKeys, selectedRows) => {
        setSelectedRowKeys(selectedRowKeys);
        setCheckboxState(selectedRows);
    }
    //清空列表选中
    const clearSelect = () => {
        setSelectedRowKeys([]);
        setCheckboxState([]);
    }

    const errFrameShow = (style, msg) => {
        setMessage(msg);
        setMessageStyle(style);
        setMessageShow(true);
        document.documentElement.scrollTop = 0;
    }

    const messageShowHandler = () => {
        setMessage("");
        setMessageShow(false);
    }
    
    const columns = [
        {
            title: '账号',
            dataIndex: 'userId',
            className: 'tableWidth16',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'userId') },
        },
        {
            title: '用戶名',
            dataIndex: 'userName',
            className: 'tableWidth16',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'userName') },
        },
        {
            title: '手机号码',
            dataIndex: 'phone',
            className: 'tableWidth15',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'phone') },
        },
        {
            title: '邮箱',
            dataIndex: 'email',
            className: 'tableWidth26',
            sorter: (a, b) => { return Base.sortMethod(a, b, '郵箱') },
        },
        {
            title: '状态',
            dataIndex: 'activeInd',
            className: 'tableWidth15',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'activeInd') },
            render: (text) => ContextHandler.getMapValue('activeMap', text)
        },
        {
            title: '操作',
            dataIndex: '',
            className: 'tableWidth12 tableRightAlignment',
            render: (text, record) => <div className='sysOperation proBrandEstateOperation'>
                <div className='sysUpdate' onClick={(e) => update(record ,e)}><EditOutlined /> 修改密码</div>
            </div>
        },
    ];

    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
        type: "radio"
    };

    return (
        <div id="container" className="template">
            <div className='breadcrumb'>
                <HomeOutlined /> / 系统管理 / <span className='breadcrumbTitle'>修改密码</span>
            </div>
            {/* 错误信息弹框 */}
            {messageShow ? <Message style={messageStyle} content={message} close={messageShowHandler}/> : ''}
            <div className='templateMain'>
                <div className="sysModelTitle">修改密码</div>
                <Spin size="large" spinning={isLoad} tip={spinInfo}>
                    {/* 審核彈框 */}
                    {isLeave ?  <PopupFrame close={leaveFrameClose} type='leave'/> : null}
                    <Audit visible={isAudit} auditModel={auditModel} close={auditCancel}/>
                    <Tabs activeKey={tabIndex} onChange={(e) => handleSelect(e)}>
                        <TabPane key="1" tab="列表">
                            <div className='sysRow'>
                                <div className='sysCol'>
                                    <div className="sysTitles">账号</div>
                                    <div className="sysSerchInput">
                                        <Input type="text" placeholder="账号" value={searchModel.userIds}
                                            onChange={(e) => searchChange('userIds', e.target.value)}
                                            onKeyDown={(e) => e.key === "Enter" ? search(pageSize, 1, "N", true) : null} />
                                    </div>
                                </div>
                                <div className='sysCol'>
                                    <div className="sysTitles">用戶名</div>
                                    <div className="sysSerchInput">
                                        <Input type="text" placeholder="用戶名" value={searchModel.userName}
                                            onChange={(e) => searchChange('userName', e.target.value)}
                                            onKeyDown={(e) => e.key === "Enter" ? search(pageSize, 1, "N", true) : null} />
                                    </div>
                                </div>
                                <div className='sysCol'>
                                    <div className="sysTitles">手机号码</div>
                                    <div className="sysSerchInput">
                                        <Input type="text" placeholder="手机号码" value={searchModel.phone}
                                            onChange={(e) => searchChange('phone', e.target.value)}
                                            onKeyDown={(e) => e.key === "Enter" ? search(pageSize, 1, "N", true) : null} />
                                    </div>
                                </div>
                                <div className='sysSearchButton'>
                                    <Button className="sysBtn btnBule" type="primary" onClick={() => search(pageSize, 1, "N", true)} icon={<SearchOutlined />}>查询</Button>
                                    <Button className="sysBtn" onClick={reset} icon={<RedoOutlined />}>重置</Button>
                                </div>
                            </div>
                            <div className="sysLineEight" />
                            <Space size={[5, 5]} wrap className="sysButtonRelative">
                                {tables.length == 0 ? null :
                                    <>
                                        <Button className="sysBtn btnBule" type="primary" onClick={audit} icon={<EyeOutlined />}>日志</Button>
                                    </>
                                }
                            </Space>
                            <div className="sysTable" id="tableOne">
                                <Table
                                    rowSelection={rowSelection} //选择
                                    rowKey="userId" //Key值
                                    columns={columns} //列名
                                    dataSource={tables} //数据
                                    pagination={{  //分页
                                        showSizeChanger: true,                      //是否显示可以设置几条一页的选项
                                        onChange: onChange,                  //上页下页或者跳页方法
                                        onShowSizeChange: onShowSizeChange,          //每页显示条数方法
                                        total: tablesCount,        //总共多少条数据
                                        current: current,       //当前页
                                        pageSize: pageSize,  //每页显示多少条数据
                                        defaultCurrent: 1,                          //默认当前页为1
                                        size:"small"
                                    }}
                                    onRow={(record) => {
                                        return {
                                            onClick: event => Base.selectRadioRowNew.call(this, record, 'userId', selectedRowKeys, setCheckboxState, setSelectedRowKeys),
                                        };
                                    }}
                                />
                            </div>
                        </TabPane>
                        <TabPane key="2" tab="修改密码" forceRender={true}>
                            <Form form={form} onFinish={onFinish}>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">用戶编号</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="userId"
                                                rules={[
                                                    { required: true, message: '用戶编号不能为空' },
                                                ]}
                                            >
                                                <Input type="text" placeholder="用戶编号" maxLength={20} disabled/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='sysCol'>
                                        <div className="sysParam">用戶名</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="userName"
                                            >
                                                <Input type="text" placeholder="用戶名" disabled/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">新密码</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="newPwd"
                                                rules={[
                                                    { required: true, message: '新密码不能为空' },
                                                    { min: 8, message: '新密码最小長度为8位' },
                                                ]}
                                            >
                                                <Input type="password" placeholder="新密码" maxLength={20}/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">确认密码</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="pwd"
                                                dependencies={['newPwd']}
                                                rules={[
                                                    { required: true, message: '确认密码不能为空' },
                                                    ({ getFieldValue }) => ({
                                                        validator(_, value) {
                                                            if (!value || getFieldValue('newPwd') === value) {
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.reject(new Error('确认密码和新密码不一致'));
                                                        },
                                                    }),
                                                ]}
                                            >
                                                <Input type="password" placeholder="确认密码" maxLength={20}/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className="sysSaveButton">
                                    <Button className="sysBtn btnBule" type="primary" disabled={isLoad} htmlType="submit" icon={<CheckCircleOutlined />}>保存</Button>
                                    <Button className="sysBtn" onClick={cancel} icon={<CloseCircleOutlined />}>取消</Button>
                                </div>
                            </Form>
                        </TabPane>
                    </Tabs>
                </Spin>
            </div>
        </div>
    )

}

export default PasswordManage;