import React, { useState, useEffect } from 'react';
import { Button, Table, Spin, Input, Modal ,Popover ,Tabs} from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, SearchOutlined, RedoOutlined } from '@ant-design/icons';
import Message from '../../component/message/message';//提示信息弹框
import PropTypes from 'prop-types';//props传参检查库
import { Config } from '../../public/js/config';
import { Base } from '../../public/js/base';

const { TabPane } = Tabs;

const SelectCommunity = (props) =>{
    const [isLoad, setIsLoad] = useState(false);
    const [spinInfo, setSpinInfo] = useState("");
    const [messageShow, setMessageShow] = useState(false);
    const [messageStyle, setMessageStyle] = useState("");
    const [message, setMessage] = useState("");
    const [searchModel, setSearchModel] = useState({});
    const [oldSearchModel, setOldSearchModel] = useState({});
    const [tables, setTables] = useState([]);
    const [tablesCount, setTablesCount] = useState(0);
    const [current, setCurrent] = useState(1);
    const [pageSize, setPageSize] = useState(8);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [checkboxState, setCheckboxState] = useState([]);
    const [houseName2, setHouseName2] = useState("");
    const [tabIndex, setTabIndex] = useState("1");

    useEffect(() => {
        search(pageSize, 1, "N");
        console.log(props);
        if(props.communityModel) {
            setSelectedRowKeys([props.communityModel.proEstateId]);
            setCheckboxState([props.communityModel]);
            setHouseName2(props.communityModel.houseName2)
        }
        if(props.communityModel.houseName2){
            setTabIndex("2")
        }
    }, []);

    const search = (pageSizeNew, currentPageNew, flag, isShowMsg) => {
        if(Config.funcInfo.canSelect === "N") {
            return;
        }
        messageShowHandler();
        let model = {};
        if ("Y" === flag) {
            model = oldSearchModel;
        } else {
            model = searchModel;
        }
        model.page = currentPageNew;
        model.pageSize = pageSizeNew;
        setIsLoad(true);
        setSpinInfo("查询中...");
        Base.callBackApi("proEstate/search", model).then((data) => {
            if ("SUCCESS" === data.msg) {
                if (!data?.object?.list && isShowMsg) {
                    errFrameShow("info", '找不到符合条件记录.');
                }
                if("N" === flag) {
                    setOldSearchModel(JSON.parse(JSON.stringify(model)));
                }
                setTablesCount(data?.object?.total ?? 0);
                data?.object?.list?.forEach(item => {
                    if(item.estateImg) {
                        item.estateDefaultImg = item.estateImg.split(";")[0];
                    }
                })
                // for(let i in data?.object.list){
                //     data.object.list[i].area = data?.object.list[i].province + "省" +data?.object.list[i].city+ "市" + data?.object.list[i].area
                //     data.object.list[i].estateDefaultImg = data?.object.list[i].estateImg.split(";")[0];
                // }
                // console.log(data?.object.list);
                setTables(data?.object?.list ?? []);
                setCurrent(currentPageNew);
                setPageSize(pageSizeNew);
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }
    
     // Tab页切换
     const handleSelect = (key) => {
        messageShowHandler();
        setTabIndex(key)
    }

    const searchChange = (key,value) => {
        messageShowHandler();
        let model = JSON.parse(JSON.stringify(searchModel));
        model[key] = value;
        setSearchModel(model);
    }

    const houseName2Change = (value) => {
       setHouseName2(value)
    }

    const reset = () => {
        messageShowHandler();
        clearSelect();
        setSearchModel({});
        setOldSearchModel({});
        setTablesCount(0);
        setPageSize(10);
        setTables([]);
    }

    //分页 页码切换变化查询
    const onChange = (currentNew, pageSizeNew) => {
        search(pageSizeNew, currentNew, "Y");
    }

    //分页 每页條數变化查询
    const onShowSizeChange = (currentNew, pageSizeNew) => {
        search(pageSizeNew, 1, "Y");
    }

    //列表选中记录
    const onSelectChange = (selectedRowKeys, selectedRows) => {
        setSelectedRowKeys(selectedRowKeys);
        setCheckboxState(selectedRows);
    }

    //清空列表选中
    const clearSelect = () => {
        setSelectedRowKeys([]);
        setCheckboxState([]);
    }

    const errFrameShow = (style, msg) => {
        setMessageStyle(style);
        setMessage(msg);
        setMessageShow(true);
    }

    const messageShowHandler = () => {
        setMessage("");
        setMessageShow(false);
    }

    const save = () => {
        messageShowHandler();
        if ((checkboxState === null || checkboxState.length < 1 || !checkboxState[0].proEstateId) && tabIndex === "1"){
            errFrameShow("error", "请至少选择一条数据");
            return
        }else if(tabIndex === "2" && !houseName2){
            errFrameShow("error", "请输入屋苑名称");
            return
        }
        console.log(houseName2);
        let model = {};
        if(tabIndex === "1"){
            model = checkboxState[0];
            setHouseName2("")
        }else{
            model = {houseName2:houseName2}
            setCheckboxState([])
        }
        props.onClose(true, model);
    }

    const onCancel = () => {
        props.onClose(false);
    }

    /*eslint-disable*/
    const columns=[
        {
            title: '屋苑/大厦图片',
            dataIndex: 'estateDefaultImg',
            className: 'tableWidth25',
            render: (text) => <img className="proEstateImg" alt='' src={Config.proEstatePath + text}/>
        },
        {
            title: '屋苑/大厦',
            dataIndex: 'estateTitle',
            className: 'tableWidth25',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'estateTitle') },
        },
        {
            title: '屋苑/大厦介绍',
            dataIndex: 'estateDesc',
            className: 'tableWidth50',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'estateDesc') },
            render: (text) => <Popover className='popover' content={text}>
                <div className='proBrandDesc'>{text}</div>
            </Popover>
        },
        // {
        //     title: '地区',
        //     dataIndex: 'area',
        //     sorter: (a, b) => { return Base.sortMethod(a, b, 'area') },
        // }
    ];
    /*eslint-enable*/

    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
        type: 'radio',
    }

    return(
        <Spin size="large" spinning={isLoad} tip={spinInfo}>
            <Modal
                visible={true}//控制弹框是否显示
                // title="选择屋苑"//标题
                centered//垂直居中
                destroyOnClose={true}//关闭即销毁？
                closable//是否显示右上角的关闭按钮
                maskClosable={false}//点击蒙层是否允许关闭	
                // width={Config.equipmentInfo.type === 'iPad' ? '65%' : (Config.equipmentInfo.type === 'phone' ? '100%' : '55%')}//弹框宽度
                width={'890px'}
                onCancel={onCancel}//点击取消触发事件
                footer={[
                    <Button key='save' className="sysBtn btnBule"  type="primary" onClick={save} icon={<CheckCircleOutlined />}>确定</Button>, 
                    <Button key='cancel' className="sysBtn" onClick={onCancel} icon={<CloseCircleOutlined />}>取消</Button>
                ]}
            >
                {messageShow ? <Message style={messageStyle} content={message} close={messageShowHandler} /> : ''}
                <Tabs activeKey={tabIndex} onChange={(e) => handleSelect(e)}>
                    <TabPane tab="选择屋苑/大厦" key="1">
                        <div>
                            <div className='sysRow'>
                                <div className='sysCol'>
                                    <div className="sysTitles">屋苑/大厦</div>
                                    <div className="sysSerchInput">
                                        <Input type="text" placeholder="屋苑/大厦" maxLength={100} value={searchModel.estateTitle}
                                            onChange={(e) => searchChange("estateTitle", e.target.value)}
                                            onKeyDown={(e) => e.key === "Enter" ? search(pageSize, 1, "N", true) : null} />
                                    </div>
                                </div>
                                <div className='sysSearchButton'>
                                    <Button className="sysBtn btnBule" type="primary" onClick={() => search(pageSize, 1, "N", true)} icon={<SearchOutlined />}>查询</Button>
                                    <Button className="sysBtn" onClick={reset} icon={<RedoOutlined />}>重置</Button>
                                </div>
                            </div>
                            <div className="sysLineEight"></div>
                            <div className="sysTable" id="tableOne">
                                <Table
                                    rowSelection={rowSelection} //选择
                                    rowKey="proEstateId"
                                    columns={columns} //列名
                                    dataSource={tables} //数据
                                    pagination={{  //分页
                                        showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                        onChange: onChange,                        //上页下页或者跳页方法
                                        onShowSizeChange: onShowSizeChange,        //每页显示多少条数据方法
                                        total: tablesCount,             //总共多少条数据
                                        current: current,                //当前页
                                        pageSize: pageSize,              //每页显示多少条数据
                                        defaultCurrent: 1,                               //默认当前页为1
                                        size:"small"
                                    }}
                                    onRow={(record) => {
                                        return {
                                            onClick: event => Base.selectRadioRowNew.call(this, record, 'proEstateId', selectedRowKeys, setCheckboxState, setSelectedRowKeys),
                                        };
                                    }}
                                />
                            </div>
                        </div>
                    </TabPane>
                    <TabPane tab="新增屋苑/大厦" key="2">
                        <div>
                            <div className='sysRow'>
                                <div className='sysColDuan'>
                                    <div className="sysTitles mustFill widthHouseName2">屋苑/大厦</div>
                                    <div className="sysSelectDuan">
                                        <Input type="text" placeholder="请输入屋苑/大厦" maxLength={100} value={houseName2} onChange={(e) => houseName2Change(e.target.value)}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TabPane>
                </Tabs>
            </Modal>
        </Spin>
    )

}
SelectCommunity.propTypes = {
    onClose: PropTypes.func.isRequired,
    communityModel:PropTypes.object.isRequired,
}

export default SelectCommunity;