import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Button, Checkbox, Spin, Form, DatePicker, Input, Select, Row, Col } from 'antd';
import moment from 'moment';//日期格式化导包

import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Config } from '../../public/js/config';
import Message from '../../component/message/message';//提示信息弹框
import { Base } from '../../public/js/base';
import context from '../../public/js/context';
import './changUserInfo.css';

const { Option } = Select;

const ChangUserInfo = (props) => {
    
    const history = useHistory();

    const [form] = Form.useForm();
    const [isLoad, setIsLoad] = useState(false);
    const [spinInfo, setSpinInfo] = useState("");
    const [messageShow, setMessageShow] = useState(false);
    const [messageStyle, setMessageStyle] = useState("");
    const [message, setMessage] = useState("");
    const [oldModel, setOldModel] = useState({});
    const [countryDownList, setCountryDownList] = useState([]);
    const [checkboxValue, setCheckboxValue] = useState(false);

    useEffect(() => {
        searchUserInfoByCode();
    }, []);

    /**查詢用戶信息列表 方法 */
    const searchUserInfoByCode = () => {
        setIsLoad(true);
        setSpinInfo("查询中...");
        Base.callBackApi("user/searchUserInfoById", { userId: Config.userInfo.userId }).then((data) => {
            if ("SUCCESS" === data.msg) {
                initForm(data.object);
            } else {
                errFrameShow("danger", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }

    const initForm = (model = {}) => {
        form.resetFields();
        form.setFieldsValue({
            userId: model.userId ?? '',
            userName: model.userName ?? '',
            oldPwd: '',
            newPwd: '',
            pwd: '',
            position: model.position ?? '',
            userType: model.userType ?? '',
            activeInd: model.activeInd ?? '',
            desc: model.desc ?? '',
            firstLoginActiveInd: model.firstLoginActiveInd ?? '',
            phone: model.phone ?? '',
            email: model.email ?? '',
            docId: model.docId ?? '',
            postalCode: model.postalCode ?? '',
            fax: model.fax ?? '',
            address: model.address ?? '',
            officePhone: model.officePhone ?? '',
            homePhone: model.homePhone ?? '',
            qq: model.qq ?? '',
            surname: model.surname ?? '',
            title: model.title ?? '',
            name: model.name ?? '',
            middleName: model.middleName ?? '',
            countryCode: model.countryCode ?? '',
            birthPlace: model.birthPlace ?? '',
            maritalStatus: model.maritalStatus ?? '',
            birthday: model.birthday ? moment(new Date(model.birthday), Config.dateFormat) : null,
        });
        setOldModel(model);
    }

    const onFinish = (values) => {
        messageShowHandler();
        setIsLoad(true);
        setSpinInfo("数据保存中...")
        let model = { ...oldModel, ...values };
        model.updatePwd = checkboxValue;
        model.menuFuncId = Config.funcInfo.funcId;
        Base.callBackApi("user/updateUserInfo", model).then((data) => {
            if ("SUCCESS" === data.msg) {
                initForm(data.object);
                setCheckboxValue(false);
                errFrameShow("success", "保存成功");
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }

    
    const checkPhone = (value) => {
        if(value) {
            if(/^1[3456879]\d{9}$/.test(value)) {
                return Promise.resolve();
            }
            return Promise.reject(new Error("请输入正确的手机号"));
        }
        return Promise.reject(new Error("手机号不能为空"));
    }


    const cancel = () => {
        history.push('/main/default');
    }

    const errFrameShow = (style, msg) => {
        setMessage(msg);
        setMessageStyle(style);
        setMessageShow(true);
        document.documentElement.scrollTop = 0;
    }

    const messageShowHandler = () => {
        setMessage("");
        setMessageShow(false);
    }
    return (
        <div id="container" className="container">
            <div className="sysModelTitle">修改基本资料&密码</div>
            
            <div>
                {/* 错误信息弹框 */}
                {messageShow ? <Message style={messageStyle} content={message} close={messageShowHandler}/> : ''}
                <Spin size="large" spinning={isLoad} tip={spinInfo}>
                    <h4 className="titleRowTop">基本信息</h4>
                    <Form form={form} onFinish={onFinish}>
                        <div className='sysRow'>
                            <div className='sysCol'>
                                <div className="sysParam mustFill">账号</div>
                                <div className="sysInput">
                                    <Form.Item
                                        name="userId"
                                        rules={[
                                            { required: true, message: '账号不能为空' },
                                        ]}
                                    >
                                        <Input type="text" placeholder="账号" maxLength={20} disabled/>
                                    </Form.Item>
                                </div>
                            </div>
                            <div className='sysCol'>
                                <div className="sysParam">用戶名</div>
                                <div className="sysInput">
                                    <Form.Item
                                        name="userName"
                                    >
                                        <Input type="text" placeholder="用戶名" maxLength={20} disabled/>
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                        <div className='sysRow'>
                            <div className='sysCol'>
                                <div className="sysParam mustFill">更改密码</div>
                                <div className="sysInput">
                                    <Checkbox onChange={e => setCheckboxValue(e.target.checked)} checked={checkboxValue} />
                                </div>
                            </div>
                        </div>
                        {checkboxValue ?
                            <>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">原密码</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="oldPwd"
                                                rules={[
                                                    { required: true, message: '原密码不能为空' }
                                                ]}
                                            >
                                                <Input type="password" placeholder="原密码" maxLength={20}/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">新密码</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="newPwd"
                                                rules={[
                                                    { required: true, message: '新密码不能为空' }
                                                ]}
                                            >
                                                <Input type="password" placeholder="新密码" maxLength={20}/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">确认密码</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="pwd"
                                                dependencies={['newPwd']}
                                                rules={[
                                                    { required: true, message: '确认密码不能为空' },
                                                    ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (!value || getFieldValue('newPwd') === value) {
                                                        return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error('确认密码和新密码不一致'));
                                                    },
                                                    }),
                                                ]}
                                            >
                                                <Input type="password" placeholder="确认密码" maxLength={20}/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                            </>
                            : null
                        }
                        <div className='sysRow'>
                            <div className='sysCol'>
                                <div className="sysParam mustFill">手机号码</div>
                                <div className="sysInput">
                                    <Form.Item
                                        name="phone"
                                        rules={[
                                            { required: true, validator: (rule, value) => checkPhone(value) },
                                        ]}
                                        getValueFromEvent={(e) => e.target.value.replace(/\D/g, '')}
                                    >
                                        <Input type="text" placeholder="手机号码" maxLength={11}/>
                                    </Form.Item>
                                </div>
                            </div>
                            <div className='sysCol'>
                                <div className="sysParam">邮箱</div>
                                <div className="sysInput">
                                    <Form.Item
                                        name="email"
                                    >
                                        <Input type="text" placeholder="邮箱" maxLength={50}/>
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                        <div className='sysRow'>
                            <div className='sysCol'>
                                <div className="sysParam">真实姓名</div>
                                <div className="sysInput">
                                    <Form.Item
                                        name="realName"
                                    >
                                        <Input type="text" disabled placeholder="真实姓名" maxLength={50}/>
                                    </Form.Item>
                                </div>
                            </div>
                            <div className='sysCol'>
                                <div className="sysParam">身份证号</div>
                                <div className="sysInput">
                                    <Form.Item
                                        name="docId"
                                        rules={[
                                            { min: 4, message: '请输入身份证号码最小头四位字母及数字' },
                                        ]}
                                        getValueFromEvent={(e) => Base.codeChange(e, 8)}
                                    >
                                        <Input type="text" placeholder="身份证号" maxLength={8} disabled/>
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                        <div className='sysSearchButton'>
                            <Button className="sysBtn btnBule" type="primary" disabled={isLoad} icon={<CheckCircleOutlined />} htmlType="submit" >保存</Button>
                            <Button className="sysBtn" icon={<CloseCircleOutlined />} onClick={cancel}>取消</Button>
                        </div>
                    </Form>
                </Spin>
            </div>
        </div>
    )

}

export default ChangUserInfo;