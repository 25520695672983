import React, { useState, useEffect } from 'react';
import { Button, Table, Form, Spin, Input, Tabs, Space, Select, Checkbox, Tooltip, Cascader, Radio } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, SearchOutlined, RedoOutlined, PlusCircleOutlined, EditOutlined, DeleteOutlined, EyeOutlined, HomeOutlined, FormOutlined } from '@ant-design/icons';
import Message from '../../component/message/message';//提示信息弹框
import PopupFrame from '../../component/popupFrame/popupFrame';//删除弹框
import Audit from '../../component/audit/audit';//审核彈框
import moment from 'moment';//日期格式化导包
import { Config } from '../../public/js/config';
import { Base } from '../../public/js/base';
import { ContextHandler } from '../../public/js/contextHandler';
import './proBrandEstate.css';
import RoomModal from './roomModal';
import EstateModel from './estateModel';

const { TabPane } = Tabs;
const { TextArea } = Input;
const { Option } = Select;

const ProBrandEstate = (props) => {

    const stipulateList = [
        { label: "不男女同住", value: "不男女同住" },
        { label: "室内禁烟", value: "室内禁烟" },
        { label: "禁养宠物", value: "禁养宠物" },
    ];
    const estateInstallationList = [
        { label: "健身房", value: "健身房" },
        { label: "泳池", value: "泳池" },
        { label: "自习室", value: "自习室" },
        { label: "球场", value: "球场" },
        { label: "琴房", value: "琴房" },
    ];
    const apartmentServicesList = [
        { label: "代缴水电煤网", value: "代缴水电煤网" },
        { label: "定期清洁", value: "定期清洁" },
        { label: "家具家电维修", value: "家具家电维修" },
        { label: "开通网络", value: "开通网络" },
        { label: "求职服务", value: "求职服务" },
    ];
    const surroundingFacilitiesList = [
        { label: "餐厅", value: "餐厅" },
        { label: "银行", value: "银行" },
        { label: "超市", value: "超市" },
        { label: "街市", value: "街市" },
    ];
    const [form] = Form.useForm();
    const proEstateId = Form.useWatch('proEstateId', form);
    const status = Form.useWatch('status', form);
    const [isLoad, setIsLoad] = useState(false);
    const [spinInfo, setSpinInfo] = useState("");
    const [isAudit, setIsAudit] = useState(false);
    const [auditModel, setAuditModel] = useState({});
    const [message, setMessage] = useState("");
    const [messageShow, setMessageShow] = useState(false);
    const [messageStyle, setMessageStyle] = useState("");
    const [tabIndex, setTabIndex] = useState("1");
    const [searchModel, setSearchModel] = useState({apartmentStatus: "Y", proBrandId: Config.userInfo.proBrandId});
    const [oldSearchModel, setOldSearchModel] = useState({});
    const [tables, setTables] = useState([]);
    const [tablesCount, setTablesCount] = useState(0);
    const [current, setCurrent] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [checkboxState, setCheckboxState] = useState([]);
    const [type, setType] = useState("");
    const [content, setContent] = useState("");
    const [frameType, setFrameType] = useState("");
    const [isPopupFrame, setIsPopupFrame] = useState(false);
    const [flag, setFlag] = useState("");
    const [brandList, setBrandList] = useState([]);
    const [estateList, setEstateList] = useState([]);
    const [lastUpdatedDate, setLastUpdatedDate] = useState(null);
    const [roomList, setRoomList] = useState([]);
    const [roomFlag, setRoomFlag] = useState("");
    const [roomModel, setRoomModel] = useState("");
    const [selectedRowKeysRoom, setSelectedRowKeysRoom] = useState([]);
    const [checkboxStateRoom, setCheckboxStateRoom] = useState([]);
    const [isRoomShow, setIsRoomShow] = useState(false);
    const [isEstateShow, setIsEstateShow] = useState(false);
    const [estateModel, setEstateModel] = useState({});
    const [schoolList, setSchoolList] = useState(null);
    const [options, setOptions] = useState([]);
    const [nearbySchoolsList, setNearbySchoolsList] = useState([]);
    const [roomTypeList, setRoomTypeList] = useState([]);
    const [estateTagList, setEstateTagList] = useState([]);

    useEffect(() => {
        searchProBrand();
        searchProEstate();
        searchSchoolList();
        searchRoomTypeList();
        searchAllArea();
        searchEstateTagList();
        search(pageSize, 1, "N");
    }, []);

    useEffect(() => {
        if(proEstateId) {
            searchEstateById();
        } else {
            setEstateModel({});
        }
    }, [proEstateId]);

    const searchSchoolList = () => {
        Base.callBackApi("commonDropDown/searchSysParam", { segment: "SCHOOL" }).then((data) => {
            if ("SUCCESS" === data.msg) {
                setSchoolList(data.object ?? []);
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { });
    }

    const searchRoomTypeList = () => {
        Base.callBackApi("commonDropDown/searchSysParam", { segment: "ROOM_TYPE" }).then((data) => {
            if ("SUCCESS" === data.msg) {
                setRoomTypeList(data.object ?? []);
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { });
    }

    const searchEstateTagList = () => {
        Base.callBackApi("commonDropDown/searchSysParam", { segment: "ESTATE_TAG" }).then((data) => {
            if ("SUCCESS" === data.msg) {
                let list = data.object ?? [];
                setEstateTagList(list.map(item => {
                    return {
                        label: item.parmValue1,
                        value: item.code
                    }
                }));
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { });
    }

    const searchAllArea = () => {
        Base.callBackApi("commonDropDown/searchAllArea").then((data) => {
            if ("SUCCESS" === data.msg) {
                setOptions(data?.object ?? []);
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { });
    }

    const searchProBrand = () => {
        Base.callBackApi("commonDropDown/searchProBrand").then((data) => {
            if ("SUCCESS" === data.msg) {
                setBrandList(data?.object ?? []);
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { });
    }

    const searchProEstate = () => {
        Base.callBackApi("commonDropDown/searchProEstate").then((data) => {
            if ("SUCCESS" === data.msg) {
                setEstateList(data?.object ?? []);
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { });
    }

    const search = (pageSizeNew, currentPageNew, flag, isShowMsg) => {
        if(Config.funcInfo.canSelect === "N") {
            return;
        }
        messageShowHandler();  // 清除提示信息
        clearSelect(); // 清除选中状态
        if(!Config.userInfo.proBrandId) {
            return;
        }
        let model = {};
        if ("Y" === flag) {     // 翻页
            model = oldSearchModel;
        } else {
            model = JSON.parse(JSON.stringify(searchModel));
        }
        model.page = currentPageNew;
        model.pageSize = pageSizeNew;
        setIsLoad(true);
        setSpinInfo("查询中...");
        Base.callBackApi("proBrandEstate/search", model).then((data) => {
            if ("SUCCESS" === data.msg) {
                if (!data?.object?.list && isShowMsg) {
                    errFrameShow("info", '找不到符合条件记录.');
                }
                if("N" === flag) {
                    setOldSearchModel(JSON.parse(JSON.stringify(model)));
                }
                setTablesCount(data?.object?.total ?? 0);
                setTables(data?.object?.list ?? []);
                setCurrent(currentPageNew);
                setPageSize(pageSizeNew);
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }

    const searchChange = (key, value) => {
        messageShowHandler();
        setSearchModel(model => {
            return { ...model, ...{ [key] : value } };
        });
    }
    
    const reset = () => {
        messageShowHandler();
        clearSelect();
        setSearchModel({proBrandId: Config.userInfo.proBrandId});
        setOldSearchModel({});
        setTablesCount(0);
        setTables([]);
        setPageSize(10);
    }

    // Tab页切换
    const handleSelect = (key) => {
        if (key) {
            if(key === "2") {
                if(Config.funcInfo.canUpdate === "Y" && selectedRowKeys.length > 0) {
                    edit();
                } else if(Config.funcInfo.canInsert === "Y") {
                    add();
                }
            } else if(tabIndex !== "1") {
                cancel();
            }
        }
    }

    const add = () => {
        messageShowHandler();
        initForm();
        setRoomList([]);
        setFlag("add");
        setTabIndex("2");
    }

    const edit = () => {
        messageShowHandler();
        if(!selectedRowKeys || selectedRowKeys.length !== 1) {
            errFrameShow("error", "请选择一条数据");
            return;
        }
        searchById(selectedRowKeys[0]);
        searchRoomList(selectedRowKeys[0]);
    }

	const see = (model, e) => {
        e.stopPropagation();
        messageShowHandler();
        setCheckboxState([model]);
        setSelectedRowKeys([model.proBrandEstateId]);
        searchById(model.proBrandEstateId, "see");
        searchRoomList(model.proBrandEstateId);
    }

    const update = (model, e) => {
        e.stopPropagation();
        messageShowHandler();
        setCheckboxState([model]);
        setSelectedRowKeys([model.proBrandEstateId]);
        searchById(model.proBrandEstateId, "edit");
        searchRoomList(model.proBrandEstateId);
    }

    const searchById = (id, flag) => {
        Base.callBackApi("proBrandEstate/searchById", id).then((data) => {
            if ("SUCCESS" === data.msg) {
                let model = data.object ?? {};
                initForm(model);
                setLastUpdatedDate(model.lastUpdatedDate);
                setFlag(flag);
                setTabIndex("2");
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { });
    }

    const searchEstateById = () => {
        Base.callBackApi("proBrandEstate/searchEstateById", proEstateId).then((data) => {
            if ("SUCCESS" === data.msg) {
                let model = data.object ?? {};
                setEstateModel(model);
                if(model.nearbySchools){
                    let array = model.nearbySchools?.split("|");
                    let newList = [];
                    for (let index = 0; index < array.length; index++) {
                        let ele = schoolList.find((item)=> item.code === array[index] );
                        newList.push(ele);
                    }
                    setNearbySchoolsList(newList);
                }
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { });
    }

    const searchRoomList = (id) => {
        Base.callBackApi("proBrandEstate/searchRoomList", id).then((data) => {
            if ("SUCCESS" === data.msg) {
                data?.object?.forEach(item => {
                    if(item.roomImg) {
                        item.roomDefaultImg = item.roomImg.split(";")[0];
                    }
                })
                setRoomList(data.object ?? []);
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { });
    }

    const initForm = (model = {}) => {
        form.resetFields();
        form.setFieldsValue({
            brandEstateTitle: model.brandEstateTitle ?? '',
            proBrandId: model.proBrandId ?? Config.userInfo.proBrandId,
            proEstateId: model.proEstateId ?? '',
            estateInstallation: model.estateInstallation?.split('|') ?? [],
            apartmentServices: model.apartmentServices?.split('|') ?? [],
            surroundingFacilities: model.surroundingFacilities?.split('|') ?? [],
            traffic: model.traffic ?? '',
            schoolTraffic: model.schoolTraffic ?? '',
            preferential: model.preferential ?? '',
            stipulateSelect: model.stipulateSelect?.split('|') ?? [],
            stipulateDesc: model.stipulateDesc ?? '',
            commonProblem: model.commonProblem ?? '',
			auditBy: flag !== "approval" ? (model.auditBy ?? '') : '',
			status: flag !== "approval" ? (model.auditStatus ?? '') : '',
			auditDate: flag !== "approval" ? (model.auditDate ? moment(model.auditDate).format(Config.dateFormatItem2) : '') : '',
			auditRemark: flag !== "approval" ? (model.auditRemark ?? '') : '',
        });
    }

    const updateListed = (model, e) => {
        e.stopPropagation();
        setCheckboxState([model]);
        setSelectedRowKeys([model.proBrandEstateId]);
        setType();
        setContent("您确定要申请上架该公寓吗？");
        setFrameType("updateListed");
        setIsPopupFrame(true);
    }

    const deleteOne = (model, e) => {
        e.stopPropagation();
        messageShowHandler();
        setCheckboxState([model]);
        setSelectedRowKeys([model.proBrandEstateId]);
        setType();
        setContent();
        setFrameType("delete");
        setIsPopupFrame(true);
    }

    const popupFrameClose = (state) => {
        if(state) {
            if(frameType === "delete") {
                deleteData();
            } else if(frameType === "deleteRoom") {
                deleteDataRoom();
            } else if(frameType === "updateListed") {
                listed();
            }
        }
        setIsPopupFrame(false);
    }

    const deleteData = () => {
        setIsLoad(true);
        setSpinInfo("数据保存中...");
        let deleteModel = checkboxState[0];
        deleteModel.menuFuncId = Config.funcInfo.funcId;
        Base.callBackApi("proBrandEstate/delete", deleteModel).then((data) => {
            if ("SUCCESS" === data.msg) {
                search(pageSize, current, "Y", false);
                errFrameShow("success", "刪除成功");
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }

    const listed = () => {
        setIsLoad(true);
        setSpinInfo("数据保存中...");
        let model = checkboxState[0];
        model.menuFuncId = Config.funcInfo.funcId;
        Base.callBackApi("proBrandEstate/updateListed", model).then((data) => {
            if ("SUCCESS" === data.msg) {
                search(pageSize, current, "Y", false);
                errFrameShow("success", "申请提交成功");
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }

    const audit = () => {
        messageShowHandler();//清空提示信息
        if(!checkboxState || checkboxState.length !== 1) {
            errFrameShow("error", "请选择一条数据");
            return;
        }
        setAuditModel(checkboxState[0]);
        setIsAudit(true);
    }

    const auditCancel = () => {
        setIsAudit(false);
    }

    const onFinish = (values) => {
        messageShowHandler();
        setIsLoad(true);
        setSpinInfo("数据保存中...")
        let url = "";
        if(flag === "add") {
            url = "proBrandEstate/insert";
        } else {
            if(checkboxState?.[0]?.status === "REJECT") {
                url = "proBrandEstate/updateAgainListed";
            } else {
                url = "proBrandEstate/update";
            }
            values.proBrandEstateId = checkboxState[0].proBrandEstateId;
            values.lastUpdatedDate = lastUpdatedDate;
        }
        values.estateInstallation = values.estateInstallation?.join('|');
        values.apartmentServices = values.apartmentServices?.join('|');
        values.surroundingFacilities = values.surroundingFacilities?.join('|');
        values.stipulateSelect = values.stipulateSelect?.join('|');
        values.menuFuncId = Config.funcInfo.funcId;
        Base.callBackApi(url, values).then((data) => {
            if ("SUCCESS" === data.msg) {
                search(pageSize, current, "Y", false);
                if(checkboxState?.[0]?.status === "REJECT") {
                    search(pageSize, current, "Y", false);
                    cancel();
                    errFrameShow("success", "重新申请成功");
                } else {
                    errFrameShow("success", "保存成功");
                    values.proBrandEstateId = data.object;
                    setCheckboxState([values]);
                    setSelectedRowKeys([data.object]);
                    searchById(data.object, "edit");
                }
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }

    const cancel = () => {
        messageShowHandler();
        clearSelect();
        clearSelectRoom();
        setTabIndex("1");
    }

    const addRoom = () => {
        messageShowHandler();
        setRoomModel({})
        setRoomFlag("add");
        setIsRoomShow(true);
    }

	const seeRoom = (model, e) => {
        e.stopPropagation();
        messageShowHandler();
        setCheckboxStateRoom([model]);
        setSelectedRowKeysRoom([model.proBrandEstateRoomId]);
        searchRoomById(model.proBrandEstateRoomId, "see");
    }

    const updateRoom = (model, e) => {
        e.stopPropagation();
        messageShowHandler();
        setCheckboxStateRoom([model]);
        setSelectedRowKeysRoom([model.proBrandEstateRoomId]);
        searchRoomById(model.proBrandEstateRoomId, "edit");
    }

    const searchRoomById = (id, flag) => {
        Base.callBackApi("proBrandEstate/searchRoomById", id).then((data) => {
            if ("SUCCESS" === data.msg) {
                let model = data.object ?? {};
                setRoomModel(model);
                setRoomFlag(flag);
                setIsRoomShow(true);
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { });
    }

    const deleteOneRoom = (model, e) => {
        e.stopPropagation();
        messageShowHandler();
        setCheckboxStateRoom([model]);
        setSelectedRowKeysRoom([model.proBrandEstateRoomId]);
        setType();
        setContent();
        setFrameType("deleteRoom");
        setIsPopupFrame(true);
    }

    const deleteDataRoom = () => {
        setIsLoad(true);
        setSpinInfo("数据保存中...");
        let deleteModel = checkboxStateRoom[0];
        deleteModel.menuFuncId = Config.funcInfo.funcId;
        Base.callBackApi("proBrandEstate/deleteRoom", deleteModel).then((data) => {
            if ("SUCCESS" === data.msg) {
                searchRoomList(checkboxState[0].proBrandEstateId);
                errFrameShow("success", "刪除成功");
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }

    const roomClose = (flag) => {
        if(flag) {
            searchRoomList(checkboxState[0].proBrandEstateId);
            errFrameShow("success", "保存成功");
        }
        setIsRoomShow(false);
    }

    const estateClose = (flag, id) => {
        if(flag) {
            form.setFieldValue("proEstateId", id);
        }
        setIsEstateShow(false);
    }

    //分页 页码切换变化查询
    const onChange = (currentNew, pageSizeNew) => {
        search(pageSizeNew, currentNew, "Y");
    }
    //分页 每页條數变化查询
    const onShowSizeChange = (currentNew, pageSizeNew) => {
        search(pageSizeNew, 1, "Y");
    }

    //列表选中记录
    const onSelectChange = (selectedRowKeys, selectedRows) => {
        setSelectedRowKeys(selectedRowKeys);
        setCheckboxState(selectedRows);
    }
    //清空列表选中
    const clearSelect = () => {
        setSelectedRowKeys([]);
        setCheckboxState([]);
    }

    const onSelectChangeRoom = (selectedRowKeys, selectedRows) => {
        setSelectedRowKeysRoom(selectedRowKeys);
        setCheckboxStateRoom(selectedRows);
    }
    const clearSelectRoom = () => {
        setSelectedRowKeysRoom([]);
        setCheckboxStateRoom([]);
    }

    const errFrameShow = (style, msg) => {
        setMessage(msg);
        setMessageStyle(style);
        setMessageShow(true);
        document.documentElement.scrollTop = 0;
    }

    const messageShowHandler = () => {
        setMessage("");
        setMessageShow(false);
    }

    /*eslint-disable*/
    const columns = [
        {
            title: '品牌名称',
            dataIndex: 'brandTitle',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'brandTitle') },
        },
        {
            title: '屋苑名称',
            dataIndex: 'estateTitle',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'estateTitle') },
        },
        {
            title: '品牌公寓名称',
            dataIndex: 'brandEstateTitle',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'brandEstateTitle') },
        },
        {
            title: '状态',
            dataIndex: 'status',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'status') },
            render: (text, record) => <div className={text}>{ContextHandler.getMapValue('brandEstateStatusMap', text)}</div>
        },
        {
            title: '操作',
            dataIndex: '',
            className: 'tableWidth18 tableRightAlignment',
            render: (text, record) => <div className='sysOperation proBrandEstateOperation'>
                {record.status === "PEND_ONSHELF" && <div className='sysUpdate' onClick={(e) => updateListed(record ,e)}><FormOutlined /> 申请上架</div>}
                <div className='sysUpdate' onClick={(e) => see(record ,e)}><EyeOutlined /> 查看</div>
                {(record.status === "PEND_ONSHELF" || record.status === "REJECT") && <div className='sysUpdate' onClick={(e) => update(record ,e)}><EditOutlined /> 修改</div>}
                <div className='sysDelete' onClick={(e) => deleteOne(record,e)}><DeleteOutlined /> 删除</div>
            </div>
        },
    ];
    const roomColumns = [
        {
            title: '房间图片',
            dataIndex: 'roomDefaultImg',
            className: 'tableWidth8',
            render: (text) => <img className="proBrandEstateImg" alt='' src={Config.proBrandEstateRoomPath + text}/>
        },
        {
            title: '房型',
            dataIndex: 'roomType',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'roomType') },
            render: (text) => roomTypeList.find(item => item.code === text)?.parmValue1 ?? text
        },
        {
            title: '租期',
            dataIndex: 'tenancy',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'tenancy') },
        },
        {
            title: '起租期',
            dataIndex: 'leaseStartDate',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'leaseStartDate') },
            render: (text) => text ? moment(text).format(Config.dateFormat) : ''
        },
        {
            title: '租金',
            dataIndex: 'rentalPrice',
            className: 'tableRightAlignment',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'rentalPrice') },
        },
        {
            title: '操作',
            dataIndex: '',
            className: 'tableWidth14 tableRightAlignment',
            render: (text, record) => <div className='sysOperation proBrandEstateOperation'>
                <div className='sysUpdate' onClick={(e) => seeRoom(record,e)}><EyeOutlined /> 查看</div>
                <div className='sysUpdate' hidden={flag === "approval" || flag === "see"} onClick={(e) => updateRoom(record ,e)}><EditOutlined /> 修改</div>
                <div className='sysDelete' hidden={flag === "approval" || flag === "see"} onClick={(e) => deleteOneRoom(record,e)}><DeleteOutlined /> 删除</div>
            </div>
        },
    ];
    /*eslint-enable*/

    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
        type: 'radio',
    }

    const rowSelectionRoom = {
        selectedRowKeys: selectedRowKeysRoom,
        onChange: onSelectChangeRoom,
        type: 'radio',
    }

    return (
        <div id="container" className="template proBrandEstate">
            <div className='breadcrumb'>
                <HomeOutlined /> /  品牌 / <span className='breadcrumbTitle'>品牌公寓</span>
            </div>
            {messageShow ? <Message style={messageStyle} content={message} close={messageShowHandler}/> : ''}
            <div className='templateMain'>
                <div className="sysModelTitle">品牌公寓</div>
                <Spin spinning={isLoad} size="large" tip={spinInfo}>
                    {/* 删除弹框 */}
                    {isPopupFrame && <PopupFrame close={popupFrameClose} type={type} content={content} />}
                    {/* 审核彈框 */}
                    <Audit visible={isAudit} auditModel={auditModel} close={auditCancel} />
                    <Tabs activeKey={tabIndex} onChange={(e) => handleSelect(e)}>
                        <TabPane tab="列表" key="1">
                            <div className='sysRow'>
                                <div className='sysCol'>
                                    <div className="sysTitle">品牌公寓名称</div>
                                    <div className="sysSerchInput">
                                        <Input type="text" placeholder="品牌公寓名称" maxLength={50} value={searchModel.brandEstateTitle}
                                            onChange={(e) => searchChange("brandEstateTitle", e.target.value)}
                                            onKeyDown={(e) => e.key === "Enter" ? search(pageSize, 1, "N", true) : null} />
                                    </div>
                                </div>
                                <div className='sysCol'>
                                    <div className="sysTitle">品牌名称</div>
                                    <div className="sysSerchInput">
                                        <Select className="sysSelect" disabled value={searchModel.proBrandId || ''} onChange={(value) => searchChange('proBrandId', value)}>
                                            <Option value="">全部</Option>
                                            {brandList.map(item => <Option key={item.proBrandId} value={item.proBrandId}>{item.brandTitle}</Option>)}
                                        </Select>
                                    </div>
                                </div>
                                <div className='sysCol'>
                                    <div className="sysTitle">屋苑名称</div>
                                    <div className="sysSerchInput">
                                        <Select className="sysSelect" value={searchModel.proEstateId || ''} onChange={(value) => searchChange('proEstateId', value)}>
                                            <Option value="">全部</Option>
                                            {estateList.map(item => <Option key={item.proEstateId} value={item.proEstateId}>{item.estateTitle}</Option>)}
                                        </Select>
                                    </div>
                                </div>
                                <div className='sysSearchButton'>
                                    <Button className="sysBtn btnBule" type="primary" onClick={() => search(pageSize, 1, "N", true)} hidden={Config.funcInfo.canSelect === "N"} icon={<SearchOutlined />}>查询</Button>
                                    <Button className="sysBtn" onClick={reset} icon={<RedoOutlined />}>重置</Button>
                                </div>
                            </div>
                            <div className="sysLineEight"></div>
                            <Space size={[5, 5]} wrap className="sysButtonRelative">
                                <Button className="sysBtn btnBule" type="primary" onClick={add} hidden={Config.funcInfo.canInsert === "N"} icon={<PlusCircleOutlined />}>新增</Button>
                                {tables.length == 0 ? null :
                                    <>
                                        <Button className="sysBtn btnBule" type="primary" onClick={audit} hidden={Config.funcInfo.canAudit === "N"} icon={<EyeOutlined />}>日志</Button>
                                    </>
                                }
                            </Space>
                            <div className="sysTable" id="tableOne">
                                <Table
                                    rowSelection={rowSelection} //选择
                                    rowKey="proBrandEstateId"
                                    columns={columns} //列名
                                    dataSource={tables} //数据
                                    pagination={{  //分页
                                        showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                        onChange: onChange,                        //上页下页或者跳页方法
                                        onShowSizeChange: onShowSizeChange,        //每页显示多少条数据方法
                                        total: tablesCount,             //总共多少条数据
                                        current: current,                //当前页
                                        pageSize: pageSize,              //每页显示多少条数据
                                        defaultCurrent: 1,                               //默认当前页为1
                                        size:"small"
                                    }}
                                    onRow={(record) => {
                                        return {
                                            onClick: event => Base.selectRadioRowNew.call(this, record, 'proBrandEstateId', selectedRowKeys, setCheckboxState, setSelectedRowKeys),
                                        };
                                    }}
                                />
                            </div>
                        </TabPane>
                        <TabPane tab="品牌公寓" key="2" forceRender={true}>
                            <Form form={form} onFinish={onFinish}>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">品牌</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="proBrandId"
                                                rules={[
                                                    { required: true, message: '品牌不能为空' },
                                                ]}
                                            >
                                                <Select className="sysSelect" disabled>
                                                    <Option value=""></Option>
                                                    {brandList.map(item => <Option key={item.proBrandId} value={item.proBrandId}>{item.brandTitle}</Option>)}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">品牌公寓名称</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="brandEstateTitle"
                                                rules={[
                                                    { required: true, message: '品牌公寓名称不能为空' },
                                                ]}
                                            >
                                                <Input type="text" placeholder="品牌公寓名称" maxLength={50} disabled={flag === "see"}/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='wholeRentHouseSouce'>
                                    <h4 className='blueBoxTitle1'>屋苑信息</h4>
                                    <div className='sysRow'>
                                        <div className='sysColDuan'>
                                            <div className="sysParam mustFill">屋苑</div>
                                            <div className="sysSelectDuan" hidden={!proEstateId}>
                                                <Form.Item
                                                    name="proEstateId"
                                                    rules={[
                                                        { required: true, message: '屋苑不能为空' },
                                                    ]}
                                                >
                                                    <Select disabled>
                                                        <Option value=""></Option>
                                                        {estateList.map(item => <Option key={item.proEstateId} value={item.proEstateId}>{item.estateTitle}</Option>)}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <Button className="buttonYellowTwoStyle" type="primary" disabled={flag === "see"} onClick={() => {setIsEstateShow(true)}}>选择屋苑</Button>
                                        </div>
                                    </div>
                                    {proEstateId && <>
                                        <div className='sysRow'>
                                            <div className='sysCol'>
                                                <div className="sysParam">屋苑标签</div>
                                                <div className="sysInputs">
                                                    <Checkbox.Group disabled options={estateTagList} value={estateModel.estateTag?.split('|') ?? []}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='sysRow'>
                                            <div className='sysCol'>
                                                <div className="sysParam">附近学校</div>
                                                <div className="sysInputs proEstateTreeDiv">
                                                    <div className='treeDiv'>
                                                        {(Base.isNotEmpty(nearbySchoolsList) && nearbySchoolsList.length > 0)?
                                                            nearbySchoolsList.map((item) => 
                                                                <Tooltip title={item.parmValue1} overlayClassName="lawTip" key={item.code}>
                                                                    <span className='lawArticleItem' key={item.code} value={item.code}>
                                                                        {item.parmValue1}
                                                                    </span>
                                                                </Tooltip>
                                                            )
                                                            :null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='sysRow'>
                                            <div className='sysCol'>
                                                <div className="sysParam mustFill">地区</div>
                                                <div className="sysInput">
                                                    <Cascader options={options} disabled value={[estateModel.provinceId + '', estateModel.cityId + '', estateModel.areaId + '']}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='sysRow'>
                                            <div className='sysCol'>
                                                <div className="sysParam">屋苑介绍</div>
                                                <div className="sysInputs">
                                                    <TextArea placeholder="屋苑介绍" disabled maxLength={4000} rows={3} value={estateModel.estateDesc} className='textarea'/>
                                                </div>
                                            </div>
                                        </div>
                                     </>}
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam">屋苑设施</div>
                                        <div className="sysInputs">
                                            <Form.Item
                                                name="estateInstallation"
                                            >
                                                <Checkbox.Group options={estateInstallationList} disabled={flag === "see"}/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam">公寓服务</div>
                                        <div className="sysInputs">
                                            <Form.Item
                                                name="apartmentServices"
                                            >
                                                <Checkbox.Group options={apartmentServicesList} disabled={flag === "see"}/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam">周边配套</div>
                                        <div className="sysInputs">
                                            <Form.Item
                                                name="surroundingFacilities"
                                            >
                                                <Checkbox.Group options={surroundingFacilitiesList} disabled={flag === "see"}/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam">交通</div>
                                        <div className="sysInputs">
                                            <Form.Item
                                                name="traffic"
                                            >
                                                <TextArea placeholder="如：近地铁线，近地铁站(旺角，佐敦等)，步行约多少分钟可达地铁站" maxLength={300} rows={3} disabled={flag === "see"}/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam">学校交通</div>
                                        <div className="sysInputs">
                                            <Form.Item
                                                name="schoolTraffic"
                                            >
                                                <TextArea placeholder="如：通勤时间，出行方式和可选交通工具及路线描述。例：25分钟可达香港大学，巴士路线xx直达。" maxLength={300} rows={3} disabled={flag === "see"}/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam">优惠政策</div>
                                        <div className="sysInputs">
                                            <Form.Item
                                                name="preferential"
                                            >
                                                <TextArea placeholder="优惠政策" maxLength={300} rows={3} disabled={flag === "see"}/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam">管理规定</div>
                                        <div className="sysInputs">
                                            <Form.Item
                                                name="stipulateSelect"
                                            >
                                                <Checkbox.Group options={stipulateList} disabled={flag === "see"}/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam">其他</div>
                                        <div className="sysInputs">
                                            <Form.Item
                                                name="stipulateDesc"
                                            >
                                                <TextArea placeholder="其他" maxLength={300} rows={3} disabled={flag === "see"}/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam">常见问题</div>
                                        <div className="sysInputs">
                                            <Form.Item
                                                name="commonProblem"
                                            >
                                                <TextArea placeholder="常见问题" maxLength={300} rows={3} disabled={flag === "see"}/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className="sysSaveButton">
                                    {flag !== "see" && <Button className="sysBtn btnBule" type="primary" disabled={isLoad} htmlType="submit" icon={<CheckCircleOutlined />}>{checkboxState?.[0]?.status === "REJECT" ? "重新申请上架" : "保存"}</Button>}
                                    <Button className="sysBtn" onClick={cancel} icon={<CloseCircleOutlined />}>取消</Button>
                                </div>
                                {flag !== "add" && <>
                                    <div className="sysLineEight"></div>
                                    <div className="sysModelTitle">房型信息</div>
                                    <Space size={[5, 5]} wrap className="sysButtonRelative">
                                        {flag === "edit" && <Button className="sysBtn btnBule" type="primary" onClick={addRoom} hidden={Config.funcInfo.canInsert === "N"} icon={<PlusCircleOutlined />}>新增</Button>}
                                        {roomList.length == 0 ? null :
                                            <>
                                                <Button className="sysBtn btnBule" type="primary" onClick={audit} hidden={Config.funcInfo.canAudit === "N"} icon={<EyeOutlined />}>日志</Button>
                                            </>
                                        }
                                    </Space>
                                    <div className="sysTable" id="tableOne">
                                        <Table
                                            rowSelection={rowSelectionRoom} //选择
                                            rowKey="proBrandEstateRoomId"
                                            columns={roomColumns} //列名
                                            dataSource={roomList} //数据
                                            pagination={false}
                                            onRow={(record) => {
                                                return {
                                                    onClick: event => Base.selectRadioRowNew.call(this, record, 'proBrandEstateRoomId', selectedRowKeysRoom, setCheckboxStateRoom, setSelectedRowKeysRoom),
                                                };
                                            }}
                                        />
                                    </div>
                                    {isRoomShow && <RoomModal model={roomModel} onClose={roomClose} id={checkboxState[0].proBrandEstateId}
                                        roomTypeList={roomTypeList} roomFlag={roomFlag}/>}
                                </>}
                                {(checkboxState?.[0]?.status === "APPROVE" || checkboxState?.[0]?.status === "REJECT" || checkboxState?.[0]?.status === "OFFSHELF") && <>
                                    <div className="sysLineEight"></div>
                                    <div className='wholeRentHouseSouce'>
                                        <h4 className='blueBoxTitle1'>审核信息</h4>
                                        <div className='sysRow'>
                                            <div className='sysCol'>
                                                <div className="sysParam">审批人</div>
                                                <div className="sysInput">
                                                    <Form.Item
                                                        name="auditBy"
                                                    >
                                                        <Input type="text" placeholder="审批人" disabled/>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className='sysCol'>
                                                <div className="sysParam">审批日期</div>
                                                <div className="sysInput">
                                                    <Form.Item
                                                        name="auditDate"
                                                    >
                                                        <Input type="text" placeholder="审批日期" disabled/>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='sysRow'>
                                            <div className='sysCol'>
                                                <div className="sysParam">审批状态</div>
                                                <div className="sysInput">
                                                    <Form.Item
                                                        name="status"
                                                    >
                                                        <Radio.Group name="radiogroup" disabled>
                                                            <Radio key="APPROVE" value="APPROVE">同意</Radio>
                                                            <Radio key="REJECT" value="REJECT">拒绝</Radio>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className='sysCol'>
                                                <div className="sysParam">审批意见</div>
                                                <div className="sysInputs">
                                                    <Form.Item
                                                        name="auditRemark"
                                                    >
                                                        <TextArea placeholder="审批意见" maxLength={300} rows={3} className='textarea' disabled/>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>}
                            </Form>
                            {isEstateShow && <EstateModel selectId={proEstateId} onClose={estateClose}/>}
                        </TabPane>
                    </Tabs>
                </Spin>
            </div>
        </div>
    )

}

export default ProBrandEstate;
    