import React, { useState, useEffect } from 'react';
import ExportJsonExcel from 'js-export-excel';
import { Button, Input, Table, Form, InputNumber, Spin, Select, Upload, Modal, Tabs, Space } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined,HomeOutlined, SearchOutlined, RedoOutlined, PlusCircleOutlined, EditOutlined, DeleteOutlined, EyeOutlined, DownloadOutlined, PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';//props传参检查库
import moment from 'moment';//日期格式化导包
import Message from '../../component/message/message';//提示信息弹框
import PopupFrame from '../../component/popupFrame/popupFrame';//删除弹框
import Audit from '../../component/audit/audit';//審核彈框
import { Config } from '../../public/js/config';
import { Base } from '../../public/js/base';
import { ContextHandler } from '../../public/js/contextHandler';
import context from '../../public/js/context';

const { Option } = Select;
const { TabPane } = Tabs;

const SysFunc = (props) => {
    const imageNum = 1;
    const [form] = Form.useForm();
    const parentIdLevel2 = Form.useWatch('parentIdLevel2', form);
    const type = Form.useWatch('type', form);
    const [tabIndex, setTabIndex] = useState("1");
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [checkboxState, setCheckboxState] = useState([]);
    const [messageShow, setMessageShow] = useState(false);
    const [messageStyle, setMessageStyle] = useState("error");
    const [message, setMessage] = useState("");
    const [flag, setFlag] = useState("add");
    const [isAudit, setIsAudit] = useState(false);
    const [isDeleteFrame, setIsDeleteFrame] = useState(false);
    const [tables, setTables] = useState([]);
    const [tablesCount, setTablesCount] = useState(0);
    const [current, setCurrent] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [searchModel, setSearchModel] = useState({});
    const [oldSearchModel, setOldSearchModel] = useState({});
    const [auditModel, setAuditModel] = useState({});
    const [imageFile, setImageFile] = useState([]);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);
    const [sysFuncList, setSysFuncList] = useState([]);
    const [isLoad, setIsLoad] = useState(false);
    const [spinInfo, setSpinInfo] = useState("");
    const [isLeave, setIsLeave] = useState(false);
    const [isMonitor, setIsMonitor] = useState(true);

    useEffect(() => {
        search(pageSize, 1, "N");
        searchAllFunc();
    },[]);

    useEffect(() => {
        if(isMonitor){
            form.setFieldsValue({
            parentIdLevel2: type === 'SUBFUNC'? parentIdLevel2 : "",
        });
        }
        
    },[type, parentIdLevel2]);

    /**查詢所有模块 */
    const searchAllFunc = () => {
        Base.callBackApi("func/searchFunc", {}).then((data) => {
            if ("SUCCESS" === data.msg) {
                setSysFuncList(data.object);
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => {});
    }

    const searchChange = (k,e) => {
        messageShowHandler();
        setSearchModel(newSearchModel =>{
            if ("parentId" === k) {
                newSearchModel.parentIdLevel2 = null;
                newSearchModel.funcId = null;
            }
            if ("parentIdLevel2" === k) {
                newSearchModel.funcId = null;
            }
            return {...newSearchModel,...{[k]:e}};
        })
    }    

    //查询
    const search = (pageSizeNew, current, flag, isShowMsg) => {
        if(Config.funcInfo.canSelect === "N") {
            return;
        }
        messageShowHandler();
        clearSelect();//清除選中狀態
        let model = {};
        if ("Y" === flag) {//翻頁
            model = oldSearchModel;
        } else {
            model = searchModel;
        }
        model.pageSize = pageSizeNew;
        model.page = current;
        setIsLoad(true);
        setSpinInfo("查询中...");
        Base.callBackApi("func/search", model).then((data) => {
            if ("SUCCESS" === data.msg) {
                if (!data.object.list && isShowMsg) {
                    errFrameShow("info", '找不到符合条件记录.');
                }
                if("N" === flag) {
                    setOldSearchModel(JSON.parse(JSON.stringify(model)));
                } 
                setTablesCount(data?.object?.total ?? 0);
                setTables(data?.object?.list ?? []);
                setCurrent(current);
                setPageSize(pageSizeNew);
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }   
    
    //重置
    const reset = () => {
        messageShowHandler();
        clearSelect();
        setSearchModel({});
        setOldSearchModel({});
        setTablesCount(0);
        setTables([]);
        setPageSize(10);
    }

    // Tab页切换
    const handleSelect = (key) => {
        if (key) {
            if(key === "2") {
                if(checkboxState.length === 1 && Config.funcInfo.canUpdate === "Y") {
                    edit();
                } else if(Config.funcInfo.canInsert === "Y") {
                    add();
                }
            } else if(tabIndex !== "1") {
                setTabIndex("1");
            }
        }
    }

    //添加
    const add = () => {
        messageShowHandler();//清空提示信息
        initForm({ activeInd: "Y"});
        setFlag("add");
        setTabIndex("2");
        setImageFile([]);
    }
    
    //修改
    const edit = () => {
        messageShowHandler();//清空提示信息
        if (checkboxState == null || checkboxState.length !== 1) {
            errFrameShow("error", "请选择一条数据");
            return
        }
        let model = checkboxState[0];
        let file = [];
        if (model.image) {
            file.push({
                url: Config.funcImg + model.image,
                originUrl: model.image,
                imgStatus: "OLD"
            })
        }
        setIsMonitor(false);
        setImageFile(file);
        initForm(model);
        setFlag("edit");
        setTabIndex("2");
    }

    const initForm = (model = {}) => {
        console.log(model)
        console.log(type)
        form.resetFields();
        form.setFieldsValue({
            parentId: model.parentId ?? '',
            funcCode: model.funcCode ?? '',
            funcName: model.funcName ?? '',
            type: model.type ?? '',
            parentIdLevel2: model.parentIdLevel2 ?? '',
            dispSeq: model.dispSeq ?? '',
            img: model.img ?? '',
            funcUrl: model.funcUrl ?? '',
        });
    }

    const deleteFrameClose = (state) => {
        if(state) {
            deleteData();
        }
        setIsDeleteFrame(false);
    }

    //删除
    const deleteData = () => {
        setIsLoad(true);
        setSpinInfo("数据删除中...");
        let model = checkboxState[0];
        model.menuFuncId = Config.funcInfo.funcId;
        Base.callBackApi("func/delete", model).then((data) => {
            if ("SUCCESS" === data.msg) {
                setSearchModel({});
                searchAllFunc();
                search(pageSize, current, "Y", false);
                errFrameShow("success", "刪除成功");
                props.searchMenu();//刷新menu
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }

    const audit = () => {
        messageShowHandler();//清空提示信息
        if (!checkboxState|| checkboxState.length !== 1) {
            errFrameShow("error", "请选择一条数据");
            return;
        }
        setAuditModel(checkboxState[0]);
        setIsAudit(true);
    }   

    // 审核弹窗
    const auditCancel = () => {
        setIsAudit(false);
    }

    const downloadExcel = () => {
        messageShowHandler();//清空提示信息
        setIsLoad(true);
        setSpinInfo("下载中...")
        fetch(Config.apiDownloadServer + "func/exportReport", {
            method: 'POST',
            body: JSON.stringify(searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob()).then(blob => {
            if (blob.type == "application/json") {
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                    errFrameShow("danger", data.msg);
                }
                reader.readAsText(blob);
            } else {
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {// for IE
                    window.navigator.msSaveOrOpenBlob(blob, "模块.xlsx");
                } else {// for Non-IE (chrome, firefox etc.)
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = "模块.xlsx";
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }
            }
        }).catch(err => ({ err })).finally(() => {
            setIsLoad(false)
        });
    }

    const onSave = (model) => {
        messageShowHandler();//清空提示信息
        setIsLoad(true);
        setSpinInfo("数据保存中...")
        if(imageFile && imageFile.length > 0){
            model.imageUploadModel = {
                status: imageFile[0].imgStatus,
                url: imageFile[0].originUrl,
            };
        }
        let url = "";
        if (flag === "add") {
            url = "func/insert";
        } else if (flag === "edit") {
            url = "func/update";
            model.funcId= checkboxState[0].funcId;
            model.lastUpdatedDate = checkboxState[0].lastUpdatedDate;
        }
        model.menuFuncId = Config.funcInfo.funcId;
        Base.callBackApi(url, model).then((data) => {
            if ("SUCCESS" == data.msg) {
                searchAllFunc();
                setIsMonitor(true);
                search(pageSize, current, "Y");
                errFrameShow("success", "保存成功");
                setTabIndex("1");
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => {
            props.searchMenu();
            setIsLoad(false)
        });    
    }

    //取消 
    const cancel = () => {
        setIsMonitor(true);
        messageShowHandler();
        clearSelect();
        setTabIndex("1");
    }

    const leaveFrameClose = (state) => {
        if(state) {
            cancel();
        }
        setIsLeave(false);
    }

    //分页 页码切换变化查询
    const onChange = (currentNew, pageSizeNew) => {
        search(pageSizeNew, currentNew, "Y");
    }
    
    //分页 每页條數变化查询
    const onShowSizeChange = (currentNew, pageSizeNew) => {
        search(pageSizeNew, 1, "Y");
    }

 //列表选中记录
    const onSelectChange = (selectedRowKeys, selectedRows) => {
        setSelectedRowKeys(selectedRowKeys);
        setCheckboxState(selectedRows);
    }

    //清空列表选中
    const clearSelect = () => {
        setSelectedRowKeys([]);
        setCheckboxState([])
    }

    /** 生成提示信息 */
    const errFrameShow = (style, msg) => {
        setMessage(msg);
        setMessageStyle(style);
        setMessageShow(true);
        document.documentElement.scrollTop = 0;//返回顶部
    }

    /** 清空提示信息 */
    const messageShowHandler = () => {
        setMessageShow(false);
        setMessage("");
    }

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div>设置图片</div>
        </div>
    );

    const update = (model, e) => {
        e.stopPropagation();
        setCheckboxState([model]);
        setSelectedRowKeys([model.funcId]);
        console.log(model)
        let file = [];
        if (model.image) {
            file.push({
                url: Config.funcImg + model.image,
                originUrl: model.image,
                imgStatus: "OLD"
            })
        }
        setIsMonitor(false);
        setImageFile(file);
        initForm(model);
        setFlag("edit");
        setTabIndex("2");
    }

    const deleteOne = (model, e) => {
        e.stopPropagation();
        setCheckboxState([model]);
        setSelectedRowKeys([model.funcId]);
        setIsDeleteFrame(true);
    }

    /**图片改变事件 */
    const handleChange = (file) => {
        let fileList = file.fileList;
        file = file.file;
        if(file.status === "removed") {
            let index = imageFile.findIndex(item => item.uid == file.uid);
            let fileList = JSON.parse(JSON.stringify(imageFile));
            fileList.splice(index, 1)
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = (e) => { 
                fileList[fileList.length - 1].originUrl = e.target.result; 
                fileList[fileList.length - 1].imgStatus = "NEW";
            }
        }
        setImageFile(fileList);
    }

    /**关闭图片弹窗 */
    const handleCancel = () => {
        setPreviewVisible(false);
    }

    const checkImg = (value) => {
        if(!!imageFile && imageFile.length > 0 && !!value) {
            let list = value.fileList;
            if(list.find(item => item.status === "uploading")) {
                return Promise.reject("请等待图片上传完成");
            }
            if(list.find(item => item.status === "error")) {
                return Promise.reject("请先处理测试上传失败的图片");
            }
        }
        return Promise.resolve();
    }

    /**查看图片 */
    const handlePreview = (file) => {
        setPreviewImage(file.url || file.thumbUrl);
        setPreviewVisible(true);
    }

    /*eslint-disable*/
    const columns = [
        {
            title: '模块编号',
            dataIndex: 'funcCode',
            className: 'tableWidth20',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'funcCode') },
        },
        {
            title: '模块名称',
            dataIndex: 'funcName',
            className: 'tableWidth20',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'funcName') },
        }, {
            title: '模块类型',
            dataIndex: 'type',
            className: 'tableWidth15',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'type') },
            render: (text) => ContextHandler.getMapValue('funcTypeMap', text)
        }, {
            title: '序号',
            dataIndex: 'dispSeq',
            className: 'tableWidth15 tableRightAlignment',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'dispSeq') },
        }, {
            title: '路由地址',
            dataIndex: 'funcUrl',
            className: 'tableWidth20',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'funcUrl') },
        },{
            title: '操作',
            dataIndex: '',
            className: 'tableWidth10 tableRightAlignment',
            render: (text, record) => <div className='sysOperation proBrandEstateOperation'>
                <div className='sysUpdate' onClick={(e) => update(record, e)}><EditOutlined /> 修改</div>
                <div className='sysDelete' onClick={(e) => deleteOne(record, e)}><DeleteOutlined /> 删除</div>
            </div>
        },
    ];
    /*eslint-enable*/

    //选中记录方法
    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
        type: 'radio',
    }

    return (
        <div id="container" className="template">
            <div className='breadcrumb'>
                <HomeOutlined /> / 系统管理 / <span className='breadcrumbTitle'>模块</span>
            </div>
            {/* 提示信息弹框 */}
            {messageShow ? <Message style={messageStyle} content={message} close={messageShowHandler} /> : ''}
            <div className='templateMain'>
                <div className="sysModelTitle">模块</div>
                <Spin spinning={isLoad} size="large" tip={spinInfo}>
                    {/* 删除弹框 */}
                    {isDeleteFrame ? <PopupFrame close={deleteFrameClose} /> : null}
                    {isLeave ? <PopupFrame close={leaveFrameClose} type='leave' /> : null}
                    {/* 审核彈框 */}
                    <Audit visible={isAudit} auditModel={auditModel} close={auditCancel} />
                    <Tabs activeKey={tabIndex} onChange={(e) => handleSelect(e)}>
                        <TabPane key="1" tab="列表">
                            <div className='sysRow'>
                                <div className='sysCol'>
                                    <div className="sysTitle">模块名称</div>
                                    <div className="sysSerchInput">
                                        <Select className="sysSelect" placeholder="模块名称"
                                            value={searchModel.parentId || ''}
                                            onChange={(e) => searchChange('parentId', e)}
                                        >
                                            <Option value=""></Option>
                                            {sysFuncList.filter(item => item.type === 'MENU').map((item) => <Option key={item.funcId} value={item.funcId}>{item.funcName}</Option>)}
                                        </Select>
                                    </div>
                                </div>
                                <div className='sysCol'>
                                    {/* <div className="sysTitle">二级模块名称</div> */}
                                    <div className="sysSerchInput">
                                        <Select className="sysSelect" placeholder="模块名称"
                                            disabled={!searchModel.parentId}
                                            value={searchModel.parentIdLevel2 || ''}
                                            onChange={(e) => searchChange('parentIdLevel2', e)}
                                        >
                                            <Option value=""></Option>
                                            {sysFuncList.filter(item => item.parentId === searchModel.parentId).map((item) => <Option key={item.funcId} value={item.funcId}>{item.funcName}</Option>)}
                                        </Select>
                                    </div>
                                </div>
                                <div className='sysCol'>
                                    {/* <div className="sysTitle">三级模块名称</div> */}
                                    <div className="sysSerchInput">
                                        <Select className="sysSelect" placeholder="模块名称"
                                            disabled={!searchModel.parentIdLevel2}
                                            value={searchModel.funcId || ''}
                                            onChange={(e) => searchChange('funcId', e)}
                                        >
                                            <Option value=""></Option>
                                            {sysFuncList.filter(item => item.parentId === searchModel.parentIdLevel2).map((item) => <Option key={item.funcId} value={item.funcId}>{item.funcName}</Option>)}
                                        </Select>
                                    </div>
                                </div>
                                <div className='sysSearchButton'>
                                    <Button className="sysBtn btnBule" type="primary" onClick={() => search(pageSize, 1, "N", true)} hidden={Config.funcInfo.canSelect === "N"} icon={<SearchOutlined />}>查询</Button>
                                    <Button className="sysBtn" onClick={reset} icon={<RedoOutlined />}>重置</Button>
                                </div>
                            </div>
                            <div className="sysLineEight"></div>
                            <Space size={[5, 5]} wrap className="sysButtonRelative">
                                <Button className="sysBtn btnBule" type="primary" onClick={add} hidden={Config.funcInfo.canInsert === "N"} icon={<PlusCircleOutlined />}>新增</Button>
                                {tables.length == 0 ? null :
                                    <>
                                        <Button className="sysBtn btnBule" type="primary" onClick={audit} hidden={Config.funcInfo.canAudit === "N"} icon={<EyeOutlined />}>日志</Button>
                                        <Button className="sysBtn btnBule" type="primary" onClick={downloadExcel} hidden={Config.funcInfo.canDownload === "N"} icon={<DownloadOutlined />}>导出Excel表格</Button>
                                    </>
                                }
                            </Space>
                            <div className="sysTable" id="tableOne">
                                <Table
                                    scroll={{ x: 1200 }}
                                    rowSelection={rowSelection} //选择
                                    rowKey="funcId"
                                    columns={columns} //列名
                                    dataSource={tables} //数据
                                    pagination={{  //分页
                                        showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                        onChange: onChange,            //上页下页或者跳页方法
                                        onShowSizeChange: onShowSizeChange,    //每页显示多少条数据方法
                                        total: tablesCount,             //总共多少条数据
                                        current: current,         //当前页
                                        pageSize: pageSize,       //每页显示多少条数据
                                        defaultCurrent: 1,                               //默认当前页为1
                                        size:"small"
                                    }}
                                    onRow={(record) => {
                                        return {
                                            onClick: event => Base.selectRadioRowNew.call(this, record, 'funcId', selectedRowKeys, setCheckboxState, setSelectedRowKeys),
                                        };
                                    }}
                                />
                            </div>
                        </TabPane>
                        <TabPane key="2" tab="模块" forceRender={true}>
                            <Form
                                form={form} onFinish={onSave}
                            >
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">模块编号</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="funcCode"
                                                rules={[
                                                    { required: true, message: '模块编号不能为空' },
                                                ]}
                                                getValueFromEvent={(e) => Base.codeChange(e, 20)}
                                            >
                                                <Input type="text"
                                                    placeholder="模块编号" maxLength={30}
                                                    disabled={flag === 'edit'}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">模块名称</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="funcName"
                                                rules={[
                                                    { required: true, message: '模块名称不能为空' },
                                                ]}
                                            >
                                                <Input type="text"
                                                    placeholder="模块名称"
                                                    maxLength={50}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">模块类型</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="type"
                                                rules={[
                                                    { required: true, message: '模块类型不能为空' },
                                                ]}
                                            >
                                                <Select placeholder="模块类型" disabled={flag === 'edit'} className='sysSelect'>
                                                    <Option value=''></Option>
                                                    {context.funcTypeMap.map((item) => <Option key={item.value} value={item.value}>{item.label}</Option>)}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    {type === "FUNC" ?
                                        <>
                                            <div className='sysCol'>
                                                <div className="sysParam mustFill">一级模块</div>
                                                <div className="sysInput">
                                                    <Form.Item
                                                        name="parentId"
                                                        rules={[
                                                            { required: true, message: '一级模块不能为空' },
                                                        ]}
                                                    >
                                                        <Select placeholder="一级模块" className='sysSelect'>
                                                            <Option value=''></Option>
                                                            {sysFuncList.filter(item => item.type === 'MENU').map((item) => <Option key={item.funcId} value={item.funcId}>{item.funcName}</Option>)}
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </> 
                                    : null}
                                </div>
                                {type === "SUBFUNC" ?
                                    <div className='sysRow'>
                                        <div className='sysCol'>
                                            <div className="sysParam mustFill">一级模块</div>
                                            <div className="sysInput">
                                                <Form.Item
                                                    name="parentIdLevel2"
                                                    rules={[
                                                        { required: true, message: '一级模块不能为空' },
                                                    ]}
                                                >
                                                    <Select placeholder="一级模块" className='sysSelect'>
                                                        <Option value=''></Option>
                                                        {sysFuncList.filter(item => item.type === 'MENU').map((item) => <Option key={item.funcId} value={item.funcId}>{item.funcName}</Option>)}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className='sysCol'>
                                            <div className="sysParam mustFill">二级模块</div>
                                            <div className="sysInput">
                                                <Form.Item
                                                    name="parentId"
                                                    rules={[
                                                        { required: true, message: '二级模块不能为空' },
                                                    ]}
                                                >
                                                    <Select placeholder="二级模块" disabled={!parentIdLevel2} className='sysSelect'>
                                                        <Option value=''></Option>
                                                        {sysFuncList.filter(item => item.parentId === parentIdLevel2).map((item) => <Option key={item.funcId} value={item.funcId}>{item.funcName}</Option>)}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </div>
                                : null}
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">序号</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="dispSeq"
                                                rules={[
                                                    { required: true, validator: (rule, value) => Base.checkNbrOrNull(value, null, 999999999)},
                                                ]}
                                            >
                                                <InputNumber className='sysInputNumber'
                                                    placeholder="序号"
                                                    precision={0} maxLength={9}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='sysCol'>
                                        <div className="sysParam">路由地址</div>
                                        <div className="sysInput">
                                            <Form.Item name="funcUrl">
                                                <Input type="text" placeholder="路由地址" maxLength={100}/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam">图片</div>
                                        <div className="sysInput">
                                            <Modal title="放大图" visible={previewVisible} footer={null} onCancel={handleCancel}>
                                                <img alt="example" style={{ width: '100%' }} src={previewImage} />
                                            </Modal>
                                            <Form.Item 
                                                name="img"
                                                rules={[
                                                    { validator: (rule, value) => checkImg(value) }
                                                ]}
                                            >
                                                <Upload
                                                    action={Config.uploadTestServer} 
                                                    key={"1"}
                                                    accept=".png, .jpg, .jpeg"
                                                    listType="picture-card"
                                                    fileList={imageFile}
                                                    onPreview={handlePreview}
                                                    onChange={(file) => handleChange(file)}>
                                                    {imageFile.length >= imageNum ? null : uploadButton}
                                                </Upload>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className="sysSaveButton">
                                    <Button className="sysBtn btnBule" type="primary" disabled={isLoad} htmlType="submit" icon={<CheckCircleOutlined />}>保存</Button>
                                    <Button className="sysBtn" onClick={cancel} icon={<CloseCircleOutlined />}>取消</Button>
                                </div>
                            </Form>
                        </TabPane>
                    </Tabs>
                </Spin>
            </div>
        </div>
    )
}

SysFunc.propTypes = {
    searchMenu: PropTypes.func.isRequired,
}

export default SysFunc;