import React, { useState, useEffect } from 'react';
import { Button, Table, Spin, DatePicker, Popover, Select, Input, Form ,Space} from 'antd';
import { SearchOutlined, RedoOutlined, EyeOutlined ,HomeOutlined} from '@ant-design/icons';
import { Config } from '../../public/js/config';
import Message from '../../component/message/message';//提示信息弹框
import Audit from '../../component/audit/audit';//審核彈框
import moment from 'moment';//日期格式化导包
import { Base } from '../../public/js/base';
import { ContextHandler } from '../../public/js/contextHandler';
import context from '../../public/js/context';
import './operationLogManage.css';

const { Option } = Select;

const OperationLogManage = (props) => {

    const [form] = Form.useForm();
    const [isLoad, setIsLoad] = useState(false);
    const [spinInfo, setSpinInfo] = useState("");
    const [isAudit, setIsAudit] = useState(false);
    const [auditModel, setAuditModel] = useState({});
    const [messageShow, setMessageShow] = useState(false);
    const [messageStyle, setMessageStyle] = useState("");
    const [message, setMessage] = useState("");
    const [tabIndex, setTabIndex] = useState("1");
    const [searchModel, setSearchModel] = useState({});
    const [oldSearchModel, setOldSearchModel] = useState({});
    const [tables, setTables] = useState([]);
    const [tablesCount, setTablesCount] = useState(0);
    const [current, setCurrent] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [checkboxState, setCheckboxState] = useState([]);

    useEffect(() => {
        var d = new Date();
        d = d.setDate( d.getDate() - 1);
        let newModel = JSON.parse(JSON.stringify(searchModel));
        newModel.startDate = moment(new Date(d), Config.dateFormatALL);
        newModel.endDate = moment(new Date(), Config.dateFormatALL);
        setSearchModel(newModel);
        form.setFieldsValue({
            startDate: moment(new Date(d), Config.dateFormatALL),
            endDate: moment(new Date(), Config.dateFormatALL),
        });
    }, []);

    const submit = () => {
        search(pageSize, 1, "N", true);
    }

    /**查詢用戶信息列表 方法 */
    const search = (pageSizeNew, currentPageNew, flag, isShowMsg) => {
        messageShowHandler();
        clearSelect();
        let model = {};
        if ("Y" === flag) {//翻頁
            model = oldSearchModel;
        }else{
            model = searchModel;
        }
        model.page = currentPageNew;
        model.pageSize = pageSizeNew;
        setIsLoad(true);
        setSpinInfo("查询中...");
        Base.callBackApi("operationLogManage/search", model).then((data) => {
            if ("SUCCESS" === data.msg) {
                if (!data?.object?.list && isShowMsg) {
                    errFrameShow("info", '找不到符合条件记录.');
                }
                if("N" === flag) {
                    setOldSearchModel(JSON.parse(JSON.stringify(model)));
                }
                setTablesCount(data?.object?.total ?? 0);
                setTables(data?.object?.list ?? []);
                setCurrent(currentPageNew);
                setPageSize(pageSizeNew);
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }

    /**用戶信息 查詢change方法 */
    const searchChange = (k, e) => {
        messageShowHandler();
        setSearchModel(newModel =>{
            return {...newModel,...{[k]:e}};
        });
    }

    const reset = () => {
        var d = new Date();
        d = d.setDate( d.getDate() - 1);
        let newModel = JSON.parse(JSON.stringify(searchModel));
        newModel.startDate = moment(new Date(d), Config.dateFormatALL);
        newModel.endDate = moment(new Date(), Config.dateFormatALL);
        newModel.operationType = "";
        setSearchModel(newModel);
        form.setFieldsValue({
            startDate: moment(new Date(d), Config.dateFormatALL),
            endDate: moment(new Date(), Config.dateFormatALL),
        });
        messageShowHandler();
        clearSelect();
        /* setSearchModel({operationType:""}); */
        setOldSearchModel({});
        setTablesCount(0);
        setPageSize(20);
        setTables([]);
        /* search(pageSize, 1, "N"); */
    }

    /**審核查看 */
    const audit = () => {
        messageShowHandler();//清空提示信息
        if (checkboxState == null || checkboxState.length !== 1) {
            errFrameShow("error", "请选择一条数据");
            return;
        }
        setAuditModel(checkboxState[0]);
        setIsAudit(true);
    }
    /**審核彈框的取消按鈕 */
    const auditCancel = () => {
        setIsAudit(false);
    }

    const cancel = () => {
        messageShowHandler();
        clearSelect();
        setTabIndex("1");
        search(pageSize, 1, "Y");
    }

    const checkStartDate = (rule, value) => {
        if (Base.isEmpty(value)) {
            return Promise.reject("开始日期不能为空");
        } else {
            if (Base.isNotEmpty(searchModel.endDate) && Base.afterDate(value, searchModel.endDate)) {
                return Promise.reject("开始日期不能晚于结束日期");
            }
            return Promise.resolve();
        }
    }

    const checkEndDate = (rule, value) => {
        if (Base.isEmpty(value)) {
            return Promise.reject("结束日期不能为空");
        } else {
            if(Base.isNotEmpty(searchModel.startDate) && Base.afterDate(searchModel.startDate, value)){
                return Promise.reject("结束日期不能早于开始日期");
            }
            return Promise.resolve();
        }
    }
    
    //分页 页码切换变化查询
    const onChange = (currentNew, pageSizeNew) => {
        search(pageSizeNew, currentNew, "Y");
    }
    //分页 每页條數变化查询
    const onShowSizeChange = (currentNew, pageSizeNew) => {
        search(pageSizeNew, 1, "Y");
    }

    //列表选中记录
    const onSelectChange = (selectedRowKeys, selectedRows) => {
        setSelectedRowKeys(selectedRowKeys);
        setCheckboxState(selectedRows);
    }
    //清空列表选中
    const clearSelect = () => {
        setSelectedRowKeys([]);
        setCheckboxState([]);
    }

    const errFrameShow = (style, msg) => {
        setMessage(msg);
        setMessageStyle(style);
        setMessageShow(true);
        document.documentElement.scrollTop = 0;
    }

    const messageShowHandler = () => {
        setMessage("");
        setMessageShow(false);
    }

    /*eslint-disable*/
    const columns = [
        {
            title: "账号",
            dataIndex: 'userCode',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'userCode') },
        },
        {
            title: "姓名",
            dataIndex: 'userName',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'userName') },
        },
        {
            title: "模块名称",
            dataIndex: 'funcName',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'funcName') },
            render: (text, record) => {
                return (
                    Base.isNotEmpty(text) ? text : (record.operationType === 'LOGIN' ? "用户登录" : (record.operationType === 'LOGINOUT' ? "用戶登出" : ""))
                );
            }
        },
        {
            title: "结果",
            dataIndex: 'status',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'status') },
        },
        {
            title: "操作类型",
            dataIndex: 'operationType',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'operationType') },
            render: (text) => ContextHandler.getMapValue('operationTypeList', text)
        },
        {
            title: "操作內容",
            dataIndex: 'operationContent',
            className: 'tableWidth30',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'operationContent') },
            render: (text) => <Popover content={text} title="" trigger="hover">
                <div className = "operationLogManageContent">{text}</div>
            </Popover>
        },
        {
            title: "操作时间",
            dataIndex: 'operationTime',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'operationTime') },
            render: (text) => moment(text).format(Config.dateFormatALL)
        },
        {
            title: "登录IP地址",
            dataIndex: 'loginIpAddress',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'loginIpAddress') },
        }
    ];
    /*eslint-enable*/

    const rowSelection = {
        type: 'radio',
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
    };

    return (
        <div id="container" className="template">
            <div className='breadcrumb'>
                <HomeOutlined /> / 系统管理 / <span className='breadcrumbTitle'>操作日志</span>
            </div>
            {/* 错误信息弹框 */}
            {messageShow ? <Message style={messageStyle} content={message} close={messageShowHandler.bind(this)} /> : ''}
            <div className='templateMain'>
                <div className="sysModelTitle">操作日志</div>
                {/* 審核彈框 */}
                <Audit visible={isAudit} auditModel={auditModel} close={auditCancel} />
                <Spin size="large" spinning={isLoad} tip="查找中...">
                    <div className='systabls'>
                        <Form form={form} onFinish={submit}>
                            <div className='sysRow'>
                                <div className='sysCols'>
                                    <div className="sysTitles">账号</div>
                                    <div className="sysSerchInput">
                                        <Input type="text" placeholder="账号" maxLength={6} 
                                            onChange={(e) => searchChange('userCode', e.target.value)}
                                            value={searchModel.userCode || ''}
                                            onKeyDown = {(e) => e.key == "Enter" ? search(pageSize, 1, "N", true) : null} />
                                    </div>
                                </div>
                                <div className='sysCols'>
                                    <div className="sysTitles">姓名</div>
                                    <div className="sysSerchInput">
                                        <Input type="text" placeholder="姓名" maxLength={40} 
                                            onChange={(e) => searchChange('userName', e.target.value)}
                                            value={searchModel.userName || ''} 
                                            onKeyDown = {(e) => e.key == "Enter" ? search(pageSize, 1, "N", true) : null} />
                                    </div>
                                </div>
                                <div className='sysCols'>
                                    <div className="sysTitles">操作时间</div>
                                    <div className="sysSerchInputTime">
                                        <Form.Item
                                            name="startDate"
                                            rules={[// 声明式验证: 直接使用别人定义好的验证规则进行验证
                                                { required: true, validator: checkStartDate },
                                            ]}>
                                            <DatePicker placeholder="开始时间"
                                                className="parts-numberInput datePickerAllWidth"
                                                allowClear={false}
                                                showTime={true}
                                                onChange={(value) => searchChange("startDate", value)}
                                                format={Config.dateFormatALL} />
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className='sysCols'>
                                    <div className="sysTitleTime">—</div>
                                    <div className="sysSerchInputTime">
                                        <Form.Item
                                            name="endDate"
                                            rules={[// 声明式验证: 直接使用别人定义好的验证规则进行验证
                                                { required: true, validator: checkEndDate },
                                            ]}>
                                            <DatePicker placeholder="结束时间"
                                                className="parts-numberInput datePickerAllWidth"
                                                allowClear={false}
                                                showTime={true}
                                                onChange={(value) => searchChange("endDate", value)}
                                                format={Config.dateFormatALL} />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <div className='sysRow'>
                                <div className='sysCols'>
                                    <div className="sysTitles">登录IP地址</div>
                                    <div className="sysSerchInput">
                                        <Input type="text" placeholder="登录IP地址" maxLength={40} 
                                            onChange={(e) => searchChange('loginIpAddress', e.target.value)}
                                            value={searchModel.loginIpAddress || ''} 
                                            onKeyDown = {(e) => e.key == "Enter" ? search(pageSize, 1, "N", true) : null} />
                                    </div>
                                </div>
                                <div className='sysCols'>
                                    <div className="sysTitles">模块名称</div>
                                    <div className="sysSerchInput">
                                        <Input type="text" placeholder="模块名称" maxLength={40} 
                                            onChange={(e) => searchChange('funcName', e.target.value)}
                                            value={searchModel.funcName || ''} 
                                            onKeyDown = {(e) => e.key == "Enter" ? search(pageSize, 1, "N", true) : null} />
                                    </div>
                                </div>
                                <div className='sysCols'>
                                    <div className="sysTitles">操作类型</div>
                                    <div className="sysSerchInput">
                                        <Select  placeholder="操作类型" value={searchModel.operationType}
                                            className="sysSelect"
                                            onChange={(e) => searchChange('operationType', e)}>
                                            <Option value=""></Option>
                                            {context.operationTypeList.map((item) => <Option key={item.value} value={item.value}>{item.label}</Option>)}
                                        </Select>
                                    </div>
                                </div>
                                <div className='sysSearchButton'>
                                    <Button className="sysBtn btnBule" type="primary" htmlType="submit" hidden={Config.funcInfo.canSelect === "N"} icon={<SearchOutlined />}>查询</Button>
                                    <Button className="sysBtn" onClick={() => reset()} icon={<RedoOutlined />} >重置</Button>
                                </div>
                            </div>
                        </Form>
                        <div className="sysLineEight" />
                        <Space size={[5, 5]} wrap className="sysButtonRelative">
                            <Button className="sysBtn btnBule" type="primary" onClick={() => { audit() }} hidden={Config.funcInfo.canAudit === "N"} icon={<EyeOutlined />}>日志</Button>
                        </Space>
                        <div className="sysTable" id="tableOne">
                            <Table
                                rowSelection={rowSelection} //选择
                                rowKey="operationLogId"
                                columns={columns} //列名
                                dataSource={tables} //数据
                                pagination={{  //分页
                                    showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                    onChange: onChange,                        //上页下页或者跳页方法
                                    onShowSizeChange: onShowSizeChange,        //每页显示多少条数据方法
                                    total: tablesCount,                  //总共多少条数据
                                    current: current,                    //当前页
                                    pageSize: pageSize,                  //每页显示多少条数据
                                    defaultCurrent: 1,                              //默认当前页为1
                                    size:"small"
                                }}
                                onRow={(record) => {
                                    return {
                                        onClick: event => Base.selectRadioRowNew.call(this,record, "operationLogId" ,selectedRowKeys, setCheckboxState, setSelectedRowKeys), // 点击行
                                    };
                                }}
                            />
                        </div>
                    </div>
                </Spin>
            </div>
        </div>
    )
}

export default OperationLogManage;