import React, { useState, useEffect } from 'react';
import { Modal, Row, Button } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';//props传参检查库
import { Base } from '../../public/js/base';

import './popupFrame.css';

/**
 * 确认弹框组件
 * props传参如下
 * @param content:消息内容（不传默认为"删除"）
 * @param close:关闭弹框时修改弹框显示状态的回调方法
 */
const PopupFrame = (props) => {

    const [content, setContent] = useState("");
    const types = {
        delete : '您确认要删除吗？'
        ,leave : '当前页信息还未保存，请确认是否离开？'
        ,sysfunc:'模块已被角色/我的最爱使用，请确认是否一并刪除？'
    };

    useEffect(() => {
        if(typeof close !== "function") {
            throw new Error('请传入关闭弹框的回调方法');
        }
        setContent(props?.content ?? types[props?.type ?? 'delete']);
    }, []);

    const closeFrame = (state) => {
        props.close(state);
    }

    return (
        <Row>
            <Modal
                closable={false}
                className="popupFrameAlign"
                width={"300px"}    
                visible={true}
                onCancel={() => {closeFrame(false)}}
                footer={[
                    <Button className="sysBtn btnBule" key='save' type="primary" onClick={() => closeFrame(true)} icon={<CheckCircleOutlined />}>确定</Button>,
                    <Button key='cancel' className="sysBtn" onClick={() => closeFrame(false)} icon={<CloseCircleOutlined />}>取消</Button>
                ]}
            >
                <div className="popupFrameCenter">{content}</div>
            </Modal>
        </Row>
    )
}

/**
 * props参数检查
 */
PopupFrame.propTypes = {
    type: PropTypes.string
    ,content: PropTypes.string
    ,close: PropTypes.func.isRequired
};

export default PopupFrame;