
/**
 * 公共方法--下拉值
 */
export default {
    inds: [
        { value: "Y", label: "是" },
        { value: "N", label: "否" },
    ],
    areaCodeMap: [
        { value: "+852", label: "+852" },
        { value: "+86", label: "+86" },
    ],
    genderMap: [
        { value: "1", label: "男" },
        { value: "2", label: "女" },
        { value: "0", label: "未知" },
    ],
    maritalMap: [
        { value: "Y", label: "已婚" },
        { value: "N", label: "未婚" },
    ],
    activeMap: [
        { value: "Y", label: "激活" },
        { value: "N", label: "冻结" },
    ],
    funcTypeMap: [
        { value: "MENU", label: "一级模块" },
        { value: "FUNC", label: "二级模块" },
        { value: "SUBFUNC", label: "三级模块" },
    ],
    operationTypeList: [
        { value: "ADD", label: "ADD" },
        { value: "MODIFY", label: "MODIFY" },
        { value: "DELETE", label: "DELETE" },
        { value: "VISIT", label: "VISIT" },
        { value: "LOGIN", label: "LOGIN" },
        { value: "LOGINOUT", label: "LOGINOUT" }
    ],
    jobStatusMap: [
        { value: "Y", label: "正常" },
        { value: "N", label: "暂停" },
    ],
    concurrentMap: [
        { value: "Y", label: "允许" },
        { value: "N", label: "禁止" },
    ],
    executeStatusMap: [
        { value: "SUCCESS", label: "成功" },
        { value: "FAIL", label: "失败" },
    ],
    hiddenOrDisplayMap: [
        { value: "Y", label: "显示" },
        { value: "N", label: "隐藏" },
    ],
    pictureCodeMap: [
        // { value: "PROMOTION_POSTER", label: "推广海报" },
        { value: "HOME_ROTATION_CHART", label: "首页轮播图" },
        { value: "HOME_LOGO", label: "品牌Logo" },
        { value: "PLATFORM_QR_CODE", label: "平台客服二维码" },
        // { value: "PIC_NAV", label: "图片导航" },
        // { value: "APARTMENT_ROTATION_CHART", label: "公寓首页轮播图" },
    ],
    productStatusMap: [
        { value: "Y", label: "已上架" },
        { value: "N", label: "未上架" },
    ],
    newsManagementMap:[
        {value:"Y",label:"是"},
        {value:"N",label:"否"},
    ],
    buildingTypeMap:[
        {value:"LOW",label:"低层"},
        {value:"HIGH",label:"高层"},
    ],
    houseSourceTypeMap:[
        {value:"AGENT",label:"地产代理"},
        {value:"OWNER",label:"业主直租"},
    ],
    inventoryCountTypeMap: [
        { value: "BY_ORDER", label: "下单减库存" },
        { value: "BY_PAY", label: "付款减库存" },
    ],
    collectState: [
        { value: "Y", label: "正常" },
        { value: "N", label: "失效" },
    ],
    orderStatusMap: [
        { value: "UNPAID", label: "待支付" },
        { value: "UNSHIPPED", label: "待发货" },
        { value: "UNRECEIVED", label: "待收货" },
        { value: "COMPLETED", label: "已完成" },
        { value: "RETURNING", label: "退货中" },
        { value: "RETURNED", label: "已退货" },
        { value: "CANCELLED", label: "已取消" },
    ],
    storeTypeMap: [
        { value: "楼层", label: "楼层" },
        { value: "低层", label: "低层" },
        { value: "中层", label: "中层" },
        { value: "高层", label: "高层" },
    ],
    numberMap: [
        { label: 0, value: 0 },
		{ label: 1, value: 1 },
		{ label: 2, value: 2 },
		{ label: 3, value: 3 },
		{ label: 4, value: 4 },
		{ label: 5, value: 5 },
		{ label: 6, value: 6 },
		{ label: 7, value: 7 },
		{ label: 8, value: 8 },
		{ label: 9, value: 9 },
		{ label: 10, value: 10 }
	],
    collectType: [
        { value: "WHOLE_RENT", label: "整租房源" },
        { value: "STUDENT_APARTMENT", label: "品牌公寓" },
    ],
    orderStatusProcessMap: [
        { value: "UNPAID", label: 0 },
        { value: "UNSHIPPED", label: 1 },
        { value: "UNRECEIVED", label: 2 },
        { value: "COMPLETED", label: 3 },
    ],
    returnExchangeTypeMap: [
        { value: "RETURN_AND_REFUND", label: "退货退款" },
        { value: "REFUND", label: "仅退款" },
        /* { value: "EXCHANGE", label: "换货" }, */
    ],
    processStatusMap: [
        { value: "PENDING_REVIEW", label: "申请中" },
        { value: "APPL_REJECT", label: "拒绝申请" },
        { value: "RETURN_UNSHIPPED", label: "待发货" },
        { value: "RETURN_UNRECEIVED", label: "待收货" },
        { value: "RETURN_UNREFUNDED", label: "待退款" },
        { value: "RETURN_REFUNDED", label: "已退款" },
        { value: "REFUND_REJECT", label: "拒绝退款" },
    ],
    buyersReceivingStatusMap: [
        { value: "RECEIVED", label: "已收到货" },
        { value: "UNRECEIVED", label: "未收到货" },
    ],
    payModeMap: [
        { value: "WECHAT", label: "微信" },
        { value: "INTEGRAL", label: "积分" },
        { value: "OTHER", label: "其它" },
        { value: "WALLET", label: "钱包" },
        { value: "COMBINED", label: "组合" },
    ],
    rewardMap: [
        { value: "CONSUMPTION", label: "消费奖励" },
        { value: "REGISTER", label: "注册奖励" },
        { value: "TEAM", label: "团队奖励" },
        { value: "AUTO_OR_MANUAL_AUDIT", label: "审核方式" },
        { value: "REWARD_WITHDRAW", label: "奖励提现" },
    ],
    auditStatusMap: [
        { value: "APPROVE", label: "同意" },
        { value: "REJECT", label: "拒绝" },
    ],
    balanceWithdraw: [
        { value: "APPROVE", label: "同意" },
        { value: "REJECT", label: "拒绝" },
        { value: "UNCHECKED",label:"未审核"}
    ],
    auditMap: [
        { value: "APPROVE", label: "审批通过" },
        { value: "REJECT", label: "审批不通过" },
        { value: "SUBMIT",label:"审批中"}
    ],
    businessTypeMap:[
        {value:"CONSUMPTION_REWARD",label:"消费奖励"},
        {value:"REGISTER_REWARD",label:"注册奖励"},
        {value:"TEAM_REWARD",label:"团队奖励"},
        {value:"WITHDRAWAL",label:"提现"}
    ],
    withdrawalType:[
        {label:"微信",value:"WECHAT"},
        {label:"支付宝",value:"ALIPAY"},
        {label:"银行卡",value:"BANK_CARD"}
    ],
    squareType:[
        {label:"说说",value:"TALK"},
        {label:"互助",value:"HELP"},
        {label:"结伴",value:"PARTNER"}
    ],
    apartmentTypeMap: [
        { value: "独栋公寓", label: "独栋公寓" },
        { value: "酒店式公寓", label: "酒店式公寓" },
        { value: "洋楼", label: "洋楼" },
        { value: "长租酒店", label: "长租酒店" },
        { value: "别墅", label: "别墅" },
    ],
    brandEstateStatusMap: [
        { value: "APPROVE", label: "上架" },
        { value: "REJECT", label: "审批不通过" },
        { value: "PEND_ONSHELF", label: "未上架" },
        { value: "SUBMIT", label: "审批中" },
        { value: "OFFSHELF", label: "下架" },
    ],
    contactWayMap: [
        {value: 'WhatsApp', label: 'WhatsApp'},
        {value: '微信', label: '微信'},
        {value: '电话', label: '电话'},
    ],
    degreeMap: [
        {value: '全新', label: '全新'},
        {value: '九成新', label: '九成新'},
        {value: '八成新', label: '八成新'},
        {value: '七成新', label: '七成新'},
        {value: '六成新', label: '六成新'},
    ],
}