/*eslint-disable*/
import React, { Component } from 'react';
import { Row, Col, Input, Button, Modal } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';//props传参检查库
import moment from 'moment';//日期格式化导包

import { Config } from '../../public/js/config';
import './audit.css';

/**
 * 審核彈框組件
 * props传参如下
 * @param visible:控制彈框顯示的變量
 * @param auditModel:彈框展示的內容
 * @param close:关闭弹框时修改弹框显示状态的回调方法
 */
export default class Audit extends Component {

    constructor(props) {
        super(props);
        let { visible, auditModel = {} } = props;
        this.state = {
            auditModel
            ,visible
        };
    }

    /**
     * props或state更新之后会触发的事件
     * @param {新的props数据} nextProps 
     * @param {新的state数据} nextState 
     */
    shouldComponentUpdate(nextProps, nextState) {
        let flag = false;
        if (this.state.visible !== nextProps.visible) {
        nextState.visible = nextProps.visible;
        nextState.auditModel = nextProps.auditModel || {};
        flag = true;
        }
        return flag;
    }
 
    /**
     * 更新state的状态，使页面重新渲染
     * @param {*} nextProps 
     * @param {*} nextState 
     */
    componentWillUpdate(nextProps, nextState) {
        this.state = nextState;
    }

    render() {
        return (
            <Modal
                visible={this.state.visible}//控制弹框是否显示
                title="日志信息"//标题
                centered//垂直居中
                closable//是否显示右上角的关闭按钮
                maskClosable={false}//点击蒙层是否允许关闭    
                width={Config.equipmentInfo.type === 'iPad' ? '65%' : (Config.equipmentInfo.type === 'phone' ? '100%' : '30%')}//弹框宽度
                onCancel={() => this.props.close()}//点击取消触发事件
                footer={[
                <Button className="sysBtn" key = "close" onClick={() => this.props.close()} icon={<CloseCircleOutlined />}>取消</Button>
                ]}>
                <div>
                    <Row gutter={30} className="auditRowTop">
                        <Col xs={6} sm={6} md={6} lg={6}>创建人ID</Col>
                        <Col xs={18} sm={18} md={18} lg={18}>
                            <Input type="text" placeholder="创建人ID" disabled value={this.state.auditModel.createdBy} />
                        </Col>
                    </Row>
                    <Row gutter={30} className="auditRowTop">
                        <Col xs={6} sm={6} md={6} lg={6}>创建时间</Col>
                        <Col xs={18} sm={18} md={18} lg={18}>
                            <Input placeholder="创建时间" disabled value={moment(this.state.auditModel.createdDate).format(Config.dateFormatALL)} />
                        </Col>
                    </Row>
                    <Row gutter={30} className="auditRowTop">
                        <Col xs={6} sm={6} md={6} lg={6}>最后修改人ID</Col>
                        <Col xs={18} sm={18} md={18} lg={18}>
                            <Input type="text" placeholder="最后修改人ID" disabled value={this.state.auditModel.lastUpdatedBy} />
                        </Col>
                    </Row>
                    <Row gutter={30} className="auditRowTop">
                        <Col xs={6} sm={6} md={6} lg={6}>最后修改时间</Col>
                        <Col xs={18} sm={18} md={18} lg={18}>
                            <Input placeholder="最后修改时间" disabled value={moment(this.state.auditModel.lastUpdatedDate).format(Config.dateFormatALL)} />
                        </Col>
                    </Row>
                </div>
            </Modal>
        )
    }
}

/**
 * props参数检查
 */
Audit.propTypes = {
    visible: PropTypes.bool.isRequired
    , auditModel: PropTypes.object.isRequired
    , close: PropTypes.func.isRequired
};