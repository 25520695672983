import React, { useState, useEffect } from 'react';
import { Button, Table, Form, Spin, Input, Tabs, Space, Upload, Modal, Select, Cascader, Switch, InputNumber } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, SearchOutlined, RedoOutlined, PlusCircleOutlined, EditOutlined, DeleteOutlined, EyeOutlined, PlusOutlined, HomeOutlined, UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import Message from '../../component/message/message';//提示信息弹框
import PopupFrame from '../../component/popupFrame/popupFrame';//删除弹框
import Audit from '../../component/audit/audit';//审核彈框
import { Config } from '../../public/js/config';
import { Base } from '../../public/js/base';
import moment from 'moment';//日期格式化导包
import ImgCrop from 'antd-img-crop';
import './secondHand.css';
import SelectUser from './selectUser';
import context from '../../public/js/context';

const { TabPane } = Tabs;
const { TextArea } = Input;
const { Option } = Select;

Math.formatFloat = function (f, digit) {
    var m = Math.pow(10, digit);
    return Math.round(f * m, 10) / m;
}
const secondHand = (props) => {

    const imageNum = 10;
    const [form] = Form.useForm();
    const [isLoad, setIsLoad] = useState(false);
    const [spinInfo, setSpinInfo] = useState("");
    const [isAudit, setIsAudit] = useState(false);
    const [auditModel, setAuditModel] = useState({});
    const [message, setMessage] = useState("");
    const [messageShow, setMessageShow] = useState(false);
    const [messageStyle, setMessageStyle] = useState("");
    const [tabIndex, setTabIndex] = useState("1");
    const [searchModel, setSearchModel] = useState({ apartmentStatus: "Y" });
    const [oldSearchModel, setOldSearchModel] = useState({});
    const [tables, setTables] = useState([]);
    const [tablesCount, setTablesCount] = useState(0);
    const [current, setCurrent] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [tablesCountTow, setTablesCountTow] = useState(0);
    const [currentTow, setCurrentTow] = useState(1);
    const [pageSizeTow, setPageSizeTow] = useState(10);
    
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [checkboxState, setCheckboxState] = useState([]);
    const [type, setType] = useState("");
    const [content, setContent] = useState("");
    const [frameType, setFrameType] = useState("");
    const [isPopupFrame, setIsPopupFrame] = useState(false);
    const [flag, setFlag] = useState("");
    const [imageFile, setImageFile] = useState([]);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);
    const [options, setOptions] = useState([]);
    const [lastUpdatedDate, setLastUpdatedDate] = useState(null);
    const [isUser, setIsUser] = useState(false);
    const [userModel, setUserModel] = useState({});
    const [updateStatusModel, setUpdateStatusModel] = useState({});
    const [groupMemberTables, setGroupMemberTables] = useState([]);
    const [model, setModel] = useState({});

    const [secondHandId, setSecondHandId] = useState("");
    
    const areaId = Form.useWatch('areaId', form);

    useEffect(() => {
        searchAllArea();
        search(pageSize, 1, "N");
    }, []);

    const searchAllArea = () => {
        Base.callBackApi("commonDropDown/searchAllArea").then((data) => {
            if ("SUCCESS" === data.msg) {
                setOptions(data?.object ?? []);
                console.log(options)
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { });
    }

    const search = (pageSizeNew, currentPageNew, flag, isShowMsg) => {
        if (Config.funcInfo.canSelect === "N") {
            return;
        }
        messageShowHandler();  // 清除提示信息
        clearSelect(); // 清除选中状态
        let model = {};
        if ("Y" === flag) {     // 翻页
            model = oldSearchModel;
        } else {
            model = JSON.parse(JSON.stringify(searchModel));
        }
        model.page = currentPageNew;
        model.pageSize = pageSizeNew;
        setIsLoad(true);
        setSpinInfo("查询中...");
        Base.callBackApi("secondHand/search", model).then((data) => {
            if ("SUCCESS" === data.msg) {
                if (!data?.object?.list && isShowMsg) {
                    errFrameShow("info", '找不到符合条件记录.');
                }
                if ("N" === flag) {
                    setOldSearchModel(JSON.parse(JSON.stringify(model)));
                }
                data?.object?.list?.forEach(item => {
                    if (item.secondHandImg) {
                        item.secondHandDefaultImg = item.secondHandImg.split(";")[0];
                    }
                })
                setTablesCount(data?.object?.total ?? 0);
                setTables(data?.object?.list ?? []);
                setCurrent(currentPageNew);
                setPageSize(pageSizeNew);
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }
    const searchComment = (pageSizeNew, currentPageNew,secondHandIdNew) => {
        let model = {};
        model.page = currentPageNew;
        model.pageSize = pageSizeNew;
        model.secondHandId=secondHandIdNew?secondHandIdNew:secondHandId;
        setIsLoad(true);
        Base.callBackApi("secondHand/searchCommentById", model).then((data) => {
            if ("SUCCESS" === data.msg) {
                setTablesCountTow(data?.object?.total ?? 0);
                setGroupMemberTables(data?.object?.list ?? []);
                setCurrentTow(currentPageNew);
                setPageSizeTow(pageSizeNew);
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }

    const searchChange = (key, value) => {
        messageShowHandler();
        setSearchModel(model => {
            return { ...model, ...{ [key]: value } };
        });
    }

    const reset = () => {
        messageShowHandler();
        clearSelect();
        setSearchModel({});
        setOldSearchModel({});
        setTablesCount(0);
        setTables([]);
        setPageSize(10);
    }

    // Tab页切换
    const handleSelect = (key) => {
        if (key) {
            if (key === "2") {
                if (Config.funcInfo.canUpdate === "Y" && selectedRowKeys.length > 0) {
                    edit();
                } else if (Config.funcInfo.canInsert === "Y") {
                    add();
                }
            } else if (tabIndex !== "1") {
                cancel();
            }
        }
    }

    const add = () => {
        messageShowHandler();
        initForm({ status: 'Y' });
        setImageFile([]);
        setFlag("add");
        setTabIndex("2");
    }

    const edit = () => {
        messageShowHandler();
        if (!selectedRowKeys || selectedRowKeys.length !== 1) {
            errFrameShow("error", "请选择一条数据");
            return;
        }
        searchById(selectedRowKeys[0]);
    }

    const update = (model, e) => {
        e.stopPropagation();
        messageShowHandler();
        setCheckboxState([model]);
        setSelectedRowKeys([model.secondHandId]);
        searchById(model.secondHandId);
    }

    const searchById = (id) => {
        Base.callBackApi("secondHand/searchById", id).then((data) => {
            if ("SUCCESS" === data.msg) {
                let model = data.object ?? {};
                setSecondHandId(model.secondHandId)  
                initForm(model);
                setImageFile(
                    model.secondHandImg?.split(";")
                        ?.map(item => {
                            return {
                                url: Config.secondHandImg + item,
                                originUrl: item,
                                thumbUrl: Config.secondHandPath + item,
                                imgStatus: "OLD"
                            };
                        })
                    || []
                );
                setGroupMemberTables([])
                setTablesCountTow(0);
                setCurrentTow(1);
                setPageSizeTow(10);
                setUserModel({userId: model.userId, userName: model.userName});
                setLastUpdatedDate(model.lastUpdatedDate);
                setFlag("edit");
                setTabIndex("2");
                searchComment(10,1,model.secondHandId);
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { });
    }

    const initForm = (model = {}) => {
        form.resetFields();
        form.setFieldsValue({
            title: model.title ?? '',
            userName: (model.userName ?? '') + (model.userId ? '(' + model.userId + ')' : ''),
            salePrice: model.salePrice ?? '',
            degree: model.degree ?? '',
            contactWay: model.contactWay ?? '',
            contactNumber: model.contactNumber ?? '',
            description: model.description ?? '',
            areaId: model.areaId ? [model.provinceId + '', model.cityId + '', model.areaId + ''] : '',
        });
        
    }

    const deleteOne = (model, e) => {
        e.stopPropagation();
        setCheckboxState([model]);
        setSelectedRowKeys([model.secondHandId]);
        setType();
        setContent();
        setFrameType("delete");
        setIsPopupFrame(true);
    }
    const deleteOnes = (model, e) => {
        e.stopPropagation();
        setType();
        setContent();
        setFrameType("commentDelete");
        setIsPopupFrame(true);
        setModel(model);
    }
    
    const popupFrameClose = (state) => {
        if (state) {
            if (frameType === "delete") {
                deleteData();
            } else if (frameType === "commentDelete") {
                commentDelete();
            } else {
                updateStatus();
            }
        }
        setIsPopupFrame(false);
    }

    const updateStatus = () => {
        setIsLoad(true);
        setSpinInfo("数据保存中...")
        updateStatusModel.menuFuncId = Config.funcInfo.funcId;
        Base.callBackApi("secondHand/updateStatus", updateStatusModel).then((data) => {
            if ("SUCCESS" === data.msg) {
                search(pageSize, current, "Y", false);
                errFrameShow("success", "保存成功");
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }

    const commentDelete = () => {
        setIsLoad(true);
        let models={
            secondHandCommentsId: model.secondHandCommentsId,
            menuFuncId: Config.funcInfo.funcId
        }
        Base.callBackApi("secondHand/commentDelete", models).then((data) => {
            if ("SUCCESS" === data.msg) {
                searchComment(pageSizeTow, currentTow);
                errFrameShow("success", "刪除成功");
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }

    const deleteData = () => {
        setIsLoad(true);
        setSpinInfo("数据保存中...");
        let deleteModel = checkboxState[0];
        deleteModel.menuFuncId = Config.funcInfo.funcId;
        Base.callBackApi("secondHand/delete", deleteModel).then((data) => {
            if ("SUCCESS" === data.msg) {
                search(pageSize, current, "Y", false);
                errFrameShow("success", "刪除成功");
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }

    const audit = () => {
        messageShowHandler();//清空提示信息
        if (!checkboxState || checkboxState.length !== 1) {
            errFrameShow("error", "请选择一条数据");
            return;
        }
        setAuditModel(checkboxState[0]);
        setIsAudit(true);
    }

    const auditCancel = () => {
        setIsAudit(false);
    }

    const onChangeAddressA = (value) => {
        console.log(value)
        if (value) {
            if (value.length < 3 || !value[2]) {
                form.setFieldsValue({
                    areaId: [],
                });
            }
        }
    }

    const chooseUser = () => {
        setIsUser(true)
    }

    const closeUserModal = (flag, model) => {
        console.log(model)
        if(flag) {
            form.setFieldsValue({
                userName: (model.userName ?? '') + (model.userId ? '(' + model.userId + ')' : ''),
            });
            setUserModel(model)
        }
        setIsUser(false);
    }

    /**查看图片 */
    const handlePreview = (file) => {
        setPreviewImage(file.url || file.originUrl);
        setPreviewVisible(true);
    }

    const checkImg = (value) => {
        if (!!imageFile && imageFile.length > 0) {
            if (value) {
                let list = value.fileList;
                if (list.find(item => item.status === "uploading")) {
                    return Promise.reject("请等待图片上传完成");
                }
                if (list.find(item => item.status === "error")) {
                    return Promise.reject("请先处理测试上传失败的图片");
                }
            }
            return Promise.resolve();
        }
        return Promise.reject("图片数量必须大于0");
    }

    const handleChange = (file) => {
        let fileList = file.fileList;
        file = file.file;
        if (file.status === "removed") {
            let index = imageFile.findIndex(item => item.uid == file.uid);
            let fileList = JSON.parse(JSON.stringify(imageFile));
            fileList.splice(index, 1)
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = (e) => {
                var img = document.createElement("img");
                img.src = e.target.result;
                img.onload = function () {
                    if (img.width < 400 || img.height < 240 || Math.formatFloat(img.width / img.height, 3) !== Math.formatFloat(5 / 3, 3)) {
                        fileList[fileList.length - 1].status = "irregular";
                        form.validateFields(['img']);
                    }
                }
                fileList[fileList.length - 1].originUrl = e.target.result;
                fileList[fileList.length - 1].imgStatus = "NEW";
            }
        }
        setImageFile(fileList);
    }

    const onFinish = (values) => {
        messageShowHandler();
        setIsLoad(true);
        setSpinInfo("数据保存中...")
        let url = "";
        if (flag === "add") {
            url = "secondHand/insert";
        } else {
            url = "secondHand/update"
            values.secondHandId = checkboxState[0].secondHandId;
            values.lastUpdatedDate = lastUpdatedDate;
        }
        let imageUploadModelList = imageFile.map(item => {
            return {
                status: item.imgStatus,
                url: item.originUrl,
            };
        });
        values.imageUploadModelList = imageUploadModelList;
        values.areaId = values.areaId[2];
        values.userId = userModel.userId;
        values.menuFuncId = Config.funcInfo.funcId;
        Base.callBackApi(url, values).then((data) => {
            if ("SUCCESS" === data.msg) {
                search(pageSize, current, "Y", false);
                cancel();
                errFrameShow("success", "保存成功");
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }

    const cancel = () => {
        messageShowHandler();
        clearSelect();
        setImageFile([]);
        setTabIndex("1");
    }

    const showTips = (flag, record) => {
        setUpdateStatusModel({
            secondHandId: record.secondHandId,
            status: flag ? 'Y' : 'N',
            lastUpdatedDate: record.lastUpdatedDate
        });
        setType();
        setContent("确定要" + (flag ? "上架" : "下架") + "该二手物品吗？");
        setFrameType("update");
        setIsPopupFrame(true);
    }

    //分页 页码切换变化查询
    const onChange = (currentNew, pageSizeNew) => {
        search(pageSizeNew, currentNew, "Y");
    }
    //分页 每页條數变化查询
    const onShowSizeChange = (currentNew, pageSizeNew) => {
        search(pageSizeNew, 1, "Y");
    }

    //列表选中记录
    const onSelectChange = (selectedRowKeys, selectedRows) => {
        setSelectedRowKeys(selectedRowKeys);
        setCheckboxState(selectedRows);
    }
    //清空列表选中
    const clearSelect = () => {
        setSelectedRowKeys([]);
        setCheckboxState([]);
    }

     //分页 页码切换变化查询
     const onChangeTow = (currentNew, pageSizeNew) => {
        searchComment(pageSizeNew, currentNew);
    }
    //分页 每页條數变化查询
    const onShowSizeChangeTow = (currentNew, pageSizeNew) => {
        searchComment(pageSizeNew, 1);
    }

    const errFrameShow = (style, msg) => {
        setMessage(msg);
        setMessageStyle(style);
        setMessageShow(true);
        document.documentElement.scrollTop = 0;
    }

    const messageShowHandler = () => {
        setMessage("");
        setMessageShow(false);
    }

    const recordModelsAdd = () => {
        // setRecordModels(true)
        // setSearchFlag("add")
        // setRecordModel({
        //     "id":1,
        //     "mc": "",
        //     'dhhm': "",
        //     'hybh': "",
        //     "smzgbh": "",
        //     "dz": ""
        // })
    }

    /*eslint-disable*/
    const columns = [
        {
            title: '物品图片',
            dataIndex: 'secondHandDefaultImg',
            className: 'tableWidth8',
            render: (text) => <img className="secondHandImg" alt='' src={Config.secondHandPath + text} />
        },
        {
            title: '标题',
            dataIndex: 'title',
        },
        {
            title: '出售价格',
            className: 'tableWidth12',
            dataIndex: 'salePrice',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'salePrice') },
            render: text => Base.numberFormat(text, 2),
        },
        {
            title: '新旧程度',
            className: 'tableWidth12',
            dataIndex: 'degree',
        },
        {
            title: '状态',
            dataIndex: 'status',
            className: 'tableWidth7',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'status') },
            render: (text, record) => <Switch checkedChildren="已上架" unCheckedChildren="未上架" checked={text === 'Y'} onChange={(e) => showTips(e, record)} />
        },
        {
            title: '上/下架时间',
            dataIndex: 'shelfTime',
            className: 'tableWidth12',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'shelfTime') },
            render: text => text ? moment(text).format(Config.dateFormatItem2) : '',
        },
        {
            title: '发布人账号',
            dataIndex: 'userId',
            className: 'tableWidth12',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'userId') },
        },
        {
            title: '操作',
            dataIndex: '',
            className: 'tableWidth12 tableRightAlignment',
            render: (text, record) => <div className='sysOperation proBrandEstateOperation'>
                <div className='sysUpdate' onClick={(e) => update(record, e)}><EditOutlined /> 修改</div>
                <div className='sysDelete' onClick={(e) => deleteOne(record, e)}><DeleteOutlined /> 删除</div>
            </div>
        },
    ];
    const groupMemberColumns = [
        {
            title: '用户名',
            dataIndex: 'userName',
            className: 'tableWidth20',
            render: (text, record) => <div >
                {record.userName}({record.userId})
            </div>
        },
        {
            title: '评论',
            dataIndex: 'content',
            className: 'tableWidth50',

        },
        {
            title: '评论时间',
            dataIndex: 'commentsDate',
            className: 'tableWidth20',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'commentsDate') },
            render: text => text ? moment(text).format(Config.dateFormatItem2) : '',
        },
        {
            title: "操作",
            dataIndex: '',
            className: 'tableWidth10 tableRightAlignment',
            render: (text, record) => <div className='sysOperation proBrandEstateOperation'>
                <div className='sysDelete' onClick={(e) => deleteOnes(record, e)}><DeleteOutlined />删除</div>
                {/* <div className='sysLogs' onClick={(e) => audit(record, e)}><EyeOutlined />日志</div> */}
            </div>
        },
    ];
    /*eslint-enable*/

    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
        type: 'radio',
    }

    return (
        <div id="container" className="template secondHand">
            <div className='breadcrumb'>
                <HomeOutlined /> /  二手物品 / <span className='breadcrumbTitle'>二手物品</span>
            </div>
            {messageShow ? <Message style={messageStyle} content={message} close={messageShowHandler} /> : ''}
            <div className='templateMain'>
                <div className="sysModelTitle">二手物品</div>
                <Spin spinning={isLoad} size="large" tip={spinInfo}>
                    {/* 删除弹框 */}
                    {isPopupFrame && <PopupFrame close={popupFrameClose} type={type} content={content} />}
                    {/* 审核彈框 */}
                    <Audit visible={isAudit} auditModel={auditModel} close={auditCancel} />
                    {/* 选择用户弹窗 */}
                    {isUser && <SelectUser onClose={closeUserModal} userModel={userModel} />}
                    <Tabs activeKey={tabIndex} onChange={(e) => handleSelect(e)}>
                        <TabPane tab="列表" key="1">
                            <div className='sysRow'>
                                <div className='sysCol'>
                                    <div className="sysTitle">标题</div>
                                    <div className="sysSerchInput">
                                        <Input type="text" placeholder="标题" maxLength={30} value={searchModel.title}
                                            onChange={(e) => searchChange("title", e.target.value)}
                                            onKeyDown={(e) => e.key === "Enter" ? search(pageSize, 1, "N", true) : null} />
                                    </div>
                                </div>
                                <div className='sysCol'>
                                    <div className="sysTitle">状态</div>
                                    <div className="sysSerchInput">
                                        <Select className="sysSelect" value={searchModel.status || ''} onChange={(value) => searchChange('status', value)}>
                                            <Option value="">全部</Option>
                                            {context.productStatusMap.map(item => <Option key={item.value} value={item.value}>{item.label}</Option>)}
                                        </Select>
                                    </div>
                                </div>
                                <div className='sysCol'>
                                    <div className="sysTitle">发布人账号</div>
                                    <div className="sysSerchInput">
                                        <Input type="text" placeholder="发布人账号" maxLength={20} value={searchModel.userId}
                                            onChange={(e) => searchChange("userId", e.target.value)}
                                            onKeyDown={(e) => e.key === "Enter" ? search(pageSize, 1, "N", true) : null} />
                                    </div>
                                </div>
                                <div className='sysSearchButton'>
                                    <Button className="sysBtn btnBule" type="primary" onClick={() => search(pageSize, 1, "N", true)} hidden={Config.funcInfo.canSelect === "N"} icon={<SearchOutlined />}>查询</Button>
                                    <Button className="sysBtn" onClick={reset} icon={<RedoOutlined />}>重置</Button>
                                </div>
                            </div>
                            <div className="sysLineEight"></div>
                            <Space size={[5, 5]} wrap className="sysButtonRelative">
                                <Button className="sysBtn btnBule" type="primary" onClick={add} hidden={Config.funcInfo.canInsert === "N"} icon={<PlusCircleOutlined />}>新增</Button>
                                {tables.length == 0 ? null :
                                    <>
                                        <Button className="sysBtn btnBule" type="primary" onClick={audit} hidden={Config.funcInfo.canAudit === "N"} icon={<EyeOutlined />}>日志</Button>
                                    </>
                                }
                            </Space>
                            <div className="sysTable" id="tableOne">
                                <Table
                                    rowSelection={rowSelection} //选择
                                    rowKey="secondHandId"
                                    columns={columns} //列名
                                    dataSource={tables} //数据
                                    pagination={{  //分页
                                        showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                        onChange: onChange,                        //上页下页或者跳页方法
                                        onShowSizeChange: onShowSizeChange,        //每页显示多少条数据方法
                                        total: tablesCount,             //总共多少条数据
                                        current: current,                //当前页
                                        pageSize: pageSize,              //每页显示多少条数据
                                        defaultCurrent: 1,                               //默认当前页为1
                                        size: "small"
                                    }}
                                    onRow={(record) => {
                                        return {
                                            onClick: event => Base.selectRadioRowNew.call(this, record, 'secondHandId', selectedRowKeys, setCheckboxState, setSelectedRowKeys),
                                        };
                                    }}
                                />
                            </div>
                        </TabPane>
                        <TabPane tab="二手物品" key="2" forceRender={true}>
                            <Form form={form} onFinish={onFinish}>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">标题</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="title"
                                                rules={[
                                                    { required: true, message: '标题不能为空' },
                                                ]}
                                            >
                                                <Input type="text" placeholder="标题" maxLength={30} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">发布人</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="userName"
                                                rules={[
                                                    { required: true, message: '发布人不能为空' },
                                                ]}
                                            >
                                                <Input type="text" placeholder="发布人" maxLength={30} disabled />
                                            </Form.Item>
                                        </div>
                                        <Button className="buttonYellowxuanzeStyle" type="primary" onClick={() => chooseUser()}>选择发布人</Button>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">出售价格</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="salePrice"
                                                rules={[
                                                    // { required: true, message: '出售价格不能为空' },
                                                    { required: true, validator: (rule, value) => Base.checkNbrOrNull(value, null, 99999999.99) },
                                                ]}
                                            >
                                                <InputNumber className='sysInputNumber' placeholder="出售价格" precision={2} maxLength={11} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">新旧程度</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="degree"
                                                rules={[
                                                    { required: true, message: '新旧程度不能为空' },
                                                ]}
                                            >
                                                <Select className="sysSelect" placeholder='新旧程度'>
                                                    <Option value=""></Option>
                                                    {context.degreeMap.map(item => <Option key={item.value} value={item.value}>{item.label}</Option>)}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">联系方式</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="contactWay"
                                                rules={[
                                                    { required: true, message: '联系方式不能为空' },
                                                ]}
                                            >
                                                <Select className="sysSelect" placeholder='联系方式'>
                                                    <Option value=""></Option>
                                                    {context.contactWayMap.map(item => <Option key={item.value} value={item.value}>{item.label}</Option>)}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">联系号码</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="contactNumber"
                                                rules={[
                                                    { required: true, message: '联系号码不能为空' },
                                                ]}
                                            >
                                                <Input type="text" placeholder="联系号码" maxLength={30} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">地区</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="areaId"
                                                rules={[
                                                    { required: true, message: '所在地区不能为空' },
                                                ]}
                                            >
                                                <Cascader options={options} onChange={onChangeAddressA} placeholder="请填写所在地区" />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">物品描述</div>
                                        <div className="sysInputs">
                                            <Form.Item
                                                name="description"
                                                rules={[
                                                    { required: true, message: '物品描述不能为空' },
                                                ]}
                                            >
                                                <TextArea placeholder="物品描述" maxLength={500} rows={3} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">物品图片</div>
                                        <div className="sysInputs">
                                            <Form.Item
                                                name="img"
                                                rules={[
                                                    { required: true, validator: (rule, value) => checkImg(value) }
                                                ]}
                                            >
                                                <ImgCrop rotationSlider aspect={1}>
                                                    <Upload
                                                        action={Config.uploadTestServer}
                                                        key={"1"}
                                                        accept=".png, .jpg, .jpeg"
                                                        listType="picture-card"
                                                        fileList={imageFile}
                                                        onPreview={handlePreview}
                                                        onChange={(file) => handleChange(file)}>
                                                        {imageFile.length >= imageNum ? null : <div className='plusOutBox'>
                                                            <PlusOutlined />
                                                            <div>添加图片</div>
                                                            {/* <div className='plusOutDesc'>(推荐上传宽高大于等于400*240像素的5:3尺寸的图片)</div> */}
                                                        </div>}
                                                    </Upload>
                                                </ImgCrop>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                {flag=="edit"&&
                                    <div className='wholeRentHouseSouce'>
                                    <h4 className='blueBoxTitle1'>评论</h4>
                                    {/* <div className='topButtonBox'>
                                        <Space size={[5, 5]} wrap className="sysButtonRelative" >
                                            <Button type="sysBtn btnBule" className="sysBtn btnBlue" hidden={Config.funcInfo.canInsert === "N"} icon={<PlusCircleOutlined />} onClick={recordModelsAdd}>新增</Button>
                                        </Space>
                                    </div> */}
                                    <div className="sysTable" id="tableTwo">
                                        <Table
                                            scroll={{ x: 1200 }}
                                            rowKey="secondHandCommentsId" //Key值
                                            columns={groupMemberColumns} //列名
                                            dataSource={groupMemberTables} //数据
                                            pagination={{  //分页
                                                showSizeChanger: true,                //是否显示可以设置几条一页的选项
                                                onChange: onChangeTow,                  //上页下页或者跳页方法
                                                onShowSizeChange: onShowSizeChangeTow,          //每页显示条数方法
                                                total: tablesCountTow,        //总共多少条数据
                                                current: currentTow,       //当前页
                                                pageSize: pageSizeTow,  //每页显示多少条数据
                                                defaultCurrent: 1,          //默认当前页为1
                                                size: "small"
                                            }}
                                        />
                                    </div>
                                </div>
                                }
                                
                                <div className="sysSaveButton">
                                    <Button className="sysBtn btnBule" type="primary" disabled={isLoad} htmlType="submit" icon={<CheckCircleOutlined />}>保存</Button>
                                    <Button className="sysBtn" onClick={cancel} icon={<CloseCircleOutlined />}>取消</Button>
                                </div>
                            </Form>
                            <Modal title="放大图" visible={previewVisible} footer={null} onCancel={() => setPreviewVisible(false)}>
                                <img alt="example" style={{ width: '100%' }} src={previewImage} />
                            </Modal>
                        </TabPane>
                    </Tabs>
                </Spin>
            </div>
        </div>
    )

}

export default secondHand;
