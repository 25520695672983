import React, { useState, useEffect } from 'react';
import { Button, Table, Spin, Select, Input, Row, Col, DatePicker, Modal, Space } from 'antd';
import { SearchOutlined, RedoOutlined, DeleteOutlined, EyeOutlined,HomeOutlined, CloseCircleOutlined } from '@ant-design/icons';
import moment from 'moment';//日期格式化导包
import Message from '../../component/message/message';//提示信息弹框
import { Config } from '../../public/js/config';
import { Base } from '../../public/js/base';
import SelectHouse from '../houseSourceAccessDetail/selectHouse';

const BrowseHouse = (props) => {
    const [tables, setTables] = useState([]);
    const [messageShow, setMessageShow] = useState(false);
    const [messageStyle, setMessageStyle] = useState("error");
    const [message, setMessage] = useState("");
    const [isLoad, setIsLoad] = useState(false);
    const [spinInfo, setSpinInfo] = useState("");
    const [current, setCurrent] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [tablesCount, setTablesCount] = useState(0);
    const [searchModel, setSearchModel] = useState({});
    const [oldSearchModel, setOldSearchModel] = useState({});
    const [isHouse, setIsHouse] = useState(false);
    const [houseModel, setHouseModel] = useState({});
    
    useEffect(() => {
        search(pageSize, 1, "N");
    }, []);

    const search = (pageSizeNew, currentPageNew, flag, isShowMsg) => {
        if(Config.funcInfo.canSelect === "N") {
            return;
        }
        messageShowHandler();//清除提示信息
        let model = {};
        if ("Y" === flag) {//是否使用原查詢條件
            model = oldSearchModel;
        } else {
            model = searchModel;
        }
        model.pageSize = pageSizeNew;
        model.page = currentPageNew;
        setIsLoad(true);
        setSpinInfo("查找中...")
        console.log(666)
        Base.callBackApi("browseRecord/searchHouseDetails", model).then((data) => {
            if ("SUCCESS" == data.msg) {
                if (Base.isEmpty(data.object.list) && isShowMsg) {
                    errFrameShow("info", '找不到符合条件记录.');
                }
                if("N" === flag) {
                    setOldSearchModel(JSON.parse(JSON.stringify(model)));
                }
                data?.object?.list?.forEach(item => {
                    if(item.houseSourceImg) {
                        item.houseSourceDefaultImg = item.houseSourceImg.split(";")[0];
                    }
                    if(!item.headPortrait){
                        item.headPortrait = "defaultHead.png"
                    }
                })
                setTablesCount(data?.object?.total ?? 0);
                setTables(data?.object?.list ?? []);
                setCurrent(currentPageNew);
                setPageSize(pageSizeNew);
            } else {
                console.log(data)
                errFrameShow("error", data.msg);
            }
        }).catch(err => ({ err })).finally(() => {
            setIsLoad(false);
        });
    }

    const reset = () => {
        messageShowHandler();
        setSearchModel({});
        setOldSearchModel({});
        setTablesCount(0);
        setTables([]);
        setPageSize(10);
    }

    const searchChange = (key, value) => {
        setSearchModel(model =>{
            let newModel = {};
            if(key === "time") {
                newModel.beginTime = value[0];
                newModel.endTime = value[1];
                return {...model,...{beginTime:value[0],endTime:value[1]}};
            }else{
                return {...model,...{[key]:value}};
            }
        });
    }

    const chooseHouse = () => {
        setIsHouse(true)
        setHouseModel( {
            houseSourceId:searchModel.houseSourceId
        });
    }

    const closeHouseModal = (flag, model) => {
        if(flag) {
            searchModel.houseSourceId = model.houseSourceId
            searchModel.houseSourceTitle = model.houseSourceTitle
        }
        setIsHouse(false);
    }

    //分页 页码切换变化查询
    const onChange = (current, pageSize) => {
        search(pageSize, current, "Y");
    }
    //分页 每页條數变化查询
    const onShowSizeChange = (current, pageSize) => {
        search(pageSize, 1, "Y");
    }

    /** 生成提示信息 */
    const errFrameShow = (style, msg) => {
        setMessageStyle(style);
        setMessage(msg);
        setMessageShow(true);
        document.documentElement.scrollTop = 0;//返回顶部
    }

    /** 清空提示信息 */
    const messageShowHandler = () => {
        setMessage("");
        setMessageShow(false);
    }

    const columns = [
        {
            title: '屋苑名称',
            dataIndex: 'estateTitle',
            className: 'tableWidth15',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'estateTitle') },
        },
        {
            title: '房源',
            dataIndex: 'dataIndex',
            className: 'tableWidth25',
            render: (text, record) => (
                <Space>
                    <img className="apartmentTableImg" alt='' src={Config.proHouseSourceImg + record.houseSourceDefaultImg}/>
                    <div className='flexBox'>
                        <div>{record.houseSourceTitle}</div>
                        <div className='greyColor'>{record.province}{record.city}{record.area}</div>
                    </div>
                </Space>
            ),
        },
        {
            title: '租金',
            dataIndex: 'rent',
            className: 'tableWidth10',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'rent') },
            render: (text) => Base.numberFormat(text, 2)
        },
        {
            title: '发布人',
            dataIndex: 'dataIndex',
            className: 'tableWidth20',
            render: (text, record) => (
                <Space>
                    <img className="apartmentTableImg"  alt="" src={Config.imgServerHeadPortraitImgs + record.headPortrait} />
                    <div className='flexBox'>
                        <div>{record.userName}</div>
                        <div className='greyColor'>{record.userId}</div>
                    </div>
                </Space>
            ),
        },
        {
            title: '浏览日期',
            dataIndex: 'browseDate',
            className: 'tableWidth15',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'browseDate') },
            render: (text) => moment(text).format(Config.dateFormat)
        },
        {
            title: '浏览量',
            dataIndex: 'frequency',
            className: 'tableWidth15',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'frequency') },
        },

        /*eslint-disable*/
    ]

    return (
        <div id="container" className="template">
             <div className='breadcrumb'>
                <HomeOutlined /> / 报表 / <span className='breadcrumbTitle'>整租房源浏览日报</span>
            </div>
            {/* 提示信息弹框 */}
            {messageShow ? <Message style={messageStyle} content={message} close={messageShowHandler} /> : ''}
             {/* 选择房源弹窗 */}
             {isHouse && <SelectHouse onClose={closeHouseModal} houseModel={houseModel}/>}
            <div className='templateMain'>
                <div className="sysModelTitle">整租房源浏览日报</div>
                <Spin spinning={isLoad} size="large" tip={spinInfo}>
                    <div className='systabls'>
                        <div className='sysRow'>
                            <div className='sysCol'>
                                <div className="sysTitle">房源名称</div>
                                <div className="sysSerchInput">
                                    <Input type="text" placeholder="房源名称" value={searchModel.houseSourceTitle} disabled
                                            onChange={(e) => searchChange('houseSourceTitle', e.target.value)}
                                            onKeyDown={(e) => e.key === "Enter" ? search(pageSize, 1, "N", true) : null} />
                                </div>
                                <Button className="buttonYellowxuanzeStyle" type="primary" onClick={() => chooseHouse()}>选择房源</Button>
                            </div>
                            <div className='sysCol'>
                                <div className="sysTitleThree">屋苑名称</div>
                                <div className="sysSerchInput">
                                    <Input type="text" placeholder="屋苑名称" value={searchModel.estateTitle}
                                            onChange={(e) => searchChange('estateTitle', e.target.value)}
                                            onKeyDown={(e) => e.key === "Enter" ? search(pageSize, 1, "N", true) : null} />
                                </div>
                            </div>
                            <div className='sysCols'>
                                <div className="sysTitles">浏览日期</div>
                                <div className="sysSerchInputTime">
                                    <DatePicker placeholder="开始日期"
                                        className="parts-numberInput datePickerAllWidth"
                                        allowClear={false}
                                        showTime={true}
                                        value={searchModel.startTime || ''}
                                        onChange={(value) => searchChange("startTime", value)}
                                        format={Config.dateFormat} />
                                </div>
                            </div>
                            <div className='sysCols'>
                                <div className="sysTitleTime">—</div>
                                <div className="sysSerchInputTime">
                                    <DatePicker placeholder="结束日期"
                                        className="parts-numberInput datePickerAllWidth"
                                        allowClear={false}
                                        showTime={true}
                                        value={searchModel.endTime || ''}
                                        onChange={(value) => searchChange("endTime", value)}
                                        format={Config.dateFormat} />
                                </div>
                            </div>
                            <div className="sysSearchButton">
                                <Button className="sysBtn btnBule" type="primary" onClick={() => search(pageSize, 1, "N", true)} icon={<SearchOutlined />}>查询</Button>
                                <Button className="sysBtn" onClick={reset} icon={<RedoOutlined />}>重置</Button>
                            </div>
                        </div>
                        <div className="sysLineEight"></div>
                        <Space size={[5, 5]} wrap className="buttonRelative">
                            {tables.length == 0 ? null :
                                <>
                                    {/* <Button className="sysBtn btnBule" type="primary" onClick={deleteFrameHandler} hidden={Config.funcInfo.canDelete === "N"} icon={<DeleteOutlined />}>删除</Button>
                                    <Button className="sysBtn btnBule" type="primary" onClick={clearFrameHandler} hidden={Config.funcInfo.canDelete === "N"} icon={<DeleteOutlined />}>清空</Button> */}
                                </>
                            }
                        </Space>
                        <div className="sysTable" id="tableOne">
                            <Table
                                columns={columns} //列名
                                dataSource={tables} //数据
                                pagination={{  //分页
                                    showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                    onChange: onChange,                        //上页下页或者跳页方法
                                    onShowSizeChange: onShowSizeChange,        //每页显示多少条数据方法
                                    total: tablesCount,             //总共多少条数据
                                    current: current,                //当前页
                                    pageSize: pageSize,              //每页显示多少条数据
                                    defaultCurrent: 1,                               //默认当前页为1
                                    size:"small"
                                }}
                            />
                        </div>
                    </div>
                </Spin>
            </div>
        </div>
    )

}

export default BrowseHouse;