import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Spin, Row, Form, Input, Button } from 'antd';
import $ from 'jquery';

import Message from '../../component/message/message';
import { Config } from '../../public/js/config';
import './login.css';
import mainPng from '../../public/img/main.png';
import loginImg from '../../public/img/login.jpg'
import xiugaimima from "../../public/img/xiugaimima.png";
import yonghu from "../../public/img/yonghu.png";

const Login = (props) => {

    const history = useHistory();

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [messageShow, setMessageShow] = useState(false);
    const [messageStyle, setMessageStyle] = useState("");
    const [message, setMessage] = useState("");

    useEffect(() => {
        checkLogin();
    }, []);

    const checkLogin = () => {
        let token = localStorage.getItem('gangyeBackRefreshToken');
        if (token) {
            $.ajax({
                url: Config.apiServer + "login/checkToken",
                type: "POST",
                data: JSON.stringify(token),
                async: false,
                contentType: "application/json; charset=utf-8",
                dataType: 'json',
                success: (data) => {
                    if ("SUCCESS" === data.msg) {
                        history.push("/main");
                    } else {
                        localStorage.removeItem('gangyeBackAccessToken');
                        localStorage.removeItem('gangyeBackRefreshToken');
                    }
                },
                error: (jqXHR, textStatus, errorThrown) => {
                }
            });
        }
    }

    const onFinish = (values) => {
        messageShowHandler();
        setLoading(true);
        fetch(Config.apiServer + "login/ajaxLogin", {
            method: 'POST',
            body: JSON.stringify(values),
            headers: Config.headers,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                Config.userInfo = data.object.userInfo;
                Config.header.authorization = data.object.accessToken + '';
                localStorage.setItem('gangyeBackAccessToken', data.object.accessToken);
                localStorage.setItem('gangyeBackRefreshToken', data.object.refreshToken);
                history.push('/main');
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setLoading(false));
    }

    const errFrameShow = (style, msg) => {
        setMessage(msg);
        setMessageStyle(style);
        setMessageShow(true);
        document.documentElement.scrollTop = 0;
    }

    const messageShowHandler = () => {
        setMessage("");
        setMessageShow(false);
    }

    return (
        <div id="container">
            <div className="newlogin">
                <Row className="newLoginFormRoot">
                    <div className='newloginBackground'>
                    </div>
                    <div className='topDiv'>
                        <div className='headPortrait'>
                            <img className="headImg" src={mainPng} alt=''/>
                        </div>
                        <div className='title'>&nbsp;港耶信息科技</div>
                    </div>
                    <div className='bodyDiv'>
                        <div className='leftBox'>
                            <img alt="" src={loginImg} />
                        </div>
                        <div className='rightBox'>
                            <div className='rightMinBox'>
                                <div className='titleBox'>
                                     {/* 错误信息弹框 */}
                                     {messageShow ? <Message style={messageStyle} messageModel= "login" content={message} close={messageShowHandler}/> : ''}
                                    <div className='title1'>欢迎登录</div>
                                    <div className='title'>管理系统</div>
                                    <div className='jianbiantiao'></div>
                                </div>
                                <div className="textBox">
                                    <Spin spinning={loading}>
                                        <Form form={form} onFinish={onFinish}>
                                            <div className='userBox'>
                                                <div className='userImg'>
                                                    <img src={yonghu}/>
                                                </div>
                                                <Form.Item name="phone"
                                                    // rules={[
                                                    // { required: true, message: '用户编号不能为空' },
                                                    // ]}
                                                    getValueFromEvent={(e) => e.target.value.replace(/\s/g, '')}//替換非空格
                                                >
                                                    <Input maxLength={20} className="loginInput" placeholder="用户编号" />
                                                </Form.Item>
                                            </div>
                                            <div className='pwdBox'>
                                                <div className='userImg'>
                                                    <img src={xiugaimima}/>
                                                </div>
                                                <Form.Item name="pwd"
                                                    // rules={[// 声明式验证: 直接使用别人定义好的验证规则进行验证
                                                    //     { required: true, whitespace: true, message: '密码不能为空' },
                                                    // ]}
                                                >
                                                    <Input type="password" className="loginInput" placeholder="密码" maxLength={20} />
                                                </Form.Item>
                                            </div>
                                            <Button type="primary" htmlType="submit" className="loginFormButton" disabled={loading}>登录</Button>
                                        </Form>
                                    </Spin>
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>
            </div>
            <div className='bot'><span className='goBeiAn' onClick={() => {window.open('https://beian.miit.gov.cn/')}}>粤ICP备2023087319号-1 </span><br/><span>深圳市港耶信息科技有限公司版权所有</span></div>
            
        </div>
    )
}

export default Login;