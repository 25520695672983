import React from 'react';
import { notification } from "antd";
import { CheckCircleOutlined, ExclamationCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Config } from '../../public/js/config';

export class Base {

    /**
     * 获取当前相对路径
     */
    static getUrlRelativePath() {
        var url = document.location.toString();
        var arrUrl = url.split("//");

        var start = arrUrl[1].indexOf("/");
        var relUrl = arrUrl[1].substring(start);

        if (relUrl.indexOf("?") !== -1) {
            relUrl = relUrl.split("?")[0];
        }
        return relUrl;
    }

    /**
     * 传入秒数,将其转换成
     */
    static timeLongConversion(timeLong) {
        if (typeof timeLong !== 'number') {
            return null;
        } else {
            let str = '';
            let h = parseInt(timeLong / (60 * 60));
            if (h > 0) { str += h + '时'; }
            let m = parseInt(timeLong % (60 * 60) / 60);
            if (m > 0) { str += m + '分'; }
            let s = parseInt(timeLong % (60 * 60) % 60);
            if (s > 0) { str += s + '秒'; }
            return str;
        }
    }


      /**
    * 时间格式化处理
    * @param date 要處理的時間
    * @param fmt 要轉換的格式
    */
       static dateFormat(date, fmt = "dd/MM/yyyy") { //author: meizz
        if (date == null) {
            return;
        }
        date = new Date(date);
        var o = {
            "M+": date.getMonth() + 1,                 //月份   
            "d+": date.getDate(),                    //日   
            "h+": date.getHours(),                   //小时   
            "m+": date.getMinutes(),                 //分   
            "s+": date.getSeconds(),                 //秒   
            "q+": Math.floor((date.getMonth() + 3) / 3), //季度   
            "S": date.getMilliseconds()             //毫秒   
        };
        if (/(y+)/.test(fmt))
            fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
        for (var k in o)
            if (new RegExp("(" + k + ")").test(fmt))
                fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        return fmt;
    }

    /**
     * 提示信息框
     * type表示类型 正常提示:success 警告:warning 错误:error
     * content表示提示信息,多条信息
     */
    static alertMessage = (type, content) => {
        // message.config({top: 100,duration: 3,maxCount: 1});
        let arr = content === null ? [] : content.split(';');
        let msgList = [];
        let msgType = '';
        let color = '';
        let iconType = '';
        if (arr.length > 0) {
            let index = 1;
            content = '';
            for (let i in arr) {
                if (arr[i].trim() !== '') {
                    msgList.push(<div style={{ paddingTop: '5px' }} key={index}>{index++ + '.' + arr[i]}</div>)
                }
            }
        }
        switch (type) {
            case 'success': msgType = '提示'; color = 'green'; iconType = <CheckCircleOutlined />; break;
            case 'warning': msgType = '警告'; iconType = <ExclamationCircleOutlined />; break;
            case 'error': msgType = '错误'; color = 'red'; iconType = <CloseCircleOutlined />; break;
        }

        notification.open({
            key: 1,
            message: <div style={{ color: color }}>{msgType}</div>,
            description: msgList,
            icon: iconType,
            style: {
                color: color
            },
            duration: 3
        });
    };

    /**
     * 根据传入的一级菜单的url地址查找到对应下级菜单的所有信息
     * @param {String} name 
     */
    static getFunc(url) {
        let menuList = window.backMenu;
        menuList = Base.sysFuncCombination(menuList);
        let func = [];
        if (menuList != null) {
            for (let i in menuList) {
                if (menuList[i].url === url) {
                    func = menuList[i].menu2List;
                    break;
                }
            }
        }
        return func;
    }

    /**
     * 对模块进行分类
     * @param {Array} data 
     */
    static sysFuncCombination(data) {
        let list = [];
        let menu = {};
        let func = {};
        for (let i in data) {
            if (data[i].type === 'menu') {
                menu = {};
                menu.name = data[i].funcName;
                menu.url = data[i].uiSref;
                menu.menu2List = [];
                for (let j in data) {
                    if (data[j].type === 'func' && data[i].sysFuncId === data[j].parentId) {
                        func = {};
                        func.name = data[j].funcName;
                        func.url = data[j].uiSref;
                        func.component = Base.transform(data[j].funcFileUrl);
                        menu.menu2List.push(func);
                    }
                }
                list.push(menu);
            }
        }
        return list;
    }

    /**
     * 校验参数是否为空
     * String 类型校验，当值为（null,undefined,'','  '）时都为空返回true
     * Array 类型校验，当值为（null,undefined,Array.length === 0）时都为空返回true
     * @param {*} param 要校验的参数  
     */
    static isEmpty(param) {
        if (param == null) {
            return true;
        }
        let flag = false;
        // 基本类型判断（还可以判断 number,boolean 类型）
        if (typeof param === "string") {
            if (param.trim() === '') {
                flag = true;
            }
        }// 对象类型判断（还可以判断 Object，Function 类型）
        else if (param instanceof Array) {
            if (param.length === 0) {
                flag = true;
            }
        }
        return flag;
    }

    /**
     * 校验参数是否不为空，跟isEmpty方法相反
     * @param {*} param 要校验的参数  
     */
    static isNotEmpty(param) {
        return !Base.isEmpty(param);
    }

    static stopClickFirstTime = 0;//记录用户上一次点击的时间
    /**防止重复点击 */
    static stopClick(time = 3000) {
        let secondTime = new Date().getTime();
        if (secondTime - Base.stopClickFirstTime > time) {
            Base.stopClickFirstTime = secondTime;
            return true;
        }
        return false;
    }

    /**
     * 选中或取消选中当前行
     * @param {*} record 当前点击行记录
     * @param {string} rowKey 当前table的主键 
     * @param {string} rows 选中记录集合名
     * @param {string} rowKeys 选中记录key集合名
     */
    static selectRow = function (record, rowKey, rows, rowKeys) {
        let selectedRowKeys = [...this.state[rowKeys]];
        let checkboxState = [...this.state[rows]];
        if (selectedRowKeys.indexOf(record[rowKey]) >= 0) {
            //当点击选中的数据，取消选中
            selectedRowKeys.splice(selectedRowKeys.indexOf(record[rowKey]), 1);
            checkboxState.splice(checkboxState.findIndex(item => item[rowKey] == record[rowKey]), 1);
        } else {
            //选中的数据放入数组
            selectedRowKeys.push(record[rowKey]);
            checkboxState.push(record);
        }
        this.setState({ [rowKeys]: selectedRowKeys, [rows]: checkboxState });
    }

    /**
     * 选中或取消选中当前行，处理radio选中
     * @param {*} record 当前点击行记录
     * @param {String} rowKey 当前table的主键 
     * @param {String} rows 选中记录集合名
     * @param {String} rowKeys 选中记录key集合名
     * @param {Boolean} flag 不清空選中
     * @param {Function} method
     */
    static selectRadioRow = function (record, rowKey, rows, rowKeys, flag, method) {
        let selectedRowKeys = [...this.state[rowKeys]];
        let checkboxState = [...this.state[rows]];
        if (!flag && selectedRowKeys.indexOf(record[rowKey]) > -1) {
            selectedRowKeys = [];
            checkboxState = [];
        } else {
            selectedRowKeys = [record[rowKey]];
            checkboxState = [record];
        }
        let that = this;
        that.setState({ [rowKeys]: selectedRowKeys, [rows]: checkboxState }, function () {
            if (method != null) {
                method.call(that);
            }
        });
    }

    /**
     * 选中或取消选中当前行
     * @param {*} record 当前点击行记录
     * @param {string} rowKey 当前table的主键 
     * @param {Array} rows 选中记录集合
     * @param {Array} rowKeys 选中记录key集合
     * @param {Function} setRows 設置选中记录key集合方法
     * @param {Function} setRowKeys 設置选中记录key集合方法
     */
    static selectRowNew = function (record, rowKey, rows, rowKeys, setRows, setRowKeys) {
        let selectedRowKeys = [...rowKeys];
        let checkboxState = [...rows];
        if (selectedRowKeys.indexOf(record[rowKey]) >= 0) {
            //当点击选中的数据，取消选中
            selectedRowKeys.splice(selectedRowKeys.indexOf(record[rowKey]), 1);
            checkboxState.splice(checkboxState.findIndex(item => item[rowKey] == record[rowKey]), 1);
        } else {
            //选中的数据放入数组
            selectedRowKeys.push(record[rowKey]);
            checkboxState.push(record);
        }
        setRows(checkboxState);
        setRowKeys(selectedRowKeys);
    }

    /**
     * 选中或取消选中当前行，处理radio选中
     * @param {*} record 当前点击行记录
     * @param {String} rowKey 当前table的主键 
     * @param {Array} rowKeys 选中记录key集合
     * @param {Function} setRows 設置选中记录key集合方法
     * @param {Function} setRowKeys 設置选中记录key集合方法
     * @param {Boolean} flag 不清空選中
     * @param {Function} method
     */
    static selectRadioRowNew = function (record, rowKey, rowKeys, setRows, setRowKeys, flag, method) {
        if (!flag && rowKeys.find(item => item == record[rowKey])) {
            setRows([]);
            setRowKeys([]);
        } else {
            setRows([record]);
            setRowKeys([record[rowKey]]);
        }
        if (method != null) {
            method.call(this);
        }
    }

    /**
     * 数字千分位格式化
     * @param {number} number 
     */
    static numberFormat(number, len) {
        if (Base.isEmpty(number)) {
            return "";
        }
        if (Object.is(parseFloat(number), NaN)) {//如果不是数字
            return number;
        }
        if (Base.isNotEmpty(len) && Object.is(parseFloat(len), NaN)) {//如果不是数字
            return number;
        }
        if (Base.isNotEmpty(len)) {
            if (len > 3) {
                /**小數大於三位時，不對小數進行千分位格式化*/
                let str = number.toString()
                let decimalStr = str.lastIndexOf(".") > -1 ? 0 + str.slice(str.lastIndexOf("."), str.length) : "0";
                let rounding = parseFloat(decimalStr).toFixed(len)
                let decimal = rounding.slice(rounding.indexOf("."), rounding.length)
                let integer = str.lastIndexOf(".") > -1 ? str.slice(0, str.lastIndexOf(".")) : str
                let formatNumber = `${parseFloat(integer)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + decimal
                return formatNumber;
            }
            return `${parseFloat(number).toFixed(len)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        } else {
            return `${parseFloat(number)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
    }

    /**
     * 校驗date1是否大於date2，只判断年月日
     * @param {*} date1 
     * @param {*} date2 
     */
    static afterDate(date1, date2) {
        let result = false;
        if (!date1 || !date2) {
            return;
        }
        date1 = new Date(date1);
        date2 = new Date(date2);
        if (date1.getFullYear() > date2.getFullYear()) {
            result = true;
        } else if (date1.getFullYear() === date2.getFullYear()) {
            if (date1.getMonth() > date2.getMonth()) {
                result = true;
            } else if (date1.getMonth() === date2.getMonth()) {
                if (date1.getDate() > date2.getDate()) {
                    result = true;
                }
            }
        }
        return result;
    }

    /**
     * 校驗date1是否大於date2，年月日时分
     * @param {*} date1 
     * @param {*} date2 
     */
    static afterDateTime(date1, date2) {
        let result = false;
        if (!date1 || !date2) {
            return;
        }
        date1 = new Date(date1);
        date2 = new Date(date2);
        if (date1.getFullYear() > date2.getFullYear()) {
            result = true;
        } else if (date1.getFullYear() === date2.getFullYear()) {
            if (date1.getMonth() > date2.getMonth()) {
                result = true;
            } else if (date1.getMonth() === date2.getMonth()) {
                if (date1.getDate() > date2.getDate()) {
                    result = true;
                }else if(date1.getDate() === date2.getDate()){
                    if(date1.getHours()>date2.getHours()){
                        result=true;
                    }else if(date1.getHours()===date2.getHours()){
                        if(date1.getMinutes()>date2.getMinutes()){
                            result=true;
                        }else if(date1.getMinutes()===date2.getMinutes()){
                            if(date1.getSeconds()>=date2.getSeconds()){
                                result=true;
                            }
                        }
                    }
                }
            }
        }
        return result;
    }

    /**
     * 校驗date1是否大於等於date2，只判断年月日
     * @param {*} date1 
     * @param {*} date2 
     */
    static afterEqDate(date1, date2) {
        let result = false;
        if (!date1 || !date2) {
            return;
        }
        date1 = new Date(date1);
        date2 = new Date(date2);
        if (date1.getFullYear() > date2.getFullYear()) {
            result = true;
        } else if (date1.getFullYear() === date2.getFullYear()) {
            if (date1.getMonth() > date2.getMonth()) {
                result = true;
            } else if (date1.getMonth() === date2.getMonth()) {
                if (date1.getDate() > date2.getDate()) {
                    result = true;
                } else if (date1.getDate() === date2.getDate()) {
                    result = true;
                }
            }
        }
        return result;
    }

    /**
     * 校驗數字不能小於0(可以為空)
     * @param {*} value 當前值 
     * @param {*} callback 組件方法
     */
    static checkNbrZero = (value) => {
        if (Base.isNotEmpty(value) && value <= 0) {
            return Promise.reject('數值錯誤,範圍應大於0');
        } else {
            return Promise.resolve();
        }
    };

    /**
     * 校驗數字不能小於0且不能為空
     * @param {*} value 當前值 
     * @param {*} callback 組件方法
     */
    static checkNbrZeroOrNull = (value) => {
        if (Base.isEmpty(value)) {
            return Promise.reject('數值不能為空');
        } else if (value <= 0) {
            return Promise.reject('數值錯誤,範圍應大於0');
        } else {
            return Promise.resolve();
        }
    };

    /**
     * 校驗數字不能大於或小於指定參數值
     * @param {*} value 當前值 
     * @param {*} min 最小值
     * @param {*} max 最大值
     */
    static checkNbr = (value, min, max) => {
        if (Base.isNotEmpty(value)) {
            if (max != null && value > max) {
                return Promise.reject('数值错误,范围不能大于' + max);
            } else if (min != null && value < min) {
                return Promise.reject('数值错误,范围不能下于' + min);
            } else if (min == null && value <= 0) {//min == null 默認賦值為0
                return Promise.reject('数值错误,范围应大于0');
            }
        }
        return Promise.resolve();
    };

    /**
     * 校驗數字不能為空，並且不能大於或小於指定參數值min, max
     * @param {*} value 當前值 
     * @param {*} min 最小值
     * @param {*} max 最大值
     */
    static checkNbrOrNull = (value, min, max) => {
        if (Base.isEmpty(value)) {
            return Promise.reject('数值不能为空');
        } else {
            if (max != null && value > max) {
                return Promise.reject('数值错误,范围不能大于' + max);
            } else if (min != null && value < min) {
                return Promise.reject('数值错误,范围不能小于' + min);
            } else if (min == null && value <= 0) {//min == null 默認賦值為0
                return Promise.reject('数值错误,范围应大于0');
            } else {
                return Promise.resolve();
            }
        }
    };

      /**
     * 效验数字不能为空，并且不能大于或小于指定参数min max和数值大于等于0
     * @param {*} value 當前值 
     * @param {*} min 最小值
     * @param {*} max 最大值
     */
       static checkNbrOrNumber = (value, min, max) => {
        if (Base.isEmpty(value)) {
            return Promise.reject('数值不能为空');
        } else {
            if (max != null && value > max) {
                return Promise.reject('数值错误,范围不能大于' + max);
            } else if (min != null && value < min) {
                return Promise.reject('数值错误,范围不能小于' + min);
            } else if (min == null && value < 0) {//min == null 默認賦值為0
                return Promise.reject('数值错误,范围不小于0');
            } else {
                return Promise.resolve();
            }
        }
    };

    /**
     * 排序方法
     * @param {*} a 
     * @param {*} b 
     * @param {*} name 栏位名
     */
    static sortMethod(a, b, name) {
        if (a[name] == null && b[name] != null) {
            return -1;
        } else if (a[name] != null && b[name] == null) {
            return 1;
        } else if (a[name] != null && b[name] != null) {
            var stringA = a[name];
            var stringB = b[name];
            if (a[name].constructor === String || b[name].constructor === String) {
                stringA = stringA.toUpperCase(); // ignore upper and lowercase
                stringB = stringB.toUpperCase();
            }
            if (stringA < stringB) {
                return -1;
            }
            if (stringA > stringB) {
                return 1;
            }
        }
        return 0;
    }

    /**
     * 获取设备类型(iPad/phone/pc)
     */
    static getEquipmentType = function () {
        let type;
        if (navigator.userAgent.indexOf('iPad') !== -1) {
            type = 'iPad';
        } else if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
            type = 'phone';
        } else {
            type = 'pc';
        }
        return type;
    };
    /**访问设备 true:手机端 false:pc端**/
    static cgIsEnd = function () {
        if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
            return true;
        } else {
            return false;
        }
    };
    /**判断手机    android:安卓FF    iPhone:苹果    0:都不是   返回类型String**/
    static cgIsPhone = function () {
        if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Windows Phone)/i))) {
            return "iPhone";
        } else if ((navigator.userAgent.match(/(Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian)/i))) {
            return "android";
        } else {
            return "0"
        }
    };
    /**判断微信浏览器访问    true:是    false:否*/
    static cgIsWeixn = function () {
        var ua = navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == "micromessenger") {
            return true;
        } else {
            return false;
        }
    };
    /**判断打开浏览器*/
    static cgIsView = function () {
        var userAgent = navigator.userAgent;
        return userAgent.split(" ");
    };

    /**
     * 调用后台api
     * @param {String} url api url
     * @param {*} param 传参
     * @returns api结果
     */
    static callBackApi = async function (url, param) {
        const res = await fetch(Config.apiBackServer + url, {
            method: 'POST',
            body: param ? JSON.stringify(param) : null,
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        });
        let result = await res.json();
        if(result.errorCode === "OVERTIME") {
            window.location.href = "/#/login";
        } else {
            if(result.errorCode === "TOKEN_EXPIRED") {
                let token = localStorage.getItem('gangyeBackRefreshToken');
                if(token) {
                    fetch(Config.apiServer + "login/refreshToken", {
                        method: 'POST',
                        body: JSON.stringify(token),
                        headers: Config.header,
                        mode: 'cors',
                        cache: 'default'
                    }).then(res => res.json()).then((data) => {
                        if ("SUCCESS" === data.msg) {
                            Config.header.authorization = data.object.accessToken + '';
                            localStorage.setItem('gangyeBackAccessToken', data.object.accessToken);
                            localStorage.setItem('gangyeBackRefreshToken', data.object.refreshToken);
                        } else {
                            localStorage.removeItem('gangyeBackAccessToken');
                            localStorage.removeItem('gangyeBackRefreshToken');
                            window.location.href = "/#/login";
                        }
                    }).catch(err => { });
                } else {
                    window.location.href = "/#/login";
                    return;
                }
            }
            return result;
        }
    }

    /**
     * code转大写
     * @param {String} e 值
     * @param {number} length 最大长度
     * @returns 大写code
     */
    static codeChange = function (e, length) {
        let value = e.target.value;
        if (value.length <= length) {
            //1.禁止两边输入空格 2.只能输入字母、-、_ 3.输入变大写
            value = value.replace(/^ +| +$/g, '').replace(/[^\w_\-*]/ig, '').toLocaleUpperCase();
        }
        return value;
    }

    /**
     * 比较两个对象内容是否相同（无递归）
     * @param {*} a 对象
     * @param {*} b 对象
     * @returns 结果
     */
    static isObjectValueEqual = function (a, b) {
        var aProps = Object.getOwnPropertyNames(a);
        var bProps = Object.getOwnPropertyNames(b);
 
        if(aProps.length != bProps.length) {
            return false;
        }
 
        aProps.forEach(propName => {
            var propA = a[propName];
            var propB = b[propName];
            if(propA !== propB) {
                    return false;
            }
        });
        return true;
    }

}