import React, { useState, useEffect } from 'react';
import { Button, Table, Spin, Select, Input, Row, Col, DatePicker, Modal, Space } from 'antd';
import { SearchOutlined, RedoOutlined, DeleteOutlined, EyeOutlined,HomeOutlined, CloseCircleOutlined } from '@ant-design/icons';
import moment from 'moment';//日期格式化导包
import Message from '../../component/message/message';//提示信息弹框
import PopupFrame from '../../component/popupFrame/popupFrame';//删除弹框
import { Config } from '../../public/js/config';
import { Base } from '../../public/js/base';
import { ContextHandler } from '../../public/js/contextHandler';
import context from '../../public/js/context';

const { Option } = Select;
const { RangePicker } = DatePicker;

const WechatOfficialAccount = (props) => {
    const [tables, setTables] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [checkboxState, setCheckboxState] = useState([]);
    const [messageShow, setMessageShow] = useState(false);
    const [messageStyle, setMessageStyle] = useState("error");
    const [message, setMessage] = useState("");
    const [isDeleteFrame, setIsDeleteFrame] = useState(false);
    const [deleteMsg, setDeleteMsg] = useState("");
    const [isLoad, setIsLoad] = useState(false);
    const [spinInfo, setSpinInfo] = useState("");
    const [current, setCurrent] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [tablesCount, setTablesCount] = useState(0);
    const [searchModel, setSearchModel] = useState({});
    const [oldSearchModel, setOldSearchModel] = useState({});
    const [jobGroupList, setJobGroupList] = useState([]);
    const [isView, setIsView] = useState(false);
    const [viewModel, setViewModel] = useState({});
    
    useEffect(() => {
        search(pageSize, 1, "N");
    }, []);

    const search = (pageSizeNew, currentPageNew, flag, isShowMsg) => {
        if(Config.funcInfo.canSelect === "N") {
            return;
        }
        messageShowHandler();//清除提示信息
        let model = {};
        if ("Y" === flag) {//是否使用原查詢條件
            model = oldSearchModel;
        } else {
            model = searchModel;
        }
        model.pageSize = pageSizeNew;
        model.page = currentPageNew;
        setIsLoad(true);
        setSpinInfo("查找中...")
        console.log(666)
        Base.callBackApi("wechatOfficialAccount/search", model).then((data) => {
            if ("SUCCESS" == data.msg) {
                if (Base.isEmpty(data.object.list) && isShowMsg) {
                    errFrameShow("info", '找不到符合条件记录.');
                }
                if("N" === flag) {
                    setOldSearchModel(JSON.parse(JSON.stringify(model)));
                }
                setTablesCount(data?.object?.total ?? 0);
                setTables(data?.object?.list ?? []);
                setCurrent(currentPageNew);
                setPageSize(pageSizeNew);
            } else {
                console.log(data)
                errFrameShow("error", data.msg);
            }
        }).catch(err => ({ err })).finally(() => {
            setIsLoad(false);
        });
    }

    const reset = () => {
        messageShowHandler();
        setSearchModel({});
        setOldSearchModel({});
        setTablesCount(0);
        setTables([]);
        setPageSize(10);
    }

    const searchChange = (key, value) => {
        setSearchModel(model =>{
            let newModel = {};
            if(key === "time") {
                newModel.beginTime = value[0];
                newModel.endTime = value[1];
                return {...model,...{beginTime:value[0],endTime:value[1]}};
            }else{
                return {...model,...{[key]:value}};
            }
        });
    }

    //分页 页码切换变化查询
    const onChange = (current, pageSize) => {
        search(pageSize, current, "Y");
    }
    //分页 每页條數变化查询
    const onShowSizeChange = (current, pageSize) => {
        search(pageSize, 1, "Y");
    }

    /** 生成提示信息 */
    const errFrameShow = (style, msg) => {
        setMessageStyle(style);
        setMessage(msg);
        setMessageShow(true);
        document.documentElement.scrollTop = 0;//返回顶部
    }

    /** 清空提示信息 */
    const messageShowHandler = () => {
        setMessage("");
        setMessageShow(false);
    }

    const columns = [
        {
            title: '账号',
            dataIndex: 'userId',
            className: 'tableWidth15',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'userId') },
        },
        {
            title: 'openId',
            dataIndex: 'openId',
            className: 'tableWidth20',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'openId') },
        },
        {
            title: '用户头像',
            dataIndex: 'headPortrait',
            className: 'tableWidth15',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'headPortrait') },
            render: (text) => (Base.isNotEmpty(text) ?
            <img width="72.5px" alt="" src={(text.search('http') == -1) && (text.search('https') == -1) ? Config.imgServerHeadPortraitImgs + text : text} /> : 
            <img width="72.5px" alt="" src={Config.imgServerHeadPortraitImgs +"defaultHead.png"}/>),
        },
        {
            title: '用户名',
            dataIndex: 'nickName',
            className: 'tableWidth15',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'nickName') },
        },
        {
            title: '手机号',
            dataIndex: 'phone',
            className: 'tableWidth15',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'phone') },
        },
        {
            title: '创建日期',
            dataIndex: 'createdDate',
            className: 'tableWidth20',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'createdDate') },
            render: (text) => moment(text).format(Config.dateFormatALL)
        }
        /*eslint-disable*/
    ]

    return (
        <div id="container" className="template">
             <div className='breadcrumb'>
                <HomeOutlined /> / 其他 / <span className='breadcrumbTitle'>微信公众号管理</span>
            </div>
            {/* 提示信息弹框 */}
            {messageShow ? <Message style={messageStyle} content={message} close={messageShowHandler} /> : ''}
            <div className='templateMain'>
                <div className="sysModelTitle">微信公众号管理</div>
                <Spin spinning={isLoad} size="large" tip={spinInfo}>
                    <div className='systabls'>
                        <div className='sysRow'>
                            <div className='sysCol'>
                                <div className="sysTitles">账号</div>
                                <div className="sysSerchInput">
                                    <Input type="text" placeholder="账号" value={searchModel.userIds}
                                        onChange={(e) => searchChange('userIds', e.target.value)}
                                        onKeyDown={(e) => e.key === "Enter" ? search(pageSize, 1, "N", true) : null} />
                                </div>
                            </div>
                            <div className='sysCol'>
                                <div className="sysTitle">用户名</div>
                                <div className="sysSerchInput">
                                    <Input type="text" placeholder="用户名" value={searchModel.nickName}
                                            onChange={(e) => searchChange('nickName', e.target.value)}
                                            onKeyDown={(e) => e.key === "Enter" ? search(pageSize, 1, "N", true) : null} />
                                </div>
                            </div>
                            <div className='sysCol'>
                                <div className="sysTitle">手机号</div>
                                <div className="sysSerchInput">
                                    <Input type="text" placeholder="手机号" value={searchModel.phone}
                                            onChange={(e) => searchChange('phone', e.target.value)}
                                            onKeyDown={(e) => e.key === "Enter" ? search(pageSize, 1, "N", true) : null} />
                                </div>
                            </div>
                            <div className="sysSearchButton">
                                <Button className="sysBtn btnBule" type="primary" onClick={() => search(pageSize, 1, "N", true)} icon={<SearchOutlined />}>查询</Button>
                                <Button className="sysBtn" onClick={reset} icon={<RedoOutlined />}>重置</Button>
                            </div>
                        </div>
                        <div className="sysLineEight"></div>
                        <Space size={[5, 5]} wrap className="buttonRelative">
                            {tables.length == 0 ? null :
                                <>
                                    {/* <Button className="sysBtn btnBule" type="primary" onClick={deleteFrameHandler} hidden={Config.funcInfo.canDelete === "N"} icon={<DeleteOutlined />}>删除</Button>
                                    <Button className="sysBtn btnBule" type="primary" onClick={clearFrameHandler} hidden={Config.funcInfo.canDelete === "N"} icon={<DeleteOutlined />}>清空</Button> */}
                                </>
                            }
                        </Space>
                        <div className="sysTable" id="tableOne">
                            <Table
                                rowKey="userId"
                                columns={columns} //列名
                                dataSource={tables} //数据
                                pagination={{  //分页
                                    showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                    onChange: onChange,                        //上页下页或者跳页方法
                                    onShowSizeChange: onShowSizeChange,        //每页显示多少条数据方法
                                    total: tablesCount,             //总共多少条数据
                                    current: current,                //当前页
                                    pageSize: pageSize,              //每页显示多少条数据
                                    defaultCurrent: 1,                               //默认当前页为1
                                    size:"small"
                                }}
                            />
                        </div>
                    </div>
                </Spin>
            </div>
        </div>
    )

}

export default WechatOfficialAccount;