import { Base } from "./base";

import Context from "./context";
/**
 * 处理Context.js中数据的方法
 */
export class ContextHandler {

    static getIndValue = (value) => {
        if (Base.isEmpty(value)) {
            return;
        }
        let index = Context.inds.findIndex(item => item.value === value);
        if (index !== -1) {
            return Context.inds[index].label;
        }
    }

    // 根據集合名字和指定key獲取對應value
    static getMapValue = (mapName, value) => {
        if (Base.isEmpty(value) || Base.isEmpty(mapName) || Base.isEmpty(Context[mapName])) {
            return;
        }
        let index = Context[mapName].findIndex(item => item.value === value);
        if (index !== -1) {
            return Context[mapName][index].label;
        }
    }
}