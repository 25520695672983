import React, { useState, useEffect } from 'react';
import { Button, Input, Table, Form, InputNumber, Spin, Select, Upload, Modal, Tabs, Space, } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined,HomeOutlined, SearchOutlined, RedoOutlined, PlusCircleOutlined, EditOutlined, DeleteOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import Message from '../../component/message/message';//提示信息弹框
import PopupFrame from '../../component/popupFrame/popupFrame';//删除弹框
import Audit from '../../component/audit/audit';//審核彈框
import { Config } from '../../public/js/config';
import { Base } from '../../public/js/base';
import { ContextHandler } from '../../public/js/contextHandler';
import context from '../../public/js/context';
import ImgCrop from 'antd-img-crop';

const { Option } = Select;
const { TabPane } = Tabs;
const { TextArea } = Input;

const PictureScrollManage = (props) => {
    const imageNum = 1;
    const [form] = Form.useForm();
    const pictureCategory = Form.useWatch('pictureCategory', form);
    const [tabIndex, setTabIndex] = useState("1");
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [checkboxState, setCheckboxState] = useState([]);
    const [messageShow, setMessageShow] = useState(false);
    const [messageStyle, setMessageStyle] = useState("error");
    const [message, setMessage] = useState("");
    const [flag, setFlag] = useState("add");
    const [isAudit, setIsAudit] = useState(false);
    const [isDeleteFrame, setIsDeleteFrame] = useState(false);
    const [tables, setTables] = useState([]);
    const [tablesCount, setTablesCount] = useState(0);
    const [current, setCurrent] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [searchModel, setSearchModel] = useState({});
    const [oldSearchModel, setOldSearchModel] = useState({});
    const [auditModel, setAuditModel] = useState({});
    const [imageFile, setImageFile] = useState([]);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);
    const [isLoad, setIsLoad] = useState(false);
    const [spinInfo, setSpinInfo] = useState("");
    const [isLeave, setIsLeave] = useState(false);
    const [widthAndHeightFlag, setWidthAndHeightFlag] = useState(false);
    const [isGoods, setIsGoods] = useState(false);
    const [auctionGoodsModel, setAuctionGoodsModel] = useState({});

    /**父组件调用子组件方法  跨域申明*/
    useEffect(() => {
        search(pageSize, 1, "N");
    },[]);


    //查询
    const search = (pageSizeNew, current, flag, isShowMsg) => {
        if(Config.funcInfo.canSelect === "N") {
            return;
        }
        messageShowHandler();
        clearSelect();//清除選中狀態
        let model = {};
        if ("Y" === flag) {//翻頁
            model = oldSearchModel;
        } else {
            model = searchModel;
        }
        model.pageSize = pageSizeNew;
        model.page = current;
        setIsLoad(true);
        setSpinInfo("查询中...");
        Base.callBackApi("pictureScrollManage/search", model).then((data) => {
            if ("SUCCESS" === data.msg) {
                if (!data.object.list && isShowMsg) {
                    errFrameShow("info", '找不到符合条件记录.');
                }
                if("N" === flag) {
                    setOldSearchModel(JSON.parse(JSON.stringify(model)));
                } 
                setTablesCount(data?.object?.total ?? 0);
                setTables(data?.object?.list ?? []);
                setCurrent(current);
                setPageSize(pageSizeNew);
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }   
    
    //重置
    const reset = () => {
        messageShowHandler();
        clearSelect();
        setSearchModel({});
        setOldSearchModel({});
        setTablesCount(0);
        setTables([]);
        setPageSize(10);
    }

    const closeGoodsModel = (flag, model) => {
        if(flag) {
            setAuctionGoodsModel(JSON.parse(JSON.stringify(model)));
        }
        setIsGoods(false);
    }

    // Tab页切换
    const handleSelect = (key) => {
        if (key) {
            if(key === "2") {
                if(checkboxState.length === 1 && Config.funcInfo.canUpdate === "Y") {
                    edit();
                } else if(Config.funcInfo.canInsert === "Y") {
                    add();
                }
            } else if(tabIndex !== "1") {
                setTabIndex("1");
            }
        }
    }

    //添加
    const add = () => {
        messageShowHandler();//清空提示信息
        let model = { status: "Y" };
        if(context.pictureCodeMap?.length === 1){
            model.pictureCategory = context.pictureCodeMap[0].value;
        }
        initForm(model);
        setFlag("add");
        setTabIndex("2");
        setImageFile([]);
        setAuctionGoodsModel({});
    }

    const update = (model,e) => {
        e.stopPropagation();
        messageShowHandler();
        let file = [];
        if (model.headPortrait) {
            file.push({
                url: Config.pictureScrollImg + model.headPortrait,
                originUrl: model.headPortrait,
                thumbUrl: Config.pictureScrollPath + model.headPortrait,
                imgStatus: "OLD"
            })
        }
        setImageFile(file);
        setCheckboxState([model]);
        setSelectedRowKeys([model.id]);
        initForm(model);
        setFlag("edit");
        setTabIndex("2");
        setAuctionGoodsModel(JSON.parse(JSON.stringify(model)));
    }
    
    //修改
    const edit = () => {
        messageShowHandler();//清空提示信息
        if (checkboxState == null || checkboxState.length !== 1) {
            errFrameShow("error", "请选择一条数据");
            return
        }
        let model = checkboxState[0];
        let file = [];
        if (model.headPortrait) {
            file.push({
                url: Config.pictureScrollPath + model.headPortrait,
                originUrl: model.headPortrait,
                thumbUrl: Config.pictureScrollPathImg + model.headPortrait,
                imgStatus: "OLD"
            })
        }
        setImageFile(file);
        initForm(model);
        setFlag("edit");
        setTabIndex("2");
        setAuctionGoodsModel(JSON.parse(JSON.stringify(model)));
        
    }

    const initForm = (model = {}) => {
        form.resetFields();
        form.setFieldsValue({
            id: model.id ?? '',
            pictureName: model.pictureName ?? '',
            pictureCategory: model.pictureCategory ?? '',
            remarks: model.remarks ?? '',
            status: model.status ?? '',
            // headPortrait: model.headPortrait ?? '',
            dispSeq: model.dispSeq ?? '',
            auctionGoodsId: model.auctionGoodsId ?? '',
            productTitle:model.productTitle ?? '',
            productImg:model.productImg ?? '',
            brandCategoryName:model.brandCategoryName ?? '',
            startPrice:model.startPrice ?? '',

        });
    }

    const deleteFrameClose = (state) => {
        if(state) {
            deleteData();
        }
        setIsDeleteFrame(false);
    }

    //删除
    const deleteData = () => {
        setIsLoad(true);
        setSpinInfo("数据删除中...");
        let model = checkboxState[0];
        model.menuFuncId = Config.funcInfo.funcId;
        Base.callBackApi("pictureScrollManage/delete", model).then((data) => {
            if ("SUCCESS" === data.msg) {
                setSearchModel({});
                search(pageSize, current, "Y", false);
                errFrameShow("success", "刪除成功");
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }

    const audit = () => {
        messageShowHandler();//清空提示信息
        if (!checkboxState|| checkboxState.length !== 1) {
            errFrameShow("error", "请选择一条数据");
            return;
        }
        setAuditModel(checkboxState[0]);
        setIsAudit(true);
    }   

    // 审核弹窗
    const auditCancel = () => {
        setIsAudit(false);
    }

    const onSave = (model) => {
        messageShowHandler();//清空提示信息
        setIsLoad(true);
        setSpinInfo("数据保存中...")
       /*  if(imageFile && imageFile.length > 0){
            model.imageUploadModel = {
                status: imageFile[0].imgStatus,
                url: imageFile[0].originUrl,
            };
        } */
        let url = "";
        if (flag === "add") {
            url = "pictureScrollManage/insert";
        } else if (flag === "edit") {
            url = "pictureScrollManage/update";
            model.id = checkboxState[0].id;
            model.lastUpdatedDate = checkboxState[0].lastUpdatedDate;
        }
        let imageUploadModelList = imageFile.map(item => {
            return {
                status: item.imgStatus,
                url: item.originUrl,
            };
        });
        model.imageUploadModelList = imageUploadModelList;
        model.auctionGoodsId = auctionGoodsModel.auctionGoodsId;
        model.headPortrait = null;
        model.menuFuncId = Config.funcInfo.funcId;
        Base.callBackApi(url, model).then((data) => {
            if ("SUCCESS" == data.msg) {
                search(pageSize, current, "Y");
                errFrameShow("success", "保存成功");
                setTabIndex("1");
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => {
            setIsLoad(false)
        });    
    }

    const deleteOne = (model,e) => {
        e.stopPropagation();
        setCheckboxState([model]);
        setSelectedRowKeys([model.id]);
        setIsDeleteFrame(true);
    }

    //取消 
    const cancel = () => {
        messageShowHandler();
        clearSelect();
        setTabIndex("1");
    }

    const leaveFrameClose = (state) => {
        if(state) {
            cancel();
        }
        setIsLeave(false);
    }

    //分页 页码切换变化查询
    const onChange = (currentNew, pageSizeNew) => {
        search(pageSizeNew, currentNew, "Y");
    }
    
    //分页 每页條數变化查询
    const onShowSizeChange = (currentNew, pageSizeNew) => {
        search(pageSizeNew, 1, "Y");
    }

 //列表选中记录
    const onSelectChange = (selectedRowKeys, selectedRows) => {
        setSelectedRowKeys(selectedRowKeys);
        setCheckboxState(selectedRows);
    }

    //清空列表选中
    const clearSelect = () => {
        setSelectedRowKeys([]);
        setCheckboxState([])
    }

    /** 生成提示信息 */
    const errFrameShow = (style, msg) => {
        setMessage(msg);
        setMessageStyle(style);
        setMessageShow(true);
        document.documentElement.scrollTop = 0;//返回顶部
    }

    /** 清空提示信息 */
    const messageShowHandler = () => {
        setMessageShow(false);
        setMessage("");
    }

    /**图片改变事件 */
    const handleChange = (file) => {
        let fileList = file.fileList;
        file = file.file;
        if(file.status === "removed") {
            let index = imageFile.findIndex(item => item.uid == file.uid);
            let fileList = JSON.parse(JSON.stringify(imageFile));
            fileList.splice(index, 1);
            setWidthAndHeightFlag(false);
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = (e) => { 
                var img = document.createElement("img");
                img.src = e.target.result;
                img.onload = function () {
                    if(pictureCategory === "HOME_ROTATION_CHART" && (img.width < 400 || img.height < 400 || (img.width / img.height) !== 1)) {
                        fileList[fileList.length - 1].status = "irregular";
                        form.validateFields(['headPortrait']);
                    }
                }
                fileList[fileList.length - 1].originUrl = e.target.result; 
                getBase64(e.target.result)
                fileList[fileList.length - 1].imgStatus = "NEW";
            }
        }
        setImageFile(fileList);
    }

    // 根据网络地址获取图片的宽高
    const getBase64 = (img) => {
        let image = new Image()
        image.crossOrigin = '';
        image.src = img;
        return new Promise((resolve, reject) => {
            image.onload = function () {
                let { width, height } = image
                // if(width !== 600 || height !== 300){
                //     setWidthAndHeightFlag(true);
                // }
                resolve({ width, height })
            }
        })
    }

    /**关闭图片弹窗 */
    const handleCancel = () => {
        setPreviewVisible(false);
    }

    const checkImg = (value) => {
        if(!!imageFile && imageFile.length > 0) {
            if(value) {
                let list = value.fileList;
                if(list.find(item => item.status === "uploading")) {
                    return Promise.reject("请等待图片上传完成");
                }
                console.log(value);
                console.log(list.find(item => item.status));
                // if(list.find(item => item.status === "error")) {
                //     return Promise.reject("请先处理测试上传失败的图片");
                // }
                // if(widthAndHeightFlag) {
                //     return Promise.reject("请上传600*300分辨率的图片");
                // }
                // if(imageFile.find(item => item.status === "irregular")) {
                //     return Promise.reject("图片尺寸不符合规则");
                // }
            }
            return Promise.resolve();
        }
        return Promise.reject("图片数量必须大于0");
    }

    /**查看图片 */
    const handlePreview = (file) => {
        setPreviewImage(file.url || file.originUrl);
        setPreviewVisible(true);
    }

    /*eslint-disable*/
    const columns = [
        {
            title: '图片',
            dataIndex: 'headPortrait',
            className: 'tableWidth25',
            render: (text) => <img style={{ height: '56px' }} alt='' src={Config.pictureScrollPath + text}/>
        }, 
        {
            title: '图片名称',
            dataIndex: 'pictureName',
            className: 'tableWidth26',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'pictureName') },
        },
        {
            title: '图片类型',
            dataIndex: 'pictureCategory',
            className: 'tableWidth19',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'pictureCategory') },
            render: (text) => ContextHandler.getMapValue('pictureCodeMap', text)
        },
        {
            title: '序号',
            dataIndex: 'dispSeq',
            className: 'tableWidth10 tableRightAlignment',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'dispSeq') },
        }, 
        {
            title: '显示状态',
            dataIndex: 'status',
            className: 'tableWidth10',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'status') },
            render: (text) => ContextHandler.getMapValue('hiddenOrDisplayMap', text)
        },
        {
            title: '操作',
            dataIndex: '',
            className: 'tableWidth10 tableRightAlignment',
            render: (text, record) => <div className='sysOperation proBrandEstateOperation'>
                <div className='sysUpdate' onClick={(e) => update(record, e)}><EditOutlined /> 修改</div>
                <div className='sysDelete' onClick={(e) => deleteOne(record, e)}><DeleteOutlined /> 删除</div>
            </div>
        },
    ];
    /*eslint-enable*/

    //选中记录方法
    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
        type: 'radio',
    }

    const searchChange = (k,e) => {
        messageShowHandler();
        setSearchModel(newSearchModel =>{
            return {...newSearchModel,...{[k]:e}};
        });
    } 

    return (
        <div id="container" className="template">
            <div className='breadcrumb'>
                <HomeOutlined /> / 其他 / <span className='breadcrumbTitle'>图片管理</span>
            </div>
            {/* 提示信息弹框 */}
            {messageShow ? <Message style={messageStyle} content={message} close={messageShowHandler} /> : ''}
            <div className='templateMain'>
                <div className="sysModelTitle">图片管理</div>
                <Spin spinning={isLoad} size="large" tip={spinInfo}>
                    {/* 删除弹框 */}
                    {isDeleteFrame ? <PopupFrame close={deleteFrameClose} /> : null}
                    {isLeave ? <PopupFrame close={leaveFrameClose} type='leave' /> : null}
                    {/* 审核彈框 */}
                    <Audit visible={isAudit} auditModel={auditModel} close={auditCancel} />
                    <Tabs activeKey={tabIndex} onChange={(e) => handleSelect(e)}>
                        <TabPane key="1" tab="列表">
                            <div className='sysRow'>
                                <div className='sysCol'>
                                    <div className="sysTitle">图片名称</div>
                                    <div className="sysSerchInput">
                                        <Input type="text" placeholder="图片名称" maxLength={20} value={searchModel.pictureName}
                                            onChange={(e) => searchChange("pictureName",e.target.value)}
                                            onKeyDown={(e) => e.key === "Enter" ? search(pageSize, 1, "N", true) : null} />
                                    </div>
                                </div>
                                <div className='sysCol'>
                                    <div className="sysTitle">显示状态</div>
                                    <div className="sysSerchInput">
                                        <Select className="sysSelect" placeholder="显示状态"
                                            value={searchModel.status || ''}
                                            onChange={(e) => searchChange('status', e)}
                                        >
                                            <Option value=""></Option>
                                            {context.hiddenOrDisplayMap.map((item) => <Option key={item.value} value={item.value}>{item.label}</Option>)}
                                        </Select>
                                    </div>
                                </div>
                                <div className='sysSearchButton'>
                                    <Button className="sysBtn btnBule" type="primary" onClick={() => search(pageSize, 1, "N", true)} hidden={Config.funcInfo.canSelect === "N"} icon={<SearchOutlined />}>查询</Button>
                                    <Button className="sysBtn" onClick={reset} icon={<RedoOutlined />}>重置</Button>
                                </div>
                            </div>
                            <div className="sysLineEight"></div>
                            <Space size={[5, 5]} wrap className="buttonRelative">
                                <Button className="sysBtn btnBule" type="primary" onClick={add} hidden={Config.funcInfo.canInsert === "N"} icon={<PlusCircleOutlined />}>新增</Button>
                                {tables.length == 0 ? null :
                                    <>
                                        <Button className="sysBtn btnBule" type="primary" onClick={audit} hidden={Config.funcInfo.canAudit === "N"} icon={<EyeOutlined />}>日志</Button>
                                    </>
                                }
                            </Space>
                            <div className="sysTable" id="tableOne">
                                <Table
                                    scroll={{ x: 1200 }}
                                    rowSelection={rowSelection} //选择
                                    rowKey="id"
                                    columns={columns} //列名
                                    dataSource={tables} //数据
                                    pagination={{  //分页
                                        showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                        onChange: onChange,            //上页下页或者跳页方法
                                        onShowSizeChange: onShowSizeChange,    //每页显示多少条数据方法
                                        total: tablesCount,             //总共多少条数据
                                        current: current,         //当前页
                                        pageSize: pageSize,       //每页显示多少条数据
                                        defaultCurrent: 1,      //默认当前页为1
                                        size:"small"                         
                                    }}
                                    onRow={(record) => {
                                        return {
                                            onClick: event => Base.selectRadioRowNew.call(this, record, 'id', selectedRowKeys, setCheckboxState, setSelectedRowKeys),
                                        };
                                    }}
                                />
                            </div>
                        </TabPane>
                        <TabPane key="2" tab="图片管理" forceRender={true}>
                            <Form
                                form={form} onFinish={onSave}
                            >
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">图片名称</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="pictureName"
                                                rules={[
                                                    { required: true, message: '图片名称不能为空' },
                                                ]}
                                            >
                                                <Input type="text"
                                                    placeholder="图片名称" maxLength={100}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">图片类型</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="pictureCategory"
                                                rules={[
                                                    { required: true, message: '图片类型不能为空' },
                                                ]}
                                            >
                                                <Select className="sysSelect">
                                                    {context.pictureCodeMap.map((item) => <Option key={item.value} value={item.value}>{item.label}</Option>)}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">序号</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="dispSeq"
                                                rules={[
                                                    { required: true, validator: (rule, value) => Base.checkNbrOrNull(value, null, 999999999)},
                                                ]}
                                            >
                                                <InputNumber className='sysInputNumber'
                                                    placeholder="序号"
                                                    precision={0} maxLength={9}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">显示状态</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="status"
                                                rules={[// 声明式验证: 直接使用别人定义好的验证规则进行验证
                                                    { required: true, message: '显示状态不能为空' },
                                                ]}
                                            >
                                                <Select className="sysSelect">
                                                    {context.hiddenOrDisplayMap.map((item) => <Option key={item.value} value={item.value}>{item.label}</Option>)}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">图片</div>
                                        <div className="sysInput">
                                            <Modal title="放大图" visible={previewVisible} footer={null} onCancel={handleCancel}>
                                                <img alt="example" style={{ width: '100%' }} src={previewImage} />
                                            </Modal>
                                            <Form.Item 
                                                name="headPortrait"
                                                rules={[
                                                    { validator: (rule, value) => checkImg(value) }
                                                ]}
                                            >
                                                { pictureCategory !== "HOME_ROTATION_CHART" ?
                                                    <Upload
                                                        action={Config.uploadTestServer} 
                                                        key={"1"}
                                                        accept=".png, .jpg, .jpeg"
                                                        listType="picture-card"
                                                        fileList={imageFile}
                                                        onPreview={handlePreview}
                                                        onChange={(file) => handleChange(file)}>
                                                        {imageFile.length >= imageNum ? null : <div className='plusOutBox'>
                                                            <PlusOutlined />
                                                            <div>添加图片</div>
                                                        </div>}
                                                    </Upload>
                                                    :
                                                    <ImgCrop rotationSlider aspect={4/3}>
                                                        <Upload
                                                            action={Config.uploadTestServer} 
                                                            key={"1"}
                                                            accept=".png, .jpg, .jpeg"
                                                            listType="picture-card"
                                                            fileList={imageFile}
                                                            onPreview={handlePreview}
                                                            onChange={(file) => handleChange(file)}>
                                                            {imageFile.length >= imageNum ? null : <div className='plusOutBox'>
                                                                <PlusOutlined />
                                                                <div>添加图片</div>
                                                                <div className='plusOutDesc'>(推荐上传宽高大于等于400*300像素的4:3尺寸的图片)</div>
                                                            </div>}
                                                        </Upload>
                                                    </ImgCrop>
                                                }
                                                
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className="sysSaveButton">
                                    <Button className="sysBtn btnBule" type="primary" disabled={isLoad} htmlType="submit" icon={<CheckCircleOutlined />}>保存</Button>
                                    <Button className="sysBtn" onClick={cancel} icon={<CloseCircleOutlined />}>取消</Button>
                                </div>
                            </Form>
                        </TabPane>
                    </Tabs>
                </Spin>
            </div>
        </div>
    )
}

export default PictureScrollManage;