import React, { useState, useEffect } from 'react';
import { useHistory, Route, Switch, Link } from 'react-router-dom';
import { Menu, Select } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

import { Config } from '../../public/js/config';
import { Base } from '../../public/js/base';
import $ from 'jquery';
import Page404 from '../../system/404/404';

import Default from '../default/default';
import Login from '../login/login';
import SysParam from '../sysParam/sysParam';
import SysParamSegment from '../sysParamSegment/sysParamSegment';
import SysFunc from '../sysFunc/sysFunc';
import SysRole from '../sysRole/sysRole';
import SysUser from '../sysUser/sysUser';
import UserFavorite from '../sysUserFavorite/sysUserFavorite';
import PasswordManage from '../passwordManage/passwordManage';
import ChangUserInfo from '../changUserInfo/changUserInfo';
import ChangeUserPassword from '../changeUserPassword/changeUserPassword';
import OperationLogManage from '../operationLogManage/operationLogManage';
import TimedTask from '../timedTask/timedTask';
import SchedulingLog from '../schedulingLog/schedulingLog';
import PictureScrollManage from '../pictureScrollManage/pictureScrollManage';
import HkLifeGuide from '../../pages/hkLifeGuide/hkLifeGuide';
import UserCollect from '../../pages/userCollect/userCollect';
import Square from '../../pages/square/square';
import WholeRentHouseSouce from '../../pages/wholeRentHouseSouce/wholeRentHouseSouce.js';
import WechatOfficialAccount from '../../pages/wechatOfficialAccount/wechatOfficialAccount';
import BrowseBrandEstate from '../../pages/browseBrandEstate/browseBrandEstate';
import BrowseHouse from '../../pages/browseHouse/browseHouse';
import BrandEstateAccessDetail from '../../pages/brandEstateAccessDetail/brandEstateAccessDetail';
import HouseSourceAccessDetail from '../../pages/houseSourceAccessDetail/houseSourceAccessDetail';
import AgentDataApprove from '../../pages/agentDataApprove/agentDataApprove';
import Apartment from '../../pages/apartment/apartment';
import AptRoom from '../../pages/aptRoom/aptRoom';
import AptSetup from '../../pages/aptSetup/aptSetup';
import mainPng from '../../public/img/main.png';
import fanhui from '../../public/img/fanhui.png';

import ProBrand from '../../pages/proBrand/proBrand';
import ProEstate from '../../pages/proEstate/proEstate';
import ProBrandEstate from '../../pages/proBrandEstate/proBrandEstate';
import ProBrandEstateAdmin from '../../pages/proBrandEstate/proBrandEstateAdmin';

import SecondHand from '../../pages/secondHand/secondHand.js';

const SubMenu = Menu.SubMenu;
const { Option } = Select;

const Main = (props) => {

    const history = useHistory();

    const [collapsed, setCollapsed] = useState(false);
    const [sysUserRoleList, setSysUserRoleList] = useState([]);
    const [defaultUserRoleId, setDefaultUserRoleId] = useState("");
    const [menuList, setMenuList] = useState([]);
    const [selectList, setSelectList] = useState([]);
    const [openKeys, setOpenKeys] = useState([]);
    const [isShow, setIsShow] = useState(false);

    useEffect(() => {
        if (Config.equipmentInfo.type === 'phone') {
            setCollapsed(true);
            setOpenKeys([]);
        }
        checkLogin();
    }, []);

    const checkLogin = () => {
        let token = localStorage.getItem('gangyeBackRefreshToken');
        if (token) {
            $.ajax({
                url: Config.apiServer + "login/checkToken",
                type: "POST",
                data: JSON.stringify(token),
                async: false,
                contentType: "application/json; charset=utf-8",
                dataType: 'json',
                success: (data) => {
                    if ("SUCCESS" === data.msg) {
                        Config.userInfo = data.object.userInfo;
                        Config.header.authorization = data.object.accessToken + '';
                        localStorage.setItem('gangyeBackAccessToken', data.object.accessToken);
                        localStorage.setItem('gangyeBackRefreshToken', data.object.refreshToken);
                        searchSysUserRoleList(data.object.userInfo);
                    } else {
                        localStorage.removeItem('gangyeBackAccessToken');
                        localStorage.removeItem('gangyeBackRefreshToken');
                        if(window.location.href.lastIndexOf("/login") === -1) {
                            history.push("/login");
                        }
                    }
                },
                error: (jqXHR, textStatus, errorThrown) => {
                }
            });
        } else {
            history.push("/login");
        }
    }

    const searchSysUserRoleList = (userModel) => {
        Base.callBackApi("user/searchDefaultSysUserRole", { userId: userModel.userId }).then((data) => {
            if ("SUCCESS" === data.msg) {
                let defaultUserRoleId = null;
                if (Base.isNotEmpty(data.object)) {
                    let sysUserRole = data.object[0];
                    defaultUserRoleId = sysUserRole.userRoleId;
                    Config.userInfo.defaultUserRoleId = sysUserRole.userRoleId;
                    Config.userInfo.defaultRoleId = sysUserRole.roleId;
                    Config.userInfo.roleCode = sysUserRole.roleCode;
                    Config.userInfo.roleName = sysUserRole.roleName;
                }
                setSysUserRoleList(JSON.parse(JSON.stringify(data.object)));
                setDefaultUserRoleId(JSON.parse(JSON.stringify(defaultUserRoleId)))
                searchMenu();
            } else {
                console.log("error", data.msg);
            }
        }).catch(err => { }).finally(() => { });
    }

    const searchMenu = () => {
        let model = { userId: Config.userInfo.userId, roleId: Config.userInfo.defaultRoleId }
        Base.callBackApi("func/leftMenuSearch", model).then((data) => {
            if ("SUCCESS" === data.msg) {
                let list = data.object;
                let newList = [];
                let openKeys = [];
                for (let item of list) {
                    item.children = []
                    for (let iterator of list) {
                        if (item.funcId === iterator.parentId) {
                            item.children.push(iterator)
                        }
                    }
                    item.children.sort(function (a, b) {
                        return a.dispSeq - b.dispSeq;
                    });
                    if (item.type === "MENU") {
                        newList.push(item)
                    }
                }
                for (let item2 of newList) {//获取需要展开的模块id
                    if (Base.isNotEmpty(item2.children)) {
                        openKeys.push(item2.funcCode);
                        for (let subItem of item2.children) {
                            if (subItem.children.length > 0) {
                                openKeys.push(subItem.funcCode);
                            }
                        }
                    }
                }
                localStorage.setItem('menuList', JSON.stringify(newList));
                localStorage.setItem('openKeys', JSON.stringify(openKeys));
                Config.openKeys = openKeys;

                let menuList = JSON.parse(localStorage.getItem("menuList"));
                var urlInfo = window.location.href.split("/");
                let selectList = []
                for (let iterator of menuList) {
                    if (Base.isNotEmpty(iterator.children)) {
                        for (let item of iterator.children) {
                            if (item.funcUrl === urlInfo[urlInfo.length - 1]) {
                                selectList = [item.funcCode + item.parentId]
                            } else if (item.funcUrl + "?sysUserFavorite" === urlInfo[urlInfo.length - 1] && iterator.funcCode === "MY_FAVORITE") {//"我的最爱"menu下模块刷新跳到其他menu问题
                                selectList = [item.funcCode + item.parentId]
                            }
                        }
                    }
                }

                let menuListUntreated = list;//没有分层级的menuList
                let href = window.location.href;
                let funcUrl = href.slice(parseInt(href.lastIndexOf("main/")) + 5, href.length);
                let menuUntreated = menuListUntreated.filter((obj) => {
                    return obj.funcUrl == funcUrl;
                })
                if (Base.isNotEmpty(menuUntreated)) {
                    Config.funcInfo = menuUntreated[0];
                }
                setMenuList(JSON.parse(JSON.stringify(menuList)));
                setSelectList(JSON.parse(JSON.stringify(selectList)));
                setIsShow(true);
            } else {
                console.log(data.msg)
            }
        }).catch(err => {
            console.log(err)
            console.log("接口调用失败，请检查服务器是否启动")
        });
    }

    //缩放menu列
    const toggleCollapsed = () => {
        var left = document.getElementById('leftMenu');
        var right = document.getElementById('rightMain');
        if (left.getAttribute('class') === "hideLeftMenu") {
            left.setAttribute("class", "showLeftMenu");
            right.setAttribute("class", "showMenu");
        } else {
            left.setAttribute("class", "hideLeftMenu");
        }
        setCollapsed(!collapsed);
    }

    //选中menu
    const select = (item) => {
        setSelectList(JSON.parse(JSON.stringify(item.selectedKeys)));
    }

    const selectMenu = (funcCode ,funcUrl) => {
        let list = menuList;
        for(let i = 0; i<list.length; i++){
            if(list[i].funcCode == funcCode){
                let listOne = list[i].children;
                for(let j = 0; j<listOne.length; j++){
                    if(listOne[j].funcUrl == funcUrl){
                        let url = listOne[j].funcCode + listOne[j].parentId;
                        setSelectList([url]);
                        Config.funcInfo = listOne[j];
                    }
                }
            }
        }
    }

    const toChangePassword = () => {
        history.push('/main/changeUserPassword');
    }

    const toChangUserInfo = () => {
        history.push('/main/changUserInfo');
    }

    // 校验权限(判断url输入的menu是否存在，不存在就跳到default页面)
    const authorization = (component) => {
        let existence = false;
        let menuList = JSON.parse(localStorage.getItem("menuList"));
        var urlInfo = window.location.href.split("/");
        if (Base.isNotEmpty(menuList)) {
            for (let iterator of menuList) {
                if (Base.isNotEmpty(iterator.children)) {
                    for (let item of iterator.children) {
                        if (Base.isNotEmpty(item.children)) {
                            for (let sbuItem of iterator.children) {
                                if (sbuItem.funcUrl === urlInfo[urlInfo.length - 1] || sbuItem.funcUrl + "?userFavorite" === urlInfo[urlInfo.length - 1]) {
                                    existence = true;
                                    Config.funcInfo = sbuItem;
                                    break;
                                }
                            }
                        } else {
                            if (item.funcUrl === urlInfo[urlInfo.length - 1] || item.funcUrl + "?userFavorite" === urlInfo[urlInfo.length - 1]) {
                                existence = true;
                                Config.funcInfo = item;
                                break;
                            }
                        }
                    }
                }
            }
        } else {
            history.push('/login');
            return <Login />;
        }
        if (!existence) {
            history.push('/main/default');
            return <Default />;
        }
        return component;
    }

    /**左侧二三级选项卡切换*/
    const twoMenu = (subItem) => {
        Config.funcInfo = subItem;
    }

    /** 切换组别 */
    const ouChange =(e) => {
        sysUserRoleList.forEach((item) => {
            if (item.userRoleId == e) {
                Config.userInfo.defaultUserRoleId = item.userRoleId;
                Config.userInfo.roleCode = item.roleCode;
                Config.userInfo.roleName = item.roleName;
            }
        });
        setDefaultUserRoleId(e);
        searchMenu();//重新查询menu
        history.push('/main/default');
    }

    // 登出方法,以前的版本，请求后台接口清除当前yoghurt信息
    const logout = () => {
        Config.userInfo = {};
        Config.header.authorization = '';
        localStorage.removeItem('gangyeBackAccessToken');
        localStorage.removeItem('gangyeBackRefreshToken');
        localStorage.removeItem("menuList");
        localStorage.removeItem("openKeys");
        history.push('/login');
    }

    return (
        <div>
            {isShow ? <>
                <div id="main">
                    {/* 左边菜单区域 */}
                    <div id="leftMenu" className={Config.equipmentInfo.type === 'phone' ? "hideLeftMenu" : "showLeftMenu"}>
                        <div className="head">
                            <div>
                                <img className="headImg" src={mainPng} alt=''/>
                                {!collapsed && <span className="headTitle">管理系统</span>}
                            </div>
                            <MenuOutlined className='headIcons' onClick={toggleCollapsed}/>
                        </div>
                        <Menu
                            defaultOpenKeys={openKeys}
                            selectedKeys={selectList}
                            onSelect={(e) => select(e)}
                            mode="inline"
                            theme="dark"
                            style={{"backgroundColor": "#eebd79"}}
                            inlineIndent={10}
                            inlineCollapsed={collapsed}>
                            {menuList.map((item) =>
                                <SubMenu
                                    key={item.funcCode}
                                    style={{"backgroundColor": "#eebd79"}}
                                    title={
                                        <div className='funcRow'>
                                            <div className="funcIcon"> 
                                                {item.image && <img className="funcImg" src={Config.funcImg + item.image}></img>}
                                            </div>
                                            {!collapsed && <div className="fontWtIcon">{item.funcName}</div>}
                                        </div>
                                    }
                                >
                                    {item.children.map((subItem) => (
                                        subItem.children.length > 0 ?
                                            <SubMenu
                                                key={subItem.funcCode}
                                                title={
                                                    <div className='funcRow'>
                                                        <div className="funcIcon"> 
                                                            {subItem.image && <img className="funcImg" src={Config.funcImg + subItem.image}></img>}
                                                        </div>
                                                        {!collapsed && <div className="fontWtIcon">{item.funcName}</div>}
                                                    </div>
                                                }
                                            >
                                                {subItem.children.map((charItem) =>
                                                    <Menu.Item key={charItem.funcCode + charItem.parentId} onClick={() => { twoMenu(charItem) }}>
                                                        <div className="fontWt">{charItem.funcName}</div>
                                                        <Link to={"/main/" + charItem.funcUrl} replace></Link>
                                                    </Menu.Item>
                                                )}
                                            </SubMenu>
                                            :
                                            <Menu.Item key={subItem.funcCode + subItem.parentId} onClick={() => { twoMenu(subItem) }}>
                                                <div className="fontWt">{subItem.funcName}</div>
                                                <Link to={"/main/" + subItem.funcUrl + (item.funcCode == "MY_FAVORITE" ? "?userFavorite" : '')} replace></Link>
                                            </Menu.Item>
                                    ))}
                                </SubMenu>
                            )}
                        </Menu>
                    </div>
                    {/* 右边内容区域 */}
                    <div id="rightMain" className={collapsed ? "hideRightWidth" : "showRightWidth"}>
                        <div className="head">
                            <div className="headCol" onClick={toChangePassword}>修改密码</div>
                            <div className="headCol" onClick={toChangUserInfo} title={"用户:" + (Config.userInfo ? Config.userInfo.userName : "" )}>{Config.userInfo ? Config.userInfo.userName : ""}</div>
                            <div className='headCol'>
                                <div>
                                    <Select style={{ width: "100%","borderRadius":"10px" }} className='sysSelect' value={defaultUserRoleId} 
                                    onChange={(e) => ouChange(e)}>
                                        {sysUserRoleList.map((item) => <Option key={item.userRoleId} value={item.userRoleId}  title={"角色:" + (item.roleCode)}>{item.roleCode}</Option>)}
                                    </Select>
                                </div>
                            </div>
                            <div className="headCols" onClick={logout}>
                                <img src={fanhui} title='登出'/>
                            </div>
                        </div>
                        <Switch>
                            <Route path="/main" exact component={Default} />
                            <Route path="/main/default" exact component={Default} />
                            {/* demo页面 */}
                            <Route path="/main/changUserInfo" exact component={ChangUserInfo} />
                            <Route path="/main/changeUserPassword" exact component={ChangeUserPassword} />
                            
                            {/* 系統管理 */}
                            <Route path="/main/sysParam" exact render={props => authorization(<SysParam {...props} />)} />
                            <Route path="/main/sysParamSegment" exact render={props => authorization(<SysParamSegment {...props} />)} />
                            <Route path="/main/sysFunc" exact render={props => authorization(<SysFunc searchMenu={searchMenu} {...props} />)} />{/* 通过searchMenu方法刷新menu */}
                            <Route path="/main/sysRole" exact render={props => authorization(<SysRole searchMenu={searchMenu} {...props} />)} />
                            <Route path="/main/sysUser" exact render={props => authorization(<SysUser {...props} />)} />
                            <Route path="/main/sysUserFavorite" exact render={props => authorization(<UserFavorite searchMenu={searchMenu} {...props} />)} />
                            <Route path="/main/passwordManage" exact render={props => authorization(<PasswordManage searchMenu={searchMenu} {...props} />)} />
                            <Route path="/main/operationLogManage" exact render={props => authorization(<OperationLogManage {...props} />)} />
                            <Route path="/main/timedTask" exact render={props => authorization(<TimedTask {...props} />)} />
                            <Route path="/main/schedulingLog" exact render={props => authorization(<SchedulingLog {...props} />)} />
                            <Route path="/main/hkLifeGuide" exact render={props => authorization(<HkLifeGuide {...props} />)} />
                            
                            {/* 品牌*/}
                            <Route path="/main/proBrand" exact render={props => authorization(<ProBrand {...props} />)} />
                            <Route path="/main/proEstate" exact render={props => authorization(<ProEstate {...props} />)} />
                            <Route path="/main/proBrandEstate" exact render={props => authorization(<ProBrandEstate {...props} />)} />
							<Route path="/main/proBrandEstateAdmin" exact render={props => authorization(<ProBrandEstateAdmin {...props} />)} />
                            <Route path="/main/agentDataApprove" exact render={props => authorization(<AgentDataApprove selectMenu={selectMenu} {...props} />)} />                            {/*报表*/}
                            <Route path="/main/browseHouse" exact render={props => authorization(<BrowseHouse {...props} />)} />
                            <Route path="/main/browseBrandEstate" exact render={props => authorization(<BrowseBrandEstate {...props} />)} />
                            <Route path="/main/brandEstateAccessDetail" exact render={props => authorization(<BrandEstateAccessDetail {...props} />)} />
                            <Route path="/main/houseSourceAccessDetail" exact render={props => authorization(<HouseSourceAccessDetail {...props} />)} />
                            {/* 二手物品 */}
                            <Route path="/main/secondHand" exact render={props => authorization(<SecondHand {...props} />)} />
                            {/* 其他*/}
                            <Route path="/main/userCollect" exact render={props => authorization(<UserCollect {...props} />)} />
                            <Route path="/main/square" exact render={props => authorization(<Square {...props} />)} />
                            <Route path="/main/pictureScrollManage" exact render={props => authorization(<PictureScrollManage {...props} />)} />
                            
                            <Route path="/main/apartment" exact render={props => authorization(<Apartment {...props} />)} />
                            <Route path="/main/aptRoom" exact render={props => authorization(<AptRoom {...props} />)} />
                            <Route path="/main/aptSetup" exact render={props => authorization(<AptSetup {...props} />)} />
                            <Route path="/main/wholeRentHouseSouce" exact render={props => authorization(<WholeRentHouseSouce {...props} />)} />
                            <Route path="/main/wechatOfficialAccount" exact render={props => authorization(<WechatOfficialAccount {...props} />)} />
                            <Route exact component={Page404} />

                        </Switch>
                    </div>
                </div>
            </> : null}
        </div>
    )
}

export default Main;