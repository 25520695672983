import React, { useState, useEffect } from 'react';
import { Button, Table, Spin, Form, Input, Select, Tabs, Space, Checkbox, Upload, Modal } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, SearchOutlined, RedoOutlined, HomeOutlined, PlusCircleOutlined, EditOutlined, DeleteOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';//日期格式化导包
import { useLocation } from 'react-router-dom';
import Message from '../../component/message/message';//提示信息弹框
import PopupFrame from '../../component/popupFrame/popupFrame';//删除弹框
import Audit from '../../component/audit/audit';//审核彈框
import { Config } from '../../public/js/config';
import { Base } from '../../public/js/base';
import { ContextHandler } from '../../public/js/contextHandler';
import context from '../../public/js/context';
import RoleFromModal from './roleFromModal';
import './sysUser.css';

const { TabPane } = Tabs;
const { Option } = Select;
const { TextArea } = Input;
const SysUser = (props) => {

    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    const [isLoad, setIsLoad] = useState(false);
    const [spinInfo, setSpinInfo] = useState("");
    const [isAudit, setIsAudit] = useState(false);
    const [auditModel, setAuditModel] = useState({});
    const [messageShow, setMessageShow] = useState(false);
    const [messageStyle, setMessageStyle] = useState("");
    const [message, setMessage] = useState("");
    const [tabIndex, setTabIndex] = useState("1");
    const [searchModel, setSearchModel] = useState({});
    const [oldSearchModel, setOldSearchModel] = useState({});
    const [tables, setTables] = useState([]);
    const [tablesCount, setTablesCount] = useState(0);
    const [current, setCurrent] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [checkboxState, setCheckboxState] = useState([]);
    const [sysRoleList, setSysRoleList] = useState([]);
    const [positionAddList, setPositionAddList] = useState([]);
    const [isDeleteFrame, setIsDeleteFrame] = useState(false);
    const [flag, setFlag] = useState("");
    const [sysUserRoleList, setSysUserRoleList] = useState([]);
    const [countryDownList, setCountryDownList] = useState([]);
    const [roleModel, setRoleModel] = useState({});
    const [sysRoleSelectedRowKeys, setSysRoleSelectedRowKeys] = useState([]);
    const [sysRoleCheckboxState, setSysRoleCheckboxState] = useState([]);
    const [isRoleBut, setIsRoleBut] = useState(false);
    const [deleteFlag, setDeleteFlag] = useState("");
    const [isRoleFrom, setIsRoleFrom] = useState(false);
    const [userRoleModel, setUserRoleModel] = useState("");
    const [isflag, setIsFlay] = useState(false);
    const [dignityList, setDignityList] = useState([]);
    const [positionList, setPositionList] = useState([]);
    const [schoolList, setSchoolList] = useState([]);
    const [brandList, setBrandList] = useState([]);
    const dignityValue = Form.useWatch('dignity', form);
    const brandEstateInd = Form.useWatch('brandEstateInd', form);
    const [contactLanguageList, setContactLanguageList] = useState([
        { label: "普通话", value: "普通话" },
        { label: "粤语", value: "粤语" },
        { label: "英语", value: "英语" },
        { label: "其他", value: "其他" },
    ]);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);
    const [wechatImgFile, setWechatImgFile] = useState([]);
    const [headFile, setHeadFile] = useState([]);
    const logoNum = 1;
    const location = useLocation();
    const cacheData = location.state;
    const [whatsAppLength, setWhatsAppLength] = useState(8);

    useEffect(() => {
        search(pageSize, 1, "N");
        sysRoleSearch();
        getPositionList();
        searchConfiguration();
        searchBrandList();
        console.log(cacheData)
        if (cacheData) {
            searchUserInfoById(cacheData);
            setTabIndex("2")
        }
    }, []);

    /**查询角色下拉列表*/
    const sysRoleSearch = () => {
        Base.callBackApi("role/searchRole").then((data) => {
            if ("SUCCESS" === data.msg) {
                setSysRoleList(data.object ?? []);
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { });
    }

    /**查询身份 职业 学校 下拉列表*/
    const searchConfiguration = () => {
        Base.callBackApi("user/searchConfiguration").then((data) => {
            if ("SUCCESS" === data.msg) {
                setDignityList(data.object.dignityList)
                setSchoolList(data.object.schoolList)
                setPositionList(data.object.positionList)
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { });
    }

    /**职位下拉 */
    const getPositionList = () => {
        Base.callBackApi("commonDropDown/searchSysParam", { segment: "POSITION" }).then((data) => {
            if ("SUCCESS" === data.msg) {
                let list = JSON.parse(JSON.stringify(data.object ?? []));
                let index = list.findIndex(item => item.code === "WEB_USER");
                list.splice(index, 1);
                setPositionAddList(list);
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { })
    }

    const searchBrandList = () => {
        Base.callBackApi("commonDropDown/searchProBrand").then((data) => {
            if ("SUCCESS" === data.msg) {
                setBrandList(data.object ?? []);
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { });
    }

    const search = (pageSizeNew, currentPageNew, flag, isShowMsg) => {
        if (Config.funcInfo.canSelect === "N") {
            return;
        }
        messageShowHandler();  // 清除提示信息
        clearSelect(); // 清除选中状态
        let model = {};
        if ("Y" === flag) {     // 翻页
            model = oldSearchModel;
        } else {
            model = searchModel;
        }
        model.page = currentPageNew;
        model.pageSize = pageSizeNew;
        setIsLoad(true);
        setSpinInfo("查询中...");
        Base.callBackApi("user/search", model).then((data) => {
            if ("SUCCESS" === data.msg) {
                if (!data?.object?.list && isShowMsg) {
                    errFrameShow("info", '找不到符合條件記錄.');
                }
                if ("N" === flag) {
                    setOldSearchModel(JSON.parse(JSON.stringify(model)));
                }
                setTablesCount(data?.object?.total ?? 0);
                setTables(data?.object?.list ?? []);
                setCurrent(currentPageNew);
                setPageSize(pageSizeNew);
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }

    const searchChange = (key, value) => {
        messageShowHandler();
        setSearchModel(model => {
            return { ...model, ...{ [key]: value } };
        });
    }

    const reset = () => {
        messageShowHandler();
        clearSelect();
        setSearchModel({});
        setOldSearchModel({});
        setTablesCount(0);
        setTables([]);
        setPageSize(10);
    }

    const areaCodeChange = (e) => {
        console.log(e)
        form.setFieldsValue({
            whatsApp: '',
        });
        if (e == '+86') {
            setWhatsAppLength(13);
        } else {
            setWhatsAppLength(8);
        }
    }


    // Tab页切换
    const handleSelect = (key) => {
        if (key) {
            if (key === "3") {
                // updateRole();
            } else if (key === "2") {
                if (Config.funcInfo.canUpdate === "Y") {
                    edit();
                } else if (Config.funcInfo.canInsert === "Y") {
                    add();
                }
            } else if (tabIndex !== "1") {
                cancel();
            }
        }
    }

    const add = () => {
        messageShowHandler();
        initForm({ activeInd: 'Y' });
        setHeadFile([]);
        setWechatImgFile([]);
        setFlag("add");
        setTabIndex("2");
    }

    const edit = () => {
        messageShowHandler();
        if (!selectedRowKeys || selectedRowKeys.length !== 1) {
            errFrameShow("error", "请选择一条数据");
            return;
        }
        searchUserInfoById(checkboxState[0])
    }

    const update = (model, e) => {
        e.stopPropagation();
        messageShowHandler();
        searchUserInfoById(model)
    }

    const searchUserInfoById = (value) => {
        Base.callBackApi("user/searchUserInfoById", value).then((data) => {
            if ("SUCCESS" === data.msg) {
                let model = data.object;
                initForm(model);
                if (model.headPortrait) {
                    setHeadFile([{
                        url: Config.imgServerHeadPortraitImgs + model.headPortrait,
                        originUrl: model.headPortrait,
                        thumbUrl: Config.imgServerHeadPortraitImgs + model.headPortrait,
                        imgStatus: "OLD"
                    }])
                }
                if (model.wechat) {
                    setWechatImgFile([{
                        url: Config.weChatBusinessCard + model.wechat,
                        originUrl: model.wechat,
                        thumbUrl: Config.weChatBusinessCard + model.wechat,
                        imgStatus: "OLD"
                    }])
                }
                setCheckboxState([model]);
                setSelectedRowKeys([model.userId]);
                setRoleModel(model);
                setFlag("edit");
                setTabIndex("2");
                searchSysUserRoleList(model);
                setIsFlay(true)
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }

    const initForm = (model = {}) => {
        form.resetFields();
        console.log(model.areaCode)
        if (model.areaCode == '+86') {
            setWhatsAppLength(13);
        } else {
            setWhatsAppLength(8);
        }
        form.setFieldsValue({
            userId: model.userId ?? '',
            userName: model.userName ?? '',
            pwd: '',
            position: model.position ?? '',
            userType: model.userType ?? '',
            activeInd: model.activeInd ?? '',
            desc: model.desc ?? '',
            firstLoginActiveInd: model.firstLoginActiveInd ?? '',
            phone: model.phone ?? '',
            email: model.email ?? '',
            docId: model.docId ?? '',
            realName: model.realName ?? '',
            gender: model.gender ?? '',
            dignity: model.dignity ?? '',
            school: model.school ?? '',
            major: model.major ?? '',
            brandEstateInd: model.brandEstateInd ?? 'N',
            proBrandId: model.proBrandId ?? '',
            language: model.language?.split('|') ?? [],
            areaCode: model.areaCode ?? '',
            whatsApp: model.whatsApp ?? '',
            userIntro: model.userIntro ?? '',
        });
    }

    const updateRole = (model, e) => {
        if (e) {
            e.stopPropagation();
        }
        messageShowHandler();
        form2.resetFields();
        form2.setFieldsValue({
            userId: model.userId ?? null,
            userCode: model.userCode ?? '',
            userName: model.userName ?? '',
            realName: model.realName ?? '',
            docId: model.docId ?? '',
        });
        setFlag("edit");
        setTabIndex("3");
        setCheckboxState([model]);
        setSelectedRowKeys([model.userId]);
    }

    const deleteOne = (model, e) => {
        e.stopPropagation();
        if (model.activeInd !== "N") {
            errFrameShow("error", "非冻结用戶不能刪除");
            return;
        }
        setCheckboxState([model]);
        setSelectedRowKeys([model.userId]);
        setIsDeleteFrame(true);
        setDeleteFlag("user");
    }

    const deleteFrameClose = (state) => {
        if (state) {
            if (deleteFlag === "user") {
                deleteData();
            } else {
                deleteUserRole();
            }
        }
        setIsDeleteFrame(false);
    }

    const deleteData = () => {
        setIsLoad(true);
        setSpinInfo("数据保存中...");
        let deleteModel = checkboxState[0];
        deleteModel.menuFuncId = Config.funcInfo.funcId;
        Base.callBackApi("user/delete", deleteModel).then((data) => {
            if ("SUCCESS" === data.msg) {
                search(pageSize, current, "Y", false);
                errFrameShow("success", "刪除成功");
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }

    const audit = () => {
        messageShowHandler();//清空提示信息
        if (!checkboxState || checkboxState.length !== 1) {
            errFrameShow("error", "请选择一条数据");
            return;
        }
        setAuditModel(checkboxState[0]);
        setIsAudit(true);
    }

    const auditCancel = () => {
        setIsAudit(false);
    }

    /**查询用戶的角色list */
    const searchSysUserRoleList = (model) => {
        clearSysRoleSelect();
        Base.callBackApi("user/searchSysUserRole", model).then((data) => {
            if ("SUCCESS" === data.msg) {
                setSysUserRoleList(data.object ?? []);
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => { });
    }

    const brandEstateIndChange = () => {
        form.setFieldValue('proBrandId', '');
    }

    const onFinish = (values) => {
        messageShowHandler();
        let url = "";
        if (flag === "add") {
            url = "user/insert";
        } else {
            url = "user/update"
            values.userId = selectedRowKeys[0];
            values.lastUpdatedDate = checkboxState[0].lastUpdatedDate;
        }
        if (!!headFile && headFile.length > 0) {
            values.headImgFile = {
                status: headFile[0].imgStatus,
                url: headFile[0].originUrl,
            };
        }
        if (!!wechatImgFile && wechatImgFile.length > 0) {
            values.wechatImgFile = {
                status: wechatImgFile[0].imgStatus,
                url: wechatImgFile[0].originUrl,
            };
        }
        if (!!values.language && values.language.length > 0 && values.language[0] != '[]') {
            console.log(values.language)
            values.language = values.language.join('|');
        } else {
            values.language = '';
        }
        console.log(values)
        values.menuFuncId = Config.funcInfo.funcId;
        setIsLoad(true);
        setSpinInfo("数据保存中...")
        Base.callBackApi(url, values).then((data) => {
            if ("SUCCESS" === data.msg) {
                let model = data.object;
                search(pageSize, current, "Y", false);
                setCheckboxState([model]);
                setSelectedRowKeys([model.userId]);
                setFlag("edit")
                initForm(model)
                setRoleModel(model)
                errFrameShow("success", "保存成功");
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }

    const checkPhone = (value) => {
        if (value) {
            let hkRegex = /^[456789]{1}\d{7}$/;
            let cnRegex = /^1[3456879]\d{9}$/;
            if (hkRegex.test(value) || cnRegex.test(value)) {
                return Promise.resolve();
            }
            return Promise.reject(new Error("请输入正确的手机号"));
        }
        return Promise.reject(new Error("新手机号不能为空"));
    }

    const onFinish2 = (values) => {
        messageShowHandler();
        setIsLoad(true);
        setSpinInfo("数据保存中...")
        values.userId = selectedRowKeys[0];
        values.lastUpdatedDate = checkboxState[0].lastUpdatedDate;
        values.menuFuncId = Config.funcInfo.funcId;
        Base.callBackApi("user/updateDocId", values).then((data) => {
            if ("SUCCESS" === data.msg) {
                // let model = data.object;
                cancel();
                search(pageSize, current, "Y", false);
                // setCheckboxState([model]);
                // setSelectedRowKeys([model.userId]);
                // setFlag("edit")
                errFrameShow("success", "保存成功");
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }

    const cancel = () => {
        messageShowHandler();
        clearSelect();
        clearSysRoleSelect();
        setSysUserRoleList([]);
        setTabIndex("1");
        setIsFlay(false)
    }

    const deleteUserRole = () => {
        setIsLoad(true);
        setSpinInfo("数据保存中...");
        let deleteModel = sysRoleCheckboxState[0];
        deleteModel.menuFuncId = Config.funcInfo.funcId;
        Base.callBackApi("user/deleteUserRole", deleteModel).then((data) => {
            if ("SUCCESS" === data.msg) {
                errFrameShow("success", "删除成功");
                searchSysUserRoleList(roleModel);//查询用戶角色list
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }

    const auditRole = () => {
        messageShowHandler();//清空提示信息
        if (!sysRoleCheckboxState || sysRoleCheckboxState.length !== 1) {
            errFrameShow("error", "请选择一条数据");
            return;
        }
        setAuditModel(sysRoleCheckboxState[0]);
        setIsAudit(true);
    }

    const sysRoleEdit = () => {
        if (!sysRoleCheckboxState || sysRoleCheckboxState.length !== 1) {
            errFrameShow("error", "请选择一条数据");
            return;
        }
        messageShowHandler();
        setUserRoleModel(sysRoleCheckboxState[0]);
        setIsRoleFrom(true);
    }

    const sysRoleDelete = () => {
        messageShowHandler();
        if (!sysRoleCheckboxState || sysRoleCheckboxState.length !== 1) {
            errFrameShow("error", "请选择一条数据");
            return;
        }
        setIsDeleteFrame(true);
        setDeleteFlag("role");
    }

    const sysRoleAdd = () => {
        messageShowHandler();
        if (Base.isEmpty(roleModel.userId)) {
            errFrameShow("error", "请先保存用戶信息");
            return;
        }
        setUserRoleModel({ defaultInd: "N", activeInd: "Y" });
        setIsRoleFrom(true);
    }

    const closeModal = (flag) => {
        if (flag) {
            errFrameShow("success", "保存成功");
            searchSysUserRoleList(roleModel);
        }
        setIsRoleFrom(false);
    }

    const checkHead = (value) => {
        if (value) {
            let list = value.fileList;
            if (list.find(item => item.status === "uploading")) {
                return Promise.reject("请等待图片上传完成");
            }
            if (list.find(item => item.status === "error")) {
                return Promise.reject("请先处理测试上传失败的图片");
            }
        }
        return Promise.resolve();
    }

    /**查看图片 */
    const handlePreview = (file) => {
        setPreviewImage(file.url || file.originUrl);
        setPreviewVisible(true);
    }

    //头像
    const headHandleChange = (file) => {
        let fileList = file.fileList;
        file = file.file;
        if (file.status === "removed") {
            fileList = [];
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = (e) => {
                fileList[0].originUrl = e.target.result;
                fileList[0].imgStatus = "NEW";
            }
        }
        setHeadFile(fileList);
    }

    //微信二维码
    const wechatImgChange = (file) => {
        let fileList = file.fileList;
        file = file.file;
        if (file.status === "removed") {
            fileList = [];
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = (e) => {
                fileList[fileList.length - 1].originUrl = e.target.result;
                fileList[fileList.length - 1].imgStatus = "NEW";
            }
        }
        setWechatImgFile(fileList);
    }

    //分页 页码切换变化查询
    const onChange = (currentNew, pageSizeNew) => {
        search(pageSizeNew, currentNew, "Y");
    }
    //分页 每页條數变化查询
    const onShowSizeChange = (currentNew, pageSizeNew) => {
        search(pageSizeNew, 1, "Y");
    }

    //列表选中记录
    const onSelectChange = (selectedRowKeys, selectedRows) => {
        setSelectedRowKeys(selectedRowKeys);
        setCheckboxState(selectedRows);
    }
    //清空列表选中
    const clearSelect = () => {
        setSelectedRowKeys([]);
        setCheckboxState([]);
    }

    const onSysRoleSelectChange = (selectedRowKeys, selectedRows) => {
        setSysRoleSelectedRowKeys(selectedRowKeys);
        setSysRoleCheckboxState(selectedRows);
    }
    const clearSysRoleSelect = () => {
        setSysRoleSelectedRowKeys([]);
        setSysRoleCheckboxState([]);
    }

    const errFrameShow = (style, msg) => {
        setMessage(msg);
        setMessageStyle(style);
        setMessageShow(true);
        document.documentElement.scrollTop = 0;
    }

    const messageShowHandler = () => {
        setMessage("");
        setMessageShow(false);
    }

    const columns = [
        {
            title: '账号',
            dataIndex: 'userId',
            className: 'tableWidth6',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'userId') },
        },
        {
            title: '用戶名',
            dataIndex: 'userName',
            className: 'tableWidth10',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'userName') },
        },
        {
            title: '手机号码',
            dataIndex: 'phone',
            className: 'tableWidth10',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'phone') },
        },
        {
            title: '真实姓名',
            dataIndex: 'realName',
            className: "tableWidth9",
            sorter: (a, b) => { return Base.sortMethod(a, b, 'realName') },
        },
        {
            title: '身份证号',
            dataIndex: 'docId',
            className: "tableWidth13",
            sorter: (a, b) => { return Base.sortMethod(a, b, 'docId') },
        },
        {
            title: '邮箱',
            dataIndex: 'email',
            className: 'tableWidth20',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'email') },
        },
        {
            title: '品牌公寓商',
            dataIndex: 'brandEstateInd',
            className: "tableWidth9",
            sorter: (a, b) => { return Base.sortMethod(a, b, 'brandEstateInd') },
            render: (text) => ContextHandler.getMapValue('inds', text)
        },
        {
            title: '状态',
            dataIndex: 'activeInd',
            className: 'tableWidth6',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'activeInd') },
            render: (text) => ContextHandler.getMapValue('activeMap', text)
        },
        {
            title: '操作',
            dataIndex: '',
            className: 'tableWidth18 tableRightAlignment',
            render: (text, record) => <div className='sysOperation proBrandEstateOperation'>
                <div className='sysUpdate' onClick={(e) => update(record, e)}><EditOutlined /> 修改</div>
                <div className='sysOperation' onClick={(e) => updateRole(record, e)}><EditOutlined /> 绑定身份证</div>
                <div className='sysDelete' onClick={(e) => deleteOne(record, e)}><DeleteOutlined /> 删除</div>
            </div>
        },
    ];

    const sysRoleColumns = [
        {
            title: '角色名称',
            dataIndex: 'roleName',
            className: "tableWidth25",
        },
        {
            title: '角色编码',
            dataIndex: 'roleCode',
            className: "tableWidth25",
        },
        {
            title: '登入默认角色',
            dataIndex: 'defaultInd',
            className: "tableWidth25",
            render: (text) => ContextHandler.getIndValue(text)
        },
        {
            title: '激活状态',
            dataIndex: 'activeInd',
            className: "tableWidth25",
            render: (text) => ContextHandler.getIndValue(text)
        },
    ]

    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
        type: 'radio',
    }

    const sysRoleRowSelection = {
        selectedRowKeys: sysRoleSelectedRowKeys,
        onChange: onSysRoleSelectChange,
        type: "radio"
    };

    const wechatButton = (
        <div>
            <PlusOutlined />
            <div>添加二维码</div>
        </div>
    );

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div>添加图片</div>
        </div>
    );

    return (
        <div id="container" className="template">
            <div className='breadcrumb'>
                <HomeOutlined /> / 系统管理 / <span className='breadcrumbTitle'>用户</span>
            </div>
            {/* 错误信息弹框 */}
            {messageShow ? <Message style={messageStyle} content={message} close={messageShowHandler} /> : ''}
            <div className='templateMain'>
                <div className="sysModelTitle">用戶</div>
                <Spin size="large" spinning={isLoad} tip={spinInfo}>
                    {/* 审核彈框 */}
                    <Audit visible={isAudit} auditModel={auditModel} close={auditCancel} />
                    {/* 删除弹框 */}
                    {isDeleteFrame ? <PopupFrame close={deleteFrameClose} /> : null}
                    <Tabs activeKey={tabIndex} onChange={(e) => handleSelect(e)}>
                        <TabPane key="1" tab="列表">
                            <div className='sysRow'>
                                <div className='sysCol'>
                                    <div className="sysTitles">角色名称</div>
                                    <div className="sysSerchInput">
                                        <Select className="sysSelect" value={searchModel.roleId || ''}
                                            onChange={(value) => searchChange('roleId', value)}
                                        >
                                            <Option value=""></Option>
                                            {sysRoleList.map((item) => <Option key={item.roleId} value={item.roleId}>{item.roleName}</Option>)}
                                        </Select>
                                    </div>
                                </div>
                                <div className='sysCol'>
                                    <div className="sysTitles">账号</div>
                                    <div className="sysSerchInput">
                                        <Input type="text" placeholder="账号" value={searchModel.userIds}
                                            onChange={(e) => searchChange('userIds', e.target.value)}
                                            onKeyDown={(e) => e.key === "Enter" ? search(pageSize, 1, "N", true) : null} />
                                    </div>
                                </div>
                                <div className='sysCol'>
                                    <div className="sysTitles">用戶名</div>
                                    <div className="sysSerchInput">
                                        <Input type="text" placeholder="用戶名"
                                            onChange={(e) => searchChange('userName', e.target.value)}
                                            value={searchModel.userName || ''}
                                            onKeyDown={(e) => e.key === "Enter" ? search(pageSize, 1, "N", true) : null} />
                                    </div>
                                </div>
                                <div className='sysCol'>
                                    <div className="sysTitles">手机号码</div>
                                    <div className="sysSerchInput">
                                        <Input type="text" placeholder="手机号码"
                                            onChange={(e) => searchChange('phone', e.target.value)}
                                            value={searchModel.phone || ''}
                                            onKeyDown={(e) => e.key === "Enter" ? search(pageSize, 1, "N", true) : null} />
                                    </div>
                                </div>
                                <div className='sysCol'>
                                    <div className="sysTitles">品牌公寓商</div>
                                    <div className="sysSerchInput">
                                        <Select className="sysSelect" value={searchModel.brandEstateInd || ''}
                                            onChange={(value) => searchChange('brandEstateInd', value)}>
                                            <Option value=""></Option>
                                            {context.inds.map((item) => <Option key={item.value} value={item.value}>{item.label}</Option>)}
                                        </Select>
                                    </div>
                                </div>
                                <div className='sysSearchButton'>
                                    <Button className="sysBtn btnBule" type="primary" onClick={() => search(pageSize, 1, "N", true)} hidden={Config.funcInfo.canSelect === "N"} icon={<SearchOutlined />}>查询</Button>
                                    <Button className="sysBtn" onClick={reset} icon={<RedoOutlined />} >重置</Button>
                                </div>
                            </div>

                            <div className="sysLineEight" />
                            <Space size={[5, 5]} wrap className="buttonRelative">
                                <Button className="sysBtn btnBule" type="primary" onClick={add} hidden={Config.funcInfo.canInsert === "N"} icon={<PlusCircleOutlined />}>新增</Button>
                                {tables.length == 0 ? null :
                                    <>
                                        <Button className="sysBtn btnBule" type="primary" onClick={audit} hidden={Config.funcInfo.canAudit === "N"} icon={<EyeOutlined />}>日志</Button>
                                    </>
                                }
                            </Space>
                            <div className="sysTable" id="tableOne">
                                <Table
                                    scroll={{ x: 1200 }}
                                    rowSelection={rowSelection} //选择
                                    rowKey="userId" //Key值
                                    columns={columns} //列名
                                    dataSource={tables} //数据
                                    pagination={{  //分页
                                        showSizeChanger: true,                      //是否显示可以设置几条一页的选项
                                        onChange: onChange,                  //上页下页或者跳页方法
                                        onShowSizeChange: onShowSizeChange,          //每页显示条数方法
                                        total: tablesCount,        //总共多少条数据
                                        current: current,       //当前页
                                        pageSize: pageSize,  //每页显示多少条数据
                                        defaultCurrent: 1,                           //默认当前页为1
                                        size: "small"
                                    }}
                                    onRow={(record) => {
                                        return {
                                            onClick: event => Base.selectRadioRowNew.call(this, record, 'userId', selectedRowKeys, setCheckboxState, setSelectedRowKeys),
                                        };
                                    }}
                                />
                            </div>
                        </TabPane>
                        <TabPane key="2" tab="用戶" forceRender={true}>
                            <Form form={form} onFinish={onFinish}>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">账号</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="userId"
                                            >
                                                <Input type="text" placeholder="账号" maxLength={20} disabled />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='sysCol'>
                                        <div className="redSize">注意：用户账号为系统自动生成</div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">用戶名</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="userName"
                                                rules={[
                                                    { required: true, message: '用戶名不能为空' },
                                                ]}
                                            >
                                                <Input type="text" placeholder="用戶名" maxLength={20} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    {flag === "add" ?
                                        <>
                                            <div className='sysCol'>
                                                <div className="sysParam mustFill">密码</div>
                                                <div className="sysInput">
                                                    <Form.Item
                                                        name="pwd"
                                                        rules={[
                                                            { required: true, message: '密码不能为空' },
                                                            { min: 8, message: '密码至少8位' },
                                                        ]}
                                                    >
                                                        <Input type="password" placeholder="密码" maxLength={20} />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        null
                                    }
                                    {flag === "edit" ?
                                        <>
                                            <div className='sysCol'>
                                                <div className="sysParam mustFill">用戶类型</div>
                                                <div className="sysInput">
                                                    <Form.Item
                                                        name="userType"
                                                    >
                                                        <Input type="text" disabled />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        null
                                    }
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">手机号码</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="phone"
                                                rules={[
                                                    { required: true, validator: (rule, value) => checkPhone(value) },
                                                ]}
                                                getValueFromEvent={(e) => e.target.value.replace(/\D/g, '')}
                                            >
                                                <Input type="text" placeholder="手机号码" maxLength={11} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='sysCol'>
                                        <div className="sysParam">邮箱</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="email"
                                            >
                                                <Input type="text" placeholder="邮箱" maxLength={50} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                {isflag &&
                                    <div className='sysRow'>
                                        <div className='sysCol'>
                                            <div className="sysParam">真实姓名</div>
                                            <div className="sysInput">
                                                <Form.Item
                                                    name="realName"
                                                >
                                                    <Input type="text" placeholder="真实姓名" maxLength={50} disabled />
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className='sysCol'>
                                            <div className="sysParam">身份证号</div>
                                            <div className="sysInput">
                                                <Form.Item
                                                    name="docId"
                                                >
                                                    <Input type="text" placeholder="身份证号" maxLength={20} disabled />
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">状态</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="activeInd"
                                                rules={[
                                                    { required: true, message: '状态不能为空' },
                                                ]}
                                            >
                                                <Select className="sysSelect">
                                                    <Option value=""></Option>
                                                    {context.activeMap.map((item) => <Option key={item.value} value={item.value}>{item.label}</Option>)}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='sysCol'>
                                        <div className="sysParam">沟通语言</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="language"
                                            >
                                                <Checkbox.Group options={contactLanguageList} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam">头像</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="head"
                                                rules={[
                                                    { required: false, validator: (rule, value) => checkHead(value) }
                                                ]}
                                            >
                                                <Upload
                                                    action={Config.uploadTestServer}
                                                    key={"1"}
                                                    accept=".png, .jpg, .jpeg"
                                                    listType="picture-card"
                                                    fileList={headFile}
                                                    onPreview={handlePreview}
                                                    onChange={(file) => headHandleChange(file)}>
                                                    {headFile.length >= logoNum ? null : uploadButton}
                                                </Upload>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='sysCol'>
                                        <div className="sysParam">微信二维码</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="qrCode"
                                            >
                                                <Upload
                                                    action={Config.uploadTestServer}
                                                    key={"1"}
                                                    accept=".png, .jpg, .jpeg"
                                                    listType="picture-card"
                                                    fileList={wechatImgFile}
                                                    onPreview={handlePreview}
                                                    onChange={(file) => wechatImgChange(file)}>
                                                    {wechatImgFile.length >= 1 ? null : wechatButton}
                                                </Upload>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam">WhatsApp</div>
                                        <div className="sysMinInput">
                                            <Form.Item
                                                name="areaCode"
                                            >
                                                <Select className="sysSelect minSelect" onChange={(e) => areaCodeChange(e)} placeholder="区号">
                                                    <Option value=""></Option>
                                                    {context.areaCodeMap.map((item) => <Option key={item.value} value={item.value}>{item.label}</Option>)}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                        <div className="minInput">
                                            <Form.Item
                                                name="whatsApp"
                                            >
                                                <Input type="text" placeholder="WhatsApp" maxLength={whatsAppLength} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='sysCol'>
                                        <div className="sysParam">性别</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="gender"
                                            >
                                                <Select className="sysSelect">
                                                    <Option value=""></Option>
                                                    {context.genderMap.map((item) => <Option key={item.value} value={item.value}>{item.label}</Option>)}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam">身份</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="dignity"
                                            >
                                                <Select className="sysSelect">
                                                    <Option value=""></Option>
                                                    {dignityList.map((item) => <Option key={item.code} value={item.code}>{item.parmValue1}</Option>)}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='sysCol'>
                                        <div className="sysParam">学校</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="school"
                                            >
                                                <Select className="sysSelect">
                                                    <Option value=""></Option>
                                                    {schoolList.map((item) => <Option key={item.code} value={item.code}>{item.parmValue1}</Option>)}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    {dignityValue == "WORK_PARTY" ?
                                        <div className='sysCol'>
                                            <div className="sysParam">职业</div>
                                            <div className="sysInput">
                                                <Form.Item
                                                    name="position"
                                                >
                                                    <Select className="sysSelect">
                                                        <Option value=""></Option>
                                                        {positionList.map((item) => <Option key={item.code} value={item.code}>{item.parmValue1}</Option>)}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </div>
                                        : <div className='sysCol'>
                                            <div className="sysParam">专业</div>
                                            <div className="sysInput">
                                                <Form.Item
                                                    name="major"
                                                >
                                                    <Input type="text" placeholder="专业" maxLength={30} />
                                                </Form.Item>
                                            </div>
                                        </div>
                                    }
                                    <div className='sysCol'>
                                        <div className="sysParam">品牌公寓商</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="brandEstateInd"
                                            >
                                                <Select className="sysSelect" onChange={brandEstateIndChange}>
                                                    {context.inds.map((item) => <Option key={item.value} value={item.value}>{item.label}</Option>)}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol' hidden={brandEstateInd === "N"}>
                                        <div className="sysParam">品牌名称</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="proBrandId"
                                            >
                                                <Select className="sysSelect">
                                                    <Option value=""></Option>
                                                    {brandList.map((item) => <Option key={item.proBrandId} value={item.proBrandId}>{item.brandTitle}</Option>)}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam">个人简介</div>
                                        <div className="sysInputs">
                                            <Form.Item
                                                name="userIntro"
                                            >
                                                <TextArea placeholder="个人简介" maxLength={300} rows={3} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className="sysSaveButton">
                                    <Button className="sysBtn btnBule" type="primary" disabled={isLoad} icon={<CheckCircleOutlined />} htmlType="submit" >保存</Button>
                                    <Button className="sysBtn" icon={<CloseCircleOutlined />} onClick={cancel}>取消</Button>
                                </div>
                            </Form>
                            {flag === "edit" &&
                                <div className="sysBodyRow">
                                    <div className="userRoleTitleRow">角色</div>
                                    <div className="sysBodyRow">
                                        <Button className="sysBtnTwo btnBule" type="primary" hidden={Config.funcInfo.canInsert === "N"} onClick={sysRoleAdd} icon={<PlusCircleOutlined />} >新增</Button>
                                        {Base.isNotEmpty(sysUserRoleList) ?
                                            <>
                                                <Button className="sysBtnTwo btnBule" type="primary" hidden={Config.funcInfo.canUpdate === "N"} onClick={sysRoleEdit} icon={<EditOutlined />}>修改</Button>
                                                <Button className="sysBtnTwo btnBule" type="primary" hidden={Config.funcInfo.canDelete === "N"} onClick={sysRoleDelete} icon={<DeleteOutlined />}>删除</Button>
                                                <Button className="sysBtnTwo btnBule" type="primary" onClick={auditRole} hidden={Config.funcInfo.canAudit === "N"} icon={<EyeOutlined />}>审核</Button>
                                            </> : null
                                        }
                                    </div>
                                </div>
                            }
                            {flag === "edit" &&
                                <div className="packing-table tableOne tabWidth" id="tableOne">
                                    <Table
                                        scroll={{ x: 800 }}
                                        rowSelection={sysRoleRowSelection} //选择
                                        rowKey="userRoleId" //Key值
                                        columns={sysRoleColumns} //列名
                                        dataSource={sysUserRoleList} //数据
                                        pagination={false}
                                        onRow={(record) => {
                                            return {
                                                onClick: event => Base.selectRadioRowNew.call(this, record, 'userRoleId', sysRoleSelectedRowKeys, setSysRoleCheckboxState, setSysRoleSelectedRowKeys),
                                            };
                                        }}
                                    />
                                </div>
                            }
                        </TabPane>
                        <TabPane key="3" tab="绑定身份证" forceRender={true}>
                            <Form form={form2} onFinish={onFinish2}>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">用户账号</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="userId"
                                            >
                                                <Input type="text" placeholder="用户账号" maxLength={20} disabled />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='sysCol'>
                                        <div className="sysParam">用户名</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="userName"
                                                rules={[
                                                    { required: true, message: '用户名不能为空' },
                                                ]}
                                            >
                                                <Input type="text" placeholder="用户名" maxLength={20} disabled />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam">真实姓名</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="realName"
                                                rules={[
                                                    { required: false, message: '真实姓名不能为空' },
                                                ]}
                                            >
                                                <Input type="text" placeholder="真实姓名" maxLength={50} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='sysCol'>
                                        <div className="sysParam">身份证号</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="docId"
                                                rules={[
                                                    { required: false, message: '身份证号不能为空' },
                                                ]}
                                            >
                                                <Input type="text" placeholder="身份证号" maxLength={20} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className="sysSaveButton">
                                    <Button className="sysBtn btnBule" type="primary" disabled={isLoad} icon={<CheckCircleOutlined />} htmlType="submit">保存</Button>
                                    <Button className="sysBtn" icon={<CloseCircleOutlined />} onClick={cancel}>取消</Button>
                                </div>
                            </Form>
                            <Modal title="放大图" visible={previewVisible} footer={null} onCancel={() => setPreviewVisible(false)}>
                                <img alt="example" style={{ width: '100%' }} src={previewImage} />
                            </Modal>
                        </TabPane>
                    </Tabs>
                </Spin>
            </div>
            {/* 用户的角色添加编辑弹框 */}
            {isRoleFrom ? <RoleFromModal onClose={closeModal} phone={roleModel.phone} userId={roleModel.userId} model={userRoleModel} /> : null}
        </div>
    )

}

export default SysUser;