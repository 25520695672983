import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Button, Checkbox, Spin, Form, DatePicker, Input, Select, Row, Col } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import moment from 'moment';//日期格式化导包

import { Config } from '../../public/js/config';
import Message from '../../component/message/message';//提示信息弹框
import { Base } from '../../public/js/base';
import context from '../../public/js/context';

const { Option } = Select;

const ChangeUserPassword = (props) => {
    
    const history = useHistory();

    const [form] = Form.useForm();
    const [isLoad, setIsLoad] = useState(false);
    const [spinInfo, setSpinInfo] = useState("");
    const [messageShow, setMessageShow] = useState(false);
    const [messageStyle, setMessageStyle] = useState("");
    const [message, setMessage] = useState("");
    const [oldModel, setOldModel] = useState({});
    const [countryDownList, setCountryDownList] = useState([]);
    const [checkboxValue, setCheckboxValue] = useState(false);

    useEffect(() => {
        searchUserInfoByCode();
    }, []);

    /**查詢用戶信息列表 方法 */
    const searchUserInfoByCode = () => {
        setIsLoad(true);
        setSpinInfo("查询中...");
        Base.callBackApi("user/searchUserInfoById", { userId: Config.userInfo.userId }).then((data) => {
            if ("SUCCESS" === data.msg) {
                initForm(data.object);
            } else {
                errFrameShow("danger", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }

    const initForm = (model = {}) => {
        form.resetFields();
        form.setFieldsValue({
            userId: model.userId ?? '',
            userName: model.userName ?? '',
            oldPwd: '',
            newPwd: '',
            pwd: '',
        });
        setOldModel(model);
    }

    const onFinish = (values) => {
        messageShowHandler();
        setIsLoad(true);
        setSpinInfo("数据保存中...")
        let model = { ...oldModel, ...values };
        model.menuFuncId = Config.funcInfo.funcId;
        Base.callBackApi("user/changePwd", model).then((data) => {
            if ("SUCCESS" === data.msg) {
                initForm(data.object);
                errFrameShow("success", "保存成功");
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }

    
    const checkPhone = (value) => {
        if(value) {
            if(/^1[3456879]\d{9}$/.test(value)) {
                return Promise.resolve();
            }
            return Promise.reject(new Error("请输入正确的手机号"));
        }
        return Promise.reject(new Error("手机号不能为空"));
    }


    const cancel = () => {
        history.push('/main/default');
    }

    const errFrameShow = (style, msg) => {
        setMessage(msg);
        setMessageStyle(style);
        setMessageShow(true);
        document.documentElement.scrollTop = 0;
    }

    const messageShowHandler = () => {
        setMessage("");
        setMessageShow(false);
    }
    return (
        <div id="container" className="container">
            <div className="sysModelTitle">修改密码</div>
            
            <div>
                {/* 错误信息弹框 */}
                {messageShow ? <Message style={messageStyle} content={message} close={messageShowHandler}/> : ''}
                <Spin size="large" spinning={isLoad} tip={spinInfo}>
                    <Form form={form} onFinish={onFinish}>
                        <div className='sysRow' style={{ marginTop: "25px" }}>
                            <div className='sysCol'>
                                <div className="sysParam mustFill">原密码</div>
                                <div className="sysInput">
                                    <Form.Item
                                        name="oldPwd"
                                        rules={[
                                            { required: true, message: '原密码不能为空' }
                                        ]}
                                    >
                                        <Input type="password" placeholder="原密码" maxLength={20}/>
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                        <div className='sysRow'>
                            <div className='sysCol'>
                                <div className="sysParam mustFill">新密码</div>
                                <div className="sysInput">
                                    <Form.Item
                                        name="newPwd"
                                        rules={[
                                            { required: true, message: '新密码不能为空' },
                                            { min: 8, message: "新密码至少8位" },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (Base.isNotEmpty(getFieldValue('oldPwd')) && Base.isNotEmpty(value) && value.length >= 8) {
                                                        if (getFieldValue('oldPwd') === value) {
                                                            return Promise.reject(new Error('新密码不能和原密码一致'));
                                                        }
                                                    }
                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input type="password" placeholder="新密码" maxLength={20}/>
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                        <div className='sysRow'>
                            <div className='sysCol'>
                                <div className="sysParam mustFill">确认密码</div>
                                <div className="sysInput">
                                    <Form.Item
                                        name="pwd"
                                        dependencies={['newPwd']}
                                        rules={[
                                            { required: true, message: '确认密码不能为空' },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue('newPwd') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error('确认密码和新密码不一致'));
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input type="password" placeholder="确认密码" maxLength={20}/>
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                        <div className='sysSearchButton'>
                            <Button className="sysBtn btnBule" type="primary" disabled={isLoad} icon={<CheckCircleOutlined />} htmlType="submit" >保存</Button>
                            <Button className="sysBtn" icon={<CloseCircleOutlined />} onClick={cancel}>取消</Button>
                        </div>
                    </Form>
                </Spin>
            </div>
        </div>
    )

}

export default ChangeUserPassword;