/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import { Tabs, Row, Table, Button, Form, Input, Select, Switch, Checkbox, Spin, InputNumber, Space } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined,HomeOutlined, SearchOutlined, RedoOutlined, PlusCircleOutlined, EditOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import Message from '../../component/message/message';
import PropTypes from 'prop-types';//props传参检查库
import PopupFrame from '../../component/popupFrame/popupFrame';
import Audit from '../../component/audit/audit';//审核彈框
import { Config } from '../../public/js/config';
import { Base } from '../../public/js/base';
import { ContextHandler } from '../../public/js/contextHandler';
import context from '../../public/js/context';

const { TabPane } = Tabs;
const { TextArea } = Input;
const { Option } = Select;

const SysRole = (props) => {
    const [form] = Form.useForm();
    const [tabIndex, setTabIndex] = useState("1");
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [checkboxState, setCheckboxState] = useState([]);
    const [messageShow, setMessageShow] = useState(false);
    const [messageStyle, setMessageStyle] = useState("error");
    const [message, setMessage] = useState("");
    const [flag, setFlag] = useState("add");
    const [isAudit, setIsAudit] = useState(false);
    const [isDeleteFrame, setIsDeleteFrame] = useState(false);
    const [tables, setTables] = useState([]);
    const [tablesCount, setTablesCount] = useState(0);
    const [current, setCurrent] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [searchModel, setSearchModel] = useState({});
    const [oldSearchModel, setOldSearchModel] = useState({});
    const [auditModel, setAuditModel] = useState({});
    const [funcList, setFuncList] = useState([]);
    const [isLoad, setIsLoad] = useState(false);
    const [spinInfo, setSpinInfo] = useState("");
    const [isLeave, setIsLeave] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        search(pageSize, 1, "N");
    },[]);

    const search = (pageSizeNew, current, flag, isShowMsg) => {
        if(Config.funcInfo.canSelect === "N") {
            return;
        }
        messageShowHandler();
        clearSelect();//清除選中狀態
        let model = {};
        if ("Y" === flag) {//翻頁
            model = oldSearchModel;
        } else {
            model = searchModel;
        }
        model.pageSize = pageSizeNew;
        model.page = current;
        setIsLoad(true);
        setSpinInfo("查询中...");
        Base.callBackApi("role/search", model).then((data) => {
            if ("SUCCESS" === data.msg) {
                if (!data.object.list && isShowMsg) {
                    errFrameShow("info", '找不到符合条件记录.');
                }
                if("N" === flag) {
                    setOldSearchModel(JSON.parse(JSON.stringify(model)));
                } 
                setTablesCount(data?.object?.total ?? 0);
                setTables(data?.object?.list ?? []);
                setCurrent(current);
                setPageSize(pageSizeNew);
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }

    const searchAllFunc = () => {
        setIsLoading(true);
        Base.callBackApi("role/searchAllFunc").then((data) => {
            if ("SUCCESS" === data.msg) {
                setFuncList(data.object);
            } else {
                this.errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoading(false));
    }

    const searchChange = (key, value) => {
        messageShowHandler();
        setSearchModel(model =>{
            return {...model,...{[key]:value}};
        });
    }
    
    const reset = () => {
        messageShowHandler();
        clearSelect();
        setSearchModel({});
        setOldSearchModel({});
        setTablesCount(0);
        setTables([]);
        setPageSize(10);
    }

    // Tab页切换
    const handleSelect = (key) => {
        if (key) {
            if(key === "2") {
                if(checkboxState.length === 1 && Config.funcInfo.canUpdate === "Y") {
                    edit();
                } else if(Config.funcInfo.canInsert === "Y") {
                    add();
                }
            } else if(tabIndex !== "1") {
                setTabIndex("1");
            }
        }
    }

    const add = () => {
        messageShowHandler();
        initForm({ activeInd: 'Y' });
        searchAllFunc();
        setFlag("add");
        setTabIndex("2");
    }

    const update = (model, e) => {
        e.stopPropagation();
        initForm(model);
        setCheckboxState([model]);
        setSelectedRowKeys([model.roleId]);
        setIsLoading(true);
        Base.callBackApi("role/searchRoleFunc", model).then((data) => {
            if ("SUCCESS" === data.msg) {
                setFlag("edit");
                setTabIndex("2");
                setFuncList(data.object);
            } else {
                this.errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoading(false));
    }


    const edit = () => {
        messageShowHandler();
        if(!selectedRowKeys || selectedRowKeys.length !== 1) {
            errFrameShow("error", "请选择一条数据");
            return;
        }
        let model = checkboxState[0];
        initForm(model);
        setIsLoading(true);
        Base.callBackApi("role/searchRoleFunc", model).then((data) => {
            if ("SUCCESS" === data.msg) {
                setFlag("edit");
                setTabIndex("2");
                setFuncList(data.object);
            } else {
                this.errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoading(false));
    }

    const initForm = (model = {}) => {
        form.resetFields();
        form.setFieldsValue({
            roleCode: model.roleCode ?? "",
            roleName: model.roleName ?? "",
            activeInd: model.activeInd ?? "",
            dispSeq: model.dispSeq ?? "",
            remark: model.remark ?? "",
        });
    }

    const deleteFrameClose = (state) => {
        if(state) {
            deleteData();
        }
        clearSelect();
        setIsDeleteFrame(false);
    }

    const deleteData = () => {
        setIsLoad(true);
        setSpinInfo("数据保存中...");
        let deleteModel = checkboxState[0];
        deleteModel.menuFuncId = Config.funcInfo.funcId;
        Base.callBackApi("role/delete", deleteModel).then((data) => {
            if ("SUCCESS" === data.msg) {
                cancel();
                search(pageSize, current, "Y", false);
                errFrameShow("success", "刪除成功");
                props.searchMenu();//刷新menu
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }

    const audit = () => {
        messageShowHandler();//清空提示信息
        if(!checkboxState || checkboxState.length !== 1) {
            errFrameShow("error", "请选择一条数据");
            return;
        }
        setAuditModel(checkboxState[0]);
        setIsAudit(true);
    }

    const auditCancel = () => {
        setIsAudit(false);
    }

    const onFinish = (values) => {
        messageShowHandler();
        setIsLoad(true);
        setSpinInfo("数据保存中...")
        let list = [];
        for (let item of funcList) {
            if (item.children != null) {
                for (let iterator of item.children) {
                    list.push(iterator);
                    if (Base.isNotEmpty(iterator.children)) {
                        for (let subFunc of iterator.children) {
                            list.push(subFunc);
                            console.log(111);
                        }
                    }
                }
            }
            list.push(item);
        }
        values.funcList = list;
        let url = "";
        if(flag === "add") {
            url = "role/insert";
        } else {
            url = "role/update"
            values.roleId = selectedRowKeys[0];
            values.lastUpdatedDate = checkboxState[0].lastUpdatedDate;
        }
        values.menuFuncId = Config.funcInfo.funcId;
        values.roleCode = values.roleCode.toLocaleUpperCase();
        Base.callBackApi(url, values).then((data) => {
            if ("SUCCESS" === data.msg) {
                cancel();
                search(pageSize, current, "Y", false);
                errFrameShow("success", "保存成功");
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => {
            setIsLoad(false);
            props.searchMenu();
        });
    }

    const cancel = () => {
        messageShowHandler();
        clearSelect();
        setTabIndex("1");
        setFuncList([]);
    }

    const leaveFrameClose = (state) => {
        if(state) {
            cancel();
        }
        setIsLeave(false);
    }

    const deleteOne = (model, e) => {
        e.stopPropagation();
        setCheckboxState([model]);
        setSelectedRowKeys([model.roleId]);
        setIsDeleteFrame(true);
    }

    const checkboxChange = (record, index, flag, value) => {
        if (value) {
            record[flag] = "Y";
            if (flag === "activeInd" && Base.isEmpty(record.children)) {
                record['canSelect'] = "Y";
                record['canInsert'] = "Y";
                record['canUpdate'] = "Y";
                record['canDelete'] = "Y";
                record['canAudit'] = "Y";
                record['canView'] = "Y";
                record['canUpload'] = "Y";
                record['canDownload'] = "Y";
            }
        } else {
            record[flag] = "N"
            if (flag === "activeInd" && Base.isEmpty(record.children)) {
                record['canSelect'] = "N";
                record['canInsert'] = "N";
                record['canUpdate'] = "N";
                record['canDelete'] = "N";
                record['canAudit'] = "N";
                record['canView'] = "N";
                record['canUpload'] = "N";
                record['canDownload'] = "N";
            }
        }
        setFuncList(JSON.parse(JSON.stringify(funcList)));
    }

    //分页 页码切换变化查询
    const onChange = (currentNew, pageSizeNew) => {
        search(pageSizeNew, currentNew, "Y");
    }
    //分页 每页條數变化查询
    const onShowSizeChange = (currentNew, pageSizeNew) => {
        search(pageSizeNew, 1, "Y");
    }

    //列表选中记录
    const onSelectChange = (selectedRowKeys, selectedRows) => {
        setSelectedRowKeys(selectedRowKeys);
        setCheckboxState(selectedRows);
    }
    //清空列表选中
    const clearSelect = () => {
        setSelectedRowKeys([]);
        setCheckboxState([]);
    }

    const errFrameShow = (style, msg) => {
        setMessage(msg);
        setMessageStyle(style);
        setMessageShow(true);
        document.documentElement.scrollTop = 0;
    }

    const messageShowHandler = () => {
        setMessage("");
        setMessageShow(false);
    }
    
    /*eslint-disable*/
    const columns = [
        {
            title: '角色编号',
            dataIndex: 'roleCode',
            className: 'tableWidth15',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'roleCode') },
        },
        {
            title: '角色名称',
            dataIndex: 'roleName',
            className: 'tableWidth15',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'roleName') },
        },
        {
            title: '备注',
            dataIndex: 'remark',
            className: 'tableWidth30',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'remark') },
        },
        {
            title: '激活状态',
            dataIndex: 'activeInd',
            className: 'tableWidth15',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'activeInd') },
            render: (text) => ContextHandler.getMapValue("inds", text)
        },
        {
            title: '序号',
            dataIndex: 'dispSeq',
            className: 'tableWidth15',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'dispSeq') },
        },
        {
            title: '操作',
            dataIndex: '',
            className: 'tableWidth10 tableRightAlignment',
            render: (text, record) => <div className='sysOperation proBrandEstateOperation'>
                <div className='sysUpdate' onClick={(e) => update(record, e)}><EditOutlined /> 修改</div>
                <div className='sysDelete' onClick={(e) => deleteOne(record, e)}><DeleteOutlined /> 删除</div>
            </div>
        },
    ];
    const funcColumns = [
        {
            title: '模块名称',
            dataIndex: 'funcName',
            className: "tableWidth20",
        },
        {
            title: '模块类型',
            dataIndex: 'type',
            className: "tableWidth10",
            render: (text) => ContextHandler.getMapValue('funcTypeMap', text)
        },
        {
            title: '激活',
            dataIndex: 'activeInd',
            className: "tableWidth10",
            render: (text, record, index) => {
                return (
                    <Switch checked={text === "Y"} onChange={(e) => checkboxChange(record, index, "activeInd", e)} />
                );
            }
        },
        {
            title: '操作',
            className: "tableWidth50 tableRightAlignment",
            sorter: (a, b) => { return Base.sortMethod(a, b, 'dispSeq') },
            render: (text, record, index) => {
                return (
                    Base.isNotEmpty(record.children) || record.type === "MENU" ? "" :
                        <>
                            <Checkbox key="canSelect" checked={record.canSelect == "Y"} onChange={(e) => checkboxChange(record, index, "canSelect", e.target.checked)}>查询</Checkbox>
                            <Checkbox key="canInsert" checked={record.canInsert == "Y"} onChange={(e) => checkboxChange(record, index, "canInsert", e.target.checked)}>添加</Checkbox>
                            <Checkbox key="canUpdate" checked={record.canUpdate == "Y"} onChange={(e) => checkboxChange(record, index, "canUpdate", e.target.checked)}>修改</Checkbox>
                            <Checkbox key="canDelete" checked={record.canDelete == "Y"} onChange={(e) => checkboxChange(record, index, "canDelete", e.target.checked)}>刪除</Checkbox>
                            <Checkbox key="canAudit" checked={record.canAudit == "Y"} onChange={(e) => checkboxChange(record, index, "canAudit", e.target.checked)}>审核</Checkbox>
                            <Checkbox key="canView" checked={record.canView == "Y"} onChange={(e) => checkboxChange(record, index, "canView", e.target.checked)}>查看</Checkbox>
                            <Checkbox key="canUpload" checked={record.canUpload == "Y"} onChange={(e) => checkboxChange(record, index, "canUpload", e.target.checked)}>上传</Checkbox>
                            <Checkbox key="canDownload" checked={record.canDownload == "Y"} onChange={(e) => checkboxChange(record, index, "canDownload", e.target.checked)}>下载</Checkbox>
                        </>

                )
            }
        },
    ];
    /*eslint-enable*/

    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
        type: 'radio',
    }
    
    return (
        <div id="container" className="template">
            <div className='breadcrumb'>
                <HomeOutlined /> / 系统管理 / <span className='breadcrumbTitle'>角色</span>
            </div>
            {/* 提示信息弹框 */}
            {messageShow ? <Message style={messageStyle} content={message} close={messageShowHandler} /> : ''}
            <div className='templateMain'>
                <div className="sysModelTitle">角色</div>
                <Spin size="large" spinning={isLoad} tip={spinInfo}>
                    {/* 删除弹框 */}
                    {isDeleteFrame ? <PopupFrame close={deleteFrameClose} /> : null}
                    {isLeave ? <PopupFrame close={leaveFrameClose} type='leave' /> : null}
                    {/* 审核彈框 */}
                    <Audit visible={isAudit} auditModel={auditModel} close={auditCancel} />
                    <Tabs activeKey={tabIndex} onChange={(e) => handleSelect(e)}>
                        <TabPane key="1" tab="列表">
                            <div className='sysRow'>
                                <div className='sysCol'>
                                    <div className="sysTitle">角色编号</div>
                                    <div className="sysSerchInput">
                                        <Input type="text" placeholder="角色编号" value={searchModel.roleCode || ''}
                                            onKeyDown={(e) => e.key === "Enter" ? search(pageSize, 1, "N", true) : null}
                                            onChange={(e) => searchChange('roleCode', e.target.value)} />
                                    </div>
                                </div>
                                <div className='sysCol'>
                                    <div className="sysTitle">角色名称</div>
                                    <div className="sysSerchInput">
                                        <Input type="text" placeholder="角色名称" value={searchModel.roleName || ''}
                                            onKeyDown={(e) => e.key === "Enter" ? search(pageSize, 1, "N", true) : null}
                                            onChange={(e) => searchChange('roleName', e.target.value)} />
                                    </div>
                                </div>
                                <div className='sysSearchButton'>
                                    <Button className="sysBtn btnBule" type="primary" onClick={() => search(pageSize, 1, "N", true)} hidden={Config.funcInfo.canSelect === "N"} icon={<SearchOutlined />}>查询</Button>
                                    <Button className="sysBtn" onClick={reset} icon={<RedoOutlined />}>重置</Button>
                                </div>
                            </div>
                            <div className="sysLineEight"></div>
                            <Space size={[5, 5]} wrap className="sysButtonRelative">
                                <Button type="primary" className="sysBtn btnBule" onClick={add} hidden={Config.funcInfo.canInsert === "N"} icon={<PlusCircleOutlined />}>新增</Button>
                                {tables.length == 0 ? null :
                                    <>
                                        <Button type="primary" className="sysBtn btnBule" onClick={audit} hidden={Config.funcInfo.canAudit === "N"} icon={<EyeOutlined />}>日志</Button>
                                    </>
                                }
                            </Space>
                                <div className="sysTable" id="tableOne">
                                    <Table
                                        scroll={{ x: 1200 }}
                                        rowSelection={rowSelection} //选择
                                        rowKey="roleId" //Key值
                                        columns={columns} //列名
                                        dataSource={tables} //数据
                                        pagination={{  //分页
                                            showSizeChanger: true,                      //是否显示可以设置几条一页的选项
                                            onChange: onChange,                  //上页下页或者跳页方法
                                            onShowSizeChange: onShowSizeChange,          //每页显示条数方法
                                            total: tablesCount,        //总共多少条数据
                                            current: current,       //当前页
                                            pageSize: pageSize,  //每页显示多少条数据
                                            defaultCurrent: 1,                           //默认当前页为1
                                            size:"small"
                                        }}
                                        onRow={(record) => {
                                            return {
                                                onClick: event => Base.selectRadioRowNew.call(this, record, 'roleId', selectedRowKeys, setCheckboxState, setSelectedRowKeys),
                                            };
                                        }}
                                    />
                                </div>
                        </TabPane>
                        <TabPane key="2" tab="用戶" forceRender={true}>
                            <Form form={form} onFinish={onFinish}>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">角色编号</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="roleCode"
                                                rules={[
                                                    { required: true, message: '角色编号不能为空' },
                                                ]}
                                                getValueFromEvent={(e) => Base.codeChange(e, 20)}
                                            >
                                                <Input type="text" placeholder="角色编号" maxLength={20} disabled={flag === "edit"}/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">角色名称</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="roleName"
                                                rules={[
                                                    { required: true, message: '角色名称不能为空' },
                                                ]}
                                            >
                                                <Input type="text" placeholder="角色名称" maxLength={20}/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">激活状态</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="activeInd"
                                                rules={[
                                                    { required: true, message: '激活状态不能为空' },
                                                ]}
                                            >
                                                <Select className='sysSelect'>
                                                    <Option value=""></Option>
                                                    {context.inds.map((item) => <Option key={item.value} value={item.value}>{item.label}</Option>)}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">序号</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="dispSeq"
                                                rules={[
                                                    { required: true, validator: (rule, value) => Base.checkNbrOrNull(value, null, 999999999) },
                                                ]}
                                            >
                                                <InputNumber placeholder="序号" precision={0} maxLength={9} className="sysRoleInputNumber"/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam">备注</div>
                                        <div className="sysInputs">
                                            <Form.Item
                                                name="remark"
                                            >
                                                <TextArea placeholder="备注" maxLength={30} rows = {3} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <Row className="sysRoleBodyRow TabsTwo">
                                    {funcList ? 
                                        <Table
                                            rowKey="funcId"
                                            defaultExpandAllRows={true}
                                            style={{ width: "1227px" }}
                                            loading={{ spinning: isLoading, tip: "加载中..." }}
                                            columns={funcColumns} //列名
                                            dataSource={funcList} //数据
                                            pagination={false}
                                        />
                                        : ""
                                    }
                                </Row>
                                <div className="sysSaveButton">
                                    <Button className="sysBtn btnBule" type="primary" disabled={isLoad} icon={<CheckCircleOutlined />} htmlType="submit">保存</Button>
                                    <Button className="sysBtn" icon={<CloseCircleOutlined />} onClick={cancel}>取消</Button>
                                </div>
                            </Form>
                        </TabPane>
                    </Tabs>
                </Spin>
            </div>
        </div>
    )

}

SysRole.propTypes = {
    searchMenu: PropTypes.func.isRequired,
}

export default SysRole;