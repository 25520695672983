export class Config {

    //本地环境
    // static apiServer = "http://192.168.0.144:9993/";
    // static imgServer= 'https://gangye-1319407761.cos.ap-guangzhou.myqcloud.com/test/';

    // //线网
    static apiServer = "https://api.gangyeah.com/back_api/";
    static imgServer= 'https://gangye-1319407761.cos.ap-guangzhou.myqcloud.com/gangye/';

    static apiBackServer = Config.apiServer + "back/";
    static apiDownloadServer = Config.apiServer + "download/";

    static funcImg = Config.imgServer + "sysFunc/original/"; //模块图片路径图
    static productVideo = Config.imgServer + "product/video/";//商品视频
    static pictureScrollImg = Config.imgServer + "pictureScroll/original/";//图片管理模块原图路径
    static pictureScrollPath = Config.imgServer + "pictureScroll/thumbnail/";//图片管理模块缩略图路径
    static productImg = Config.imgServer + "product/original/";//商品原图
    static productPath = Config.imgServer + "product/thumbnail/";//商品缩略图
    static chatImg = Config.imgServer + "chat/original/";//客服聊天原图
    static chatPath = Config.imgServer + "chat/thumbnail/";//客服聊天缩略图
    static imgServerHeadPortraitImgs = Config.imgServer + "headPortrait/original/";//用户头像
    static apartmentImg = Config.imgServer + "apartment/original/";//公寓原图
    static apartmentPath = Config.imgServer + "apartment/thumbnail/";//公寓缩略图
    static apartmentVideo = Config.imgServer + "apartment/video/";//公寓视频
    static aptRoomImg = Config.imgServer + "apartmentRoom/original/";//公寓房间原图
    static aptRoomPath = Config.imgServer + "apartmentRoom/thumbnail/";//公寓房间缩略图
    static aptRoomVideo = Config.imgServer + "apartmentRoom/video/";//公寓房间视频
    static proBrandPath = Config.imgServer + "brand/thumbnail/";//品牌原图
    static proBrandImg = Config.imgServer + "brand/original/";//品牌原图
    static proEstatePath = Config.imgServer + "estate/thumbnail/";//品牌原图
    static proEstateImg = Config.imgServer + "estate/original/";//品牌原图
    static proBrandEstateRoomPath = Config.imgServer + "brandEstateRoom/thumbnail/";//房间缩略图
    static proBrandEstateRoomImg = Config.imgServer + "brandEstateRoom/original/";//房间原图
    static proHouseSourceImg = Config.imgServer + "wholeRentHouseSouce/original/";//整租原图
    static proHouseSourceVideo = Config.imgServer + "wholeRentHouseSouce/video/";//整租视频
    static companyLogo = Config.imgServer + "companyLogo/original/";//公司logo图片
    static weChatBusinessCard = Config.imgServer + "wechatBusinessCard/original/";//微信名片图片
    static propertyAgent = Config.imgServer + "propertyAgent/original/";//代理公司图片
    static hkLifeGuideImg = Config.imgServer + "hkLifeGuideImg/original/";//香港生活指南图片
    static secondHandImg = Config.imgServer + "secondHandImg/original/";//二手物品原图
    static secondHandPath = Config.imgServer + "secondHandImg/thumbnail/";//二手物品缩略图

    static uploadTestServer = Config.apiServer + "test/imageUpload";//上传测试地址
    // static websockedServer = Config.websockedApi + "websocket/";//聊天通信地址

    static header = {
        'authorization': "",
        'Content-Type': 'application/json;charset=UTF-8'
    };

    static headers = {
        'Content-Type': 'application/json;charset=UTF-8'
    };

    //PC登录人信息
    static userInfo = {
        token: "",
        userId: "",
        userName: "",

        defaultUserRoleId: "",
        defaultRoleId: "",
        roleCode: "",
        roleName: "",
    };

    //登录人信息
    static sysUserRoleList = [];

    //模块权限信息--控制按钮显示
    static funcInfo = {
        canSelect: 'N',
        canInsert: 'N',
        canUpdate: 'N',
        canDelete: 'N',
        canAudit: 'N',
        canView: 'N',
        canUpload: 'N',
        canDownload: 'N',
    };

    static openKeys = [];//默认展开的menu的Key集合

    // 訪問設備信息
    static equipmentInfo = {
        type: 'pc', //默认是pc(设备类型phone/iPad/pc)
        name: null,
        isWx: null,
    };
    static years = 'YYYY'; //年格式化样式
    static dateFormat = 'YYYY-MM-DD'; //日期格式化样式
    static dateFormatItem2 = 'YYYY-MM-DD HH:mm'; //日期格式化样式时分秒
    static dateFormatALL = 'YYYY-MM-DD HH:mm:ss'; //日期格式化样式
    static dateFormat3 = '_YYYYMMDDHHmmssSSS'; //日期格式化样式
    static dateFormat4 = '_YYYYMMDD'; //日期格式化样式
    static dateFormat5 = 'YYYY/MM/DD'; //日期格式化样式
    static allCnDate = 'YYYY年MM月DD日 HH:mm:ss';

    static ckEditorConfig = {
        toolbar: {
            items: [
                'sourceEditing',
                '|','undo','redo',
                '|','heading',
                '|','findandReplace','alignment','bold','italic','underline','code',
                'horizontalLine','removeformat','link','strikethrough','subscript','superscript','blockQuote','specialCharacters',
                '|','FontSize','FontColor','FontBackgroundColor','FontFamily','highlight',
                '|','numberedList','bulletedList','todoList','outdent','indent','pageBreak',
                '|','insertImage'//,'cKFinder'
                ,'insertTable','mediaEmbed',
            ]
        },
        //设置字体
        fontFamily: {
            options: [
                'default',
                'Blackoak Std',
                '宋体,SimSun',
                '新宋体,NSimSun',
                '微软雅黑,Microsoft YaHei',
                '楷体_GB2312,KaiTi_GB2312',
                '隶书,LiSu',
                '幼园,YouYuan',
                '华文细黑,STXihei',
            ]
        },
        image: {
            styles: [
                'full', 'alignLeft', 'alignCenter', 'alignRight'
            ],
            resizeOptions: [
                {
                    name: 'resizeImage:原尺寸',
                    label: '原尺寸',
                    value: null
                },
                {
                    name: 'resizeImage:25',
                    label: '25%',
                    value: '25'
                },
                {
                    name: 'resizeImage:50',
                    label: '50%',
                    value: '50'
                },
                {
                    name: 'resizeImage:75',
                    label: '75%',
                    value: '75'
                }
            ],
            toolbar: [
                // 'imageStyle:full',
                // 'imageStyle:side',
                'imageStyle:alignLeft',
                'imageStyle:alignCenter',
                'imageStyle:alignRight',
                '|',
                'resizeImage',
                '|',
                'toggleImageCaption',
                'imageTextAlternative',
                'linkImage'
            ],
        },
        table: {
            contentToolbar: [
                'tableColumn',
                'tableRow',
                'mergeTableCells',
                'tableCellProperties',
                'tableProperties'
            ]
        },
        simpleUpload: {
            uploadUrl: Config.apiServer + "ckEditorUploadImg/uploadImg",
            headers: {
                Authorization: Config.header.authorization
            }
        },
    };
    /**
     * 定位信息 切记一定要https部署才能体验到真实数据
    */
    static locationModel={
        detailAddress: null, //省市区
        address: null,       //详细地址
        signModel:{         //标记数据源
            detailAddress: null,
            address: null,
            lat: null,
            lng: null
        },
    }
}