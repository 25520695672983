import ImgCrop from 'antd-img-crop';
import React, { useState, useEffect } from 'react';
import { Button, Table, Form, InputNumber, Spin, Select, Input, Tabs, Space, Modal, Upload, Switch, Cascader, Checkbox ,DatePicker ,Tooltip ,Image} from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, SearchOutlined, RedoOutlined, PlusCircleOutlined, EditOutlined, DeleteOutlined, EyeOutlined, PlusOutlined, HomeOutlined } from '@ant-design/icons';
import Message from '../../component/message/message';//提示信息弹框
import PopupFrame from '../../component/popupFrame/popupFrame';//删除弹框
import Audit from '../../component/audit/audit';//审核彈框
import { Config } from '../../public/js/config';
import { Base } from '../../public/js/base';
import ReactPlayer from 'react-player';
import context from '../../public/js/context';
import moment from 'moment';//日期格式化导包
import SelectCommunity from './selectCommunity';
import './wholeRentHouseSouce.css';
import SelectUser from './selectUser';

const { TabPane } = Tabs;
const { Option } = Select;
const { TextArea } = Input;

const WholeRentHouseSouce = (props) => {

    const [form] = Form.useForm();
    const [isLoad, setIsLoad] = useState(false);
    const [spinInfo, setSpinInfo] = useState("");
    const [isAudit, setIsAudit] = useState(false);
    const [auditModel, setAuditModel] = useState({});
    const [messageShow, setMessageShow] = useState(false);
    const [messageStyle, setMessageStyle] = useState("");
    const [message, setMessage] = useState("");
    const [tabIndex, setTabIndex] = useState("1");
    const [searchModel, setSearchModel] = useState({status: "Y"});
    const [oldSearchModel, setOldSearchModel] = useState({});
    const [tables, setTables] = useState([]);
    const [tablesCount, setTablesCount] = useState(0);
    const [current, setCurrent] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [checkboxState, setCheckboxState] = useState([]);
    const [type, setType] = useState("");
    const [content, setContent] = useState("");
    const [frameType, setFrameType] = useState("");
    const [isPopupFrame, setIsPopupFrame] = useState(false);
    const [flag, setFlag] = useState("");
    const [imageFile, setImageFile] = useState([]);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);
    const [videoFile, setVideoFile] = useState([]);
    const [previewVideoVisible, setPreviewVideoVisible] = useState(false);
    const [previewVideo, setPreviewVideo] = useState(null);
    const [options, setOptions] = useState([]);//地区
    const [categoryList,setCategoryList] = useState([]);//区域id  
    const [isCommunity, setIsCommunity] = useState(false);
    const [communityModel, setCommunityModel] = useState({});
    const [isUser, setIsUser] = useState(false);
    const [userModel, setUserModel] = useState({});
    const [userId, setUserId] = useState('');
    const [propertyModel, setPropertyModel] = useState({});
    const rent = Form.useWatch('rent', form);
    const usableArea = Form.useWatch('usableArea', form);
    const houseName = Form.useWatch('houseName', form);
    const houseName2 = Form.useWatch('houseName2', form);
    const tenancyList = [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        24,
        36
    ];
    const [houseTagList, setHouseTagList] = useState([
        { label: "有装修", value: "有装修" },
		{ label: "连套房", value: "连套房" },
		{ label: "雪柜", value: "雪柜" },
		{ label: "床", value: "床" },
		{ label: "桌椅", value: "桌椅" },
		{ label: "空调", value: "空调" },
		{ label: "电视机", value: "电视机" },
		{ label: "洗衣机", value: "洗衣机" },
		{ label: "冰箱", value: "冰箱" },
		{ label: "餐桌", value: "餐桌" },
		{ label: "抽油烟机", value: "抽油烟机" },
		{ label: "烤箱", value: "烤箱" },
		{ label: "微波炉", value: "微波炉" },
        { label: "炉灶", value: "炉灶" }
    ]);
    const [houseTagListTwo, setHouseTagListTwo] = useState([
        { label: "有装修", value: "有装修" },
		{ label: "连套房", value: "连套房" },
		{ label: "雪柜", value: "雪柜" },
		{ label: "床", value: "床" },
		{ label: "桌椅", value: "桌椅" },
		{ label: "空调", value: "空调" },
		{ label: "电视机", value: "电视机" },
		{ label: "洗衣机", value: "洗衣机" },
		{ label: "冰箱", value: "冰箱" },
		{ label: "餐桌", value: "餐桌" },
		{ label: "抽油烟机", value: "抽油烟机" },
		{ label: "烤箱", value: "烤箱" },
		{ label: "微波炉", value: "微波炉" },
        { label: "炉灶", value: "炉灶" }
    ]);
    const [rentIncludesList, setRentIncludesList] = useState([
        { label: "差饷地租", value: "差饷地租" },
        { label: "管理费",   value: "管理费" },
    ]);
    const [rentIncludesListTwo, setRentIncludesListTwo] = useState([
        { label: "差饷地租", value: "差饷地租" },
        { label: "管理费",   value: "管理费" },
    ]);
    const [aptRoomModel, setAptRoomModel] = useState({});
    const [updateStatusModel, setUpdateStatusModel] = useState({});
    const [proBrandEstateList, setProBrandEstateList] = useState([]);
    const [nearbySchoolsList, setNearbySchoolsList] = useState([]);
    const [schoolList, setSchoolList] = useState(null);
    const [estateTagList, setEstateTagList] = useState([]);
    const houseSourceType = Form.useWatch('houseSourceType', form);
    const [addHouseTagValue, setAddHouseTagValue] = useState("");
    const [addRentIncludesValue, setAddRentIncludesValue] = useState("");
    const [isHomeName, setIsHomeName] = useState(false);
    const [isStoreType, setIsStoreType] = useState(false);
    
    useEffect(() => {
        searchAll();
        search(pageSize, 1, "N");
        searchAllArea();
        searchSchoolList();
        searchCategoryList();
        searchEstateTagList();
    }, []);


    useEffect(() => {
        if(houseName){
            searchCommunity(houseName);
        }
    },[houseName]);


    const addTag = (tag) => {
        let list = houseTagListTwo;
        let listTwo = tag.split('|');
        let listThree =[];
        for(let num in listTwo) {
            let model = {
                label: listTwo[num],
                value: listTwo[num]
            }
            listThree.push(model)
        }
        let arr = [...list, ...listThree]
        for (var i = 0; i < arr.length; i++) {
            for (var j = i + 1; j < arr.length; j++) {
                if (arr[j].label == arr[i].label) {
                //splice(索引,长度,添加)
                    arr.splice(j, 1);
                    j--;
                }
            }
        }
        setHouseTagList(arr)
    }

    const clear = () => {
        setPropertyModel({})
        form.setFieldsValue({
            userName:''
        })
        setUserId('')
    }

    const addRentIncludesTag = (tag) => {
        let list = rentIncludesListTwo;
        let listTwo = tag.split('|');
        let listThree =[];
        for(let num in listTwo) {
            let model = {
                label: listTwo[num],
                value: listTwo[num]
            }
            listThree.push(model)
        }
        let arr = [...list, ...listThree]
        for (var i = 0; i < arr.length; i++) {
            for (var j = i + 1; j < arr.length; j++) {
                if (arr[j].label == arr[i].label) {
                //splice(索引,长度,添加)
                    arr.splice(j, 1);
                    j--;
                }
            }
        }
        setRentIncludesList(arr)
    }

    const searchSchoolList = () => {
        Base.callBackApi("commonDropDown/searchSysParam", { segment: "SCHOOL" }).then((data) => {
            if ("SUCCESS" === data.msg) {
                setSchoolList(data.object ?? []);
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { });
    }

    const searchCategoryList = () => {
        Base.callBackApi("commonDropDown/searchSysParam", { segment: "BUILDING_TYPE" }).then((data) => {
            if ("SUCCESS" === data.msg) {
                setCategoryList(data.object ?? []);
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { });
    }

    const searchCommunity = (id) => {
        Base.callBackApi("proEstate/searchById", id).then((data) => {
            if ("SUCCESS" === data.msg) {
                let model = data.object
                if(model.nearbySchools){
                    let array = model.nearbySchools?.split("|");
                    let newList = [];
                    for (let index = 0; index < array.length; index++) {
                        let ele = schoolList.find((item)=> item.code === array[index] );
                        newList.push(ele);
                    }
                    setNearbySchoolsList(newList);
                }else{
                    setNearbySchoolsList([])
                }
                initFormCommunity(model);
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { });
    }

    const searchEstateTagList = () => {
        Base.callBackApi("commonDropDown/searchSysParam", { segment: "ESTATE_TAG" }).then((data) => {
            if ("SUCCESS" === data.msg) {
                let list = data.object ?? [];
                setEstateTagList(list.map(item => {
                    return {
                        label: item.parmValue1,
                        value: item.code
                    }
                }));
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { });
    }

    
    const searchAll = () => {
        Base.callBackApi("commonDropDown/searchProEstate").then((data) => {
            if ("SUCCESS" === data.msg) {
                setProBrandEstateList(data.object);
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { });
    }

    const searchAllArea = () => {
        Base.callBackApi("commonDropDown/searchAllArea").then((data) => {
            if ("SUCCESS" === data.msg) {
                setOptions(data?.object ?? []);
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { });
    }

    const search = (pageSizeNew, currentPageNew, flag, isShowMsg) => {
        if(Config.funcInfo.canSelect === "N") {
            return;
        }
        messageShowHandler();  // 清除提示信息
        clearSelect(); // 清除选中状态
        let model = {};
        if ("Y" === flag) {     // 翻页
            model = oldSearchModel;
        } else {
            model = JSON.parse(JSON.stringify(searchModel));
        }
        model.page = currentPageNew;
        model.pageSize = pageSizeNew;
        setIsLoad(true);
        setSpinInfo("查询中...");
        Base.callBackApi("wholeRentHouseSouce/search", model).then((data) => {
            if ("SUCCESS" === data.msg) {
                if (!data?.object?.list && isShowMsg) {
                    errFrameShow("info", '找不到符合条件记录.');
                }
                if("N" === flag) {
                    setOldSearchModel(JSON.parse(JSON.stringify(model)));
                }
                data?.object?.list?.forEach(item => {
                    if(item.houseSourceImg) {
                        item.houseSourceDefaultImg = item.houseSourceImg.split(";")[0];
                    }
                })
                setTablesCount(data?.object?.total ?? 0);
                setTables(data?.object?.list ?? []);
                setCurrent(currentPageNew);
                setPageSize(pageSizeNew);
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }

    const searchChange = (key, value) => {
        messageShowHandler();
        let model = JSON.parse(JSON.stringify(searchModel));
        model[key] = value;
        setSearchModel(model);
    }
    
    const reset = () => {
        messageShowHandler();
        clearSelect();
        setSearchModel({});
        setOldSearchModel({});
        setTablesCount(0);
        setTables([]);
        setPageSize(10);
    }

    // Tab页切换
    const handleSelect = (key) => {
        if (key) {
            if(key === "2") {
                if(checkboxState.length === 1 && Config.funcInfo.canUpdate === "Y") {
                    edit();
                } else if(Config.funcInfo.canInsert === "Y") {
                    add();
                }
            } else if(tabIndex !== "1") {
                cancel();
            }
        }
    }

    const add = () => {
        console.log("666")
        messageShowHandler();
        form.resetFields();
        form.setFieldsValue({
            houseSourceType:"OWNER",
            roomNumber: 1,
            hallNumber: 0,
            bathroomNumber: 0,
        })
        setPropertyModel({})
        let tagList = JSON.parse(JSON.stringify(houseTagListTwo));
        let rentIncludesList = JSON.parse(JSON.stringify(rentIncludesListTwo));
        setHouseTagList(tagList)
        setRentIncludesList(rentIncludesList)
        setImageFile([]);
        setVideoFile([]);
        setNearbySchoolsList([]);
        setFlag("add");
        setTabIndex("2");
    }
    const initFormCommunity = (model = {}) => {
        form.setFieldsValue({
            estateTitle: model.estateTitle ?? '',
            estateDesc: model.estateDesc ?? '',
            estateTag: model.estateTag?.split('|') ?? [],
            CommunityAreaId: model.areaId ? [model.provinceId + '', model.cityId + '', model.areaId + ''] : '',
        });
    }

    const initForm = (model = {}) => {
        if(model.storeType == '楼层') {
            setIsStoreType(true)
        }else{
            setIsStoreType(false)
        }
        form.resetFields();
        form.setFieldsValue({
            houseSourceTitle: model.houseSourceTitle ?? '',
            rent: model.rent ?? '',
            houseName: model.houseName ?? '',
            houseName2: model.houseName2?? '',
            usableArea: model.usableArea ?? '',
            propertyNo: model.propertyNo ?? '',
            buildingNo: model.buildingNo ?? '',
            houseSourceType: model.houseSourceType ?? '',
            buildingIntro: model.buildingIntro ?? '',
            areaId: model.areaId ? [model.provinceId + '', model.cityId + '', model.areaId + ''] : '',
            address: model.address ?? '',
            joinYear:  model.joinYear? moment(new Date(model.joinYear), Config.years): null,
            buildingAge: model.buildingAge ?? '',
            buildingType: model.buildingType ?? '',
            store: model.store ?? '',
            storeType: model.storeType ?? '',
            houseLayout: model.houseLayout ?? '',
            houseTag: model.houseTag?.split('|') ?? [],
            rentIncludes: model.rentIncludes?.split('|') ?? [],
            agentCompanyName: model.agentCompanyName ?? '',
            companyAddress: model.companyAddress ?? '',
            companyLicenseNumber: model.companyLicenseNumber ?? '',
            companyWebsite: model.companyWebsite ?? '',
            contactTitle: model.contactTitle ?? '',
            contactLanguage: model.contactLanguage?.split('|') ?? [],
            contactPhone: model.contactPhone ?? '',
            wechat: model.wechat ?? '',
            status: model.status ?? '',
            roomIntro: model.roomIntro ?? '',
            tenancy: model.tenancy ?? '',
            roomNumber: model.roomNumber?? '',
            hallNumber: model.hallNumber?? '',
            bathroomNumber: model.bathroomNumber ?? '',
            sittingDirection:model.sittingDirection ?? '',
            userName: (model.userName ?? '') + '(' + model.userId + ')',
        });
        setUserId(model.userId)
    }

    const edit = () => {
        messageShowHandler();
        if(!selectedRowKeys || selectedRowKeys.length !== 1) {
            errFrameShow("error", "请选择一条数据");
            return;
        }
        setCheckboxState(checkboxState[0]);
        setSelectedRowKeys(checkboxState[0].houseSourceId);
        searchById(checkboxState[0])
    }

    const update = (model, e) => {
        e.stopPropagation();
        messageShowHandler();
        setCheckboxState([model]);
        setSelectedRowKeys([model.houseSourceId]);
        setIsHomeName(!model.houseName2)
        searchById(model)
    }

    const searchById = (model) => {
        console.log("xzcs")
        Base.callBackApi("wholeRentHouseSouce/searchById",model).then((data) => {
            if ("SUCCESS" === data.msg) {
                let model = data.object ?? {};
                addTag(model.houseTag);
                addRentIncludesTag(model.rentIncludes)
                setAptRoomModel(model);
                initForm(model);
                setFlag("edit");
                setTabIndex("2");
                setPropertyModel(model)
                setImageFile(
                    model.houseSourceImg?.split(";")
                        ?.map(item => {
                            return {
                                url: Config.proHouseSourceImg + item,
                                originUrl: item,
                                thumbUrl: Config.proHouseSourceImg + item,
                                imgStatus: "OLD"
                            };
                        })
                    || []
                );
                if(model.houseSourceVideo){
                    let str = model.houseSourceVideo.replace("/","\\");
                    console.log(str);
                    let arr = str.split("\\");
                    console.log(arr);
                    let newArr = arr[1].split(".");
                    console.log(newArr);
                    let videoFile = [{
                        url: Config.proHouseSourceVideo + model.houseSourceVideo,
                        originUrl: model.houseSourceVideo,
                        name: arr[1],
                        thumbUrl:Config.proHouseSourceVideo + arr[0] + "\\" +  model.houseSourceVideoImg,
                        imgStatus: "OLD"
                    }]
                    setVideoFile(videoFile);
                }else{
                    setVideoFile([]);
                }
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }

    const popupFrameClose = (state) => {
        if(state) {
            if(frameType === "delete") {
                deleteData();
            } else if(frameType === "leave") {
                cancel();
            } else {
                updateStatus();
            }
        }
        setIsPopupFrame(false);
    }

    const deleteOne = (model, e) => {
        e.stopPropagation();
        setCheckboxState([model]);
        setSelectedRowKeys([model.houseSourceId]);
        setType();
        setContent();
        setFrameType("delete");
        setIsPopupFrame(true);
    }

    const showTips = (flag, record) => {
        setUpdateStatusModel({
            houseSourceId: record.houseSourceId,
            status: flag ? 'Y' : 'N',
            lastUpdatedDate: record.lastUpdatedDate
        });
        setType();
        setContent("确定要" + (flag ? "上架" : "下架") + "该房源吗？");
        setFrameType("update");
        setIsPopupFrame(true);
    }

    const updateStatus = () => {
        setIsLoad(true);
        setSpinInfo("数据保存中...")
        updateStatusModel.menuFuncId = Config.funcInfo.funcId;
        Base.callBackApi("wholeRentHouseSouce/updateStatus", updateStatusModel).then((data) => {
            if ("SUCCESS" === data.msg) {
                search(pageSize, current, "Y", false);
                errFrameShow("success", "保存成功");
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }

    const deleteData = () => {
        setIsLoad(true);
        setSpinInfo("数据保存中...");
        let deleteModel = checkboxState[0];
        deleteModel.menuFuncId = Config.funcInfo.funcId;
        Base.callBackApi("wholeRentHouseSouce/delete", deleteModel).then((data) => {
            if ("SUCCESS" === data.msg) {
                search(pageSize, current, "Y", false);
                errFrameShow("success", "刪除成功");
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }

    const audit = () => {
        messageShowHandler();//清空提示信息
        if(!checkboxState || checkboxState.length !== 1) {
            errFrameShow("error", "请选择一条数据");
            return;
        }
        setAuditModel(checkboxState[0]);
        setIsAudit(true);
    }

    const addRentIncludes = () => {
        if(!addRentIncludesValue){
            return
        }
        let model = {
            label: addRentIncludesValue, 
            value: addRentIncludesValue
        }
        let list = rentIncludesList;
        for(var i in list) {
            if(list[i].value == model.value){
                errFrameShow("error", "重复标签");
                return
            }
         }
        console.log(model)
        list.push(model)
        setRentIncludesList(list)
        setAddRentIncludesValue('')
    }
    
    const addHouseTag = () => {
        if(!addHouseTagValue){
            return
        }
        let model = {
            label: addHouseTagValue, 
            value: addHouseTagValue
        }
        let list = houseTagList;
        for(var i in list) {
            if(list[i].value == model.value){
                errFrameShow("error", "重复标签");
                return
            }
         }
        console.log(model)
        list.push(model)
        setHouseTagList(list)
        setAddHouseTagValue('')
    }

    const auditCancel = () => {
        setIsAudit(false);
    }

    const cancel = () => {
        messageShowHandler();
        clearSelect();
        setVideoFile([]);
        setImageFile([]);
        setPreviewVisible(false);
        setPreviewImage(null);
        setTabIndex("1");
    }

    const onFinish = (values) => {
        messageShowHandler();
        setIsLoad(true);
        setSpinInfo("数据保存中...")
        let url = "";
        if(flag === "add") {
            url = "wholeRentHouseSouce/insert";
        } else {
            url = "wholeRentHouseSouce/update"
            values.houseSourceId = aptRoomModel.houseSourceId;
            values.lastUpdatedDate = aptRoomModel.lastUpdatedDate;
        }
        let videoUploadModelList = videoFile.map(item => {
            return {
                status: item.imgStatus,
                url: item.originUrl,
            };
        });
        if(videoUploadModelList.length > 0){
            if(videoUploadModelList[0].status == 'NEW'){
                values.houseSourceVideoImg = '';
            }else{
                console.log(aptRoomModel)
                values.houseSourceVideoImg = aptRoomModel.houseSourceVideoImg;
            }
        }
        values.videoUploadModelList = videoUploadModelList;
        console.log(values)
        let imageUploadModelList = imageFile.map(item => {
            return {
                status: item.imgStatus,
                url: item.originUrl,
            };
        });
        values.imageUploadModelList = imageUploadModelList;
        values.menuFuncId = Config.funcInfo.funcId;
        if(values.contactLanguage){
            values.contactLanguage = values.contactLanguage.join('|');
        }
        values.houseTag = values.houseTag.join('|');
        values.rentIncludes = values.rentIncludes.join('|');
        if(values.areaId){
            values.areaId = values.areaId[2];
        }
        values.userId = userId;
        Base.callBackApi(url, values).then((data) => {
            if ("SUCCESS" === data.msg) {
                search(pageSize, current, "Y", false);
                errFrameShow("success", "保存成功");
                setTabIndex("1");
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }


    const handleChange = (file) => {
        let fileList = file.fileList;
        file = file.file;
        if(file.status === "removed") {
            let index = imageFile.findIndex(item => item.uid == file.uid);
            let fileList = JSON.parse(JSON.stringify(imageFile));
            fileList.splice(index, 1)
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = (e) => { 
                var img = document.createElement("img");
                img.src = e.target.result;
                // img.onload = function () {
                //     if(img.width < 400 || img.height < 240 || Math.formatFloat(img.width / img.height, 3) !== Math.formatFloat(5 / 3, 3)) {
                //         fileList[fileList.length - 1].status = "irregular";
                //         form.validateFields(['houseSourceImg']);
                //     }
                // }
                fileList[fileList.length - 1].originUrl = e.target.result; 
                fileList[fileList.length - 1].imgStatus = "NEW";
            }
        }
        setImageFile(fileList);
    }

    /**查看图片 */
    const handlePreview = (file) => {
        setPreviewImage(file.url || file.originUrl);
        setPreviewVisible(true);
    }

    const checkImg = (value) => {
        if(!!imageFile && imageFile.length > 0) {
            if(value) {
                let list = value.fileList;
                if(list.find(item => item.status === "uploading")) {
                    return Promise.reject("请等待图片上传完成");
                }
                if(list.find(item => item.status === "error")) {
                    return Promise.reject("请先处理测试上传失败的图片");
                }
                // if(imageFile.find(item => item.status === "irregular")) {
                //     return Promise.reject("图片尺寸不符合规则");
                // }
            }
            return Promise.resolve();
        }
        return Promise.reject("图片数量必须大于0");
    }

    const checkVideo = (value) => {
        if(value) {
            let list = value.fileList;
            if(list.find(item => item.status === "uploading")) {
                return Promise.reject("请等待视频上传完成");
            }
            if(list.find(item => item.status === "error")) {
                return Promise.reject("请先处理测试上传失败的视频");
            }
            if(list.find(item => item.size > 5 * 1024 * 1024)) {
                return Promise.reject("请选择小于 5M 的视频文件");
            }
        }
        return Promise.resolve();
    }

    const chooseUser = () => {
        setIsUser(true)
        setUserModel({
            ...propertyModel,
            houseSourceType:houseSourceType === "AGENT" ? "AGENCY" : '',
            userId:userId
        });
    }

    const handleVideoPreview = (file) => {
        setPreviewVideo(file.url || file.originUrl);
        setPreviewVideoVisible(true);
    }

    const onChangeAddressA = (value) =>{
        if(value) {
            if(value.length < 3 || !value[2]) {
                form.setFieldsValue({
                    areaId: [],
                });
            }
        }
    }

    const handleVideoChange = (file) => {
        let fileList = file.fileList;
        file = file.file;
        if(file.status === "removed") {
            let index = imageFile.findIndex(item => item.uid == file.uid);
            let fileList = JSON.parse(JSON.stringify(imageFile));
            fileList.splice(index, 1)
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = (e) => { 
                fileList[fileList.length - 1].originUrl = e.target.result; 
                fileList[fileList.length - 1].imgStatus = "NEW";
            }
        }
        setVideoFile(fileList);

    }

    const inputChange = (id,value) => {
        setAddHouseTagValue(value)
    }
    const inputChangeTwo = (id,value) => {
        setAddRentIncludesValue(value)
    }
    
    //分页 页码切换变化查询
    const onChange = (currentNew, pageSizeNew) => {
        search(pageSizeNew, currentNew, "Y");
    }
    //分页 每页條數变化查询
    const onShowSizeChange = (currentNew, pageSizeNew) => {
        search(pageSizeNew, 1, "Y");
    }

    //列表选中记录
    const onSelectChange = (selectedRowKeys, selectedRows) => {
        setSelectedRowKeys(selectedRowKeys);
        setCheckboxState(selectedRows);
    }
    //清空列表选中
    const clearSelect = () => {
        setSelectedRowKeys([]);
        setCheckboxState([]);
    }

    const errFrameShow = (style, msg) => {
        setMessage(msg);
        setMessageStyle(style);
        setMessageShow(true);
        document.documentElement.scrollTop = 0;
    }

    const messageShowHandler = () => {
        setMessage("");
        setMessageShow(false);
    }

    const closeCommunityModal = (flag, model) => {
        if(flag) {
            if(model.houseName2){
                setIsHomeName(false);
                form.setFieldsValue({
                    houseName2: model.houseName2 ?? '',
                    houseName: '',
                });
            }else{
                setIsHomeName(true);
                form.setFieldsValue({
                    houseName: model.proEstateId ?? '',
                    houseName2: '',
                });
            }
        }
        setIsCommunity(false);
    }
    
    const storeTypeChange = (e) => {
        console.log(e)
        form.setFieldsValue({
            store:'',
        });
        if(e == '楼层') {
            setIsStoreType(true)
        }else{
            setIsStoreType(false)
        }
    }

    const closeUserModal = (flag, model) => {
        console.log(model)
        if(flag) {
            setPropertyModel(model)
            form.setFieldsValue({
                userName: (model.userName ?? '') + '(' + model.userId + ')',
            });
            setUserId(model.userId)
        }
        setIsUser(false);
    }

    const chooseCommunity = () => {
        setIsCommunity(true)
        setCommunityModel( {
            proEstateId:houseName,
            houseName2:houseName2,
        });
    }

    /*eslint-disable*/
    const columns = [
        {
            title: '房间图片',
            dataIndex: 'houseSourceDefaultImg',
            className: 'tableWidth10',
            render: (text) => <img className="apartmentTableImg" alt='' src={Config.proHouseSourceImg + text}/>
        },
        {
            title: '房源标题',
            dataIndex: 'houseSourceTitle',
            className: 'tableWidth10',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'houseSourceTitle') },
        },
        {
            title: '屋苑/大厦',
            dataIndex:'',
            className: 'tableWidth10',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'houseName') },
            render: (text, record) => {
                return record.houseName2 || record.estateTitle
            }
        },
        {
            title: '实用面积',
            dataIndex: 'usableArea',
            className: 'tableWidth8',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'usableArea') },
        },
        {
            title: '租金',
            dataIndex: 'rent',
            className: 'tableRightAlignment',
            className: 'tableWidth8',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'rent') },
            render: (text) => Base.numberFormat(text, 2)
        },
        {
            title: '房间标签',
            dataIndex: 'houseTag',
            className: 'tableWidth29',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'houseTag') },
        },
        {
            title: '状态',
            dataIndex: 'status',
            className: 'tableWidth7',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'status') },
            render: (text, record) => <Switch checkedChildren="已上架" unCheckedChildren="未上架" checked={text === 'Y'} onChange={(e) => showTips(e, record)} />
        },
        {
            title: '发布人账号',
            dataIndex: 'userId',
            className: 'tableWidth8',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'userId') },
        },
        {
            title: '操作',
            dataIndex: '',
            className: 'tableWidth10 tableRightAlignment',
            render: (text, record) => <div className='sysOperation proBrandEstateOperation'>
                <div className='sysUpdate' onClick={(e) => update(record ,e)}><EditOutlined /> 修改</div>
                <div className='sysDelete' onClick={(e) => deleteOne(record,e)}><DeleteOutlined /> 删除</div>
            </div>
        },
    ];
    /*eslint-enable*/

    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
        type: 'radio',
    }

    const uploadVideoButton = (
        <div>
            <Button icon={<PlusOutlined />}>添加房间视频</Button>
        </div>
    );

    return(
        <div id="container" className="template aptRoom">
            <div className='breadcrumb'>
                <HomeOutlined /> /  品牌 / <span className='breadcrumbTitle'>整租房源</span>
            </div>
            {messageShow ? <Message style={messageStyle} content={message} close={messageShowHandler}/> : ''}
            <div className='templateMain'>
                <div className="sysModelTitle">整租房源</div>
                <Spin spinning={isLoad} size="large" tip={spinInfo}>
                    {/* 删除弹框 */}
                    {isPopupFrame && <PopupFrame close={popupFrameClose} type={type} content={content} />}
                    {/* 审核彈框 */}
                    <Audit visible={isAudit} auditModel={auditModel} close={auditCancel} />
                    {/* 选择屋苑弹窗 */}
                    {isCommunity && <SelectCommunity onClose={closeCommunityModal} communityModel={communityModel}/>}
                    {/* 选择用户弹窗 */}
                    {isUser && <SelectUser onClose={closeUserModal} userModel={userModel}/>}
                    <Tabs activeKey={tabIndex} onChange={(e) => handleSelect(e)}>
                        <TabPane tab="列表" key="1">
                            <div className='sysRow'>
                                <div className='sysCol'>
                                    <div className="sysTitle">房源标题</div>
                                    <div className="sysSerchInput">
                                        <Input type="text" placeholder="房源标题" maxLength={20} value={searchModel.houseSourceTitle}
                                            onChange={(e) => searchChange("houseSourceTitle", e.target.value)}
                                            onKeyDown={(e) => e.key === "Enter" ? search(pageSize, 1, "N", true) : null} />
                                    </div>
                                </div>
                                <div className='sysCol'>
                                    <div className="sysTitle">状态</div>
                                    <div className="sysSerchInput">
                                        <Select className="sysSelect" value={searchModel.status || ''} onChange={(value) => searchChange('status', value)}>
                                            <Option value="">全部</Option>
                                            {context.productStatusMap.map(item => <Option key={item.value} value={item.value}>{item.label}</Option>)}
                                        </Select>
                                    </div>
                                </div>
                                <div className='sysCol'>
                                    <div className="sysTitle">发布人账号</div>
                                    <div className="sysSerchInput">
                                        <Input type="text" placeholder="发布人账号" maxLength={20} value={searchModel.userIds}
                                            onChange={(e) => searchChange("userIds", e.target.value)}
                                            onKeyDown={(e) => e.key === "Enter" ? search(pageSize, 1, "N", true) : null} />
                                    </div>
                                </div>
                                <div className='sysSearchButton'>
                                    <Button className="sysBtn btnBule" type="primary" onClick={() => search(pageSize, 1, "N", true)} hidden={Config.funcInfo.canSelect === "N"} icon={<SearchOutlined />}>查询</Button>
                                    <Button className="sysBtn" onClick={reset} icon={<RedoOutlined />}>重置</Button>
                                </div>
                            </div>
                            <div className="sysLineEight"></div>
                            <Space size={[5, 5]} wrap className="sysButtonRelative">
                                <Button className="sysBtn btnBule" type="primary" onClick={add} hidden={Config.funcInfo.canInsert === "N"} icon={<PlusCircleOutlined />}>新增</Button>
                                {tables.length == 0 ? null :
                                    <>
                                        <Button className="sysBtn btnBule" type="primary" onClick={audit} hidden={Config.funcInfo.canAudit === "N"} icon={<EyeOutlined />}>日志</Button>
                                    </>
                                }
                            </Space>
                            <div className="sysTable" id="tableOne">
                                <Table
                                    rowSelection={rowSelection} //选择
                                    rowKey="houseSourceId"
                                    columns={columns} //列名
                                    dataSource={tables} //数据
                                    pagination={{  //分页
                                        showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                        onChange: onChange,                        //上页下页或者跳页方法
                                        onShowSizeChange: onShowSizeChange,        //每页显示多少条数据方法
                                        total: tablesCount,             //总共多少条数据
                                        current: current,                //当前页
                                        pageSize: pageSize,              //每页显示多少条数据
                                        defaultCurrent: 1,                               //默认当前页为1
                                        size:"small"
                                    }}
                                    onRow={(record) => {
                                        return {
                                            onClick: event => Base.selectRadioRowNew.call(this, record, 'houseSourceId', selectedRowKeys, setCheckboxState, setSelectedRowKeys),
                                        };
                                    }}
                                />
                            </div>
                        </TabPane>
                        <TabPane tab="整租房源" key="2" forceRender={true}>
                            <Form form={form} onFinish={onFinish}>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">租房类型</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="houseSourceType"
                                                rules={[
                                                    { required: true, message: '租房类型不能为空' },
                                                ]}
                                            >
                                                <Select className="sysSelect" onChange={clear}>
                                                    <Option value=""></Option>
                                                    {context.houseSourceTypeMap.map(item => <Option key={item.value} value={item.value}>{item.label}</Option>)}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">发布人</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="userName"
                                                rules={[
                                                    { required: true, message: '发布人为空' },
                                                ]}
                                            >
                                                <Input type="text" placeholder="发布人" maxLength={50} disabled/>
                                            </Form.Item>
                                        </div>
                                        <Button className="buttonYellowxuanzeStyle" type="primary" onClick={() => chooseUser()}>选择发布人</Button>
                                    </div>
                                </div>
                                {propertyModel?.auditStatus === "APPROVE" &&
                                    <div className='wholeRentHouseSouce2'>
                                        <h4 className='blueBoxTitle3'>地产代理公司资料</h4>
                                        <div className='flexBoxOne'>
                                            <Image src={Config.propertyAgent + propertyModel.comanyLogo} className="propertyImg"></Image>
                                            <div className='rightBox'>
                                                <div className='labelTitle'>{propertyModel.companyName}</div>
                                                <div className='labelBox'>
                                                    <span className='label'>地址:</span>
                                                    <span className='labelDelis'>{propertyModel.paAddress}</span>
                                                </div>
                                                <div className='labelBox'>
                                                    <span className='label'>网站:</span>
                                                    <span className='labelDelis'>{propertyModel.companyWebsite}</span>
                                                </div>
                                                <div className='labelBox'>
                                                    <span className='label'>牌照:</span>
                                                    <span className='labelDelis'>{propertyModel.agentLicense}</span>
                                                </div>
                                            </div>
                                            <div className='headImg'>
                                                <div className='headImgTitle'>商业登记证(BR)</div>
                                                <Image src={Config.propertyAgent + propertyModel.businessRegPhoto} className="propertyImg"></Image>
                                                <div className='headImgTitle'>个人地产名片</div>
                                                <Image src={Config.propertyAgent + propertyModel.agentLicensePhoto} className="propertyImg"></Image>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    propertyModel?.userId &&
                                    <div className='wholeRentHouseSouce2'>
                                        <h4 className='blueBoxTitle3'>联络人信息</h4>
                                        <div className='flexBoxOne'>
                                            { propertyModel.headPortrait ?
                                                <Image src={Config.imgServerHeadPortraitImgs + propertyModel.headPortrait} className="propertyImg"></Image>:
                                                <img  className="propertyImg" alt="" src={Config.imgServerHeadPortraitImgs +"defaultHead.png"}/>
                                            }
                                            <div className='rightBox'>
                                                <div className='labelTitle'>{propertyModel.userName}</div>
                                                {
                                                    propertyModel.phone && 
                                                    <div className='labelBox'>
                                                        <span className='label'>电话:</span>
                                                        <span className='labelDelis'>{propertyModel.phone}</span>
                                                    </div>
                                                }
                                                {
                                                    propertyModel.language &&
                                                    <div className='labelBox'>
                                                        <span className='label'>语言:</span>
                                                        <span className='labelDelis'>{propertyModel.language}</span>
                                                    </div>
                                                }
                                                {
                                                    propertyModel.areaCode && propertyModel.whatsApp ?
                                                    <div className='labelBox'>
                                                        <span className='label'>WhatsApp:</span>
                                                        <span className='labelDelis'>{propertyModel.areaCode +' '+ propertyModel.whatsApp}</span>
                                                    </div>: null
                                                }
                                               
                                            </div>
                                            {
                                            propertyModel?.wechat &&
                                            <div className='headImg'>
                                                <div className='headImgTitle'>微信二维码</div>
                                                <Image src={Config.weChatBusinessCard + propertyModel.wechat} className="propertyImg"></Image>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                }
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">标题</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="houseSourceTitle"
                                                rules={[
                                                    { required: true, message: '标题不能为空' },
                                                ]}
                                            >
                                                <Input type="text" placeholder="标题" maxLength={50}/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">租金</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="rent"
                                                rules={[
                                                   { required: true, message: '租金不能为空' },
                                                ]}
                                            >
                                                <InputNumber className='sysInputNumber' placeholder="租金" precision={2} maxLength={11} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">租期(月)</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="tenancy"
                                                rules={[
                                                    { required: true, message: '租期不能为空' },
                                                ]}
                                            >
                                                <Select className="sysSelect">
                                                    <Option value=""></Option>
                                                    {tenancyList?.map(item => <Option key={item} value={item}>{item}</Option>)}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">实用面积</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="usableArea"
                                                rules={[
                                                    { required: true, message: '实用面积不能为空' },
                                                ]}
                                            >
                                                <InputNumber className='sysShortInputNumber' placeholder="实用面积" precision={2} maxLength={11} />
                                            </Form.Item>
                                        </div>
                                        { usableArea && rent ?
                                            <span className="">&nbsp;&nbsp;(尺价:HKD{(rent/usableArea).toFixed(2)})</span>
                                            : null
                                        }
                                    </div>
                                </div>
                                <div className='wholeRentHouseSouce'>
                                    <h4 className='blueBoxTitle1'>屋苑/大厦信息</h4>
                                    <div className='sysRow'>
                                        <div className='sysColDuan'>
                                            <div className="sysParam mustFill">屋苑/大厦</div>
                                            {isHomeName ? 
                                                <div className="sysSelectDuan">
                                                    <Form.Item
                                                        name="houseName"
                                                        rules={[
                                                            { required: true, message: '屋苑/大厦不能为空' },
                                                        ]}
                                                    >
                                                        <Select disabled>
                                                            <Option value=""></Option>
                                                            {proBrandEstateList.map(item => <Option key={item.proEstateId} value={item.proEstateId}>{item.estateTitle}</Option>)}
                                                        </Select>
                                                    </Form.Item>
                                                </div>:
                                                <div className="sysSelectDuan">
                                                    <Form.Item
                                                        name="houseName2"
                                                        rules={[
                                                            { required: true, message: '屋苑/大厦不能为空' },
                                                        ]}
                                                    >
                                                    <Input type="text" placeholder="屋苑/大厦" maxLength={100} disabled/>
                                                    </Form.Item>
                                                </div>
                                            }
                                        </div>
                                        <Button className="buttonYellowTwoStyle" type="primary" onClick={() => chooseCommunity()}>选择屋苑/大厦</Button>
                                    </div>
                                    { houseName &&
                                        <div>
                                            <div className='sysRow'>
                                                <div className='sysCol'>
                                                    <div className="sysParam">屋苑/大厦标签</div>
                                                    <div className="sysInputs">
                                                        <Form.Item
                                                            name="estateTag"
                                                        >
                                                            <Checkbox.Group options={estateTagList} disabled/>
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='sysRow'>
                                                <div className='sysCol'>
                                                    <div className="sysParam">附近学校</div>
                                                    <div className="sysInputs proEstateTreeDiv">
                                                        <div className='treeDiv'>
                                                            {(Base.isNotEmpty(nearbySchoolsList) && nearbySchoolsList.length > 0)?
                                                                nearbySchoolsList.map((item) => 
                                                                    <Tooltip title={item.parmValue1} overlayClassName="lawTip" key={item.code}>
                                                                        <span className='lawArticleItem' key={item.code} value={item.code}>
                                                                            {item.parmValue1}
                                                                        </span>
                                                                    </Tooltip>
                                                                )
                                                                :null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='sysRow'>
                                                <div className='sysCol'>
                                                    <div className="sysParam mustFill">地区</div>
                                                    <div className="sysInput">
                                                        <Form.Item
                                                            name="CommunityAreaId"
                                                            rules={[
                                                                { required: true, message: '地区不能为空' },
                                                            ]}
                                                        >
                                                            <Cascader options={options} onChange={onChangeAddressA} placeholder="请填写所在地区" disabled/>
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='sysRow'>
                                                <div className='sysCol'>
                                                    <div className="sysParam">屋苑/大厦介绍</div>
                                                    <div className="sysInputs">
                                                        <Form.Item
                                                            name="estateDesc"
                                                        >
                                                            <TextArea placeholder="屋苑/大厦介绍" maxLength={200} rows={3} disabled className='textarea'/>
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                {(houseSourceType === "AGENT" && flag != "add") &&
                                    <div className='sysRow'>
                                        <div className='sysCol'>
                                            <div className="sysParam">物业编号</div>
                                            <div className="sysInput">
                                                <Form.Item
                                                    name="propertyNo"
                                                >
                                                    <Input type="text" disabled placeholder="物业编号" maxLength={50}/>
                                                </Form.Item>
                                            </div>
                                        </div>
                                        {/* <div className='sysCol'>
                                            <div className="sysParam">楼盘编号</div>
                                            <div className="sysInput">
                                                <Form.Item
                                                    name="buildingNo"
                                                >
                                                    <Input type="text" placeholder="楼盘编号" maxLength={50}/>
                                                </Form.Item>
                                            </div>
                                        </div> */}
                                    </div>
                                }
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam">房间视频</div>
                                        <div className="sysInputs">
                                            <Form.Item 
                                                name="houseSourceVideo"
                                                rules={[
                                                    { required: true, validator: (rule, value) => checkVideo(value) }
                                                ]}
                                            >
                                                <Upload
                                                    action={Config.uploadTestServer}
                                                    key={"2"}
                                                    className="videoUpload"
                                                    listType="picture"
                                                    maxCount={1}
                                                    fileList={videoFile}
                                                    onPreview={handleVideoPreview}
                                                    onChange={(file) => handleVideoChange(file)}>
                                                    {videoFile.length >= 1 ? null : uploadVideoButton }
                                                </Upload>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">房间图片</div>
                                        <div className="sysInputs">
                                            <Form.Item 
                                                name="houseSourceImg"
                                                rules={[
                                                    { required: true, validator: (rule, value) => checkImg(value) }
                                                ]}
                                            >
                                                <ImgCrop rotationSlider aspect={5/3}>
                                                    <Upload
                                                        action={Config.uploadTestServer} 
                                                        key={"1"}
                                                        accept=".png, .jpg, .jpeg"
                                                        listType="picture-card"
                                                        fileList={imageFile}
                                                        onPreview={handlePreview}
                                                        onChange={(file) => handleChange(file)}>
                                                        {imageFile.length >= 10 ? null : <div className='plusOutBox'>
                                                            <PlusOutlined />
                                                            <div>添加图片</div>
                                                            <div className='plusOutDesc'>(推荐上传宽高大于等于400*240像素的5:3尺寸的图片)</div>
                                                        </div>}
                                                    </Upload>
                                                </ImgCrop>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <h4 className='box'>楼盘信息</h4>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam">地区</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="areaId"
                                            >
                                                <Cascader className="sysSelect" options={options} onChange={onChangeAddressA} placeholder="请填写所在地区" />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='sysCol'>
                                        <div className="sysParam">详细地址</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="address"
                                            >
                                                <Input type="text" placeholder="可填入区域以及商圈，如：九龙太子、九龙旺角" maxLength={50}/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam">楼龄</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="buildingAge"
                                            >
                                                <InputNumber className='sysInputNumber' placeholder="楼龄" maxLength={11} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='sysCol'>
                                        <div className="sysParam">入伙年份</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="joinYear"
                                                >
                                                <DatePicker placeholder="入伙年份"
                                                    className="parts-numberInput datePickerAllWidth"
                                                    allowClear={false}
                                                    showTime={true}
                                                    picker="year"
                                                    format={Config.years} />
                                                    {/* className="parts-numberInput datePickerAllWidth"
                                                    allowClear={false}
                                                    showTime={true}
                                                    // picker="year"
                                                    format={Config.dateFormatItem2}
                                                     /> */}
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam">类别</div>
                                        <div className="sysInput">
                                        <Form.Item
                                            name="buildingType"
                                        >
                                            <Select className="sysSelect">
                                                <Option value=""></Option>
                                                {categoryList.map((item) => <Option key={item.code} value={item.code}>{item.parmValue1}</Option>)}
                                            </Select>
                                        </Form.Item>
                                        </div>
                                    </div>
                                    <div className='sysCol'>
                                        <div className="sysParam">坐向</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="sittingDirection"
                                            >
                                                <Input type="text" placeholder="坐向" maxLength={50}/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam">楼盘介绍</div>
                                        <div className="sysInputs">
                                            <Form.Item
                                                name="buildingIntro"
                                            >
                                                <TextArea placeholder="楼盘介绍" maxLength={250} rows={3}/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <h4 className='box'>房间信息</h4>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">楼层</div>
                                        <div className='storeWidth sysInput'>
                                            <div className="storeTypeInput">
                                                <Form.Item
                                                    name="storeType"
                                                    rules={[
                                                        { required: true, message: '楼层不能为空' },
                                                    ]}
                                                >
                                                    <Select className="sysSelect"  onChange={(e) => storeTypeChange(e)}>
                                                        <Option value=""></Option>
                                                        {context.storeTypeMap.map(item => <Option key={item.value} value={item.value}>{item.label}</Option>)}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            {isStoreType &&
                                                <div className="storeInput">
                                                    <Form.Item
                                                        name="store"
                                                        rules={[
                                                            { required: true, message: '楼层不能为空' },
                                                        ]}
                                                    >
                                                        <InputNumber className='sysInputNumber' placeholder="楼层" precision={0} maxLength={11} />
                                                    </Form.Item>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">房间及卫浴</div>
                                        <div className="sysMinInput">
                                            <Form.Item
                                                name="roomNumber"
                                                rules={[
                                                    { required: true, message: '房间数量不能为空' },
                                                ]}
                                            >
                                                <Select className="sysSelect">
                                                    {context.numberMap.map(item => <Option key={item.value} value={item.value}>{item.label}</Option>)}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                        室
                                        <div className="sysMinInput">
                                            <Form.Item
                                                name="hallNumber"
                                                rules={[
                                                    { required: true, message: '大厅数量不能为空' },
                                                ]}
                                            >
                                                <Select className="sysSelect">
                                                    {context.numberMap.map(item => <Option key={item.value} value={item.value}>{item.label}</Option>)}
                                                </Select>
                                            </Form.Item>
                                            
                                        </div>
                                        厅
                                        <div className="sysMinInput">
                                            <Form.Item
                                                name="bathroomNumber"
                                                rules={[
                                                    { required: true, message: '卫浴数量不能为空' },
                                                ]}
                                            >
                                                <Select className="sysSelect">
                                                    {context.numberMap.map(item => <Option key={item.value} value={item.value}>{item.label}</Option>)}
                                                </Select>
                                            </Form.Item>
                                            
                                        </div>
                                        卫
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">标签集</div>
                                        <div className="sysInputs">
                                            <Form.Item
                                                    name="houseTag"
                                                    rules={[
                                                        { required: true, message: '标签集不能为空' },
                                                    ]}
                                                >
                                                <Checkbox.Group options={houseTagList}  />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className="sysParam"></div>
                                    <div className="sysInput">
                                        <Input type="text" className='inputButton' placeholder="新建标签" maxLength={20} value={addHouseTagValue}  onChange={(e) => inputChange("addHouseTagValue", e.target.value)}/>
                                    </div>
                                    <Button className="buttonStyles" type="primary" onClick={() => addHouseTag()}>新建标签</Button>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">租金已包</div>
                                        <div className="sysInputs">
                                            <Form.Item
                                                    name="rentIncludes"
                                                    rules={[
                                                        { required: true, message: '租金已包不能为空' },
                                                    ]}
                                                >
                                                <Checkbox.Group options={rentIncludesList}  />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className="sysParam"></div>
                                    <div className="sysInput">
                                        <Input type="text"  className='inputButton' placeholder="新建其它" maxLength={20} value={addRentIncludesValue}  onChange={(e) => inputChangeTwo("addRentIncludesValue", e.target.value)}/>
                                    </div>
                                    <Button className="buttonStyles" type="primary" onClick={() => addRentIncludes()}>新建其它</Button>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam">房间介绍</div>
                                        <div className="sysInputs">
                                            <Form.Item
                                                name="roomIntro"
                                            >
                                                <TextArea placeholder="房间介绍" maxLength={250} rows={3}/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className="sysSaveButton">
                                    <Button className="sysBtn btnBule" type="primary" disabled={isLoad} htmlType="submit" icon={<CheckCircleOutlined />}>保存</Button>
                                    <Button className="sysBtn" onClick={cancel} icon={<CloseCircleOutlined />}>取消</Button>
                                </div>
                            </Form>
                            <Modal title="放大图" visible={previewVisible} footer={null} onCancel={() => setPreviewVisible(false)}>
                                <img alt="example" style={{ width: '100%' }} src={previewImage} />
                            </Modal>
                            {previewVideoVisible ?
                                <Modal title="视频预览" visible={true} footer={null} onCancel={() => setPreviewVideoVisible(false)}>
                                    <ReactPlayer 
                                        className="reactPlayer" 
                                        key={"1"}
                                        url={previewVideo}
                                        playing={true}
                                        width='100%'
                                        height="100%"
                                        controls
                                    ></ReactPlayer>
                                </Modal>
                                :null
                            }
                        </TabPane>
                    </Tabs>
                </Spin>
            </div>
        </div>
    )

}

export default WholeRentHouseSouce;