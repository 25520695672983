import React from 'react';
import './default.css';

const Default = (props) => {

    return (
        <div className="default">
        </div>
    );

}

export default Default;