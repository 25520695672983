import React, { useState, useEffect } from 'react';
import { Button, Input, Select, InputNumber } from 'antd';
import { CheckCircleOutlined, HomeOutlined } from '@ant-design/icons';
import moment from 'moment';//日期格式化导包
import Message from '../../component/message/message';//提示信息弹框
import { Config } from '../../public/js/config';
import { Base } from '../../public/js/base';

import SelectProductModal from './selectProductModal';
import SelectPictureModal from './selectPictureModal';
import './aptSetup.css';

const { Option } = Select;

const AptSetup = (props) => {

    const [messageShow, setMessageShow] = useState(false);
    const [messageStyle, setMessageStyle] = useState("");
    const [message, setMessage] = useState("");

    const [shopModel, setShopModel] = useState({});
    const [tables, setTables] = useState([]);
    const [detailTables, setDetailTables] = useState([]);
    const [isSelect, setIsSelect] = useState(false);
    const [isProductModal, setIsProductModal] = useState(false);
    const [isPictureModal, setIsPictureModal] = useState(false);
    const [rightType, setRightType] = useState("");
    const [rowNum, setRowNum] = useState(1);
    const [pictureId, setPictureId] = useState("");
    const [slectModel, setSlectModel] = useState({});
    const [picNavId, setPicNavId] = useState("");
    const [isPicNavPictureModal, setIsPicNavPictureModal] = useState(false);

    useEffect(() => {
        search();
    }, []);

    const search = () => {
        if(Config.funcInfo.canSelect === "N") {
            return;
        }
        messageShowHandler();  // 清除提示信息
        Base.callBackApi("aptSetup/search").then((data) => {
            if ("SUCCESS" === data.msg) {
                data.object.aptSetupList.map(item =>{
                    item.id = item.aptSetupId;
                    item?.detailList.map(itemTwo=>{
                        itemTwo.id = itemTwo.aptSetupDetailId;
                        if(item.aptSetupType === "PRODUCT" && Base.isNotEmpty(itemTwo.apartmentImg)) {
                            itemTwo.apartmentDefaultImg = itemTwo.apartmentImg.split(";")[0];
                        }
                    })
                })
                console.log(data.object.aptSetupList);
                setShopModel(JSON.parse(JSON.stringify(data.object)));
                setTables(data.object.aptSetupList ?? []);
                setIsSelect(false);
                setDetailTables([]);
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => {});
    }

    const errFrameShow = (style, msg) => {
        setMessage(msg);
        setMessageStyle(style);
        setMessageShow(true);
        document.documentElement.scrollTop = 0;
    }

    const messageShowHandler = () => {
        setMessage("");
        setMessageShow(false);
    }

    // 添加组件区块
    const toChangType = (value) => {
        let newModel = { id: "DIV"+moment(new Date()).format(Config.dateFormat3), aptSetupType: value, isSelect: false }
        if(value === "SLIDER"){
            newModel.detailList = [
                { id: "SLIDER"+moment(new Date()).format(Config.dateFormat3), linkName: "", link: "" }
            ];
        }
        let table = JSON.parse(JSON.stringify(tables));
        table.push(newModel)
        setTables(table);
    }

    // 选中组件区块
    const selectChange = (key) => {
        let newList = JSON.parse(JSON.stringify(tables));
        let newDetailTables = [];
        let rightType = "";
        let rowNum = 1;
        for(let i = 0; i< newList.length; i++){
            let itemModel = newList[i];
            if(itemModel.id === key){
                itemModel.isSelect = true;
                newDetailTables = itemModel.detailList ?? [];
                rightType = itemModel.aptSetupType;
                rowNum = itemModel.rowNum;
            }else{
                itemModel.isSelect = false;
            }
        }
        setTables(newList);
        setIsSelect(true);
        setDetailTables(JSON.parse(JSON.stringify(newDetailTables)));
        setRightType(JSON.parse(JSON.stringify(rightType)));
        setRowNum(rowNum);
    }

    // 删除组件区块
    const onRemove = (model) => {
        let index = tables.findIndex(item => item.id === model.id);
        if(index !== -1) {
            tables.splice(index, 1);
        }
        setTables(JSON.parse(JSON.stringify(tables)));
    }

    // 商品弹框回调方法
    const closeProductModal = (flag, list) => {
        if(flag) {
            list.forEach((item) => {
                item.comprehensiveId = item.apartmentId;
                item.id = item.apartmentId + moment(new Date()).format(Config.dateFormat3)
            });
            let newList = [];
            tables.forEach(item => {
                if(item.isSelect) {
                    if(Base.isNotEmpty(item.detailList)){
                        item.detailList = [...item.detailList, ...list];
                    }else{
                        item.detailList = list
                    }
                    let num = 1;
                    item.detailList.forEach((item)=>{
                        item.dispSeq = num++;
                    })
                    newList = item.detailList;
                }
            });
            setTables(JSON.parse(JSON.stringify(tables)));
            setDetailTables(JSON.parse(JSON.stringify(newList)));
        }
        setIsProductModal(false);
    }

    //商品升序方法
    const ascending = (model, id) => {
        let num = model.dispSeq-1;
        if(model.dispSeq != 1){
            detailTables.forEach((item)=>{
                if(item.id === id){
                    item.dispSeq = item.dispSeq-1;
                }
                if(item.id !== id && item.dispSeq === num){
                    item.dispSeq = item.dispSeq+1;
                }
            })
            detailTables.sort(function (a, b) {
                return a.dispSeq - b.dispSeq;
            });
            tables.forEach(item => {
                if(item.isSelect) {
                    item.detailList = detailTables;
                }
            });
            setTables(JSON.parse(JSON.stringify(tables)));
            setDetailTables(JSON.parse(JSON.stringify(detailTables)));
        }
    }

    //商品降序方法
    const descending = (model, id) => {
        let num = model.dispSeq+1;
        if(model.dispSeq != detailTables.length){
            detailTables.forEach((item)=>{
                if(item.id === id){
                    item.dispSeq = item.dispSeq+1;
                }
                if(item.id !== id && item.dispSeq === num){
                    item.dispSeq = item.dispSeq-1;
                }
            })
            detailTables.sort(function (a, b) {
                return a.dispSeq - b.dispSeq;
            });
            tables.forEach(item => {
                if(item.isSelect) {
                    item.detailList = detailTables;
                }
            });
            setTables(JSON.parse(JSON.stringify(tables)));
            setDetailTables(JSON.parse(JSON.stringify(detailTables)));
        }
    }

    // 商品删除方法
    const onRemoveProduct = (key)=> {
        let index = detailTables.findIndex(item => item.id === key);
        if(index !== -1) {
            detailTables.splice(index, 1);
        }
        tables.forEach(item => {
            if(item.isSelect) {
                let detailList = item.detailList;
                let indexTwo = detailList.findIndex(item => item.id === key);
                if(indexTwo !== -1) {
                    detailList.splice(index, 1);
                }
            }
        })
        setTables(JSON.parse(JSON.stringify(tables)));
        setDetailTables(JSON.parse(JSON.stringify(detailTables)));
    }

    // 文本导航
    const toChangNavType = (value) => {
        let newModel = { id: "NAV"+moment(new Date()).format(Config.dateFormat3), aptSetupDetailType: value, linkName: "", link: "" }
        detailTables.push(newModel);
        setDetailTables(JSON.parse(JSON.stringify(detailTables)));
    }

    // 文本导航删除方法
    const onRemoveNav = (key) => {
        let index = detailTables.findIndex(item => item.id === key);
        if(index !== -1) {
            detailTables.splice(index, 1);
        }
        tables.forEach(item => {
            if(item.isSelect) {
                item.detailList = detailTables;
            }
        })
        setTables(JSON.parse(JSON.stringify(tables)));
        setDetailTables(JSON.parse(JSON.stringify(detailTables)));
    }

    // 文本导航 栏目名称Change方法
    const navModelChange = (id, key, value) => {
        detailTables.forEach(item => {
            if(item.id === id) {
                item[key] = value;
            }
        })
        setDetailTables(JSON.parse(JSON.stringify(detailTables)));
    }

    // 文本导航 栏目名称失去焦点方法
    const navBlurChange = () => {
        tables.forEach(item => {
            if(item.isSelect) {
                item.detailList = detailTables;
            }
        })
        setTables(JSON.parse(JSON.stringify(tables)));
    }

    // 图片导航
    const toChangPicNavType = (value) => {
        if(detailTables.length === 0){
            let newModel = { id: "PIC_NAV"+moment(new Date()).format(Config.dateFormat3), aptSetupDetailType: value, linkName: "", link: "" }
            detailTables.push(newModel);
            setDetailTables(JSON.parse(JSON.stringify(detailTables)));
        }else{
            errFrameShow("error", "只能添加一个图片导航");
        }
    }

    // 图片导航删除方法
    const onRemovePicNav = (key) => {
        messageShowHandler();
        let index = detailTables.findIndex(item => item.id === key);
        if(index !== -1) {
            detailTables.splice(index, 1);
        }
        tables.forEach(item => {
            if(item.isSelect) {
                item.detailList = detailTables;
            }
        })
        setTables(JSON.parse(JSON.stringify(tables)));
        setDetailTables(JSON.parse(JSON.stringify(detailTables)));
    }

    // 图片导航 选择图片事件
    const selectPicNavPicture = (model) => {
        setSlectModel(JSON.parse(JSON.stringify(model)));
        setPicNavId(model.id);
        setIsPicNavPictureModal(true);
    }

    // 图片导航 选择图片弹框回调方法
    const closePicNavPictureModal = (flag, model) => {
        if(flag) {
            detailTables.forEach((item) => {
                if(item.id === picNavId) {
                    item.comprehensiveId = model.id;
                    item.linkName = model.headPortrait;
                }
            })
            tables.forEach(item => {
                if(item.isSelect) {
                    item.detailList = detailTables;
                }
            })
            setTables(JSON.parse(JSON.stringify(tables)));
            setDetailTables(JSON.parse(JSON.stringify(detailTables)));
        }
        setIsPicNavPictureModal(false);
    }

    // 添加轮播图
    const sliderAdd = () => {
        let newList = JSON.parse(JSON.stringify(detailTables));
        let itemModel = { id: "SLIDER"+moment(new Date()).format(Config.dateFormat3), imgsrc: "", imglink: "" };
        newList.push(itemModel);
        setDetailTables(newList);
    }

    // 删除轮播图
    const onRemoveSlider = (key) => {
        let index = detailTables.findIndex(item => item.id === key);
        if(index !== -1) {
            detailTables.splice(index, 1);
        }
        tables.forEach(item => {
            if(item.isSelect) {
                item.detailList = detailTables;
            }
        })
        setTables(JSON.parse(JSON.stringify(tables)));
        setDetailTables(JSON.parse(JSON.stringify(detailTables)));
    }

    // 选择图片事件
    const selectPicture = (model) => {
        setSlectModel(JSON.parse(JSON.stringify(model)));
        setPictureId(model.id);
        setIsPictureModal(true);
    }

    // 选择图片弹框回调方法
    const closePictureModal = (flag, model) => {
        if(flag) {
            detailTables.forEach((item) => {
                if(item.id === pictureId) {
                    item.comprehensiveId = model.id;
                    item.linkName = model.headPortrait;
                    // item.link = model.productId;
                }
            })
            tables.forEach(item => {
                if(item.isSelect) {
                    item.detailList = detailTables;
                }
            })
            setTables(JSON.parse(JSON.stringify(tables)));
            setDetailTables(JSON.parse(JSON.stringify(detailTables)));
        }
        setIsPictureModal(false);
    }

    const save = () => {
        if(Base.isEmpty(tables)){
            errFrameShow("error", "数据不能为空");
            return;
        }
        let str = check(tables);
        if(str) {
            errFrameShow("error", str);
            return;
        }
        let model = shopModel;
        model.aptSetupList = tables;
        Base.callBackApi("aptSetup/save", model).then((data) => {
            if ("SUCCESS" === data.msg) {
                search();
                errFrameShow("success", "保存成功");
            } else {
                errFrameShow("error", Base.isEmpty(data?.msg) ? "保存失败" : data?.msg );
            }
        }).catch(err => { }).finally(() => {});
    }

    const check = (list) => {
        let str = "";
        list.forEach(item => {
            switch(item.aptSetupType) {
                case "PRODUCT":
                    if(Base.isEmpty(item.detailList)) {
                        str += "请正确配置公寓展示;";
                    }
                    break;
                case "NAV":
                    if(Base.isEmpty(item.detailList)) {
                        str += "请正确配置文本导航;";
                    }
                    break;
                case "PIC_NAV":
                        if(Base.isEmpty(item.detailList)) {
                            str += "请正确配置活动导航;";
                        }
                        break; 
                case "SLIDER":
                    if(Base.isEmpty(item.detailList)) {
                        str += "请正确配置轮播图;";
                    }
                    break;
                default:
                    break;
            }
        });
        return str;
    }

    return (
        <div id="container" className="template aptSetup">
            <div className='breadcrumb'>
                <HomeOutlined /> / 其他 / <span className='breadcrumbTitle'>品牌公寓装修</span>
            </div>
            <div className='templateMain'>
                <div className="sysModelTitle">品牌公寓装修</div>
                {/* 提示信息弹框 */}
                {messageShow ? <Message style={messageStyle} content={message} close={messageShowHandler} /> : ''}
                {/* 图片添加弹框 */}
                {isPictureModal ? <SelectPictureModal onClose={closePictureModal} slectModel={slectModel} pictureCategory="APARTMENT_ROTATION_CHART"/> : null}
                {/* 公寓添加弹框 */}
                {isProductModal ? <SelectProductModal onClose={closeProductModal} selectList={[]} btnType="checkBox"/> : null}
                {/* 图片添加弹框 */}
                {isPicNavPictureModal ? <SelectPictureModal onClose={closePicNavPictureModal} slectModel={slectModel} pictureCategory="PIC_NAV"/> : null}
                <div className='aptSetupMain'>
                    <div className="widgets">
                        <ul>
                            <li className="widgetsItems" onClick={()=>toChangType("PRODUCT")}><i className="fa fa-th-large"><br/>公寓展示</i></li>
                            <li className="widgetsItems" onClick={()=>toChangType("NAV")}><i className="fa fa-bars"><br/>文本导航</i></li>
                            <li className="widgetsItems" onClick={()=>toChangType("PIC_NAV")}><i className="fa fa-bars"><br/>图片导航</i></li>
                            <li className="widgetsItems" onClick={()=>toChangType("SLIDER")}><i className="fa fa-film"><br/>轮播图</i></li>
                        </ul>
                    </div>
                    {/* 控制层 */}
                    <div className="control">
                        <div className="dd" id="decorateControl">
                            <ol className="ddList">
                                {tables.map((item) => 
                                    <li key={item.id}
                                        className={"ddItem decorateControlItems" + (item.isSelect ? " decorateActive" : "")}
                                        onClick={()=>selectChange(item.id)}>
                                        <div className="ddHandle"></div>
                                    </li>
                                )}
                            </ol>
                        </div>
                    </div>
                    <div className="layout">
                        <div className="layoutHeader"></div>
                        <div className="layoutBobdy">
                            <div className="layoutView">
                                {tables.map((item) => 
                                    <div className={"decorateLayoutItems" + (item.isSelect ? " decorateActive" : "")} key={item.id}
                                        onClick={()=>selectChange(item.id)}>
                                            {item.aptSetupType === "PRODUCT" ?
                                                <div className="widgetsGoodsLike">
                                                    <div className="proListGrid">
                                                        {Base.isNotEmpty(item.detailList) ?
                                                            item.detailList.map((record)=>
                                                                <div className="productItem" key={record.id}>
                                                                    <img className="productImg" src={Config.apartmentPath + record.apartmentImg?.split(";")[0]?.replace(/\\/g, "/")} alt=""/>
                                                                    <div className="goodsTitle">{record.apartmentTitle}</div>
                                                                    <div className="goodsHouseTag">
                                                                        {record.houseTag?.split('|').map((rec,ind)=>
                                                                            <div className="descBox" key={ind}>{rec}</div>
                                                                        )}
                                                                    </div>
                                                                    <div className="productPrice">HKD{record.rentalPrice}</div>
                                                                    <div className="productAddress">{record.province}/{record.city}/{record.country}</div>
                                                                </div>
                                                            )
                                                        :
                                                            <>
                                                                <div className="productItem">
                                                                    <img className="productImg" src="https://via.placeholder.com/180x180" alt=""/>
                                                                    <div className="caption">
                                                                        <div className="goodsTitle">公寓标题</div>
                                                                        <div className="goodsHouseTag">
                                                                            <div className="descBox">公寓标签</div>
                                                                        </div>
                                                                        <div className="productPrice">HKD300</div>
                                                                    </div>
                                                                </div>
                                                                <div className="productItem">
                                                                    <img className="productImg" src="https://via.placeholder.com/180x180" alt=""/>
                                                                    <div className="caption">
                                                                        <div className="goodsTitle">公寓标题</div>
                                                                        <div className="goodsHouseTag">
                                                                            <div className="descBox">公寓标签</div>
                                                                        </div>
                                                                        <div className="productPrice">HKD300</div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            : item.aptSetupType === "NAV" ?
                                                <div className="shopNav">
                                                    {Base.isNotEmpty(item.detailList) ?
                                                        item.detailList.map((record)=>
                                                            <div className="shopNavItem" key={record.id}>
                                                                <a>{record?.linkName}</a>
                                                            </div>
                                                        )
                                                    :
                                                        <>
                                                            <div className="shopNavItem">内容</div>
                                                            <div className="shopNavItem">内容</div>
                                                        </>
                                                    }
                                                </div>
                                            : item.aptSetupType === "PIC_NAV" ?
                                                <div className="shopPicNav">
                                                    {Base.isNotEmpty(item.detailList) ?
                                                        item.detailList.map((record)=>
                                                            <div className="shopPicNavItem" key={record.id}>
                                                                <a ><img src={Config.pictureScrollPath + record.linkName} /></a>
                                                            </div>
                                                        )
                                                    :
                                                        <div className="shopPicNavItem">
                                                            <a ><img src="https://via.placeholder.com/180x180" alt="" /></a>
                                                        </div>
                                                    }
                                                </div>
                                            : item.aptSetupType === "SLIDER" ?
                                                <div className="shopexSlider">
                                                    <div className="shopexSliderGroup shopexSliderLoop shopexSliderStyle">
                                                        {item.detailList.map((record)=>
                                                            <div className="shopexSliderItem" key={record.id}>
                                                                <a ><img src={Config.pictureScrollPath + record.linkName} /></a>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="shopexSliderIndicator">
                                                        {item.detailList.map((record, index)=>
                                                            <div className={"shopexIndicator" + (index === 0 ? " shopexActive" : "")} key={record.id}></div>
                                                        )}
                                                    </div>
                                                </div>
                                            : <></> }
                                        <div className="decorate-action-remove" hidden={!item.isSelect} onClick={()=>onRemove(item)}><i className="fa fa-trash"></i></div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="rightForm">
                    {isSelect ? 
                            rightType === "PRODUCT" ?
                                <div className="product">
                                    <h5>公寓展示</h5>
                                    <div className="form-group">
                                        <p className="help-block">已选择公寓</p>
                                        <div className="selected_goods">
                                            {detailTables.map((item) => 
                                                <div className="form-group goodsList" key={item.id}>
                                                    <div className="productImg">
                                                        <img src={Config.apartmentPath + item.apartmentImg?.split(";")[0]?.replace(/\\/g, "/")} alt=""/>
                                                    </div>
                                                    <div className="productInfo">
                                                        <div className="productTitle">{item.apartmentTitle}</div>
                                                        <div className="productHouseTag">
                                                            {item.houseTag?.split('|').map((record,index)=>
                                                                <div className="descBox" key={index}>{record}</div>
                                                            )}
                                                        </div>
                                                        <div className="salesPriceBox">HKD{item.rentalPrice}</div>
                                                        <div className="productAddress">{item.province}/{item.city}/{item.country}</div>
                                                    </div>
                                                    <div className="sort" onClick={()=>ascending(item, item.id)}>
                                                        <i className="fa fa-caret-up"></i>
                                                        <div>升序</div>
                                                    </div>
                                                    <div className="sort" onClick={()=>descending(item, item.id)}>
                                                        <i className="fa fa-caret-down"></i>
                                                        <div>降序</div>
                                                    </div>
                                                    <div className="productRemove" onClick={()=>onRemoveProduct(item.id)}>
                                                        <i className="fa fa-trash"></i>
                                                        <div>删除</div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <Button className="shop-coupon-btn" type="primary" onClick={() => {setIsProductModal(true)}}>添加公寓</Button>
                                    </div>
                                </div>
                            : rightType === "NAV" ?
                                <div className="nav">
                                    <h5>文本导航</h5>
                                    <div className="nav-items-list">
                                        {detailTables.map((item, index) => 
                                            item.aptSetupDetailType === "CUSTOM" ?
                                                <div className="form-group form-item" key={item.id}>
                                                    <div className="form-group">
                                                        <Input type="text" maxLength={50} value={item?.linkName || ""} placeholder="栏目名称"
                                                            onChange={(e) => navModelChange(item.id, "linkName", e.target.value)}
                                                            onBlur={navBlurChange} />
                                                    </div>
                                                    <div className="form-group">
                                                        <Input type="text" maxLength={100} value={item?.link || ""} placeholder="链接地址"
                                                            onChange={(e) => navModelChange(item.id, "link", e.target.value)}
                                                            onBlur={navBlurChange}/>
                                                    </div>
                                                    <div className="form-item-action" onClick={()=>onRemoveNav(item.id)}><i className="fa fa-times fa-lg"></i></div>
                                                </div>
                                            // : item.aptSetupDetailType === "PRODUCT" ?
                                            //     <div className="form-group form-item" key={item.id}>
                                            //         <div className="input-group">
                                            //             <Input className="navLinkName" maxLength={50} type="text" value={item?.linkName || ""} placeholder="栏目名称"
                                            //                 onChange={(e) => navModelChange(item.id, "linkName", e.target.value)}
                                            //                 onBlur={navBlurChange} />
                                            //             <span className="input-group-addon" onClick={()=> selectNavPicture(item)}>选择商品</span>
                                            //         </div>
                                            //         <div className="form-item-action" onClick={()=>onRemoveNav(item.id)}><i className="fa fa-times fa-lg"></i></div>
                                            //         {Base.isNotEmpty(item.productTitle) ?
                                            //             <div className="form-group">
                                            //                 <p className="help-block">已选择商品</p>
                                            //                 <div className="selected_goods">
                                            //                     <div className="form-group goodsList">
                                            //                         <div className="productImg">
                                            //                             <img src={Config.apartmentPath + item.apartmentImg?.split(";")[0]?.replace(/\\/g, "/")} alt=""/>
                                            //                         </div>
                                            //                         <div className="productInfo">
                                            //                             <div className="productTitle">{item.productTitle}</div>
                                            //                             <div className="salesPrice">销售价￥{Base.numberFormat(item.rentalPrice, 2)}</div>
                                            //                         </div>
                                            //                     </div>
                                            //                 </div>
                                            //             </div>
                                            //         : ''}
                                            //     </div>
                                            // : item.aptSetupDetailType === "ACTIVITY" ?
                                            //     <div className="form-group form-item" key={item.id}>
                                            //         <div className="input-group">
                                            //             <Input className="navLinkName" maxLength={50} type="text" value={item?.linkName || ""} placeholder="栏目名称"
                                            //                 onChange={(e) => navModelChange(item.id, "linkName", e.target.value)}
                                            //                 onBlur={navBlurChange} />
                                            //             <span className="input-group-addon" onClick={()=> selectNavActivity(item)}>选择活动</span>
                                            //         </div>
                                            //         <div className="form-item-action" onClick={()=>onRemoveNav(item.id)}><i className="fa fa-times fa-lg"></i></div>
                                            //     </div>
                                            : <></>
                                        )}
                                    </div>
                                    <h5 className="text-muted">请选择下列新增栏目类型</h5>
                                    <div className="btn-group-justified">
                                        <Button className="shop-nav-btn" type="primary" onClick={()=>toChangNavType("CUSTOM")}>自定义链接</Button>
                                        {/* <Button className="shop-nav-btn" type="primary" onClick={()=>toChangNavType("PRODUCT")}>产品索引</Button> */}
                                        {/* <Button className="shop-nav-btn" type="primary" onClick={()=>toChangNavType("ACTIVITY")}>活动索引</Button> */}
                                    </div>
                                </div>
                            : rightType === "PIC_NAV" ?
                                <div className="nav slider">
                                    <h5>图片导航</h5>
                                    <div className="nav-items-list">
                                        {detailTables.map((item, index) => 
                                            item.aptSetupDetailType === "CUSTOM" ?
                                                <div className="form-group form-item" key={item.id}>
                                                    <div className="form-group">
                                                        <Input className="imgsrc" value={item.linkName  || ""} type="text" disabled placeholder="图片地址"/>
                                                        <span className="input-group-addon" onClick={()=> selectPicNavPicture(item)}>选择图片</span>
                                                    </div>
                                                    <div className="form-group">
                                                        <Input type="text" maxLength={100} value={item?.link || ""} placeholder="链接地址"
                                                            onChange={(e) => navModelChange(item.id, "link", e.target.value)}
                                                            onBlur={navBlurChange}/>
                                                    </div>
                                                    <div className="form-item-action" onClick={()=>onRemovePicNav(item.id)}><i className="fa fa-times fa-lg"></i></div>
                                                </div>
                                            : <></>
                                        )}
                                    </div>
                                    <h5 className="text-muted">请选择下列新增栏目类型</h5>
                                    <div className="btn-group-justified">
                                        <Button className="shop-nav-btn" type="primary" onClick={()=>toChangPicNavType("CUSTOM")}>自定义链接</Button>
                                    </div>
                                </div>
                            : rightType === "SLIDER" ?
                                <div className="slider">
                                    <h5>轮播图</h5>
                                    {detailTables.map((item, index)=>
                                        <div className="slider-items-list" key={index}>
                                            <div className="slider-form-item">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <Input className="imgsrc" value={item?.linkName || ""} type="text" disabled placeholder="图片地址"
                                                        />
                                                        <span className="input-group-addon" onClick={()=> selectPicture(item)}>选择图片</span>
                                                    </div>
                                                </div>
                                                <div hidden={index === 0} className="form-item-action" onClick={()=>onRemoveSlider(item.id)}><i className="fa fa-times fa-lg"></i></div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="form-group">
                                        <Button className="shop-coupon-btn" type="primary" onClick={sliderAdd}>添加</Button>
                                    </div>
                                </div>
                            : <></>
                        : <div className="decorate-form-tip">请选择挂件</div> }
                    </div>
                    <div className="footbar">
                        <Button type="primary" onClick={save} icon={<CheckCircleOutlined />}>保存</Button>
                    </div>
                </div>
            </div>
        </div>
    )

}
export default AptSetup;