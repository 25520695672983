import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Button, Table, Form, InputNumber, Spin, Select, Input, Tabs, Space, Modal, Upload, Switch, Tooltip, Checkbox } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, SearchOutlined, RedoOutlined, PlusCircleOutlined, EditOutlined, DeleteOutlined, EyeOutlined, PlusOutlined, HomeOutlined } from '@ant-design/icons';
import Message from '../../component/message/message';//提示信息弹框
import PopupFrame from '../../component/popupFrame/popupFrame';//删除弹框
import Audit from '../../component/audit/audit';//审核彈框
import { Config } from '../../public/js/config';
import { Base } from '../../public/js/base';
import ReactPlayer from 'react-player';
import context from '../../public/js/context';

const { TabPane } = Tabs;
const { Option } = Select;
const ClassicEditor = window.ClassicEditor;

const AptRoom = (props) => {

    const history = useHistory();
    const [form] = Form.useForm();
    const [isLoad, setIsLoad] = useState(false);
    const [spinInfo, setSpinInfo] = useState("");
    const [isAudit, setIsAudit] = useState(false);
    const [auditModel, setAuditModel] = useState({});
    const [messageShow, setMessageShow] = useState(false);
    const [messageStyle, setMessageStyle] = useState("");
    const [message, setMessage] = useState("");
    const [tabIndex, setTabIndex] = useState("1");
    const [searchModel, setSearchModel] = useState({aptRoomStatus: "Y"});
    const [oldSearchModel, setOldSearchModel] = useState({});
    const [tables, setTables] = useState([]);
    const [tablesCount, setTablesCount] = useState(0);
    const [current, setCurrent] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [checkboxState, setCheckboxState] = useState([]);
    const [type, setType] = useState("");
    const [content, setContent] = useState("");
    const [frameType, setFrameType] = useState("");
    const [isPopupFrame, setIsPopupFrame] = useState(false);
    const [flag, setFlag] = useState("");
    const [imageFile, setImageFile] = useState([]);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);
    const [videoFile, setVideoFile] = useState([]);
    const [previewVideoVisible, setPreviewVideoVisible] = useState(false);
    const [previewVideo, setPreviewVideo] = useState(null);
    const [ckeditor, setCkeditor] = useState(null);
    const [checkList, setCheckList] = useState([
        { label: "有家具", value: "有家具" },
        { label: "有电器", value: "有电器" },
        { label: "采光好", value: "采光好" },
        { label: "视野好", value: "视野好" },
    ]);
    const [tagList, setTagList] = useState([
        { label: "床", value: "床" },
        { label: "床上用品", value: "床上用品" },
        { label: "灯具", value: "灯具" },
        { label: "桌子", value: "桌子" },
        { label: "衣柜", value: "衣柜" },
        { label: "桌椅", value: "桌椅" },
    ]);
    const [tagListTwo, setTagListTwo] = useState([
        { label: "冰箱", value: "冰箱" },
        { label: "插座", value: "插座" },
        { label: "空调", value: "空调" },
        { label: "洗烘一体机", value: "洗烘一体机" },
    ]);
    const [tagListThree, setTagListThree] = useState([
        { label: "油烟机", value: "油烟机" },
        { label: "微波炉", value: "微波炉" },
    ]);
    const [serviceList, setServiceList] = useState([
        { label: "房屋清洁", value: "房屋清洁" },
    ]);
    const [costList, setCostList] = useState([
        { label: "水费", value: "水费" },
        { label: "电费", value: "电费" },
        { label: "网络费", value: "网络费" },
    ]);
    const [aptRoomModel, setAptRoomModel] = useState({});
    const [updateStatusModel, setUpdateStatusModel] = useState({});
    const [apartmentList, setApartmentList] = useState([]);

    useEffect(() => {
        searchAll();
        search(pageSize, 1, "N");
        ClassicEditor.create(document.querySelector('#editor'), {...Config.ckEditorConfig, ...{simpleUpload: { uploadUrl: Config.apiServer + "ckEditorUploadImg/uploadAptRoomDescImg" }}})
        .then(editor => {
            setCkeditor(editor);
        }).catch(error => { console.log(error) });
    }, []);

    const searchAll = () => {
        Base.callBackApi("apartment/searchAll").then((data) => {
            if ("SUCCESS" === data.msg) {
                setApartmentList(data.object);
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { });
    }

    const search = (pageSizeNew, currentPageNew, flag, isShowMsg) => {
        if(Config.funcInfo.canSelect === "N") {
            return;
        }
        messageShowHandler();  // 清除提示信息
        clearSelect(); // 清除选中状态
        let model = {};
        if ("Y" === flag) {     // 翻页
            model = oldSearchModel;
        } else {
            model = JSON.parse(JSON.stringify(searchModel));
        }
        model.page = currentPageNew;
        model.pageSize = pageSizeNew;
        setIsLoad(true);
        setSpinInfo("查询中...");
        Base.callBackApi("aptRoom/search", model).then((data) => {
            if ("SUCCESS" === data.msg) {
                if (!data?.object?.list && isShowMsg) {
                    errFrameShow("info", '找不到符合条件记录.');
                }
                if("N" === flag) {
                    setOldSearchModel(JSON.parse(JSON.stringify(model)));
                }
                data?.object?.list?.forEach(item => {
                    if(item.aptRoomImg) {
                        item.aptRoomDefaultImg = item.aptRoomImg.split(";")[0];
                    }
                })
                setTablesCount(data?.object?.total ?? 0);
                setTables(data?.object?.list ?? []);
                setCurrent(currentPageNew);
                setPageSize(pageSizeNew);
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }

    const searchChange = (key, value) => {
        messageShowHandler();
        let model = JSON.parse(JSON.stringify(searchModel));
        model[key] = value;
        setSearchModel(model);
    }
    
    const reset = () => {
        messageShowHandler();
        clearSelect();
        setSearchModel({});
        setOldSearchModel({});
        setTablesCount(0);
        setTables([]);
        setPageSize(10);
    }

    // Tab页切换
    const handleSelect = (key) => {
        if (key) {
            if(key === "2") {
                if(checkboxState.length === 1 && Config.funcInfo.canUpdate === "Y") {
                    edit();
                } else if(Config.funcInfo.canInsert === "Y") {
                    add();
                }
            } else if(tabIndex !== "1") {
                setType("leave");
                setContent();
                setFrameType("leave");
                setIsPopupFrame(true);
            }
        }
    }

    const add = () => {
        messageShowHandler();
        initForm();
        ckeditor.setData('');
        setImageFile([]);
        setVideoFile([]);
        setFlag("add");
        setTabIndex("2");
    }

    const initForm = (model = {}) => {
        form.resetFields();
        form.setFieldsValue({
            aptRoomTitle: model.aptRoomTitle ?? '',
            apartmentId: model.apartmentId ?? '',
            aptRoomType: model.aptRoomType ?? '',
            aptRoomTag: model.aptRoomTag?.split('|') ?? '',
            rentalPrice: model.rentalPrice ?? '',
            furnitureFacilities: model.furnitureFacilities?.split('|') ?? '',
            electricalFacilities: model.electricalFacilities?.split('|') ?? '',
            kitchenFacilities: model.kitchenFacilities?.split('|') ?? '',
            expenseDetails: model.expenseDetails?.split('|') ?? '',
            aptRoomServices: model.aptRoomServices?.split('|') ?? '',
        });
    }

    const edit = () => {
        messageShowHandler();
        if(!selectedRowKeys || selectedRowKeys.length !== 1) {
            errFrameShow("error", "请选择一条数据");
            return;
        }
        searchById(selectedRowKeys[0])
    }

    const update = (model, e) => {
        e.stopPropagation();
        messageShowHandler();
        setCheckboxState([model]);
        setSelectedRowKeys([model.aptRoomId]);
        searchById(model.aptRoomId)
    }

    const searchById = (id) => {
        Base.callBackApi("aptRoom/searchById", id).then((data) => {
            if ("SUCCESS" === data.msg) {
                let model = data.object ?? {};
                setAptRoomModel(model);
                initForm(model);
                ckeditor.setData(model.aptRoomDesc);
                setImageFile(
                    model.aptRoomImg?.split(";")
                        ?.map(item => {
                            return {
                                url: Config.aptRoomImg + item,
                                originUrl: item,
                                thumbUrl: Config.aptRoomPath + item,
                                imgStatus: "OLD"
                            };
                        })
                    || []
                );
                if(model.aptRoomVideo){
                    let str = model.aptRoomVideo.replace("/","\\");
                    let arr = str.split("\\");
                    let newArr = arr[1].split(".");
                    let videoFile = [{
                        url: Config.aptRoomVideo + model.aptRoomVideo,
                        originUrl: model.aptRoomVideo,
                        name: arr[1],
                        thumbUrl:Config.aptRoomVideo + arr[0] + "\\" + "previewImg_" + newArr[0] + ".jpg",
                        imgStatus: "OLD"
                    }]
                    setVideoFile(videoFile);
                }else{
                    setVideoFile([]);
                }
                setFlag("edit");
                setTabIndex("2");
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }

    const popupFrameClose = (state) => {
        if(state) {
            if(frameType === "delete") {
                deleteData();
            } else if(frameType === "leave") {
                cancel();
            } else {
                updateStatus();
            }
        }
        setIsPopupFrame(false);
    }

    const deleteOne = (model, e) => {
        e.stopPropagation();
        setCheckboxState([model]);
        setSelectedRowKeys([model.aptRoomId]);
        setType();
        setContent();
        setFrameType("delete");
        setIsPopupFrame(true);
    }

    const showTips = (flag, record) => {
        setUpdateStatusModel({
            aptRoomId: record.aptRoomId,
            aptRoomStatus: flag ? 'Y' : 'N',
            lastUpdatedDate: record.lastUpdatedDate
        });
        setType();
        setContent("确定要" + (flag ? "上架" : "下架") + "该商品吗？");
        setFrameType("update");
        setIsPopupFrame(true);
    }

    const updateStatus = () => {
        setIsLoad(true);
        setSpinInfo("数据保存中...")
        updateStatusModel.menuFuncId = Config.funcInfo.funcId;
        Base.callBackApi("aptRoom/updateStatus", updateStatusModel).then((data) => {
            if ("SUCCESS" === data.msg) {
                search(pageSize, current, "Y", false);
                errFrameShow("success", "保存成功");
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }

    const deleteData = () => {
        setIsLoad(true);
        setSpinInfo("数据保存中...");
        let deleteModel = checkboxState[0];
        deleteModel.menuFuncId = Config.funcInfo.funcId;
        Base.callBackApi("aptRoom/delete", deleteModel).then((data) => {
            if ("SUCCESS" === data.msg) {
                search(pageSize, current, "Y", false);
                errFrameShow("success", "刪除成功");
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }

    const audit = () => {
        messageShowHandler();//清空提示信息
        if(!checkboxState || checkboxState.length !== 1) {
            errFrameShow("error", "请选择一条数据");
            return;
        }
        setAuditModel(checkboxState[0]);
        setIsAudit(true);
    }

    const auditCancel = () => {
        setIsAudit(false);
    }

    const cancel = () => {
        messageShowHandler();
        clearSelect();
        ckeditor.setData('');
        setVideoFile([]);
        setImageFile([]);
        setPreviewVisible(false);
        setPreviewImage(null);
        setTabIndex("1");
    }

    const onFinish = (values) => {
        messageShowHandler();
        setIsLoad(true);
        setSpinInfo("数据保存中...")
        let url = "";
        if(flag === "add") {
            url = "aptRoom/insert";
        } else {
            url = "aptRoom/update"
            values.aptRoomId = aptRoomModel.aptRoomId;
            values.lastUpdatedDate = aptRoomModel.lastUpdatedDate;
        }
        let videoUploadModelList = videoFile.map(item => {
            return {
                status: item.imgStatus,
                url: item.originUrl,
            };
        });
        values.videoUploadModelList = videoUploadModelList;
        let imageUploadModelList = imageFile.map(item => {
            return {
                status: item.imgStatus,
                url: item.originUrl,
            };
        });
        values.imageUploadModelList = imageUploadModelList;
        values.aptRoomDesc = ckeditor.getData();
        values.menuFuncId = Config.funcInfo.funcId;
        values.aptRoomTag = values.aptRoomTag.join('|');
        values.furnitureFacilities = values.furnitureFacilities.join('|');
        values.electricalFacilities = values.electricalFacilities.join('|');
        values.kitchenFacilities = values.kitchenFacilities.join('|');
        values.expenseDetails = values.expenseDetails.join('|');
        values.aptRoomServices = values.aptRoomServices.join('|');
        Base.callBackApi(url, values).then((data) => {
            if ("SUCCESS" === data.msg) {
                search(pageSize, current, "Y", false);
                ckeditor.setData('');
                errFrameShow("success", "保存成功");
                setTabIndex("1");
            } else {
                errFrameShow("error", data.msg);
            }
        }).catch(err => { }).finally(() => setIsLoad(false));
    }

    const handleChange = (file) => {
        let fileList = file.fileList;
        file = file.file;
        if(file.status === "removed") {
            let index = imageFile.findIndex(item => item.uid == file.uid);
            let fileList = JSON.parse(JSON.stringify(imageFile));
            fileList.splice(index, 1)
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = (e) => { 
                fileList[fileList.length - 1].originUrl = e.target.result; 
                fileList[fileList.length - 1].imgStatus = "NEW";
            }
        }
        setImageFile(fileList);
    }

    /**查看图片 */
    const handlePreview = (file) => {
        setPreviewImage(file.url || file.originUrl);
        setPreviewVisible(true);
    }

    const checkImg = (value) => {
        if(!!imageFile && imageFile.length > 0) {
            if(value) {
                let list = value.fileList;
                if(list.find(item => item.status === "uploading")) {
                    return Promise.reject("请等待图片上传完成");
                }
                if(list.find(item => item.status === "error")) {
                    return Promise.reject("请先处理测试上传失败的图片");
                }
            }
            return Promise.resolve();
        }
        return Promise.reject("图片数量必须大于0");
    }

    const checkVideo = (value) => {
        if(value) {
            let list = value.fileList;
            if(list.find(item => item.status === "uploading")) {
                return Promise.reject("请等待视频上传完成");
            }
            if(list.find(item => item.status === "error")) {
                return Promise.reject("请先处理测试上传失败的视频");
            }
            if(list.find(item => item.size > 5 * 1024 * 1024)) {
                return Promise.reject("请选择小于 5M 的视频文件");
            }
        }
        return Promise.resolve();
    }

    const handleVideoPreview = (file) => {
        setPreviewVideo(file.url || file.originUrl);
        setPreviewVideoVisible(true);
    }

    const handleVideoChange = (file) => {
        let fileList = file.fileList;
        file = file.file;
        if(file.status === "removed") {
            let index = imageFile.findIndex(item => item.uid == file.uid);
            let fileList = JSON.parse(JSON.stringify(imageFile));
            fileList.splice(index, 1)
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = (e) => { 
                fileList[fileList.length - 1].originUrl = e.target.result; 
                fileList[fileList.length - 1].imgStatus = "NEW";
            }
        }
        setVideoFile(fileList);
    }

    //分页 页码切换变化查询
    const onChange = (currentNew, pageSizeNew) => {
        search(pageSizeNew, currentNew, "Y");
    }
    //分页 每页條數变化查询
    const onShowSizeChange = (currentNew, pageSizeNew) => {
        search(pageSizeNew, 1, "Y");
    }

    //列表选中记录
    const onSelectChange = (selectedRowKeys, selectedRows) => {
        setSelectedRowKeys(selectedRowKeys);
        setCheckboxState(selectedRows);
    }
    //清空列表选中
    const clearSelect = () => {
        setSelectedRowKeys([]);
        setCheckboxState([]);
    }

    const errFrameShow = (style, msg) => {
        setMessage(msg);
        setMessageStyle(style);
        setMessageShow(true);
        document.documentElement.scrollTop = 0;
    }

    const messageShowHandler = () => {
        setMessage("");
        setMessageShow(false);
    }

    /*eslint-disable*/
    const columns = [
        {
            title: '房间图片',
            dataIndex: 'aptRoomDefaultImg',
            render: (text) => <img className="apartmentTableImg" alt='' src={Config.aptRoomPath + text}/>
        },
        {
            title: '房间名称',
            dataIndex: 'aptRoomTitle',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'aptRoomTitle') },
        },
        {
            title: '租金',
            dataIndex: 'rentalPrice',
            className: 'tableRightAlignment',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'rentalPrice') },
            render: (text) => Base.numberFormat(text, 2)
        },
        {
            title: '房间标签',
            dataIndex: 'aptRoomTag',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'aptRoomTag') },
        },
        {
            title: '状态',
            dataIndex: 'aptRoomStatus',
            sorter: (a, b) => { return Base.sortMethod(a, b, 'aptRoomStatus') },
            render: (text, record) => <Switch checkedChildren="已上架" unCheckedChildren="未上架" checked={text === 'Y'} onChange={(e) => showTips(e, record)} />
        },
        {
            title: '操作',
            dataIndex: '',
            className: 'tableWidth14 tableRightAlignment',
            render: (text, record) => <div className='sysOperation proBrandEstateOperation'>
                <div className='sysUpdate' onClick={(e) => update(record ,e)}><EditOutlined /> 修改</div>
                <div className='sysDelete' onClick={(e) => deleteOne(record,e)}><DeleteOutlined /> 删除</div>
            </div>
        },
    ];
    /*eslint-enable*/

    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
        type: 'radio',
    }

    const uploadVideoButton = (
        <div>
            <Button icon={<PlusOutlined />}>添加房间视频</Button>
        </div>
    );

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div>添加房间图片</div>
        </div>
    );

    return(
        <div id="container" className="template aptRoom">
            <div className='breadcrumb'>
                <HomeOutlined /> /  其他 / <span className='breadcrumbTitle'>品牌公寓房间</span>
            </div>
            {messageShow ? <Message style={messageStyle} content={message} close={messageShowHandler}/> : ''}
            <div className='templateMain'>
                <div className="sysModelTitle">品牌公寓房间</div>
                <Spin spinning={isLoad} size="large" tip={spinInfo}>
                    {/* 删除弹框 */}
                    {isPopupFrame && <PopupFrame close={popupFrameClose} type={type} content={content} />}
                    {/* 审核彈框 */}
                    <Audit visible={isAudit} auditModel={auditModel} close={auditCancel} />
                    <Tabs activeKey={tabIndex} onChange={(e) => handleSelect(e)}>
                        <TabPane tab="列表" key="1">
                            <div className='sysRow'>
                                <div className='sysCol'>
                                    <div className="sysTitle">房间名称</div>
                                    <div className="sysSerchInput">
                                        <Input type="text" placeholder="房间名称" maxLength={20} value={searchModel.aptRoomTitle}
                                            onChange={(e) => searchChange("aptRoomTitle", e.target.value)}
                                            onKeyDown={(e) => e.key === "Enter" ? search(pageSize, 1, "N", true) : null} />
                                    </div>
                                </div>
                                {/* <div className='sysCol'>
                                    <div className="sysTitle">品牌公寓类型</div>
                                    <div className="sysSerchInput">
                                        <Select className="sysSelect" value={searchModel.apartmentType || ''} onChange={(value) => searchChange('apartmentType', value)}>
                                            <Option value=""></Option>
                                            {context.apartmentTypeMap.map((item) => <Option key={item.value} value={item.value}>{item.label}</Option>)}
                                        </Select>
                                    </div>
                                </div> */}
                                <div className='sysCol'>
                                    <div className="sysTitle">状态</div>
                                    <div className="sysSerchInput">
                                        <Select className="sysSelect" value={searchModel.aptRoomStatus || ''} onChange={(value) => searchChange('aptRoomStatus', value)}>
                                            <Option value="">全部</Option>
                                            {context.productStatusMap.map(item => <Option key={item.value} value={item.value}>{item.label}</Option>)}
                                        </Select>
                                    </div>
                                </div>
                                <div className='sysSearchButton'>
                                    <Button className="sysBtn btnBule" type="primary" onClick={() => search(pageSize, 1, "N", true)} hidden={Config.funcInfo.canSelect === "N"} icon={<SearchOutlined />}>查询</Button>
                                    <Button className="sysBtn" onClick={reset} icon={<RedoOutlined />}>重置</Button>
                                </div>
                            </div>
                            <div className="sysLineEight"></div>
                            <Space size={[5, 5]} wrap className="sysButtonRelative">
                                <Button className="sysBtn btnBule" type="primary" onClick={add} hidden={Config.funcInfo.canInsert === "N"} icon={<PlusCircleOutlined />}>新增</Button>
                                {tables.length == 0 ? null :
                                    <>
                                        <Button className="sysBtn btnBule" type="primary" onClick={audit} hidden={Config.funcInfo.canAudit === "N"} icon={<EyeOutlined />}>日志</Button>
                                    </>
                                }
                            </Space>
                            <div className="sysTable" id="tableOne">
                                <Table
                                    rowSelection={rowSelection} //选择
                                    rowKey="aptRoomId"
                                    columns={columns} //列名
                                    dataSource={tables} //数据
                                    pagination={{  //分页
                                        showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                        onChange: onChange,                        //上页下页或者跳页方法
                                        onShowSizeChange: onShowSizeChange,        //每页显示多少条数据方法
                                        total: tablesCount,             //总共多少条数据
                                        current: current,                //当前页
                                        pageSize: pageSize,              //每页显示多少条数据
                                        defaultCurrent: 1,                               //默认当前页为1
                                        size:"small"
                                    }}
                                    onRow={(record) => {
                                        return {
                                            onClick: event => Base.selectRadioRowNew.call(this, record, 'aptRoomId', selectedRowKeys, setCheckboxState, setSelectedRowKeys),
                                        };
                                    }}
                                />
                            </div>
                        </TabPane>
                        <TabPane tab="品牌公寓房间" key="2" forceRender={true}>
                            <Form form={form} onFinish={onFinish}>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">房间名称</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="aptRoomTitle"
                                                rules={[
                                                    { required: true, message: '房间名称不能为空' },
                                                ]}
                                            >
                                                <Input type="text" placeholder="房间名称" maxLength={50}/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">品牌公寓</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="apartmentId"
                                                rules={[
                                                    { required: true, message: '品牌公寓不能为空' },
                                                ]}
                                            >
                                                <Select className="sysSelect">
                                                    <Option value=""></Option>
                                                    {apartmentList.map(item => <Option key={item.apartmentId} value={item.apartmentId}>{item.apartmentTitle}</Option>)}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">房间标签</div>
                                        <div className="sysInputs">
                                            <Form.Item
                                                name="aptRoomTag"
                                                rules={[
                                                    { required: true, message: '房间标签不能为空' },
                                                ]}
                                            >
                                                <Checkbox.Group options={checkList}  />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam">附近学校</div>
                                        <div className="sysInputs apartmentTreeDiv">
                                            <div className='treeDiv'>
                                                {(Base.isNotEmpty(nearbySchoolsList) && nearbySchoolsList.length > 0)?
                                                    nearbySchoolsList.map((item) => 
                                                        <Tooltip title={item.parmValue1} overlayClassName="lawTip" key={item.code}>
                                                            <span className='lawArticleItem' key={item.code} value={item.code}>
                                                                {item.parmValue1}
                                                                <CloseCircleOutlined className='closeImg' onClick={()=> deleteSchool(item)} />
                                                            </span>
                                                        </Tooltip>
                                                    )
                                                    :null
                                                }
                                            </div>
                                            <Button className="buttonYellowTwoStyle" type="primary" onClick={() => {setIsSchoolModal(true)}}>选择学校</Button>
                                        </div>
                                    </div>
                                </div> */}
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">租金</div>
                                        <div className="sysInput">
                                            <Form.Item
                                                name="rentalPrice"
                                                rules={[
                                                    { required: true, validator: (rule, value) => Base.checkNbrOrNull(value, null, 99999999.99) }
                                                ]}
                                            >
                                                <InputNumber className='sysInputNumber' placeholder="租金" precision={2} maxLength={11} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam">房间视频</div>
                                        <div className="sysInputs">
                                            <Form.Item 
                                                name="aptRoomVideo"
                                                rules={[
                                                    { required: true, validator: (rule, value) => checkVideo(value) }
                                                ]}
                                            >
                                                <Upload
                                                    action={Config.uploadTestServer}
                                                    key={"2"}
                                                    className="videoUpload"
                                                    listType="picture"
                                                    maxCount={1}
                                                    fileList={videoFile}
                                                    onPreview={handleVideoPreview}
                                                    onChange={(file) => handleVideoChange(file)}>
                                                    {videoFile.length >= 1 ? null : uploadVideoButton }
                                                </Upload>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">房间图片</div>
                                        <div className="sysInputs">
                                            <Form.Item 
                                                name="img"
                                                rules={[
                                                    { required: true, validator: (rule, value) => checkImg(value) }
                                                ]}
                                            >
                                                <Upload
                                                    action={Config.uploadTestServer} 
                                                    key={"1"}
                                                    accept=".png, .jpg, .jpeg"
                                                    listType="picture-card"
                                                    fileList={imageFile}
                                                    onPreview={handlePreview}
                                                    onChange={(file) => handleChange(file)}>
                                                    {uploadButton}
                                                </Upload>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam">房间描述</div>
                                        <div className="sysInputs">
                                            <div id="editor" className="editor"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">家具设施</div>
                                        <div className="sysInputs">
                                            <Form.Item
                                                    name="furnitureFacilities"
                                                    rules={[
                                                        { required: true, message: '家具设施不能为空' },
                                                    ]}
                                                >
                                                <Checkbox.Group options={tagList}  />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">电器设施</div>
                                        <div className="sysInputs">
                                            <Form.Item
                                                    name="electricalFacilities"
                                                    rules={[
                                                        { required: true, message: '电器设施不能为空' },
                                                    ]}
                                                >
                                                <Checkbox.Group options={tagListTwo}  />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">厨房设施</div>
                                        <div className="sysInputs">
                                            <Form.Item
                                                    name="kitchenFacilities"
                                                    rules={[
                                                        { required: true, message: '厨房设施不能为空' },
                                                    ]}
                                                >
                                                <Checkbox.Group options={tagListThree}  />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">费用明细</div>
                                        <div className="sysInputs">
                                            <Form.Item
                                                    name="expenseDetails"
                                                    rules={[
                                                        { required: true, message: '费用明细不能为空' },
                                                    ]}
                                                >
                                                <Checkbox.Group options={costList}  />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className='sysRow'>
                                    <div className='sysCol'>
                                        <div className="sysParam mustFill">服务</div>
                                        <div className="sysInputs">
                                            <Form.Item
                                                    name="aptRoomServices"
                                                    rules={[
                                                        { required: true, message: '服务不能为空' },
                                                    ]}
                                                >
                                                <Checkbox.Group options={serviceList}  />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className="sysSaveButton">
                                    <Button className="sysBtn btnBule" type="primary" disabled={isLoad} htmlType="submit" icon={<CheckCircleOutlined />}>保存</Button>
                                    <Button className="sysBtn" onClick={cancel} icon={<CloseCircleOutlined />}>取消</Button>
                                </div>
                            </Form>
                            <Modal title="放大图" visible={previewVisible} footer={null} onCancel={() => setPreviewVisible(false)}>
                                <img alt="example" style={{ width: '100%' }} src={previewImage} />
                            </Modal>
                            {previewVideoVisible ?
                                <Modal title="视频预览" visible={true} footer={null} onCancel={() => setPreviewVideoVisible(false)}>
                                    <ReactPlayer 
                                        className="reactPlayer" 
                                        key={"1"}
                                        url={previewVideo}
                                        playing={true}
                                        width='100%'
                                        height="100%"
                                        controls
                                    ></ReactPlayer>
                                </Modal>
                                :null
                            }
                        </TabPane>
                    </Tabs>
                </Spin>
            </div>
        </div>
    )

}

export default AptRoom;