import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';

import { ConfigProvider } from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import 'moment/locale/zh-cn';
import 'antd/dist/antd.css';//antd样式导入

import Main from './system/main/main';
import Page404 from './system/404/404';
import Login from './system/login/login';

import { Config } from './public/js/config';
import { Base } from './public/js/base';

import './index.css';

export default class Root extends Component {

  constructor() {
    super();

    // 獲取訪問設備信息
    this.getEquipmentInfo();
    Config.equipmentInfo.type = Base.getEquipmentType();
  }

  /**访问设备equipmentInfo */
  getEquipmentInfo() {
    if (Base.cgIsEnd()) {
      /**移动端 */
      Config.equipmentInfo.name = Base.cgIsPhone();
    } else {
      /**pc端 */
      Config.equipmentInfo.name = Base.cgIsView();
    }
    /**微信 */
    if (Base.cgIsWeixn()) {
      Config.equipmentInfo.isWx = true;
    } else {
      Config.equipmentInfo.isWx = false;
    }
  }

  render() {

    // const routePathMain = props => {
    //   let obj = Object.assign({}, {}, props)
    //   return <Main {...obj} />
    // }

    return (
      <ConfigProvider locale={zh_CN}>
        <Router>
          <Switch>
            <Route key='/' path="/" exact component={Login} />
            <Route key='login' path="/login" component={Login} />
            <Route key='main' path="/main" component={Main} />
            <Route component={Page404} />
          </Switch>
        </Router>
      </ConfigProvider>
    );
  }

}

ReactDOM.render(<Root />, document.getElementById('root'));

serviceWorker.unregister();
